/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import data from '../data.json';

type Props = {
  next: () => void;
  x: number;
  setX: any;
};

const gender = {
  male: 'Male',
  female: 'Female',
};

const NextOfKin: FC<Props> = ({ next, x, setX }) => {
  const { loansStore } = React.useContext(StoreContext);
  const { sending, success, loanUserdetails } = loansStore;

  const [nOKDetails, setNOKDetails] = React.useState({
    firstName: '',
    lastName: '',
    gender: '',
    relationship: '',
    email: '',
    phoneNumber: '',
    Address: '',
  });

  React.useEffect(() => {
    setNOKDetails({
      firstName:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.firstName != undefined
          ? loanUserdetails?.nextOfKinDetails?.firstName
          : '',
      lastName:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.lastName != undefined
          ? loanUserdetails?.nextOfKinDetails?.lastName
          : '',
      gender:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.gender != undefined
          ? loanUserdetails?.nextOfKinDetails?.gender
          : '',
      relationship:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.relationship != undefined
          ? loanUserdetails?.nextOfKinDetails?.relationship
          : '',
      email:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.email != undefined
          ? loanUserdetails?.nextOfKinDetails?.email
          : '',
      phoneNumber:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.phoneNumber != undefined
          ? loanUserdetails?.nextOfKinDetails?.phoneNumber
          : '',
      Address:
        loanUserdetails?.nextOfKinDetails &&
        loanUserdetails?.nextOfKinDetails?.Address != undefined
          ? loanUserdetails?.nextOfKinDetails?.Address
          : '',
    });
  }, [loanUserdetails?.nextOfKinDetails]);

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const [phoneNumber, setPhoneNumber] = React.useState('');
  React.useEffect(() => {
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, phoneNumber };
    });
  }, [phoneNumber]);

  const handleCreateNOK = (e: React.FormEvent) => {
    e.preventDefault();

    if (Object.values(nOKDetails).filter((d) => d === '').length === 0) {
      const cleanData = {
        firstName: nOKDetails.firstName.trim(),
        lastName: nOKDetails.lastName.trim(),
        gender: nOKDetails.gender.trim(),
        relationship: nOKDetails.relationship.trim(),
        email: nOKDetails.email.trim(),
        phoneNumber: nOKDetails.phoneNumber.trim(),
        Address: nOKDetails.Address,
      };

      loansStore.createNextOfKin(cleanData);
      return;
    }

    if (
      nOKDetails?.phoneNumber !== '' &&
      nOKDetails?.phoneNumber != undefined
    ) {
      if (isPossiblePhoneNumber(nOKDetails?.phoneNumber) !== true) {
        toast.error('Phone number fields are empty!');
      }
    } else {
      toast.error('Phone number fields are empty!');
    }
    const inputs = document.querySelectorAll('.form-control');

    // if (message.msg === 'Some fields are empty!') {
    inputs.forEach((input: any) => {
      if (
        input.value == '' ||
        input.value == 'NULL' ||
        nOKDetails[input.name] === '' ||
        nOKDetails[input.name] === 'NULL'
      ) {
        toast.error('Some fields are empty!');
        input.style.border = '2px solid #ff8989';
        input.focus();
        setTimeout(() => {
          input.style.border = '1px solid #ced4da';
        }, 30000);
      }
    });
    // }
  };

  React.useEffect(() => {
    if (success === 'Next of kin details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Next of kin details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className="">
      <div className="update-arm-form">
        <h4>NEXT OF KIN DETAILS</h4>
        <p>
          Industry regulation requires us to collect this information to begin
          investment.
        </p>

        <form action="" onSubmit={handleCreateNOK}>
          <div className="row">
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              <div className="col-md-12 input-box">
                <label htmlFor="firstName" className="mb-1 mt-3">
                  First Name
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="firstName"
                  classes="form-control"
                  type="text"
                  value={nOKDetails?.firstName}
                  id="firstName"
                  onChange={handleInputChange}
                  required
                  disabled={sending}
                />
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="gender" className="mb-1 mt-3">
                  Gender
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <select
                  name="gender"
                  id="gender"
                  onChange={handleInputChange}
                  className="form-control"
                  value={nOKDetails?.gender}
                  required
                  disabled={sending}>
                  <option value="">Select Gender</option>
                  <option value={gender.female}>{gender.female}</option>
                  <option value={gender.male}>{gender.male}</option>
                </select>
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="relationship" className="mb-1 mt-3">
                  Email Address
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="email"
                  classes="form-control"
                  type="email"
                  id="email"
                  value={nOKDetails?.email}
                  onChange={handleInputChange}
                  required
                  disabled={sending}
                />
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="Address" className="mb-1 mt-3">
                  Address
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="Address"
                  classes="form-control"
                  type="text"
                  id="Address"
                  value={nOKDetails?.Address}
                  onChange={handleInputChange}
                  required
                  disabled={sending}
                />
              </div>
            </div>
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              <div className="col-md-12 input-box">
                <label htmlFor="lastName" className="mb-1 mt-3">
                  Last Name
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="lastName"
                  classes="form-control"
                  type="text"
                  value={nOKDetails?.lastName}
                  id="lastName"
                  onChange={handleInputChange}
                  required
                  disabled={sending}
                />
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="relationship" className="mb-1 mt-3">
                  Next of kin’s Relationship
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <select
                  name="relationship"
                  className="form-control"
                  id="relationship"
                  onChange={handleInputChange}
                  required={true}
                  disabled={sending}
                  value={nOKDetails?.relationship}>
                  <option value="">Select option</option>
                  {data.relationshipTypes.map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="phoneNumber" className="mb-1 mt-3">
                  Mobile number
                  <span style={{ color: 'red' }}> *</span>
                </label>

                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="NG"
                  onChange={(e: string) => {
                    setPhoneNumber(e);
                  }}
                  className="form-control"
                  id="phoneNumber"
                  required
                  value={loanUserdetails?.nextOfKinDetails?.phoneNumber}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>

          <div className="profile-btn" style={{ width: '50%' }}>
            <Button
              classes="primary-btn"
              disabled={sending}
              content={
                sending || sending ? 'Submitting...' : 'Save and Continue'
              }
              type="submit"
              onClick={() => {
                setX(1000);
              }}
            />
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default observer(NextOfKin);
