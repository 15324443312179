import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import BlueCard from './sections/BlueCard';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Newsletter from './sections/Newsletter';

const AssetFinancing = () => {
  const [assetFinanceReadMore, setReadMore] = useState(false);
  const clickAssetFinaceReadMore = () => {
    setReadMore((prev) => !prev);
  };
  return (
    <>
      <Helmet>
        <title>Asset Finance | Trade Lenda Landing Page!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="features landing-page-hero">
        <div className="container">
          <div>
            <div className="mb-5 group">
              <div className="asset-financing-div p-5">
                <div
                  className="col-lg-6"
                  style={{
                    position: 'relative',
                    top: '0',
                    left: '0',
                    width: '100%',
                    maxWidth: '500px',
                    marginTop: '20px',
                    marginBottom: '30px',
                    // margin: '15px',
                  }}>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/asset_financing_windmill.svg`}
                    alt="Trade Lenda"
                    style={{
                      position: 'absolute',
                      top: '0',
                      // left: '0',
                      // backgroundSize: 'contain',
                      left: '0',
                      zIndex: '1',
                      width: '95%',
                      height: 'auto',
                      maxHeight: '400px',
                      maxWidth: '500px',
                      // objectFit: 'contain',
                    }}
                  />
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/asset_financing_solar.svg`}
                    alt="Trade Lenda"
                    style={{
                      position: 'relative',
                      marginTop: '45%',
                      marginLeft: '10%',
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      maxWidth: '600px',
                      // backgroundSize: 'contain',
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <div
                        style={{ textAlign: 'justify', wordSpacing: '-1px' }}>
                        <h1 className="mb-3" style={{ color: '#06A77D' }}>
                          Asset Financing
                        </h1>
                        <h3>
                          Welcome to our Asset Financing <br /> services!
                        </h3>
                        <p>
                          At Trade Lenda, we understand that securing the right
                          assets is crucial for your business's growth and
                          success. Our comprehensive asset financing solutions
                          are designed to help you obtain the equipment and
                          resources you need while maintaining your financial
                          stability.
                        </p>
                        {assetFinanceReadMore && (
                          <>
                            <div className="d-flex align-items-start gap-3 mb-3">
                              <p>Explore the possibilities with us today!</p>
                            </div>
                            <div>
                              <h3>Our Asset Financing Services</h3>
                            </div>
                            <div className="mt-3">
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Flexible Options: We provide a range of
                                  financing options, including asset-based
                                  loans. Choose the solution that best suits
                                  your businss goals and financial preferences.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Customized Terms: Our team of experts will
                                  work with you to create financing terms that
                                  align with your cash flow and budget
                                  requirements, ensuring a comfortable repayment
                                  schedule.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Competitive Rates: Benefit from competitive
                                  interest rates and fees that make our asset
                                  financing solutions not only smart but
                                  cost-effective.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Fast Approval: We understand that time is of
                                  the essence in business. Our streamlined
                                  approval process ensures that you get the
                                  financing you need promptly.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Diverse Asset Types: Whether you need to
                                  finance solar-powered appliances, mobile
                                  devices, or any other essential asset, we've
                                  got you covered. Why Choose Trade Lenda for
                                  Asset Financing?
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Expertise: With our extensive experience in
                                  asset financing, you can trust that you're
                                  working with a team of professionals who know
                                  the industry inside and out.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Tailored Solutions: We don't believe in
                                  one-size-fits-all. Our solutions are tailored
                                  to your specific needs, ensuring that you get
                                  exactly what you require.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Customer-Centric Approach: We put your
                                  interests at the forefront, aiming to provide
                                  the most convenient and beneficial financing
                                  experience.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <p>
                                  Financial Stability: Rest assured that your
                                  financing is backed by a financially stable
                                  and reputable institution.
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-lg btn-outline-primary mt-3"
                          style={{ width: '113px', marginRight: '20px' }}
                          onClick={clickAssetFinaceReadMore}>
                          {assetFinanceReadMore ? 'less' : 'Read more'}
                        </button>
                        <Button
                          classes="primary-btn btn-lg mt-3"
                          content="Get Financing"
                          route="/sign_up"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlueCard />
      <GetStarted />
      <Newsletter />
      <Footer />
    </>
  );
};

export default AssetFinancing;
