import { mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';
import { FC } from 'react';

type Props = {
  close: () => void;
};

const Media: FC<Props> = ({ close }) => {
  return (
    <section className="media-modal">
      <span role="button" className="close" onClick={close}>
        <Icon path={mdiWindowClose} size={1.5} />
      </span>
      <div className="media-modal-container">
        <iframe
          width="100%"
          height="315"
          // src="https://www.youtube.com/embed/nUUWKhz92iI?start=1"
          src="https://www.youtube.com/embed/pMyVLLEqOkk?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </section>
  );
};

export default Media;
