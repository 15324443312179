import { mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

type Props = {
  state: boolean;
};

const MediaVideo: FC<Props> = ({ state }) => {
  const [watchVideo, setWatchVideo] = useState(state);
  const navigate = useNavigate();

  const closeMondal = () => {
    setWatchVideo(false);
    navigate('/');
  };

  return (
    <>
      {watchVideo && (
        <section className="media-modal">
          <span role="button" className="close" onClick={closeMondal}>
            <Icon path={mdiWindowClose} size={1.5} />
          </span>
          <div className="media-modal-container">
            <iframe
              width="100%"
              height="315"
              src="https://drive.google.com/file/d/17AVq0izyrMulwt2xX3z7lVJNT-jNd8dt/preview"
              title="Introduction Video"
              frameBorder="0"
              allow="autoplay"
              allowFullScreen></iframe>
          </div>
        </section>
      )}
    </>
  );
};

export default MediaVideo;
