/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  mdiEye,
  mdiEyeOff,
  mdiLockOpenCheck,
} from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import { FormEvent, useContext, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordToggleValues, setPasswordToggleValues] = useState({
    showPassword: '',
  });

  //Profile store context
  const { authStore } = useContext(StoreContext);

  const { submitting, message } = authStore;

  const handlePasswordChange = (e: FormEvent) => {
    e.preventDefault();

    authStore.changePassword(currentPassword, newPassword, confirmPassword);
  };

  useEffect(() => {
    if (message.type === 'success') {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  }, [message.type]);

  const handleClickShowPassword = (fieldName: string) => {
    setPasswordToggleValues({
      ...passwordToggleValues,
      showPassword:
        fieldName === passwordToggleValues.showPassword ? '' : fieldName,
    });
  };

  return (
    <div className="security">
      {submitting && <Spinner />}

      <div className="blue-line"></div>
      <form className="form-box px-4" onSubmit={handlePasswordChange}>
        <div className="row justify-content-center mt-4">
          <div className="col-lg-7 col-md-10">
            <div className="text-center">
              <Icon
                path={mdiLockOpenCheck}
                size={3}
                color="#06A77D"
                className=""
              />
            </div>

            <h3>Security</h3>
            <div className="col-md-12 input-box mb-3">
              <label htmlFor="currentPassword" className="mb-1">
                Current password
              </label>
              <Input
                name="currentPassword"
                value={currentPassword}
                classes="form-control py-2"
                type={
                  passwordToggleValues.showPassword === 'currentPassword'
                    ? 'text'
                    : 'password'
                }
                id="currentPassword"
                placeholder="Current password"
                onChange={(e: any) => setCurrentPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={() => handleClickShowPassword('currentPassword')}>
                <Icon
                  path={
                    passwordToggleValues.showPassword === 'currentPassword'
                      ? mdiEyeOff
                      : mdiEye
                  }
                  vertical
                  size={1}
                />
              </span>
            </div>
            <div className="col-md-12 input-box mb-3">
              <label htmlFor="newPassword" className="mb-1">
                New password
              </label>
              <Input
                name="newPassword"
                value={newPassword}
                classes="form-control py-2"
                type={
                  passwordToggleValues.showPassword === 'newPassword'
                    ? 'text'
                    : 'password'
                }
                id="newPassword"
                placeholder="New password"
                onChange={(e: any) => setNewPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={() => handleClickShowPassword('newPassword')}>
                <Icon
                  path={
                    passwordToggleValues.showPassword === 'newPassword'
                      ? mdiEyeOff
                      : mdiEye
                  }
                  vertical
                  size={1}
                />
              </span>
            </div>
            <div className="col-md-12 input-box mb-3">
              <label htmlFor="confirmPassword" className="mb-1">
                Confirm password
              </label>
              <Input
                name="confirmPassword"
                value={confirmPassword}
                classes="form-control py-2"
                type={
                  passwordToggleValues.showPassword === 'confirmPassword'
                    ? 'text'
                    : 'password'
                }
                id="confirmPassword"
                placeholder="Confirm password"
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={() => handleClickShowPassword('confirmPassword')}>
                <Icon
                  path={
                    passwordToggleValues.showPassword === 'confirmPassword'
                      ? mdiEyeOff
                      : mdiEye
                  }
                  vertical
                  size={1}
                />
              </span>
            </div>
          </div>

          <div className="row justify-content-center text-center mt-2">
            <div className="col-lg-6 col-md-8">
              <div className="profile-btn">
                <Button
                  classes="primary-btn btn-md px-4 py-2"
                  content="Save changes"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(Security);
