import React from 'react';
import { Outlet } from 'react-router';

const Bookkeeping = () => {
  return (
    <div className="bookkeeping">
      <Outlet />
    </div>
  );
};

export default Bookkeeping;
