import {
  mdiAccountBox,
  mdiBellCog,
  mdiChevronRight,
  mdiDeleteForever,
  mdiLock,
  mdiSecurity,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const Settings = () => {
  const [navActive, setNavActive] = useState(false);

  return (
    <div className="settings">
      <h1 className="section-head">Settings</h1>
      <div className={navActive ? 'settings-nav nav-active' : 'settings-nav'}>
        <ul>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'settings-nav-link settings-nav-link-active bg-green'
                  : 'settings-nav-link'
              }
              to="account_settings"
              onClick={() => setNavActive(false)}>
              <Icon path={mdiAccountBox} size={1} />
              Account Profile
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'settings-nav-link settings-nav-link-active'
                  : 'settings-nav-link'
              }
              to="security"
              onClick={() => setNavActive(false)}>
              <Icon path={mdiSecurity} size={1} />
              Security
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'settings-nav-link settings-nav-link-active'
                  : 'settings-nav-link'
              }
              to="create-pin"
              onClick={() => setNavActive(false)}>
              <Icon path={mdiLock} size={1} />
              PIN
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'settings-nav-link settings-nav-link-active'
                  : 'settings-nav-link'
              }
              to="notification_settings"
              onClick={() => setNavActive(false)}>
              <Icon path={mdiBellCog} size={1} />
              Notification settings
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'settings-nav-link settings-nav-link-active'
                  : 'settings-nav-link'
              }
              to="delete_account"
              onClick={() => setNavActive(false)}>
              <Icon path={mdiDeleteForever} size={1} />
              Delete Account
            </NavLink>
          </li>
        </ul>
        <button
          className={
            navActive
              ? 'settings-nav-toggle nav-toggle-active'
              : 'settings-nav-toggle'
          }
          type="button"
          onClick={() => setNavActive(!navActive)}>
          <Icon path={mdiChevronRight} size={1} />
        </button>
      </div>
      <div className="settings-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
