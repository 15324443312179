/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/no-empty-interface */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiEyeSettingsOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import { StoreContext } from '../../../mobx_stores/RootStore';

interface ILoansCardProps {}

const LoansCard: React.FunctionComponent<ILoansCardProps> = () => {
  const [allLoans, setAllLoans] = useState<any>([]);
  const [approvedLoans, setApprovedLoans] = useState<any>([]);
  const [paidLoans, setPaidLoans] = useState<any>([]);
  const [pendingLoans, setPendingLoans] = useState<any>([]);

  const { loansStore } = useContext(StoreContext);
  const { loanUserdetails } = loansStore;

  // New M starts
  const [loanRoute, setLoanRoute] = useState<any>([]);

  useEffect(() => {
    setLoanRoute(toJS(loansStore.loanUserdetails));
  }, [loansStore.allLoanAmounts]);
  // New M ends

  useEffect(() => {
    loansStore.getAllLoans();
    loansStore.getApprovedLoans();
    loansStore.getPaidLoans();
    loansStore.getPendingLoans();
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  useEffect(() => {
    setAllLoans(toJS(loansStore.loans));
    setApprovedLoans(toJS(loansStore.approvedLoans));
    setPaidLoans(toJS(loansStore.paidLoans));
    setPendingLoans(toJS(loansStore.pendingLoans));
  }, [
    loansStore.loans,
    loansStore.paidLoans,
    loansStore.approvedLoans,
    loansStore.pendingLoans,
  ]);

  const { loading } = loansStore;

  const handleLoanDetailId = (loanIddd: string) => {
    loansStore.setLoanDetailId(loanIddd);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [headerText, setHeaderText] = React.useState('');

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  function activeHeader(head: any) {
    setHeaderText(head.target.innerHTML);
    setIsOpen(!isOpen);
  }

  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 767 ? false : true);

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screen]);

  return (
    <div className="loans-card-container">
      {loading ? (
        <div>
          <div className="bg-white guarantor-list">
            <div className="d-flex m-0 pt-4 guarantor-heade ">
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
            </div>

            <div className="bg-white table-responsive">
              <table className="table table-striped responsive-table mb-0">
                <thead>
                  <tr>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th className="mobile-tr">
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td className="mobile-tr">
                      <Skeleton width={150} height={25} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td className="mobile-tr">
                      <Skeleton width={150} height={25} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="navigation">
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <div className="guarantor-list">
          {!isOpen ? (
            <FontAwesomeIcon
              icon={faChevronDown}
              onClick={toggleMenu}
              className="mobile-menu-icon"
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronUp}
              onClick={toggleMenu}
              className="mobile-menu-icon"
            />
          )}

          <Tabs selectedTabClassName="selected">
            {mobile ? (
              <>
                <TabList
                  className={
                    'd-flex flex-column flex-md-row m-0 p-0 guarantor-heade automatic'
                  }>
                  {!isOpen && (
                    <div className="guarantors-header-link">
                      <h5>
                        {headerText === ''
                          ? `All ${
                              loanUserdetails?.organizationDetails?.shariaCom
                                ? 'Facility'
                                : 'Loan'
                            } Applications`
                          : headerText}
                      </h5>
                    </div>
                  )}

                  <div className={isOpen ? '' : 'hidden-header'}>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}>
                      <h5>
                        All{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Facility'
                          : 'Loan'}{' '}
                        Applications
                      </h5>
                    </Tab>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}>
                      <h5>
                        Approved{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Facility'
                          : 'Loans'}
                      </h5>
                    </Tab>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}>
                      <h5>
                        Pending{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Facility'
                          : 'Loans'}
                      </h5>
                    </Tab>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}>
                      <h5>
                        Paid{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Facility'
                          : 'Loans'}
                      </h5>
                    </Tab>
                  </div>
                </TabList>
              </>
            ) : (
              <TabList className={'d-flex m-0 p-0 guarantor-heade automatic'}>
                <Tab className="guarantors-header-link">
                  <h5>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loan'}{' '}
                    Applications
                  </h5>
                </Tab>
                <Tab className="guarantors-header-link">
                  <h5>
                    Active{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loans'}
                  </h5>
                </Tab>
                <Tab className="guarantors-header-link">
                  <h5>
                    Paid{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loans'}
                  </h5>
                </Tab>
                <Tab className="guarantors-header-link">
                  <h5>
                    Unpaid{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loans'}
                  </h5>
                </Tab>
              </TabList>
            )}

            <TabPanel>
              {allLoans.length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/no-loans.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>
                    You do not have a{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'loan'}{' '}
                    yet!{' '}
                  </p>
                  <Button
                    content={`Request For  ${
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? 'Facility'
                        : 'Loans'
                    }`}
                    title="Request For Loan"
                    classes="primary-btn btn-lg add-btn"
                    route={
                      loanRoute.loanDocumentDetails === undefined
                        ? loanUserdetails?.organizationDetails?.shariaCom
                          ? '/dashboard/facility/user_personal_details'
                          : '/dashboard/loans/user_personal_details'
                        : loanUserdetails?.organizationDetails?.shariaCom
                          ? '/dashboard/facility/request_for_facility'
                          : '/dashboard/loans/request_for_loan'
                    }
                  />
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}>
                  <table className="table table-striped responsive-table mb-0">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th>Amount(₦)</th>
                        <th className="mobile-tr">
                          {' '}
                          {loanUserdetails?.organizationDetails?.shariaCom
                            ? 'Facility'
                            : 'Loans'}{' '}
                          tenor
                        </th>
                        <th className="mobile-tr">
                          {toJS(loanUserdetails) &&
                          toJS(loanUserdetails)?.organizationDetails?.shariaCom
                            ? 'Mark-up'
                            : 'Interest'}
                        </th>
                        <th className="mobile-tr">Status</th>
                        <th className="mobile-tr"></th>
                        <th className="mobile-th"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allLoans.map((loan: any, i: number) => (
                        <tr key={i}>
                          <td>{loan.reason}</td>
                          <td>{loan.amount.toLocaleString()}</td>
                          <td className="mobile-tr">{loan.loanTenor}</td>
                          <td className="mobile-tr">
                            {loan.interest === undefined ||
                            loan.loanType === 'Musharakah'
                              ? '0'
                              : Math.round(loan.interest * 100) / 100}
                            %
                          </td>
                          <td
                            className={`${
                              loan.status === 'paid'
                                ? loan.status
                                : loan.approvalStatus
                            } mobile-tr`}>
                            {loan.status === 'paid'
                              ? loan.status.toUpperCase()
                              : loan.approvalStatus.toUpperCase()}
                          </td>
                          <td className="mobile-tr">
                            <Link
                              to={
                                loanUserdetails?.organizationDetails?.shariaCom
                                  ? '/dashboard/facility/facility_details'
                                  : '/dashboard/loans/loan_details'
                              }
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Button
                                content="View more"
                                classes="secondary-btn btn-lg bg-white"
                              />
                            </Link>
                          </td>
                          <td className="mobile-th">
                            <Link
                              to="/dashboard/loans/loan_details"
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Icon
                                path={mdiEyeSettingsOutline}
                                className={`${
                                  loan.status === 'paid'
                                    ? loan.status
                                    : loan.approvalStatus
                                }`}
                                style={{ width: '1.5rem' }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {approvedLoans.length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/no-loans.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>
                    You do not have an approved{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    yet!{' '}
                  </p>
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}>
                  <table className="table table-striped responsive-table mb-0">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th> Amount(₦) </th>
                        <th className="mobile-tr">
                          {' '}
                          {loanUserdetails?.organizationDetails?.shariaCom
                            ? 'Facility'
                            : 'Loans'}{' '}
                          tenor{' '}
                        </th>
                        <th className="mobile-tr">
                          {toJS(loanUserdetails) &&
                          toJS(loanUserdetails)?.organizationDetails?.shariaCom
                            ? 'Mark-up'
                            : 'Interest'}
                        </th>
                        <th className="mobile-tr"> Status </th>
                        <th className="mobile-tr"> </th>
                        <th className="mobile-th"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {approvedLoans.map((loan: any, i: number) => (
                        <tr key={i}>
                          <td>{loan.reason}</td>
                          <td>{loan.amount.toLocaleString()}</td>
                          <td className="mobile-tr">{loan.loanTenor}</td>
                          <td className="mobile-tr">
                            {loan.interest === undefined ||
                            loan.loanType === 'Musharakah'
                              ? '0'
                              : Math.round(loan.interest * 100) / 100}
                            %
                          </td>
                          <td className="approved mobile-tr">
                            {loan.approvalStatus}
                          </td>
                          <td className="mobile-tr">
                            <Link
                              to={
                                loanUserdetails?.organizationDetails?.shariaCom
                                  ? '/dashboard/facility/facility_details'
                                  : '/dashboard/loans/loan_details'
                              }
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Button
                                content="View more"
                                classes="secondary-btn btn-lg bg-white"
                              />
                            </Link>
                          </td>
                          <td className="mobile-th">
                            <Link
                              to="/dashboard/loans/loan_details"
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Icon
                                path={mdiEyeSettingsOutline}
                                className={`${loan.approvalStatus}`}
                                style={{ width: '1.5rem' }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {pendingLoans.length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/no-loans.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>
                    You do not have a pending{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    yet!{' '}
                  </p>
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}>
                  <table className="table table-striped responsive-table mb-0">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th> Amount(₦) </th>
                        <th className="mobile-tr">
                          {' '}
                          {loanUserdetails?.organizationDetails?.shariaCom
                            ? 'Facility'
                            : 'Loan'}{' '}
                          tenor{' '}
                        </th>
                        <th className="mobile-tr">
                          {toJS(loanUserdetails) &&
                          toJS(loanUserdetails)?.organizationDetails?.shariaCom
                            ? 'Mark-up'
                            : 'Interest'}
                        </th>
                        <th className="mobile-tr"> Status </th>
                        <th className="mobile-tr"> </th>
                        <th className="mobile-th"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingLoans.map((loan: any, i: number) => (
                        <tr key={i}>
                          <td>{loan.reason}</td>
                          <td>{loan.amount.toLocaleString()}</td>
                          <td className="mobile-tr">{loan.loanTenor}</td>
                          <td className="mobile-tr">
                            {loan.interest === undefined ||
                            loan.loanType === 'Musharakah'
                              ? '0'
                              : Math.round(loan.interest * 100) / 100}
                            %
                          </td>
                          <td className="pending mobile-tr">
                            {loan.approvalStatus}
                          </td>
                          <td className="mobile-tr">
                            <Link
                              to={
                                loanUserdetails?.organizationDetails?.shariaCom
                                  ? '/dashboard/facility/facility_details'
                                  : '/dashboard/loans/loan_details'
                              }
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Button
                                content="View more"
                                classes="secondary-btn btn-lg bg-white"
                              />
                            </Link>
                          </td>
                          <td className="mobile-th">
                            <Link
                              to="/dashboard/loans/loan_details"
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Icon
                                path={mdiEyeSettingsOutline}
                                className={`${loan.approvalStatus}`}
                                style={{ width: '1.5rem' }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {paidLoans.length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/no-loans.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>
                    You do not have a paid{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    yet!{' '}
                  </p>
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}>
                  <table className="table table-striped responsive-table mb-0">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th> Amount(₦) </th>
                        <th className="mobile-tr">
                          {' '}
                          {loanUserdetails?.organizationDetails?.shariaCom
                            ? 'Facility'
                            : 'Loan'}{' '}
                          tenor{' '}
                        </th>
                        <th className="mobile-tr">
                          {toJS(loanUserdetails) &&
                          toJS(loanUserdetails)?.organizationDetails?.shariaCom
                            ? 'Mark-up'
                            : 'Interest'}
                        </th>
                        <th className="mobile-tr"> Status </th>
                        <th className="mobile-tr"> </th>
                        <th className="mobile-th"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {paidLoans.map((loan: any, i: number) => (
                        <tr key={i}>
                          <td>{loan.reason}</td>
                          <td>{loan.amount.toLocaleString()}</td>
                          <td className="mobile-tr">{loan.loanTenor}</td>
                          <td className="mobile-tr">
                            {loan.interest === undefined ||
                            loan.loanType === 'Musharakah'
                              ? '0'
                              : Math.round(loan.interest * 100) / 100}
                            %
                          </td>
                          <td className="paid mobile-tr">{loan.status}</td>
                          <td className="mobile-tr">
                            <Link
                              to={
                                loanUserdetails?.organizationDetails?.shariaCom
                                  ? '/dashboard/facility/facility_details'
                                  : '/dashboard/loans/loan_details'
                              }
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Button
                                content="View more"
                                classes="secondary-btn btn-lg bg-white"
                              />
                            </Link>
                          </td>
                          <td className="mobile-th">
                            <Link
                              to={
                                loanUserdetails?.organizationDetails?.shariaCom
                                  ? '/dashboard/facility/facility_details'
                                  : '/dashboard/loans/loan_details'
                              }
                              onClick={() => handleLoanDetailId(loan._id)}
                              state={{
                                loanId: loan._id,
                              }}>
                              <Icon
                                path={mdiEyeSettingsOutline}
                                className={`${loan.status}`}
                                style={{ width: '1.5rem' }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
          </Tabs>

          <div className="navigation">
            <button
              className="btn prev disabled navigation-btn"
              title="Previous">
              <Icon path={mdiChevronLeft} size={1} />
            </button>
            <Button
              content="1"
              title="Page 1"
              classes="active prev navigation-btn"
            />
            <Button
              content="2"
              title="Page 2"
              classes="active prev navigation-btn"
            />
            <button className="btn next navigation-btn" title="Next">
              <Icon path={mdiChevronRight} size={1} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(LoansCard);
