/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import { StoreContext } from '../../../mobx_stores/RootStore';

interface params {
  name: string;
  description: string;
  productCode: string;
  currency: string;
  minimumInvestmentAmount: number;
}

const SaveWithArm = ({ setShowArmModal }) => {
  const navigate = useNavigate();
  const { investStore } = useContext(StoreContext);
  const { armPlans } = investStore;
  const [plans, setPlans] = useState<any>([]);

  useEffect(() => {
    setPlans(toJS(armPlans));
  }, [armPlans, investStore]);

  const handleInvestment = (data: params) => {
    navigate('/dashboard/invest/money_market_fund', {
      state: {
        name: data.name,
        description: data.description,
        productCode: data.productCode,
        currency: data.currency,
        minimumInvestmentAmount: data.minimumInvestmentAmount,
      },
    });
  };
  return (
    <div>
      <div className="arm-details-modal s-w-arm">
        <div className="bg-white notification-box ">
          <span
            onClick={() => {
              setShowArmModal(false);
            }}
            className="close"
            role="button">
            <FaTimes />
          </span>
          <div className="title ">
            <span className="t-header my-4">
              <h3>Save With ARM</h3>
            </span>
          </div>
          <p className="s-heading my-5">Recommended Product</p>

          {plans?.length > 0 ? (
            <div className="main-content">
              {plans.map((plan: any, id: string) => (
                <div
                  className="product-group d-flex my-3 p-2"
                  style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => handleInvestment(plan)}
                  key={id}>
                  <span className="d-flex align-items-center">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/chartIcon.png`}
                      alt=""
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <h6 className="ms-4 ms-md-2">{plan.name}</h6>
                  </span>

                  <span className="d-flex align-items-center ms-2 d-none d-md-block">
                    {plan.description}
                  </span>
                  <span className="d-flex align-items-center">
                    ₦{' '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(plan.minimumInvestmentAmount)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="rq-loan-details">
              <div
                className="product-group d-flex my-3 p-2"
                style={{
                  cursor: 'pointer',
                  alignItems: 'center',
                }}>
                <span className="d-flex align-items-center">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/chartIcon.png`}
                    alt=""
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>

                <span className="d-flex align-items-center ms-2 d-none d-md-block">
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>
                <span className="d-flex align-items-center">
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaveWithArm;
