import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiArrowRight } from '@mdi/js';

const WhatWeDoCard = ({ imgSrc, altText, title, text, link }) => (
  <div className="col">
    <div className="card p-3 h-100">
      <div className="d-flex flex-column flex-md-row gap-2">
        <div className="col-md-6">
          <img src={imgSrc} className="card-img-top h-100" alt={altText} />
        </div>
        <div className="col-md-6">
          <div className="card-body px-0 pb-0">
            <div className="align-items-start">
              <h5 className="card-title">{title}</h5>
              <p className="card-text mt-0">{text}</p>
              <Link to={link}>
                Learn More <Icon path={mdiArrowRight} size={1} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WhatWeDoCard;
