import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Privacy = () => {
  return (
    <>
      <Header />
      <div className="container lp-privacy">
        <h3 className="mt-5 ">Privacy Policy</h3>

        <div className="md:p-7 md:mr-6">
          <p className="text-xxs md:text-xs text-gray07 mt-6 mb-5">
            Trade Lenda Com Limited (also known as Trade Lenda) will be the
            controller for the data that you provide us or we collect in
            relation to the provision of our lending services to you. We respect
            your privacy and protecting your information is paramount. We want
            you to be informed and empowered with respect to your privacy when
            you use our services. Please read this Privacy Policy carefully
            before using our website or apps or registering to use our services.
            Together with our Terms and Cookies Policy, this Privacy Policy sets
            out our views and practices regarding your personal data and how we
            collect, process and treat it. This notice explains how we handle
            your personal data and our attitude towards data protection.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">The Information We Collect</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We collect and process information which:
          </p>
          <ul>
            <li className="text-xxs md:text-xs text-gray07 mt-6">
              You give to us when you register with us, such as your name and
              contact details
            </li>
            <li className="text-xxs md:text-xs text-gray07 mt-6">
              We obtain from Credit Reference Agencies on your behalf
            </li>
            <li className="text-xxs md:text-xs text-gray07 mt-6">
              We collect about you based on your use of our website
            </li>
            <li className="text-xxs md:text-xs text-gray07 mt-6">
              We receive from other sources, such as third parties who give us
              information about you
            </li>
            <li className="text-xxs md:text-xs text-gray07 mt-6">
              We may collect and process the following data about you.
            </li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">Information You Give Us</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            You may give us information about you when you use our services or
            by communicating with us. This includes information you provide when
            you participate in discussion boards, answer specific questions on
            our website, provide us with feedback, participate in surveys, and
            when you report a problem. The information you give us may include:
          </p>
          <ul>
            <li>Title</li>
            <li>Name</li>
            <li>Income</li>
            <li>Email Address</li>
            <li>Date of Birth</li>
            <li>Phone Number</li>
            <li>Monthly Expenditure</li>
            <li>Residential Details</li>
            <li>Employment Details</li>
            <li>Address history</li>
            <li>Bank</li>
            <li>Property value</li>
            <li>Driving license details</li>
            <li>Lifestyle Information</li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information We Collect from Credit Reference Agencies on your behalf
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            If you register to use our services, we will obtain your credit
            score and credit report from one or more Credit Rating Bureau on
            your behalf.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information We Collect from Our Partners on Your behalf
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            Each time you visit our website we may automatically collect the
            following information:
          </p>
          <ul>
            <li>
              Technical Information: including the Internet protocol (IP)
              address, your login information, browser type and version, time
              zone setting, operating system and platform;
            </li>
            <li>
              Information about your visit: including the full Uniform Resource
              Locators (URL) clickstream to, through and from our website
              (including date and time); products you viewed or searched for;
              page response times, download errors, length of visits to certain
              pages, page interaction information (such as scrolling, clicks,
              and mouse-overs), and methods used to browse away from the page
              and any phone number used to call our customer service number.
            </li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information We receive from Other Sources
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We may receive information about you if you use our website. We are
            also working closely with third parties (including, for example,
            business partners, service providers, analytics providers, search
            information providers, social media) and may receive information
            from them, such as your credit report information, whether or not
            you have taken out a product with them, what other products you may
            have used, and other such details.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">How We use the Information</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We use information we hold about you to provide our services to you,
            improve those services, administer your account, to fulfil our legal
            obligation, respond to your enquiries, customer support, manage our
            relationship with you, and communicate with you and to use
            information on an anonymous basis for research and analytical
            purposes.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6  mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information you give to Us or We collect from Credit Reporting
            Bureaus, Partners or Other Third Parties on Your behalf as part of
            Our Services
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6"></p>
          <ul>
            <li>
              Administer your account and relationship with us and to,
              communicate with you by telephone, mail, email, text (SMS)
              message, push notification or other electronic means;
            </li>
            <li>
              Verify your identity as part of our identity authentication
              process;
            </li>
            <li>Provide you with information, products and services;</li>
            <li>
              Where you have provided your consent for us to market to you,
              provide you with information about other products and services we
              feel may interest you or be best for you;
            </li>
            <li>Notify you about changes to our services;</li>
            <li>
              Ensure that content from our website is presented in the most
              effective manner for you and your device;
            </li>
            <li>
              Aggregate it on an anonymous basis with other data for data
              analytical and reporting purposes; and
            </li>
            <li>
              Undertake analysis and profiling of your credit information in
              order to identify and inform you of credit products that we
              consider are likely to interest you or be suited to your credit
              circumstances or to enhance our services.
            </li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information We Collect about You based on Your use of Our Website
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6"></p>
          <ul>
            <li>
              To administer our website and for internal operations, including
              troubleshooting, data analysis, testing, research, statistical and
              survey purposes;
            </li>
            <li>
              To improve the services we offer you such as understanding the
              effectiveness of advertising we serve to you and others, and to
              deliver relevant advertising to you;
            </li>
            <li>
              As part of our efforts to keep our website safe and secure and to
              prevent fraud;
            </li>
            <li>
              To make recommendations about products or services that may be of
              interest.
            </li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Information We receive from Other Sources
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We may combine this information with information you give to us and
            use this information and the combined information for the purposes
            set out above.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Recording Telephone Calls and Other Communications
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We will use telephone recordings or transcripts of communications to
            check your instructions to us, analyse, assess and improve our
            services, for training and quality purposes and for the purposes of
            investigating any complaint you may make, or as evidence in any
            dispute between you and us.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">What We will never Do</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            Rest assured, we will never sell your information to third parties.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Who We may Share Your Data with
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We may share your data with other third parties, such as our service
            providers, Credit Rating Bureaus, and Anti-Graft Agencies and
            Partners. We may need to disclose your data to others to ensure the
            smooth provision to you of the products, services and information
            you request. Your data may be disclosed to the other entities as
            described below. These third parties act on our instructions and are
            processors of your information. The personal information we have
            collected from you will be shared with Background Check agencies who
            will use it to verify your identity. We will share your data due to
            our legal obligation to ensure we meet know your customer (KYC) and
            anti-money laundering obligations under extant laws.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Sub-Processors</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We may share your information with the following sub-processors:
          </p>
          <ul>
            <li>Google</li>
            <li>Microsoft</li>
            <li>Systemspecs</li>
            <li>CreditClan</li>
            <li>Paystack</li>
            <li>Flutterwave</li>
            <li>Nigerian Interbank Settlement System(NIBSS)</li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Selected Third Parties</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We may also share your information with selected third parties
            including:
          </p>
          <ul>
            <li>
              Credit Rating Bureaus, to obtain your credit score and credit
              report on your behalf and to provide our services to you;
            </li>
            <li>Background Check agencies, to verify identity;</li>
            <li>
              Anti-graft agencies or other regulators to comply with our legal
              obligations;
            </li>
            <li>
              Product providers, such as our business partners who offer you
              credit cards, loans, car finance, mortgages, insurance, pensions,
              investments and other related products, to:
            </li>
            <li>
              Assess if you are an existing customer and for Background Check
              purpose;
            </li>
            <li>
              Conduct analysis to provide you with better products and services
              in the future, and for segmentation purposes;
            </li>
            <li>Pre-fill an application form with the product provider;</li>
            <li>assess your probability of being accepted for a product</li>
            <li>
              Analytics and search engine providers that assist us in the
              improvement and optimisation of our service
            </li>
            <li>
              If we sell or buy any business or assets, in which case we may
              disclose your personal data to the prospective seller or buyer of
              such business or assets (or the buyer or seller’s advisers)
            </li>
            <li>
              If we or part or all of our assets are acquired by a third party,
              in which case personal data held by us about our customers will be
              one of the transferred assets
            </li>
            <li>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our Terms and other agreements; or to protect the rights,
              property, or safety of Tradelenda, our customers, or others. This
              includes exchanging information with other companies and
              organisations for the purposes of fraud protection and credit risk
              reduction.
            </li>
          </ul>
          <p>
            The Product provider does not have permission to use this data for
            any other purpose including marketing.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Credit Rating Bureaus</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We make we have appropriate legal bases on which to collect, use and
            share data about you. If you have any questions about the lawful
            bases upon which we collect and use your personal data, you can
            contact our Data Protection Officer. Our lawful bases may include
            consent (where you have given consent), contract (where processing
            is necessary for the performance of a contract with you such as
            delivering our core services to you) and, legal obligation, and our
            own legitimate interests.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Consent</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We will always seek your consent to process certain types of
            information where we are legally required to do so. You have the
            right to withdraw or decline your consent at any time.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Performance of Contract</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            Processing your data is necessary for a contract you have with us,
            or because we have asked you to take specific steps before entering
            into that contract.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Legitimate Interests</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            Processing your data is necessary for our legitimate interests or
            the legitimate interests of a third party, provided those interests
            are not outweighed by your rights and interests. These legitimate
            interests are:
          </p>
          <ul>
            .<li>Delivering, developing and improving our website;</li>
            <li>
              Enabling us to enhance, customise or modify our services and
              communication;
            </li>
            <li>Determining whether marketing campaigns are effective;</li>
            <li>Enhancing data security.</li>
          </ul>
          <p>
            In each case, these legitimate interests are only valid if they are
            not outweighed by your rights and interests. Where we rely on
            legitimate interests, you have the right to object at any time. We
            may also process data if there is a legal obligation on us to
            process such data under existing laws and regulations in Nigeria.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Direct Marketing and how You can Change Your Preference
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We offer you the opportunity to receive marketing information from
            us. You can opt out easily of receiving marketing from us at any
            time. We will normally send direct marketing by email if we have
            your email address, but may choose to contact you via other methods,
            such as push notifications to your devices. You may receive the
            following types of communications from us:
          </p>
          <ul>
            <li>
              Product recommendations - we’ll get in touch with personalized,
              timely product recommendations that can help you improve your
              financial situation. We will only ever send these if you
              explicitly consent to receiving them and you can unsubscribe
              whenever you like.
            </li>
            <li>
              Content communications - we’ll send you content such as tips,
              research, features and news, coaching programs on how to keep on
              top of your money and other related content. You can unsubscribe
              from these at any time and we will never spam your inbox.
            </li>
            <li>
              Core communications - we will send you your credit report every
              month, alerts whenever there is a change to your credit report,
              security alerts pertaining to your Tradelenda account, significant
              changes which may impact our service and other such related
              content. These communications are an intrinsic part of owning a
              Tradelenda account and cannot be opted-out of.
            </li>
          </ul>
          <p>
            f you would like us to stop sending direct marketing to you, we
            offer simple ways to do this. Whenever you receive direct marketing
            you will be given an option to unsubscribe. You can also tell us
            that you do not wish to receive any more marketing communications at
            any time by writing, with your full name, address and other contact
            details (to enable us to find your records), to: Data Protection
            Officer Tradelenda .
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            How We protect Your Information
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We take the security of your data very seriously and use strict
            procedures to protect it. Whenever we transfer personal data
            Nigeria, we ensure that appropriate safeguards are in place to
            protect the data. All information you provide to us is stored on our
            secure servers. We do our best to protect your personal data, but we
            cannot guarantee the security of your data transmitted to our
            website; any transmission is at your own risk. Once we have received
            your information, we will use strict procedures and security
            features to try to prevent unauthorised access, loss or damage.
            Where possible, we try to only process your information within
            Nigeria. If we or our service providers transfer personal data
            outside Nigeria, we always require that appropriate safeguards are
            in place to protect the information when it is processed.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            How long We keep Your Information for
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            While your account remains active, we keep your information for no
            longer than is necessary depending on the purpose for which we are
            using it. We will keep your credit information for an initial period
            not less than six (6) years from the date such information is
            provide, and archived for a period of ten (10) years in compliance
            with the provision of Section 5 of the Credit Reporting Act, 2017.
            How long we keep your information will depend on the purpose for
            which we use it. While you are a customer of ours, we will only
            retain your information for as long as is necessary for those
            purposes. After termination of your account, we may continue to use
            anonymised data (which does not identify individual users) which is
            aggregated with anonymised data of other users. We use this
            aggregated anonymised data for data analysis and research purposes,
            for example to gain insights about our users. We may also keep your
            email address to ensure that you no longer receive any
            communications from us as well as your name, date of birth and
            latest address for Background Check purposes and for the exercise or
            defence of a legal claim.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">
            Links to and from Third Party Websites
          </p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            Our website may, from time to time, contain links to and from the
            websites of our partner networks and affiliates. If you follow a
            link to any of these websites, please note that these websites have
            their own privacy notices and that we do not accept any
            responsibility or liability for these policies.
          </p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Automated Decision Making</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            We use an automated decision making system to make automated
            decisions based on personal information we have about you. This
            helps us to make sure our decisions are quick and fair, based on
            what we know. We use automated processing for the following
            purposes:
          </p>
          <ul>
            <li>
              Identity verification - We use an automated decision making system
              to verify the details you provide against those held by third
              party providers. If you do not pass the check using the automated
              system, we cannot provide our services to you without being able
              to verify your identity.
            </li>
            <li>
              Tailored products and services - We may use your information to
              predict the probability that you may be accepted for a product, or
              to determine the best order or manner in which to display products
              to you.
            </li>
            <li>
              Tailored communications - We want to make sure we're only sending
              you emails that are relevant to you, and so we will use your
              personal information to determine which content you may be more
              interested in receiving. You've the right not to be subject to a
              decision based solely on automated processing, including
              profiling. We understand that not everyone is comfortable with
              decisions being left entirely up to machines. If you have any
              questions about automated decision making, please contact us via
              info@Tradelenda.com.
            </li>
          </ul>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Your Rights</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            You can exercise specific rights with regards to the data that we
            hold about you. You will be able to:
          </p>
          <ul>
            <li>
              Correct your data - you will usually be able to amend any
              information that we hold about you that is inaccurate or
              incomplete through the settings in your account;
            </li>
            <li>
              Request access to your data - you can ask for access to the
              personal data that we hold about you so that you can check that we
              are using your information in accordance with data protection law;
            </li>
            <li>
              Erase your data - you can ask us to fully or partially delete your
              personal data where there is no compelling reason for us to keep
              using it, although we may not be able to continue to provide our
              services. We may keep your email address to make sure the
              restriction is respected in future. We also have the right to
              continue using your information if such usage is necessary for
              compliance with our legal obligations;
            </li>
            <li>
              Download your data or send it to another controller - you can
              obtain a copy of the data you provided us in a machine-readable
              format. In addition, where certain conditions apply, you have the
              right to have such information transferred directly to a third
              party;
            </li>
            <li>
              Restrict the use of your data - You have the right to 'block' or
              suppress further use of your information in certain circumstances
              (for example, where you think the information we are using about
              you is inaccurate, whilst we verify its accuracy). When usage is
              restricted, we can still store your information, but may not use
              it further;
            </li>
            <li>
              Right to withdraw consent - If you have given your consent for us
              to use your information, you have the right to withdraw your
              consent at any time. This can be done by contacting our Data
              Protection Officer;
            </li>
            <li>
              Right to lodge complaint with the Regulator: - If you are not
              satisfied with our response to any complaints you raise with us or
              you believe our processing of your information does not comply
              with the data protection law, we suggest you contact our Data
              Protection Officer. However, you can make a complaint to the
              Central Bank of Nigeria or the National Information Technology
              Development Agency.
            </li>
          </ul>
          For the exercise of any of your rights, you can also contact us at
          info@Tradelenda.com.
          <p></p>
        </div>
        <div className="md:p-7 md:mr-6 mt-6 mb-5">
          <p className="text-xs text-gray07 mt-6">Contact Us</p>
          <p className="text-xxs md:text-xs text-gray07 mt-6">
            ll access requests, questions, comments, complaints and other
            requests regarding the cookies policy should be sent to
            info@tradelenda.com. We may request additional details from you
            regarding your complaints and keep records of your requests and
            resolution.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
