import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mdiEmail, mdiPhoneInTalk, mdiWhatsapp } from '@mdi/js';
import Icon from '@mdi/react';
import { Helmet } from 'react-helmet-async';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Newsletter from './sections/Newsletter';

const SupportPage = () => {
  return (
    <div className="bg-white support">
      <Helmet>
        {/* <title>Support | Trade Lenda Support Center!</title> */}
        <title>Trade Lenda Support - Helping SMEs with Banking Solutions</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Get the help you need with Trade Lenda's support services. We are here to assist you with bill payments, loans, insurance, and investment queries."
        />
        <meta
          name="keywords"
          content="Trade Lenda support, banking help, customer support, SME services help"
        />
      </Helmet>
      <Header />
      <div className="container">
        <div
          className=" ss-wous position-relative"
          style={{ marginBottom: '5rem', marginTop: '5rem' }}>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="lp-faq">
                <h1>Common questions people ask</h1>
                <div className="lp-faq-abs">
                  <p>Have more questions?</p>
                  <p>
                    Chat with us
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      style={{ marginLeft: '.5rem', color: '#24348B' }}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="lp-inwy-accordion">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        What is Trade Lenda?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Trade Lenda is a Digital Finance Plug to assist Micro,
                        Small and informal retailers to access finance for
                        business needs and business growth within the space of
                        six(6) hours. We also assist retailers with access to
                        distributors and manufacturers.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo">
                        What are the benefits of using Trade Lenda?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Trade Lenda has huge number of benefits that can’t be
                        seen in any other;{' '}
                        <li>Opportunity to grow your business</li>
                        <li>Quick Finance</li> <li>Smart Savings</li>
                        <li>Fast Payments</li>
                        <li> Insurance (HMO, Credit Life Assurance etc)</li>
                        <li>Free Credit Reports</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree">
                        How do I qualify for a Tradelenda loan?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        To qualify for a loan on Trade Lenda is as simple as
                        following the steps below <br />
                        1. Register on our website using the Sign up button{' '}
                        <br />
                        2. Complete your Profile on your Dashboard; <br />
                        <li>Personal Information</li>
                        <li>Business Information</li>
                        <li>Financial Information</li>
                        <li>Then add a Guarantor to your dashboard</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour">
                        What is the duration of the loan pay back?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Trade Lenda expect a return of loan with 30days to
                        90days
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive">
                        What happens when I cannot repay my loan on time?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Trade Lenda charges a default rate of 1% and an
                        additional rate of 1% is added weekly on the default
                        amount.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix">
                        Is Trade Lenda a Bank?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        No, We are a digital lending platform for MSMEs aimed at
                        providing Credits to support our customers. We have
                        partners we work with in order to offer value-added
                        financial services which are simple, faster, and
                        affordable for our customers.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven">
                        How does Insurance work on Trade Lenda?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        We have partnered with Custodian and Alliance Insurance
                        firms to make access to affordable insurance coverage
                        possible for our clients.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseEight">
                        I just started my business, do I qualify for a loan ?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEight"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        Yes, definitely you do. Just sign-up, complete your
                        profile and make your request.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lp-faq-block">
              <p>Have more questions?</p>
              <p>
                Chat with us
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  style={{ marginLeft: '5rem', color: '#24348B' }}
                />
              </p>
            </div>
          </div>
        </div>

        <section className="values contact-section">
          <div className="text-center">
            <h2 id="contact">Contact us</h2>
          </div>
          <div>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-5">
                <form className="row g-3">
                  <div className="col-12">
                    <label htmlFor="fullname" className="form-label">
                      Full Name *
                    </label>
                    <input type="text" className="form-control" id="fullname" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="email" className="form-label">
                      Email Address *
                    </label>
                    <input type="email" className="form-control" id="email" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea id="messgae" className="form-control"></textarea>
                  </div>
                  <div className="col-12 text-end">
                    <Button content="Send" classes="primary-btn btn-lg" />
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="contact-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/contact-img.png`}
                    alt="Trade Lenda"
                    className="speed"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center get-in-touch">
            <div>
              <h2 className="mb-0">Still have questions?</h2>
              <h2>Get in touch with us</h2>
            </div>
            <p className="desc">
              Your satisfaction is our top priority! our support service is
              available 24/7 to assist you with any questions you have about
              Tradelenda and our services; loans, investments, insurance etc,{' '}
              <br />
              <strong>You can get in touch with us any way you prefer:</strong>
            </p>
            <div className="lp-footer-icons justify-content-center mb-5">
              <a
                href="https://www.linkedin.com/company/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} className="lp-fi" />
              </a>
              <a
                href="https://instagram.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="lp-fi" />
              </a>
              <a
                href="https://twitter.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="lp-fi" />
              </a>
              <a
                href="https://www.facebook.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="lp-fi" />
              </a>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card card1 mb-4 mb-md-0">
                  <span>
                    <Icon path={mdiEmail} size={1} />
                  </span>
                  <h3>Send us a mail</h3>
                  <p>info@tradelenda.com</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card2 mb-4 mb-md-0">
                  <span>
                    <Icon path={mdiPhoneInTalk} size={1} />
                  </span>
                  <h3>give us a Call us</h3>
                  {/* <p>+234 817 079 6881</p> */}
                  <p>02017000064</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card3 mb-4 mb-md-0">
                  <span>
                    <Icon path={mdiWhatsapp} size={1} />
                  </span>
                  <h3>chat with us</h3>
                  <p>Chat with us on Whatsapp</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <GetStarted />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default SupportPage;
