import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import BaseDirectories from '../../base directories/BaseDirectories';
import LoginForm from '../../components/forms/LoginForm';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

const UserLogin: FC = (_props) => {
  return (
    <div>
      <Helmet>
        <title>LOGIN | Access your account on Trade Lenda!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="user-login-form">
        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="container-fluid">
          <div className="row">
            {/* <div className=" col-md-6  signup-image-container">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/signupImage.png`}
                alt=""
              />
            </div> */}

            <div className="col-md-6 col-sm-9 signup-form-container mt-2 mt-md-5">
              <div className="d-md-none d-flex text-start mt-4 back-top">
                <Link to="/">
                  <Icon path={mdiArrowLeft} size={1} />
                </Link>
              </div>
              <Link to="/">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Logo3.png`}
                  alt="logo"
                  style={{ width: '18%' }}
                />
              </Link>
              <div className="signup-form py-2 mt-py-5">
                <h2 className="mb-4">Log In</h2>
                <p className="welcome-text mb-5">
                  Welcome back! <br />
                  Login to get financing to boost your smes
                </p>
                <LoginForm />
                <div>
                  <p className="mt-2">
                    Don't have an account?{' '}
                    <u style={{ color: '#fff' }}>
                      <Link to="/sign_up">Sign Up</Link>
                    </u>
                  </p>
                </div>
                <div className="d-none d-md-block back">
                  <Link to="/">
                    <Icon
                      path={mdiArrowLeft}
                      size={1}
                      style={{ color: '#fff' }}
                    />
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default UserLogin;
