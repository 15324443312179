import React from 'react';
import { Helmet } from 'react-helmet-async';

const LoanTerms = () => {
  return (
    <div>
      <Helmet>
        <title>Loan Terms | Trade Lenda Loan Terms!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <embed
        src="/LOAN POLICY.pdf#toolbar=0"
        width="100%"
        height="100vh"
        style={{ height: '100vh' }}
      />
    </div>
  );
};

export default LoanTerms;
