/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import axios from 'axios';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';

configure({ enforceActions: 'always' });

type Message = {
  type: string;
  msg: any;
};

type LoanId = {
  [key: string]: any;
};

type Profile = {
  [key: string]: any;
};

export class LoansStore {
  loading = false;
  sending = false;
  error = '';
  success = '';
  token: any = sessionStorage.getItem('accessToken') || '';
  userId = '';
  loans = [];
  loanId: LoanId = {};
  approvedLoans = [];
  pendingLoans = [];
  paidLoans = [];
  paidLoansAmount = [];
  approvedLoansAmount = [];
  pendingLoansAmount = [];
  loanDetailId = '';
  allLoanAmounts: any = {};
  state = [];
  city = [];
  profileDetails = {};
  loanUserdetails: any = {};
  credrails = [];
  loanTenorDetails: any = [];
  authorized = false;
  uploadProgress = 0;
  documentS3Url = '';
  uploadSuccess = false;
  uploading = false;
  message: Message = {
    type: '',
    msg: '',
  };
  loanTenor: any = [];
  loanRepaymentPlan: any = [];

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
    });
  }

  createLoan(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .post(`${BaseDirectories.API_BASE_URL}/loans/create-loan-web`, details, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | Create Loan | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setSuccess("Request successful. We'll get back to you soon ");
        setTimeout(() => {
          this.setSuccess('');
        }, 100);
        toast.success("Request successful. We'll get back to you soon ", {
          autoClose: 2000,
        });

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Loan | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  createNextOfKin(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    if (Object.values(details).filter((d: any) => d === '').length === 0) {
      this.setSending(true);
      axios
        .post(
          `${BaseDirectories.API_BASE_URL}/loan-details/create/next-of-kin`,
          details,
          {
            headers,
          },
        )
        .then((res: any) => {
          this.logger.info(
            `User | Create Next of kin | ${toJS(this.userProfile?.email)}`,
            res,
          );
          this.setSuccess('Next of kin details created successfully');
          setTimeout(() => {
            this.setSuccess('');
          }, 100);

          setTimeout(() => {
            toast.success('Next of kin details created successfully', {
              autoClose: 2000,
            });
          }, 1000);

          runInAction(() => {
            this.sending = false;
          });
        })
        .catch((err) => {
          this.logger.error(
            `User | Create Loan | ${toJS(this.userProfile?.email)}`,
            err,
          );
          runInAction(() => {
            this.sending = false;
          });

          if (err.response.status === 400) {
            this.setMessage(
              'error',
              'Please check your inputs then try again!',
            );
            return toast.error('Please check your inputs then try again!');
          }
          if (err.response.status === 403 || 401) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 422 || 404) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 500 || 501 || 502 || 503) {
            return toast.error('Network Error. Please try again later');
          } else {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        });
    } else {
      this.logger.error(
        `User | Create Next of kin | ${toJS(this.userProfile?.email)}`,
        details,
      );
      const keysWithEmptyValues = Object.keys(details).filter(
        (key: any) =>
          details[key] === null ||
          details[key] === undefined ||
          details[key] === '',
      );

      toast.error(
        `Invalid ${keysWithEmptyValues
          .toString()
          .toLowerCase()}, check the input and try again!`,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  createBankDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    if (
      Object.keys(details).filter(
        (d: any) => d !== 'loanAmount' && details[d] === '',
      ).length === 0
    ) {
      this.setSending(true);
      axios
        .post(
          `${BaseDirectories.API_BASE_URL}/loan-details/create/bank-details`,
          details,
          {
            headers,
          },
        )
        .then((res: any) => {
          this.logger.info(
            `User | Create Bank Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess('Bank details created successfully');
          setTimeout(() => {
            this.setSuccess('');
          }, 100);

          setTimeout(() => {
            toast.success('Bank details created successfully', {
              autoClose: 2000,
            });
          }, 1000);

          runInAction(() => {
            this.sending = false;
          });
        })
        .catch((err) => {
          this.logger.error(
            `User | Create Bank Details | ${toJS(this.userProfile?.email)}`,
            err,
          );
          runInAction(() => {
            this.sending = false;
          });

          if (err.response.status === 400) {
            return toast.error('Please check your inputs then try again!');
          }
          if (err.response.status === 403 || 401) {
            return toast.error('You cannot perform this action.');
          }
          if (err.response.status === 422 || 404) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 500 || 501 || 502 || 503) {
            return toast.error('Network Error. Please try again later');
          } else {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        });
    } else {
      this.logger.error(
        `User | Create Bank Details | ${toJS(this.userProfile?.email)}`,
        details,
      );
      const keysWithEmptyValues = Object.keys(details).filter(
        (key: any) =>
          details[key] === null ||
          details[key] === undefined ||
          details[key] === '',
      );

      toast.error(
        `Invalid ${keysWithEmptyValues
          .toString()
          .toLowerCase()}, check the input and try again!`,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  createCredailsDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-details/get-consent`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Create Credrails Details | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setSuccess('Please check your email to connect your mobile bank!');
        setTimeout(() => {
          this.setSuccess('');
        }, 100);

        setTimeout(() => {
          toast.success(
            'Please check your email to connect your mobile bank!',
            { autoClose: 2000 },
          );
        }, 1000);

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Credrails Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  createBusinessDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setSending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-details/create/organization`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Create Business Details | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setSuccess('Business details created successfully');
        setTimeout(() => {
          this.setSuccess('');
        }, 100);

        setTimeout(() => {
          toast.success('Business details created successfully', {
            autoClose: 2000,
          });
        }, 1000);

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Business Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          this.setMessage('error', 'Please check your inputs then try again!');
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  createDocumentsDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-details/save-documents`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Create Document Details | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setSuccess('Document details created successfully');
        toast.success('Document details created successfully', {
          autoClose: 2000,
        });

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Document Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  updateDocumentsDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .put(
        `${BaseDirectories.API_BASE_URL}/loan-details/update-documents`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.code === 99) {
          this.logger.error(
            `User | Update Document Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setError(res.data.message);
          toast.error(res.data.message);
        } else {
          this.logger.info(
            `User | Update Document Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess('Document details updated successfully');
          toast.success('Document details updated successfully', {
            autoClose: 2000,
          });
        }

        setTimeout(() => {
          this.setSuccess('');
        }, 4000);

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Update Document Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error('You cannot perform this action.');
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  updateArmDocumentsDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .put(
        `${BaseDirectories.API_BASE_URL}/loan-details/update/arm-documents`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.code === 99) {
          this.logger.error(
            `User | Update ARM Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setError(res.data.message);
          toast.error(res.data.message);
        } else {
          this.logger.info(
            `User | Update ARM Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          runInAction(() => {
            this.setSuccess('Document Updated successfully');
            this.setMessage('success', 'Document Updated successfully');
            this.sending = false;
          });
          toast.success('Document Updated successfully', {
            autoClose: 2000,
          });
        }

        setTimeout(() => {
          this.setSuccess('');
        }, 5000);
      })
      .catch((err) => {
        this.logger.error(
          `User | Update ARM Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.setSuccess('Document Updated Failed');
          this.setMessage('error', 'Document Updated Failed');
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error('You cannot perform this action.');
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  createArmDocumentsDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .put(
        `${BaseDirectories.API_BASE_URL}/loan-details/update/arm-details`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.code === 99) {
          this.logger.error(
            `User | Created ARM Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setError(res.data.message);
          toast.error(res.data.message);
        } else {
          this.logger.info(
            `User | Created ARM Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess('ARM details created successfully');
          toast.success('ARM details created successfully', {
            autoClose: 2000,
          });
        }

        setTimeout(() => {
          this.setSuccess('');
        }, 10000);

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Created ARM Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error('You cannot perform this action.');
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  updateArmDetails(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setSending(true);
    axios
      .put(
        `${BaseDirectories.API_BASE_URL}/loan-details/update/arm-details`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        toast.success('Profile updated successfully');
        this.setMessage('success', 'Profile updated successfully');
        setTimeout(() => {
          this.setMessage('', '');
        }, 10000);
        this.setSending(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setMessage('error', err.response.data.message);
        this.setSending(false);
      });
  }

  updatePersonalDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    if (Object.values(details).filter((d: any) => d === '').length === 0) {
      this.setSending(true);
      axios
        .put(`${BaseDirectories.API_BASE_URL}/users/update`, details, {
          headers,
        })
        .then((res: any) => {
          this.logger.info(
            `User | Update Personal Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess('Personal details updated successfully');
          setTimeout(() => {
            this.setSuccess('');
          }, 100);

          toast.success('Personal details updated successfully', {
            autoClose: 2000,
          });

          runInAction(() => {
            this.sending = false;
          });
        })
        .catch((err) => {
          this.logger.error(
            `User | Update Personal Details | ${toJS(this.userProfile?.email)}`,
            err,
          );
          runInAction(() => {
            this.sending = false;
          });

          if (err.response.status === 400) {
            return toast.error('Please check your inputs then try again!');
          }
          if (err.response.status === 403 || 401) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 422 || 404) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 500 || 501 || 502 || 503) {
            return toast.error('Network Error. Please try again later');
          } else {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        });
    } else {
      this.logger.error(
        `User | Update Personal Details | ${toJS(this.userProfile?.email)}`,
        details,
      );
      const keysWithEmptyValues = Object.keys(details).filter(
        (key: any) =>
          details[key] === null ||
          details[key] === undefined ||
          details[key] === '',
      );

      toast.error(
        `Invalid ${keysWithEmptyValues
          .toString()
          .toLowerCase()}, check the input and try again!`,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  updateNokDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    if (Object.values(details).filter((d: any) => d === '').length === 0) {
      this.setSending(true);
      axios
        .put(
          `${BaseDirectories.API_BASE_URL}/loan-details/update/next-of-kin`,
          details,
          {
            headers,
          },
        )
        .then((res: any) => {
          if (res.data.code === 99) {
            this.logger.error(
              `User | Update Next of kin Details | ${toJS(
                this.userProfile?.email,
              )}`,
              res.data,
            );
            this.setError(res.data.message);
            toast.error(res.data.message);
          } else {
            this.logger.info(
              `User | Update Next of kin Details | ${toJS(
                this.userProfile?.email,
              )}`,
              res.data,
            );
            this.setSuccess('Next of kin details updated successfully');
            setTimeout(() => {
              this.setSuccess('');
            }, 100);
            toast.success('Next of kin details updated successfully', {
              autoClose: 2000,
            });
          }

          runInAction(() => {
            this.sending = false;
          });
        })
        .catch((err) => {
          runInAction(() => {
            this.sending = false;
          });

          if (err.response.status === 400) {
            this.setMessage(
              'error',
              'Please check your inputs then try again!',
            );
            return toast.error('Please check your inputs then try again!');
          }
          if (err.response.status === 403 || 401) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 422 || 404) {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 500 || 501 || 502 || 503) {
            return toast.error('Network Error. Please try again later');
          } else {
            return toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        });
    } else {
      this.logger.error(
        `User | Update Next of Kin Details | ${toJS(this.userProfile?.email)}`,
        details,
      );
      const keysWithEmptyValues = Object.keys(details).filter(
        (key: any) =>
          details[key] === null ||
          details[key] === undefined ||
          details[key] === '',
      );

      toast.error(
        `Invalid ${keysWithEmptyValues
          .toString()
          .toLowerCase()}, check the input and try again!`,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  updateBankDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    if (
      (Object.values(details).filter((d: any) => d === '').length === 1 &&
        Object.keys(details).filter((d: any) => details[d] === '')[0] ===
          'loanAmount') ||
      Object.values(details).filter((d: any) => d === '').length === 0
    ) {
      this.setSending(true);
      axios
        .put(
          `${BaseDirectories.API_BASE_URL}/loan-details/update/bank-details`,
          details,
          {
            headers,
          },
        )
        .then((res: any) => {
          runInAction(() => {
            this.sending = false;
          });
          if (res?.data?.code === 99) {
            this.logger.error(
              `User | Update Bank Details | ${toJS(this.userProfile?.email)}`,
              res.data,
            );
            setTimeout(() => {
              toast.error(res.data.message);
            }, 1000);
          } else {
            this.logger.info(
              `User | Update Bank Details | ${toJS(this.userProfile?.email)}`,
              res.data,
            );
            this.setSuccess('Bank details updated successfully');
            setTimeout(() => {
              this.setSuccess('');
            }, 100);
            toast.success('Bank details updated successfully', {
              autoClose: 2000,
            });
          }
        })
        .catch((err) => {
          this.logger.error(
            `User | Update Bank Details | ${toJS(this.userProfile?.email)}`,
            err,
          );
          runInAction(() => {
            this.sending = false;
          });
          if (err.response.status === 400) {
            toast.error('Please check your inputs then try again!');
          }
          if (err.response.status === 403 || 401) {
            toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 422 || 404) {
            toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
          if (err.response.status === 500 || 501 || 502 || 503) {
            toast.error('Network Error. Please try again later');
          } else {
            toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        });
    } else {
      this.logger.error(
        `User | Update Bank Details | ${toJS(this.userProfile?.email)}`,
        details,
      );
      const keysWithEmptyValues = Object.keys(details).filter(
        (key: any) =>
          (key != 'loanAmount' && details[key] === null) ||
          (key != 'loanAmount' && details[key] === undefined) ||
          (key != 'loanAmount' && details[key] === ''),
      );

      toast.error(
        `Invalid ${keysWithEmptyValues
          .toString()
          .toLowerCase()}, check the input and try again!`,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  updateBusinessDetails(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setSending(true);
    axios
      .put(
        `${BaseDirectories.API_BASE_URL}/loan-details/update/organization`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.code === 99) {
          this.logger.error(
            `User | Update Business Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setError(res.data.message);
          toast.error(res.data.message);
        } else {
          this.logger.info(
            `User | Update Business Details | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess('Business details updated successfully');
          toast.success('Business details updated successfully', {
            autoClose: 2000,
          });
        }
        setTimeout(() => {
          this.setSuccess('');
        }, 1000);

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Update Business Details | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          this.setMessage('error', 'Please check your inputs then try again!');
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  getLoanById(id: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/get-loan/${id}`, {
        headers,
      })
      .then((res: any) => {
        this.setLoanId(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getAllLoans() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/get-loans`, { headers })
      .then((res: any) => {
        this.setAllLoans(res.data);
      })
      .catch((err) => {
        this.logger.error(
          `User | Get AllLoans | ${toJS(this.userProfile?.email)}`,
          err,
        );
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getApprovedLoans() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/approved-loans/list`, {
        headers,
      })
      .then((res: any) => {
        this.setApprovedLoans(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getPaidLoans() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/paid-loans/list`, { headers })
      .then((res: any) => {
        this.setpaidLoans(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getPendingLoans() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/pending-loans/list`, {
        headers,
      })
      .then((res: any) => {
        this.setPendingLoans(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getPaidLoansAmount() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/paid/total-loan-amount`, {
        headers,
      })
      .then((res: any) => {
        this.setPaidLoansAmount(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getApprovedLoansAmount() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/approved/total-loan-amount`, {
        headers,
      })
      .then((res: any) => {
        this.setApprovedLoansAmount(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getPendingLoansAmount() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/pending/total-loan-amount`, {
        headers,
      })
      .then((res: any) => {
        this.setPendingLoansAmount(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getAllLoanAmounts() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/loan-statistics`, { headers })
      .then((res: any) => {
        this.setAllLoanAmounts(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getState() {
    axios
      .get(`${BaseDirectories.API_BASE_URL}/address/get-state`)
      .then((res: any) => {
        this.setState(res.data);
      })
      .catch((err) => {
        return;
      });
  }

  getCity(cityByState: string) {
    if (cityByState?.length > 0) {
      axios
        .get(`${BaseDirectories.API_BASE_URL}/address/get-city/${cityByState}`)
        .then((res: any) => {
          this.setCity(res.data);
        })
        .catch((err) => {
          return;
        });
    }
  }

  getCredailsBanks() {
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loan-details/bank-list`)
      .then((res: any) => {
        this.setCredails(res.data);
      })
      .catch((err) => {
        return;
      });
  }

  getProfileDetails(uid: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/users/get-profile?userId=${uid}`, {
        headers,
      })
      .then((res: any) => {
        this.setProfileDetails(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  getLoanUserDetails() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loan-details/loan-details`, {
        headers,
      })
      .then((res: any) => {
        this.setLoanUserDetails(res.data);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  createLoanPayment(loanId: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/jumia-pay/create-purchase`,
        loanId,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.statusCode === '00') {
          this.logger.info(
            `User | Create Loan Payment | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          window.open(res.data.link, '_blank')?.focus();
        } else if (res.data.statusCode === '99') {
          this.logger.error(
            `User | Create Loan Payment | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          toast.error(res.data.message);
        }
        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Loan Payment | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your request at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your request at this time. Please try again later!',
          );
        }
      });
  }

  acceptLoan(id: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/accept-offer/${id}`, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | Accept Loan | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        toast.success(
          'You have successfully accepted the offer. Please Check mail to authorize the direct debit',
        );
        this.setMessage('success', 'You have successfully accepted loan.');
        setTimeout(() => {
          this.setMessage('', '');
        }, 200);
        runInAction(() => {
          this.loading = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Accept Loan | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(
          'We are unable to process your request at this time. Please try again later!',
        );
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  connectBank(loanId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/paystack-recurring-debit/testcharge/${loanId}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          window.open(res.data.data.authorizationUrl, '_blank')?.focus();
        }
      })
      .catch((err) => {
        this.setLoading(false);
      });
  }

  checkRecurrentDebit(loanId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loans/authorization/check-recurrent-debit/${loanId}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.setLoading(false);
        this.setAuthorized(res.data.authorized);
        if (!res.data.authorized) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        this.setLoading(false);
      });
  }

  documentUpload(formData: any, documentName: string) {
    runInAction(() => {
      this.uploading = true;
    });

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    const config = {
      onUploadProgress: ({ loaded, total }) =>
        this.setUploadProgress(Math.round((loaded / total) * 100)),
      headers,
    };

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-details/upload/${documentName}`,
        formData,
        // {
        //   headers,
        // },
        config,
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          this.setUploadProgress(0);
          runInAction(() => {
            this.uploadSuccess = true;
            this.documentS3Url = res?.data?.data?.url;
            this.uploading = false;
          });
          setTimeout(() => {
            runInAction(() => {
              this.uploadSuccess = false;
            });
          }, 2000);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        this.setUploadProgress(0);
        runInAction(() => {
          this.uploadSuccess = false;
          this.uploading = false;
        });
        this.setLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          runInAction(() => {
            this.setLoading(false);
            this.uploading = false;
          });
        }, 2000);
      });
  }

  getLoanDetails(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    this.setSending(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loans/details-web?amount=${data.amount}&tenor=${data.tenor}&type=${data.type}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.setLoanDetails(res.data);

        runInAction(() => {
          this.setSending(false);
          this.setLoading(false);
          this.loanTenorDetails = res.data;
          this.loading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.setSending(false);
          this.setLoading(false);
          this.loading = false;
        });
      });
  }

  getLoanTenor() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loans/mobile-loan-tenor`, {
        headers,
      })
      .then((res: any) => {
        this.setLoanTenor(res.data);
      })
      .catch((err) => {
        return;
      });
  }

  getLoanRepayment(data: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loans/get-loan-repayments/${data}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.setLoanRepaymentPlan(res.data);
      })
      .catch((err) => {
        return;
      });
  }

  performLoanRepayment(details: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loans/self-loan-repayment`,
        details,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Self Repayment of Loan | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setSuccess('Loan repayment successful');
        setTimeout(() => {
          this.setSuccess('');
        }, 100);
        toast.success('Loan repayment successful', {
          autoClose: 2000,
        });

        runInAction(() => {
          this.sending = false;
        });
      })
      .catch((err) => {
        this.logger.error(
          `User | Self Repayment of Loan | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });

        if (err.response.status === 400) {
          return toast.error('Please check your inputs then try again!');
        }
        if (err.response.status === 403 || 401) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 422 || 404) {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
        if (err.response.status === 500 || 501 || 502 || 503) {
          return toast.error('Network Error. Please try again later');
        } else {
          return toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
        }
      });
  }

  repayLoan(loanRepaymentId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSending(true);
    const payload = {
      loanRepaymentId: loanRepaymentId,
    };
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loans/self-loan-repayment`,
        payload,
        {
          headers,
        },
      )
      .then((res: any) => {
        if (res.data.error === false) {
          this.logger.info(
            `User | Pay Back Loan | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          this.setSuccess(res.data.message);
          setTimeout(() => {
            this.setSuccess('');
          }, 2000);
          toast.success(res.data.message, {
            autoClose: 2000,
          });

          runInAction(() => {
            this.sending = false;
          });
        } else {
          this.logger.info(
            `User | Pay Back Loan Failed | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          toast.error(res.data.message, {
            autoClose: 5000,
          });

          runInAction(() => {
            this.sending = false;
          });
        }
      })
      .catch((err) => {
        this.logger.error(
          `User | Pay Back Loan Error | ${toJS(this.userProfile?.email)}`,
          err,
        );
        runInAction(() => {
          this.sending = false;
        });
      });
  }

  setUploadProgress = (progress: any) => {
    this.uploadProgress = progress;
  };

  setAuthorized = (val: boolean) => {
    this.authorized = val;
  };

  setAllLoans = (res: any) => {
    this.loans = res;
  };
  setLoanId = (res: any) => {
    this.loanId = res;
  };
  setLoanDetailId = (loanIddd: string) => {
    this.loanDetailId = loanIddd;
  };

  setApprovedLoans = (res: any) => {
    this.approvedLoans = res;
  };

  setpaidLoans = (res: any) => {
    this.paidLoans = res;
  };

  setPendingLoans = (res: any) => {
    this.pendingLoans = res;
  };

  setPaidLoansAmount = (res: any) => {
    this.paidLoansAmount = res;
  };
  setApprovedLoansAmount = (res: any) => {
    this.approvedLoansAmount = res;
  };
  setPendingLoansAmount = (res: any) => {
    this.pendingLoansAmount = res;
  };
  setAllLoanAmounts = (res: any) => {
    this.allLoanAmounts = res;
  };
  setState = (res: any) => {
    this.state = res;
  };
  setCity = (res: any) => {
    this.city = res;
  };
  setCredails = (res: any) => {
    this.credrails = res;
  };
  setProfileDetails = (res: any) => {
    this.profileDetails = res;
  };
  setLoanUserDetails = (res: any) => {
    this.loanUserdetails = res;
  };

  setLoanTenor = (res: any) => {
    this.loanTenor = res;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setToLocalStorage = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  clearFromLocalStorage = () => {
    window.localStorage.clear();
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };
  setUserId = (uid: string) => {
    this.userId = uid;
  };
  setLoading = (val: boolean) => {
    this.loading = val;
  };
  setSending = (val: boolean) => {
    this.sending = val;
  };
  setSuccess = (success: string) => {
    this.success = success;
  };
  setError = (error: any) => {
    this.error = error;
  };

  setLoanDetails = (val: any) => {
    this.loanTenorDetails = val;
  };

  setLoanRepaymentPlan = (val: any) => {
    this.loanRepaymentPlan = val;
  };
}
