/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  mdiChartBox,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFlowerTulip,
  mdiPoll,
  mdiWallet,
} from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaAsterisk } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import { StoreContext } from '../../../mobx_stores/RootStore';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'react-router-dom';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

const Overview = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [loanRoute, setLoanRoute] = useState<any>([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(sessionStorage.getItem('accessToken'));

  //Profile store context
  const { profileStore, loansStore, walletStore, investStore } =
    useContext(StoreContext);
  //Destucture profileStore, wallet and loanStore to get the needed observables
  const { profile, loadingData, dashboardBalances, isPinExist } = profileStore;
  const { loans, loanUserdetails } = loansStore;
  const {
    wallet,
    loadingWallet,
    multipleWallet,
    seerbitWalletBallance,
    vfdAccountBallance,
  } = walletStore;
  const { userInvestments, userLendaInvestments } = investStore;

  const percentage = toJS(profile).profileProgress || 0;
  const [showBalanceWallet, setShowBalanceWallet] = useState(false);
  const [showBalanceLoan, setShowBalanceLoan] = useState(false);

  // get profile datas
  useEffect(() => {
    if (token) {
      profileStore.getDashboardBalances();
      profileStore.getProfile();
      profileStore.checkPin();
      profileStore.setPin;
      investStore.getUsersInvestmentWithoutReload();
      investStore.getUserLenderInvestmentsWithoutLoading();
    } else {
      setToken(sessionStorage.getItem('accessToken'));
    }
  }, [profileStore, token]);

  // get loan sdata
  useEffect(() => {
    if (token) {
      try {
        loansStore.getAllLoans();
      } catch (err) {}
    } else {
      setToken(sessionStorage.getItem('accessToken'));
    }
  }, [loansStore, token]);

  useEffect(() => {
    if (token) {
      try {
        if (!loadingWallet) {
          walletStore.getWallet();
          walletStore.getAllWalletWithoutLoading();
          // Get Balance
        }
      } catch (err) {}
    } else {
      setToken(sessionStorage.getItem('accessToken'));
    }
  }, [walletStore, token]);

  useEffect(() => {
    if (token) {
      if (!loadingWallet) {
        try {
          if (toJS(multipleWallet) && toJS(multipleWallet)?.length > 0) {
            if (
              toJS(multipleWallet)[0]?.banker === '9PSB' ||
              toJS(multipleWallet)[0]?.banker === '9 Payment Service Bank'
            ) {
              walletStore.getSeerbitWalletBalance(
                toJS(multipleWallet)[0]?.pocketId,
              );
            } else if (
              toJS(multipleWallet)[1]?.banker === '9PSB' ||
              toJS(multipleWallet)[0]?.banker === '9 Payment Service Bank'
            ) {
              walletStore.getSeerbitWalletBalance(
                toJS(multipleWallet)[1]?.pocketId,
              );
            } else if (toJS(multipleWallet)[0]?.banker === 'VFD') {
              walletStore.getVfdBalance(
                toJS(multipleWallet)[0]?.walletIdAccountNumber,
              );
            } else if (toJS(multipleWallet)[1]?.banker === 'VFD') {
              walletStore.getVfdBalance(
                toJS(multipleWallet)[1]?.walletIdAccountNumber,
              );
            }
          }
        } catch (error) {}
      }
    }
  }, [multipleWallet]);

  // get wallet data at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingWallet) {
        walletStore.getWalletWithoutLoading();
        walletStore.getAllWalletWithoutLoading();
        try {
          if (toJS(multipleWallet) && toJS(multipleWallet)?.length > 0) {
            if (
              toJS(multipleWallet)[0]?.banker === '9PSB' ||
              toJS(multipleWallet)[0]?.banker === '9 Payment Service Bank'
            ) {
              walletStore.getSeerbitWalletBalance(
                toJS(multipleWallet)[0]?.pocketId,
              );
            } else if (
              toJS(multipleWallet)[1]?.banker === '9PSB' ||
              toJS(multipleWallet)[0]?.banker === '9 Payment Service Bank'
            ) {
              walletStore.getSeerbitWalletBalance(
                toJS(multipleWallet)[1]?.pocketId,
              );
            } else if (toJS(multipleWallet)[0]?.banker === 'VFD') {
              walletStore.getVfdBalance(
                toJS(multipleWallet)[0]?.walletIdAccountNumber,
              );
            } else if (toJS(multipleWallet)[1]?.banker === 'VFD') {
              walletStore.getVfdBalance(
                toJS(multipleWallet)[1]?.walletIdAccountNumber,
              );
            }
          }
        } catch (error) {}
        // Get Balance
      }
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setLoanRoute(toJS(loansStore.loanUserdetails));
  }, [loansStore.loanUserdetails]);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const activeLendaInvestments: any = toJS(userLendaInvestments)
    ? toJS(userLendaInvestments).filter(
        (investment: any) => investment?.investmentStatus == 'ACTIVE',
      )
    : 0.0;

  const activeARMinvestment: any = toJS(userInvestments)
    ? toJS(userInvestments).filter(
        (investment: any) => investment?.processStatus == 'WEBHOOK_SUCCESSFUL',
      )
    : 0.0;

  const handleCardClick = (serviceName: string) => {
    switch (serviceName) {
      case 'Loans':
        navigate('/dashboard/loans');
        break;
      case 'Invest':
        navigate('/dashboard/invest');
        break;
      case 'Insurance':
        navigate('/dashboard/insurance');
        break;
      case 'Transfer':
        navigate('/dashboard/wallet');
        break;
      case 'Pay bills':
        navigate('/dashboard/Wallet');
        break;
      default:
        break;
    }
  };

  // asterisks
  const renderMaskedBalance = () => (
    <h4 style={{ height: '40px' }}>
      <FaAsterisk className="no-show-balance p-0 me-1" />
      <FaAsterisk className="no-show-balance p-0 me-1" />
      <FaAsterisk className="no-show-balance p-0 me-1" />
      <FaAsterisk className="no-show-balance p-0" />
    </h4>
  );

  return (
    <div className="overview">
      {!isPinExist && (
        <div
          className="container-fluid"
          style={{ marginBottom: '0', marginTop: 10 }}>
          <div className="alert alert-danger">
            <div className="transaction-pin-message d-flex align-items-center">
              <p style={{ marginBottom: '0' }}>
                {/* <strong> */}
                You do not have a transaction pin,{' '}
                <span>
                  please create one for effective transactions.{''}
                  <Link
                    to="/dashboard/Settings/create-pin"
                    className="primary-link"
                    style={{
                      marginLeft: '10px',
                      textDecoration: 'none',
                      color: '#007bff',
                      transition: 'color 0.3s',
                    }}>
                    Create Transaction Pin
                  </Link>{' '}
                </span>
                {/* </strong> */}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* {loadingData && <Spinner />} */}
      <div className="container-xl">
        <div className="d-md-none row mb-3">
          <h3 className="greeting">Welcome {toJS(profile).firstName}</h3>
          <p>Let’s boost your business today</p>
        </div>
        <div className=" complete-profile-notification my-4">
          <div className=" d-flex right_content align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/completeIcon.png`}
                alt=""
                style={{ width: '32px', height: '32px' }}
              />
              <p>
                {percentage === 100
                  ? 'Your profile is complete'
                  : `Complete your Profile to get your first ${
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? 'facility'
                        : 'loan'
                    }`}
              </p>
            </div>
            <div className="middle_content">
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '24px',
                  pathTransitionDuration: 1,
                  pathColor: `#f4b740`,
                  textColor: '#fff',
                  trailColor: '#eaeaef',
                  backgroundColor: '#3e98c7',
                })}
              />
            </div>
          </div>
          <div className="left_content">
            {percentage === 100 ? (
              <Button
                classes="primary-btn"
                content={`Request for ${
                  loanUserdetails?.organizationDetails?.shariaCom
                    ? 'facility'
                    : 'loan'
                }`}
                route={
                  loanRoute.loanDocumentDetails === undefined
                    ? '/dashboard/loans/user_personal_details'
                    : '/dashboard/loans/request_for_loan'
                }
              />
            ) : (
              <Button
                classes="yellowcp-btn"
                content="Complete Profile"
                route="/dashboard/settings/account_settings"
              />
            )}
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={pagination}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            450: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              autoplay: {
                delay: 500,
                disableOnInteraction: false,
              },
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1184: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          modules={[Pagination]}
          className="lp-career-container mySwiper">
          <SwiperSlide>
            <div
              className="products-cards wallet pt-2 ps-4"
              style={{
                backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/walletballance.png")`,
              }}>
              <div className="product-group d-flex align-items-center justify-content-start mt-3 mb-5 flex-wrap">
                <Icon path={mdiWallet} size={1} />
                <p className="m-3">Wallet Balance</p>
                {!loadingData &&
                  !loadingWallet &&
                  wallet &&
                  (wallet.active === true ? (
                    <h6></h6>
                  ) : wallet.active === false ? (
                    <h6 className="wallet-status badge bg-danger mb-1">
                      Deactivated
                    </h6>
                  ) : (
                    <h6></h6>
                  ))}
                {!loadingData &&
                  !loadingWallet &&
                  wallet &&
                  (wallet.PND === false ? (
                    <h6></h6>
                  ) : wallet.PND === true ? (
                    <h6 className="wallet-status badge bg-danger mb-1">
                      No Debits Allowed
                    </h6>
                  ) : (
                    <h6></h6>
                  ))}
                {!loadingData &&
                  !loadingWallet &&
                  wallet &&
                  (wallet.PNC === false ? (
                    <h6></h6>
                  ) : wallet.PNC === true ? (
                    <h6 className="wallet-status badge bg-danger mb-1">
                      No Credits Allowed
                    </h6>
                  ) : (
                    <h6></h6>
                  ))}
              </div>
              <div className="balance-show mt-3 py-0 mb-lg-4">
                <div className="balance-group">
                  {showBalanceWallet ? (
                    toJS(multipleWallet) && toJS(multipleWallet)?.length > 0 ? (
                      toJS(multipleWallet).map(
                        (walletData: any, index: number) => (
                          <div className="mb-lg-3 " key={index}>
                            {/* <small>Bal {index + 1}: </small> */}
                            <h5 className="wallet-bal">
                              ₦{' '}
                              {walletData?.availableBalance &&
                              !isNaN(parseFloat(walletData.availableBalance))
                                ? new Intl.NumberFormat('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(
                                    parseFloat(walletData.availableBalance),
                                  )
                                : '0.00'}
                              {walletData?.banker && ` (${walletData.banker})`}
                            </h5>
                          </div>
                        ),
                      )
                    ) : (
                      <h5
                        className="wallet-status badge bg-danger text-white p-2"
                        style={{ marginLeft: '0px' }}>
                        Wallet does not exist
                      </h5>
                    )
                  ) : (
                    renderMaskedBalance()
                  )}
                </div>
                <div className="bal-visibility">
                  <button
                    className=""
                    onClick={() => setShowBalanceWallet(!showBalanceWallet)}>
                    <Icon
                      path={
                        showBalanceWallet ? mdiEyeOffOutline : mdiEyeOutline
                      }
                      size={1}
                    />
                  </button>
                </div>
              </div>

              <div className="view-group ">
                <Button
                  content="Fund Wallet"
                  classes="primary-btn btn-sm "
                  onClick={() => navigate('/dashboard/wallet')}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="products-cards loans pt-2 ps-4"
              style={{
                backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/loanImage1.png")`,
              }}>
              <div className="product-group d-flex align-items-center mt-3 mb-5">
                <Icon path={mdiChartBox} size={1} />
                <p className="m-3">
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'}
                </p>
              </div>
              <div className="balance-show mt-3 py-0 mb-lg-4">
                <div className="balance-group">
                  {/* <h5>₦{activeLoansAmount?.toLocaleString() || '0.00'}</h5> */}
                  {showBalanceLoan ? (
                    <h5>₦{dashboardBalances?.loanBalance || '0.00'}</h5>
                  ) : (
                    renderMaskedBalance()
                  )}
                </div>
                <div className="bal-visibility">
                  <button
                    className=""
                    onClick={() => setShowBalanceLoan(!showBalanceLoan)}>
                    <Icon
                      path={showBalanceLoan ? mdiEyeOffOutline : mdiEyeOutline}
                      size={1}
                    />
                  </button>
                </div>
              </div>
              <div className="view-group ">
                <Button
                  content="View all"
                  classes="secondary-btn btn-sm"
                  onClick={() => navigate('/dashboard/loans')}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className=" products-cards investments pt-2 ps-4"
              style={{
                backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/loanImage2.png")`,
              }}>
              <div className="product-group d-flex align-items-center mt-3 mb-5">
                <Icon path={mdiFlowerTulip} size={1} />
                <p className="m-3">Investments</p>
              </div>
              <div className="balance-group mb-lg-4">
                {/* <h5>₦{dashboardBalances?.investmentBalance || '0.00'}</h5>  */}
                <h5>
                  {' '}
                  ARM: ₦{' '}
                  {(activeARMinvestment &&
                    new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      activeARMinvestment?.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator +
                          (currentValue.investmentAmount -
                            currentValue?.redemptionAmount),
                        0,
                      ),
                    )) ||
                    '0.00'}
                </h5>
                <h5>
                  LENDA: ₦{' '}
                  {(activeLendaInvestments &&
                    new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      activeLendaInvestments?.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue?.totalReturn,
                        0,
                      ),
                    )) ||
                    '0.00'}
                </h5>
              </div>
              <div className="view-group">
                <Button
                  content="View all"
                  classes="secondary-btn btn-sm"
                  onClick={() => navigate('/dashboard/invest')}
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="row ">
          <div className=" our-services-cards">
            <div className="row mx-0  my-3 p-0 title">
              <h4>Our Services</h4>
            </div>
            <div className="d-flex our-services-card-group flex-wrap gap-3 justify-content-between">
              <div
                className=" services-card-group card "
                onClick={() => handleCardClick('Loans')}>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/circularchart.png`}
                  alt=""
                  style={{ width: '48px', height: '48px' }}
                />
                <p>
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'}
                </p>
              </div>

              <div
                className=" services-card-group card "
                onClick={() => handleCardClick('Invest')}>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/circularinvest.png`}
                  alt=""
                  style={{ width: '48px', height: '48px' }}
                />
                <p>Invest</p>
              </div>

              <div
                className=" services-card-group card "
                // onClick={() => handleCardClick('Insurance')}
              >
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/circularinsurance.png`}
                  alt=""
                  style={{ width: '48px', height: '48px' }}
                />
                <p>Get Insurance</p>
              </div>

              <div
                className=" services-card-group card "
                onClick={() => handleCardClick('Transfer')}>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/circulartransfer.png`}
                  alt=""
                  style={{ width: '48px', height: '48px' }}
                />
                <p>Transfer</p>
              </div>

              <div
                className=" services-card-group card "
                onClick={() => handleCardClick('Pay bills')}>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/circularbills.png`}
                  alt=""
                  style={{ width: '48px', height: '48px' }}
                />
                <p>Pay bills</p>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-start bottom-cards mx-0 my-5">
            <div className="col-6 bottom-card-group left">
              <div className="heading d-flex">
                <Icon path={mdiPoll} size={1} />
                <h5>Recent Transactions</h5>
              </div>
              <hr />
              <div className="content">
                {loans.length === 0 ? (
                  <p>No recent transactions</p>
                ) : (
                  <table>
                    <tbody>
                      {loans.map((loan: any, i: number) => (
                        <React.Fragment key={i}>
                          {width < 1150 ? (
                            <tr className="recent-transaction d-flex gap-3">
                              <td className="left-items d-flex gap-3">
                                <span>{loan.reason}</span>
                                <span className="mobile-tr">
                                  {loan.loanTenor}(Tenor)
                                </span>
                              </td>
                              <td className="right-items d-flex gap-3">
                                <span
                                  className={`${
                                    loan.status === 'paid'
                                      ? loan.status
                                      : loan.approvalStatus
                                  } mobile-tr`}>
                                  {loan.status === 'paid'
                                    ? loan.status
                                    : loan.approvalStatus}
                                </span>
                                <span>₦{loan.amount.toLocaleString()}</span>
                              </td>
                            </tr>
                          ) : (
                            <tr key={i}>
                              <td>{loan.reason}</td>
                              <td className="mobile-tr">
                                {loan.loanTenor}(Tenor)
                              </td>
                              <td
                                className={`${
                                  loan.status === 'paid'
                                    ? loan.status
                                    : loan.approvalStatus
                                } mobile-tr`}>
                                {loan.status === 'paid'
                                  ? loan.status
                                  : loan.approvalStatus}
                              </td>
                              <td>₦{loan.amount.toLocaleString()}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="col-6 bottom-card-group right">
              <div className="heading d-flex">
                <Icon path={mdiPoll} size={1} />
                <h5>AI PREDICTIONS</h5>
              </div>
              <hr />
              <div className="content">
                <p>
                  Credit score / upcoming payment based on previous{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'facility'
                    : 'loans'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Overview);
