import { mdiEmailOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Button from '../../../components/ui/Button';

const Newsletter = () => {
  return (
    <section className="newsletter">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-10">
            <div>
              <h2>Subscribe to our Newsletter</h2>
              <p>
                Subscribe to our newsletter to receive updates, exclusive
                offers, news, and more.
              </p>
              <form className="d-flex">
                <div className="input">
                  <input type="email" placeholder="Email address" />
                  <Icon path={mdiEmailOutline} size={1} />
                </div>
                <Button
                  classes="primary-btn btn-lg"
                  content="Subscribe"
                  type="button"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
