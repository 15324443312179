import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Tab, TabList } from 'react-tabs';
import { StoreContext } from '../../mobx_stores/RootStore';
import '../../styles/sass/landing-page-sass/BlogPage.scss';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';

export interface BlogPost {
  slug: any;
  id: number;
  title: string;
  content: string;
  category: string;
  image: string;
  fullContent: string;
}

const BlogPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('All');
  const { blogStore } = useContext(StoreContext);
  const [posts, setPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const post = blogStore.getAllBlogsPost();
      setPosts(post);
    };

    fetchPosts();
  }, [blogStore]);

  const filteredPosts =
    selectedTab === 'All'
      ? posts
      : posts.filter((post) => post.category === selectedTab);

  const handleTabClick = (category: string) => {
    setSelectedTab(category);
  };

  return (
    <>
      <div className="blog-page">
        <Helmet>
          <title>Why Trade Lenda | Trade Lenda Blog!</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header />

        <div className="header-image">
          <img src="/images/BlogOverview.png" alt="" />
        </div>

        <>
          <TabList className="blog-tab-list">
            <Tab
              className={`tab-button ${selectedTab === 'All' ? 'active' : ''}`}
              onClick={() => handleTabClick('All')}>
              ALL
            </Tab>
            <Tab
              className={`tab-button ${
                selectedTab === 'Finance' ? 'active' : ''
              }`}
              onClick={() => handleTabClick('Finance')}>
              FINANCE
            </Tab>
            <Tab
              className={`tab-button ${
                selectedTab === 'Banking' ? 'active' : ''
              }`}
              onClick={() => handleTabClick('Banking')}>
              BANKING
            </Tab>
            <Tab
              className={`tab-button ${
                selectedTab === 'Literacy' ? 'active' : ''
              }`}
              onClick={() => handleTabClick('Literacy')}>
              LITERACY
            </Tab>
          </TabList>
        </>

        <>
          {filteredPosts.map((post) => (
            <div className="blog-post mt-20vh" key={post.id}>
              <div className="post-info">
                <h2>{post.title}</h2>
                <p>{post.content}</p>
                <Link
                  to={`/blog/view/${post.slug}`}
                  state={{
                    id: post.id,
                    post: post.fullContent,
                    title: post.title,
                    category: post.category,
                    image: post.image,
                  }}>
                  View more
                </Link>
              </div>
              <img src={post.image} alt={post.title} />
            </div>
          ))}
        </>
      </div>
      <GetStarted />
      <Footer />
    </>
  );
};

export default BlogPage;
