/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../../../components/ui/Button';
import Spinner from '../../../../components/ui/Spinner';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import RedemptionModal from './LendaRedemptionModal';
import TopUpModal from './LendaTopUpModal';

interface LocationState {
  [key: string]: any;
}

const LendaInvestmentDetails = () => {
  const location: LocationState = useLocation();
  const { investStore } = useContext(StoreContext);
  const [investmentDetail, setInvestmentDetail] = useState<any>();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  useEffect(() => {
    investStore.getSingleUserLenderInvestments(location?.state?.InvestmentId);
    investStore.setLendaInvestment;
  }, []);

  useEffect(() => {
    if (toJS(investStore.lendaInvestment)) {
      setInvestmentDetail(toJS(investStore.lendaInvestment));
    }
  }, [investStore.lendaInvestment]);

  const handleTopUP = () => {
    setOpenPaymentModal(true);
  };

  const handleInvestmentRedemption = () => {
    setOpenOtpModal(true);
  };

  return (
    <div className="loans">
      {investStore.loadingPlans && <Spinner />}

      {openPaymentModal && (
        <TopUpModal
          button={true}
          route="/dashboard/invest"
          investmentData={investmentDetail}
          setOpenPaymentModal={setOpenPaymentModal}
        />
      )}

      {openOtpModal && (
        <RedemptionModal
          button={true}
          route="/dashboard/invest"
          investmentData={investmentDetail}
          setOpenOtpModal={setOpenOtpModal}
        />
      )}

      <div className="section-head">Investment Details</div>
      <div className="loans-container">
        <Link to="" onClick={() => window.history.back()}>
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="rq-loan-details">
          <h5>{investmentDetail?.investmentStatus} Investment</h5>
          <div className="d-flex flex-column flex-md-row">
            <div className="box-1">
              <div className="ld-box">
                <h5>Investment Plan</h5>
                <p>{investmentDetail?.investmentType || 'NIL'}</p>
              </div>

              <div className="ld-box">
                <h5>Investment Tenor</h5>
                <p>{investmentDetail?.investmentTenor || 'NIL'}</p>
              </div>

              <div className="ld-box">
                <h5>Investment Interest Rate</h5>
                <p>{investmentDetail?.interestRate || 'NIL'}</p>
              </div>

              <div className="ld-box">
                <h5>Investment Commencement Date</h5>
                <p>
                  {moment(investmentDetail?.created_at).format('MM-D-YYYY') ||
                    'NIL'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Initial Investment Amount</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.investmentAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        Number(investmentDetail?.investmentAmount),
                        // Number(investmentDetail?.topUpAmount),
                      )
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Monthly Return</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.monthlyReturn
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.monthlyReturn)
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Daily Return</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.dailyReturn
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 3,
                      }).format(investmentDetail?.dailyReturn)
                    : '0.00'}
                </p>
              </div>
            </div>

            <div className="box-1">
              <div className="ld-box">
                <h5>Referrer ID</h5>
                <p style={{ textTransform: 'capitalize', fontSize: '17px' }}>
                  {investmentDetail?._id || 'NIL'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Total Top-Up Amount</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.topUpAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.topUpAmount)
                    : '0.00'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Total Redeemed Amount</h5>
                <p>
                  ₦
                  {investmentDetail?.redemptionAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.redemptionAmount)
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Total Expected Return </h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.totalReturn
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.totalReturn)
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Accrued Interest</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.totalReturn
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        Number(investmentDetail?.totalReturn) -
                          Number(investmentDetail?.investmentAmount) -
                          Number(investmentDetail?.topUpAmount),
                      )
                    : '0.00'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Investment Return Date</h5>
                <p>
                  {moment(investmentDetail?.expectedReturnDate).format(
                    'MM-D-YYYY',
                  ) || 'NIL'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Service Charge</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.serviceCharge
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.serviceCharge)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row ms-4">
            <div className="ld-box m-2">
              <Button
                content="Top Up"
                classes="secondary-btn btn-lg bg-white"
                onClick={() => handleTopUP()}
                disabled={
                  Number(investmentDetail?.redemptionAmount) > 0 ? true : false
                }
              />
            </div>
            <div className="ld-box m-2">
              <Button
                content="Redeem Investment"
                classes="secondary-btn btn-lg bg-white"
                onClick={() => handleInvestmentRedemption()}
                disabled={
                  Number(investmentDetail?.redemptionAmount) > 0 ? true : false
                }
              />
            </div>

            <div className="ld-box m-2">
              <Link
                to="/dashboard/invest/lenda_transactions"
                state={{
                  TrackingId: investmentDetail?.trackingId,
                }}>
                <Button
                  content="View Statement"
                  classes="secondary-btn btn-lg bg-white"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LendaInvestmentDetails);
