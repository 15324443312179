/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { MdArrowBack, MdArrowDropUp, MdCheck } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';
import InputAmountModal from './InputAmountModal';

const MoneyMarketFund = () => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const location = useLocation();
  const [data, setData] = useState<any>(location.state);
  const { investStore } = useContext(StoreContext);
  const { submitting, submittingInvestment, armPlanYield } = investStore;

  useEffect(() => {
    investStore.getInvestmentYield(data.productCode);
    investStore.setArmInvestmentYield;
  }, []);

  return (
    <div className="m-m-fund container">
      {submitting || submittingInvestment ? <Spinner /> : <></>}

      {openPaymentModal && (
        <InputAmountModal
          button={true}
          btnText1="Start Investing"
          investmentData={data}
          setOpenPaymentModal={setOpenPaymentModal}
        />
      )}
      <span
        className="back"
        role="button"
        onClick={() => window.history.back()}>
        <MdArrowBack />
        <p>Back</p>
      </span>

      <div>
        <h5>ARM {data.name}</h5>
        <span className="d-flex y-date" style={{ display: 'flex' }}>
          <p>{moment(Date()).format('ddd, Do MMM YYYY')}</p>
          {toJS(armPlanYield)?.yield && (
            <span
              className="indicator"
              style={{ color: '#44ab3b', fontWeight: 'bold', marginLeft: 2 }}>
              <span style={{ color: '#000', fontWeight: 'normal' }}>
                : Yield{' '}
              </span>{' '}
              <MdArrowDropUp />
              {toJS(armPlanYield)?.yield
                ? new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  }).format(toJS(armPlanYield)?.yield)
                : '0.00'}
              %
            </span>
          )}
        </span>
      </div>

      <div className="content-cont mt-5 row">
        <div className="left col-md-6">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/performanceChart.png`}
            alt=""
            style={{ width: '70%' }}
          />
        </div>

        <div className="right col-md-6">
          <h6 className="mb-3">Learn more about this fund</h6>
          <p>
            The {data.description} offers a higher interest rate on your savings
            than a traditional savings account. And it doesn't have to be long
            term; the ARM {data.name} allows you quick access to your money,
            competitive interest rates, regular tax free returns and expert fund
            management.
          </p>
          <div className="list-group">
            <h6 className="mb-3">Key Benefits</h6>
            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Long term income generation.</p>
            </span>

            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Quarterly interest payments.</p>
            </span>
            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Competitive interest rates.</p>
            </span>
            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Quick access to your money.</p>
            </span>

            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Low Risk Mutual Fund.</p>
            </span>

            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>Suitable for short term or long term investment.</p>
            </span>
            <span>
              <MdCheck
                size={15}
                max={15}
                min={15}
                style={{ marginRight: '8px' }}
              />
              <p>
                Minimum investment amount ₦{' '}
                {data.minimumInvestmentAmount
                  ? new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(data.minimumInvestmentAmount)
                  : '0.00'}
              </p>
            </span>
          </div>

          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Invest Now"
            type="button"
            onClick={() => {
              setOpenPaymentModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(MoneyMarketFund);
