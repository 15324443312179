/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AWS from 'aws-sdk';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';
import Footer from './Footer';
import Header from './Header';

const s3 = new AWS.S3({
  accessKeyId: 'AKIAVSLWJSUJPXEDU6XN',
  secretAccessKey: '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',
});

const Application = () => {
  const { walletStore, loansStore } = useContext(StoreContext);
  const { fullDetails, loadingData, message } = walletStore;
  const [currentStep, setCurrentStep] = useState(0);
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: '',
    walletAccountNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    bvn: '',
    nin: '',
    businessName: '',
    registered: false,
    areYouADirector: false,
    percentageOwned: '',
    rcNum: '',
    numberOfDirectors: '',
    femaleCoCEO: false,
    femaleCoCEOPercentOwned: '',
    businessAddress: '',
    state: '',
    whatsAppNumber: '',
    businessDescription: '',
    businessSector: '',
    fundsNeeded: '',
    useOfFund: '',
    durationOfLoan: '',
    durationBeforeProfitable: '',
    willToSellEquity: false,
    percentOfEquity: '',
    previousFundsReceived: false,
    sourceOfFunding: '',
    valueOfFunding: '',
    businessWebSite: '',
    instagramHandle: '',
    facebookHandle: '',
    cacDocsURL: '',
    cacStatusDocsURL: '',
    utilitiesBillDocsURL: '',
    applicantIDURL: '',
  });
  const steps = [
    '1. Validation',
    '2. Personal Details',
    '3. Business Details',
    '4. Confirmation',
  ];

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    walletStore.submitApplicationForm(formData);
  };

  const handleAccountValidation = (e: any) => {
    e.preventDefault();
    if (formData?.walletAccountNumber?.length < 10) {
      toast.error('Please check the account number and try again.');
      return;
    } else {
      walletStore.verifyWalletNumber(formData?.walletAccountNumber);
    }
  };

  const handleChangeEvent = (e: any) => {
    const element = e.target as HTMLInputElement;
    setFormData((prevDetails: any) => {
      if (element.value === 'true') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.value === 'false') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
  };

  useEffect(() => {
    loansStore.getState();
  }, [loansStore]);

  useEffect(() => {
    setState(toJS(loansStore.state));
  }, [loansStore.state]);

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Account verified successfully'
    ) {
      toast.success(message.msg);
      handleNext();
    }

    if (
      message.type === 'success' &&
      message.msg === 'Application processed successfully'
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        navigate('/');
      }, 3000);
    }
  }, [message.type, message.msg]);

  const accountDetails: any = fullDetails;

  useEffect(() => {
    if (formData.walletAccountNumber.length === 10) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        userId: accountDetails?.accountData?.userId
          ? accountDetails?.accountData?.userId
          : '',
        firstName: accountDetails?.accountData?.firstName
          ? accountDetails?.accountData?.firstName
          : '',
        lastName: accountDetails?.accountData?.lastName
          ? accountDetails?.accountData?.lastName
          : '',
        email: accountDetails?.accountData?.email
          ? accountDetails?.accountData?.email
          : '',
        phone: accountDetails?.accountData?.phone
          ? accountDetails?.accountData?.phone
          : '',
        nin: accountDetails?.accountData?.nin
          ? accountDetails?.accountData?.nin
          : '',
        bvn: accountDetails?.accountData?.bvn
          ? accountDetails?.accountData?.bvn
          : '',
        businessName: accountDetails?.orgData?.organizationDetails?.businessName
          ? accountDetails?.orgData?.organizationDetails?.businessName
          : '',
        businessAddress: accountDetails?.orgData?.organizationDetails
          ?.businessAddress
          ? accountDetails?.orgData?.organizationDetails?.businessAddress
          : '',
        rcNum: accountDetails?.orgData?.organizationDetails?.rcNum
          ? accountDetails?.orgData?.organizationDetails?.rcNum
          : '',
        state: accountDetails?.orgData?.organizationDetails?.state
          ? accountDetails?.orgData?.organizationDetails?.state
          : '',
      }));
    }
  }, [fullDetails]);

  const validatePercentage = (value: number): number => {
    return Math.min(Math.max(value, 0), 100);
  };

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommasAndParseInt = (value) => {
    return parseInt(value.replace(/,/g, ''), 10);
  };

  const Required = () => {
    return <span style={{ fontSize: '1rem', color: 'red' }}>&nbsp;*</span>;
  };

  const disableIt =
    formData?.businessName === '' ||
    formData?.businessAddress === '' ||
    formData?.state === '' ||
    formData?.whatsAppNumber === '' ||
    formData?.businessDescription === '' ||
    formData?.businessSector === '' ||
    formData?.fundsNeeded === '' ||
    formData?.useOfFund === '' ||
    formData?.durationOfLoan === '' ||
    formData?.durationBeforeProfitable === '';

  const validateData = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleNext();
    }, 3000);
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file != undefined || file != null) {
      uploadFileToS3(file, event.target.name);
    }
  };

  const uploadFileToS3 = (file: any, fileName: string) => {
    setIsLoading(true);
    const lastModified = Math.floor(new Date().getTime() / 1000);
    const mime = 'application/path/';
    const path = `${mime}${lastModified}/${[fileName]}/${
      accountDetails?.accountData?.userId
    }/${file.name}`;
    const params = {
      Bucket: 'tl-app-dev',
      Key: path,
      Body: file,
    };

    s3.upload(params, (err: any, data: any) => {
      if (err) {
        setIsLoading(false);
        toast.error('Upload Failed ' + err);
      } else {
        setIsLoading(false);
        toast.success('Document upload successful!');
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [fileName]: data?.Location?.toString(),
        }));
      }
    });
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <form className="" onSubmit={handleAccountValidation}>
            <div className="row">
              <div className="col-12 mt-5 mb-6">
                <label
                  htmlFor="walletAccountNumber"
                  className="col-12 mb-2 mt-1">
                  Wallet Account Number
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="walletAccountNumber"
                  onChange={(e: any) => {
                    if (e.target.value.length <= 10) {
                      handleChangeEvent(e);
                    }
                  }}
                  value={formData.walletAccountNumber}
                  required
                />
              </div>
            </div>
            <div className="row ">
              <div className="sbmt-btn-class">
                <Button
                  classes="primary-btn btn-lg mt-4 mb-6"
                  content="Validate"
                />
              </div>
            </div>

            <div className="blue-card-cont-inner">
              <h3 className="mb-3">
                Don’t have a wallet, click App Icon to get started
              </h3>
              <div className="ss-appstore">
                <a
                  href="https://apps.apple.com/us/app/trade-lenda/id6447363171"
                  target="blank">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/apple-app.png`}
                    alt="Trade Lenda"
                    className="ss-appst-img"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.tradelenda.app&hl=en&gl=US"
                  target="blank">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/googleplaystore.png`}
                    alt="Trade Lenda"
                    className="ss-appst-img"
                  />
                </a>
                <a href="https://tradelenda.com/sign_up" target="blank">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/TL_web_sign_in.png`}
                    alt="Trade Lenda"
                    className="ss-appst-img"
                  />
                </a>
              </div>
            </div>
          </form>
        );
      case 1:
        return (
          <form className="">
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="firstName" className="col-12 mb-2 mt-1">
                  First Name
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  onChange={(e: any) => {
                    if (e.target.value.length <= 10) {
                      handleChangeEvent(e);
                    }
                  }}
                  value={formData.firstName}
                  readOnly
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="lastName" className="col-12 mb-2 mt-1">
                  Last Name
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  onChange={(e: any) => {
                    if (e.target.value.length <= 10) {
                      handleChangeEvent(e);
                    }
                  }}
                  value={formData.lastName}
                  readOnly
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="email" className="col-12 mb-2 mt-1">
                  Email Address
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  value={formData.email}
                  readOnly
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="phone" className="col-12 mb-2 mt-1">
                  Phone Number
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  readOnly
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="bvn" className="col-12 mb-2 mt-1">
                  BVN
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="bvn"
                  value={formData.bvn}
                  readOnly
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="nin" className="col-12 mb-2 mt-1">
                  NIN
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="nin"
                  value={formData.nin}
                  readOnly
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="gender" className="col-12 mb-2 mt-1">
                  Gender
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <select
                  name="gender"
                  id="gender"
                  className="form-control"
                  onChange={handleChangeEvent}
                  value={formData?.gender}
                  required>
                  <option value="">Select Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
            </div>
            <div className="row d-flex gap-3">
              <div className="sbmt-btn-class">
                <Button
                  classes="primary-btn btn-lg mt-4 mb-6"
                  content="Previous"
                  onClick={handlePrevious}
                />

                <Button
                  classes="primary-btn btn-lg mt-4 mb-6 ms-3"
                  content="Confirm & Next"
                  onClick={handleNext}
                />
              </div>
            </div>
          </form>
        );
      case 2:
        return (
          <form className="" onSubmit={validateData}>
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="businessName" className="col-12 mb-2 mt-1">
                  Business Name
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="businessName"
                  onChange={handleChangeEvent}
                  value={formData.businessName}
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="registered" className="col-12 mb-2 mt-1">
                  Is the business registered?
                  <Required />
                </label>
                <select
                  name="registered"
                  id="registered"
                  className="form-control"
                  onChange={handleChangeEvent}
                  required
                  defaultValue={formData?.registered ? 'true' : 'false'}>
                  <option value="">Select option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            {formData?.registered && (
              <div className="row">
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label htmlFor="rcNum" className="col-12 mb-2 mt-1">
                    RC/BN Number
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="rcNum"
                    onChange={handleChangeEvent}
                    value={formData.rcNum}
                    required={formData?.registered ? true : false}
                  />
                </div>
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label
                    htmlFor="numberOfDirectors"
                    className="col-12 mb-2 mt-1">
                    Number Of Directors
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="numberOfDirectors"
                    onChange={handleChangeEvent}
                    value={formData.numberOfDirectors}
                    required={formData?.registered ? true : false}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="areYouADirector" className="col-12 mb-2 mt-1">
                  Are you a Director?
                  <Required />
                </label>
                <select
                  name="areYouADirector"
                  id="areYouADirector"
                  className="form-control"
                  onChange={handleChangeEvent}
                  required
                  defaultValue={formData?.areYouADirector ? 'true' : 'false'}>
                  <option value="">Select option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="percentageOwned" className="col-12 mb-2 mt-1">
                  % of Business Owned
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="percentageOwned"
                  onChange={(e) => {
                    const newValue = validatePercentage(
                      parseInt(e.target.value),
                    );
                    setFormData((prevDetails: any) => ({
                      ...prevDetails,
                      percentageOwned: newValue,
                    }));
                  }}
                  value={formData.percentageOwned}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="femaleCoCEO" className="col-12 mb-2 mt-1">
                  Do you have any Female Co-Founder?
                  <Required />
                </label>
                <select
                  name="femaleCoCEO"
                  id="femaleCoCEO"
                  className="form-control"
                  onChange={handleChangeEvent}
                  required
                  defaultValue={formData?.femaleCoCEO ? 'true' : 'false'}>
                  <option value="">Select option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              {formData?.femaleCoCEO && (
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label
                    htmlFor="femaleCoCEOPercentOwned"
                    className="col-12 mb-2 mt-1">
                    % Owned
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="femaleCoCEOPercentOwned"
                    onChange={(e) => {
                      const newValue = validatePercentage(
                        parseInt(e.target.value),
                      );
                      setFormData((prevDetails: any) => ({
                        ...prevDetails,
                        femaleCoCEOPercentOwned: newValue,
                      }));
                    }}
                    value={formData.femaleCoCEOPercentOwned}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="businessAddress" className="col-12 mb-2 mt-1">
                  Business Address
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="businessAddress"
                  onChange={handleChangeEvent}
                  value={formData.businessAddress}
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="state" className="col-12 mb-2 mt-1">
                  State
                  <Required />
                </label>
                <select
                  name="state"
                  id="state"
                  className="form-control"
                  onChange={handleChangeEvent}
                  defaultValue={formData?.state}>
                  <option value="">Select State</option>
                  {state.map((state: string, i: number) => (
                    <option value={state} key={i}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="whatsAppNumber" className="col-12 mb-2 mt-1">
                  WhatsApp Number
                  <Required />
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="NG"
                  onChange={(e: string) =>
                    setFormData((prevDetails: any) => {
                      return { ...prevDetails, whatsAppNumber: e };
                    })
                  }
                  className="form-control"
                  id="whatsAppNumber"
                  value={formData.whatsAppNumber}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="businessSector" className="col-12 mb-2 mt-1">
                  Business Sector
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="businessSector"
                  onChange={handleChangeEvent}
                  value={formData.businessSector}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5 mb-6">
                <label
                  htmlFor="businessDescription"
                  className="col-12 mb-2 mt-1">
                  Business Description
                  <Required />
                </label>
                <textarea
                  className="col-12 text-area form-control"
                  name="businessDescription"
                  id="businessDescription"
                  value={formData?.businessDescription}
                  onChange={handleChangeEvent}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="fundsNeeded" className="col-12 mb-2 mt-1">
                  How Much Funding is Needed?
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="fundsNeeded"
                  onChange={handleChangeEvent}
                  value={formData.fundsNeeded}
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="useOfFund" className="col-12 mb-2 mt-1">
                  Use of Funds
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="useOfFund"
                  onChange={handleChangeEvent}
                  value={formData.useOfFund}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="durationOfLoan" className="col-12 mb-2 mt-1">
                  Duration of loan?
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="durationOfLoan"
                  onChange={handleChangeEvent}
                  value={formData.durationOfLoan}
                  required
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label
                  htmlFor="durationBeforeProfitable"
                  className="col-12 mb-2 mt-1">
                  How long before you are profitable?
                  <Required />
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="durationBeforeProfitable"
                  onChange={handleChangeEvent}
                  value={formData.durationBeforeProfitable}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="willToSellEquity" className="col-12 mb-2 mt-1">
                  Are you willing to sell Equity?
                  <Required />
                </label>
                <select
                  name="willToSellEquity"
                  id="willToSellEquity"
                  className="form-control"
                  onChange={handleChangeEvent}
                  required
                  defaultValue={formData?.willToSellEquity ? 'true' : 'false'}>
                  <option value="">Select option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              {formData?.willToSellEquity && (
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label htmlFor="percentOfEquity" className="col-12 mb-2 mt-1">
                    If Yes what %?
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="percentOfEquity"
                    onChange={(e) => {
                      const newValue = validatePercentage(
                        parseInt(e.target.value),
                      );
                      setFormData((prevDetails: any) => ({
                        ...prevDetails,
                        percentOfEquity: newValue,
                      }));
                    }}
                    value={formData.percentOfEquity}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label
                  htmlFor="previousFundsReceived"
                  className="col-12 mb-2 mt-1">
                  Any previous funding received?
                  <Required />
                </label>
                <select
                  name="previousFundsReceived"
                  id="previousFundsReceived"
                  className="form-control"
                  onChange={handleChangeEvent}
                  required
                  defaultValue={
                    formData?.previousFundsReceived ? 'true' : 'false'
                  }>
                  <option value="">Select option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              {formData?.previousFundsReceived && (
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label htmlFor="sourceOfFunding" className="col-12 mb-2 mt-1">
                    If yes, state the source of funding?
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="sourceOfFunding"
                    onChange={handleChangeEvent}
                    value={formData.sourceOfFunding}
                  />
                </div>
              )}
            </div>

            <div className="row">
              {formData?.previousFundsReceived && (
                <div className="col-md-6 col-12 mt-5 mb-6">
                  <label htmlFor="valueOfFunding" className="col-12 mb-2 mt-1">
                    State the value of funding
                    <Required />
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="valueOfFunding"
                    onChange={handleChangeEvent}
                    value={formData.valueOfFunding}
                  />
                </div>
              )}
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="businessWebSite" className="col-12 mb-2 mt-1">
                  Business Website
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="businessWebSite"
                  onChange={handleChangeEvent}
                  value={formData.businessWebSite}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="instagramHandle" className="col-12 mb-2 mt-1">
                  Instagram Handle
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="instagramHandle"
                  onChange={handleChangeEvent}
                  value={formData.instagramHandle}
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="facebookHandle" className="col-12 mb-2 mt-1">
                  Facebook Handle
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="facebookHandle"
                  onChange={handleChangeEvent}
                  value={formData.facebookHandle}
                />
              </div>
            </div>

            <div className="row d-flex gap-3">
              <div className="sbmt-btn-class">
                <Button
                  classes="primary-btn btn-lg mt-4 mb-6"
                  content="Previous"
                  onClick={handlePrevious}
                />

                <Button
                  classes="primary-btn btn-lg mt-4 mb-6 ms-3"
                  content="Confirm & Next"
                  disabled={disableIt}
                />
              </div>
            </div>
          </form>
        );
      case 3:
        return (
          <form className="" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="cacDocsURL" className="col-12 mb-2 mt-1">
                  Upload CAC Certificate
                  <Required />
                  <small className="ms-3 text-success">
                    {formData?.cacDocsURL != '' ? 'file uploaded' : ''}
                  </small>
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="cacDocsURL"
                  onChange={handleFileSelect}
                  required={formData?.cacDocsURL != '' ? false : true}
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="cacStatusDocsURL" className="col-12 mb-2 mt-1">
                  Upload CAC Status Report
                  <Required />
                  <small className="ms-3 text-success">
                    {formData?.cacStatusDocsURL != '' ? 'file uploaded' : ''}
                  </small>
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="cacStatusDocsURL"
                  onChange={handleFileSelect}
                  required={formData?.cacStatusDocsURL != '' ? false : true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label htmlFor="applicantIDURL" className="col-12 mb-2 mt-1">
                  Upload Valid ID
                  <Required />
                  <small className="ms-3 text-success">
                    {formData?.applicantIDURL != '' ? 'file uploaded' : ''}
                  </small>
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="applicantIDURL"
                  onChange={handleFileSelect}
                  required={formData?.applicantIDURL != '' ? false : true}
                />
              </div>
              <div className="col-md-6 col-12 mt-5 mb-6">
                <label
                  htmlFor="utilitiesBillDocsURL"
                  className="col-12 mb-2 mt-1">
                  Upload Utility Bill
                  <Required />
                  <small className="ms-3 text-success">
                    {formData?.utilitiesBillDocsURL != ''
                      ? 'file uploaded'
                      : ''}
                  </small>
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="utilitiesBillDocsURL"
                  onChange={handleFileSelect}
                  required={formData?.utilitiesBillDocsURL != '' ? false : true}
                />
              </div>
            </div>
            <div className="row">
              <div className=" d-flex form-check mb-3 my-2 mt-5">
                <Input
                  name="checkbox"
                  classes="form-check-input "
                  type="checkbox"
                  required
                  id="flexCheckDefault"
                />
                <label className="form-check-label " htmlFor="flexCheckDefault">
                  I agree with the
                  <a
                    href="https://docs.google.com/document/d/1Gg-uvbCqS0dPo2lZSh1zDnteQphVVAeR/edit?usp=sharing&ouid=106690636404743294862&rtpof=true&sd=true"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#054b99' }}>
                    {' '}
                    Terms and Conditions{' '}
                  </a>
                  of Trade Lenda
                </label>
              </div>
            </div>
            <div className="row d-flex gap-3">
              <div className="sbmt-btn-class">
                <Button
                  classes="primary-btn btn-lg mt-4 mb-6"
                  content="Previous"
                  onClick={handlePrevious}
                />

                <Button
                  classes="primary-btn btn-lg mt-4 mb-6 ms-3"
                  content="Submit Application"
                  type="submit"
                />
              </div>
            </div>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="lp-contact-us">
      <Header />
      {loadingData && <Spinner />}
      {isLoading && <Spinner />}
      <section className="application-container-landing">
        <section className="appication-section-one">
          <h1>
            Unlock Your Business Potential with Our Exclusive Funding
            Opportunity
          </h1>
          <p>
            Welcome to Trade Lenda, where your dreams of business expansion come
            to life
          </p>
          <p>
            We believe in your potential and are committed to supporting
            entrepreneurs like you in reaching new heights.
          </p>
          <p>
            Why not Share your business journey and expansion plans with us?
          </p>
          <p>
            Ready to transform your business? Register now to secure your chance
            at funding up to 10 million Naira! Your success story begins here!
          </p>
          <p>Start, Scale, Soar!!</p>
          <img
            src={`${BaseDirectories.IMAGES_DIR}/application-logo.png`}
            alt="logo"
          />
        </section>

        <section className="lp-cntct-main-sect">
          <h2>Application Form</h2>
          <div className="main-cont">
            <div className="contct-frm">
              <div className="mx-auto">
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}>
                  {steps.map((title, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign: 'center',
                        marginRight: '10px',
                        fontWeight: currentStep === index ? 'bold' : 'normal',
                      }}>
                      {title}
                    </div>
                  ))}
                </div>
                <progress
                  value={currentStep + 1}
                  max={steps.length}
                  style={{
                    width: '100%',
                    height: '10px',
                    borderWidth: '0px',
                    backgroundColor: '#e0f2f1',
                  }}></progress>
                <style>
                  {`
          progress[value] {
            appearance: none;
            -webkit-appearance: none;
            width: 100%;
          }
          progress[value]::-webkit-progress-bar {
            background-color: #e0f2f1;
          }
          progress[value]::-webkit-progress-value {
            background-color: skyblue;
          }
        `}
                </style>
              </div>

              <div className="mx-auto">{renderStep(currentStep)}</div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
};
export default observer(Application);
