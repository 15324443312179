/* eslint-disable @typescript-eslint/no-unused-vars */
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import AddGuarantorForm from '../../../components/forms/AddGuarantorForm';
import GuarantorNotification from './GuarantorNotification';

const AddGuarantors = () => {
  const [success, setSuccess] = useState(false);
  const [cancel, setCancel] = useState(false);

  return (
    <div className="add-guarantors">
      <h4 className="section-head">Guarantors</h4>
      <Link
        className="mb-4 d-inline-block back-link"
        to="/dashboard/guarantors/all_guarantors">
        <Icon path={mdiArrowLeft} size={1} className="Back" />
        Back
      </Link>

      <div className="bg-white custom">
        <div className="row justify-content-center p-2">
          <div
            className="text-center col-lg-6 col-md-9 col-sm-10 rounded p-2"
            style={{ backgroundColor: '#054b99', margin: '20px' }}>
            <h5 className="text-white">Add Guarantor</h5>
            <p className="text-white p-1">
              Tell us who to call in case of emergencies
            </p>
          </div>

          <AddGuarantorForm
            success={success}
            setSuccess={setSuccess}
            setCancel={setCancel}
          />
          {cancel && (
            <GuarantorNotification
              image={`${BaseDirectories.IMAGES_DIR}/cancel.png`}
              body="Are you sure you want to cancel?"
              button={true}
              route="/dashboard/guarantors/all_guarantors"
              btnText1="Yes"
              btnText2="No"
              close={() => setCancel(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddGuarantors;
