import React from 'react';
import Blank from './sections/Blank';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import HeroSection from './sections/HeroSection';
import HowItWorks from './sections/HowItWorks';
import Newsletter from './sections/Newsletter';
import Testimonials from './sections/Testimonials';
import WhatWeDo from './sections/WhatWeDo';
import { Helmet } from 'react-helmet-async';
import LoanGuide from './sections/LoanGuide';

const Home = () => {
  return (
    <div className="bg-white">
      <Helmet>
        {/* <title>Home | Trade Lenda Landing Page!</title> */}
        <title>Trade Lenda - Digital Banking Solutions for SMEs</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Discover Trade Lenda, a digital bank for SMEs offering innovative solutions including bill payment, loans, insurance, and investment services to grow your business."
        />
        <meta
          name="keywords"
          content="Digital bank, SME banking, loans for businesses, investment services, bill payment, insurance for SMEs"
        />
      </Helmet>
      <Header />
      <HeroSection />
      <WhatWeDo />
      <HowItWorks />
      <LoanGuide />
      <Testimonials />
      <Blank height="40px" />
      <GetStarted />
      <Blank height="40px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;
