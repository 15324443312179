import { MdArrowBack, MdArrowDropUp, MdCheck } from 'react-icons/md';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';

const EuroBond = () => {
  return (
    <div className="m-m-fund container">
      <span
        className="back"
        role="button"
        onClick={() => window.history.back()}>
        <MdArrowBack />
        <p>Back</p>
      </span>

      <div>
        <h5>Discovery Balance Fund</h5>
        <span className="d-flex y-date">
          <p>Yield on 4th Dec 2022: </p>
          <span className="indicator" style={{ color: '#44ab3b' }}>
            <MdArrowDropUp /> 12.25%
          </span>
        </span>

        <span className="risk">High Risk</span>
      </div>

      <div className=" content-cont  mt-5 row">
        <div className="left col-md-6">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/performanceChart.png`}
            alt=""
            style={{ width: '70%' }}
          />
        </div>

        <div className="right col-md-6">
          <h6 className="mb-3">Fund Factsheet</h6>
          <p>
            Suitable for investors with a low-risk appetite interested in medium
            to long-term capital growth and competitive returns on their US
            Dollar investment.
          </p>
          <div className="list-group">
            <span>
              <MdCheck />
              <p>
                The fund invests in dollar-denominated Eurobond floated by the
                Federal Government of Nigeria and top-tier Nigerian corporates.
              </p>
            </span>

            <span>
              <MdCheck />
              <p>A good hedge against depreciation of the Naira.</p>
            </span>
            <span>
              <MdCheck />
              <p>Enjoy professional fund management and expertise.</p>
            </span>
            <span>
              <MdCheck />
              <p>
                Affordable investing with minimum initial investment $1,000; and
                minimum additional contributions in multiples of $100.
              </p>
            </span>
          </div>

          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Invest Now"
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default EuroBond;
