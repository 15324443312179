import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-tabs/style/react-tabs.css';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/index';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const canonicalUrl = document.querySelector(
  'link[rel="canonical"]',
) as HTMLLinkElement;
if (canonicalUrl) {
  canonicalUrl.href = window.location.href;
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </PersistGate>
    </Provider>
    ,
  </React.StrictMode>,
);
