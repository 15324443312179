// prettier-ignore
/* eslint-disable no-console */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import axios from 'axios';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import BaseDirectories from '../base directories/BaseDirectories';
import { Profile } from '../common/interfaces/n_waller_types';
import {
  Bank,
  BeneficiaryNIPAccountData,
  SenderNIPAccountData,
  TransferDto,
  TransferType,
  VfdAccountEnquiryDto,
} from '../common/interfaces/n_wallet_interfaces';
import { BrowserLogger } from '../common/logger/Logger';
import logger from '../common/logger/CustomLogger';

type Message = {
  type: string;
  msg: any;
};

export class NWalletStore {
  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;

      /// Fetch Banks ON Start
      this.getVfdBanks();
    });
  }

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  submitting = false;
  message: Message = {
    type: '',
    msg: '',
  };
  error = {
    type: 'error',
    msg: '',
  };
  success = {
    type: 'success',
    msg: '',
  };
  /// Auth and Required
  private logger!: BrowserLogger;
  private userProfile!: Profile;
  token: any = sessionStorage.getItem('accessToken') || '';

  /// Static headers
  private _headers: any = {
    accept: 'application/json',
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json',
    'cf-cache-status-check': BaseDirectories.FCP,
  };
  public get headers(): any {
    return this._headers;
  }
  public set headers(value: any) {
    this._headers = value;
  }

  /// VFD
  // Banks
  private _vfdBanks: Bank[] = [];
  public get vfdBanks(): Bank[] {
    return this._vfdBanks;
  }
  public set vfdBanks(value: Bank[]) {
    this._vfdBanks = value;
  }

  // Selected Bank
  private _selectedVfdBank?: Bank | null | undefined;
  public get selectedVfdBank(): Bank | null | undefined {
    return this._selectedVfdBank;
  }
  public set selectedVfdBank(value: Bank | null | undefined) {
    this._selectedVfdBank = value;
  }

  // Get Banks Loading State
  private _gettingBanks: boolean = false;
  public get gettingBanks(): boolean {
    return this._gettingBanks;
  }
  public set gettingBanks(value: boolean) {
    this._gettingBanks = value;
  }

  // Account Details
  // sender
  private _sender?: SenderNIPAccountData;
  public get sender(): SenderNIPAccountData | undefined {
    return this._sender;
  }
  public set sender(value: SenderNIPAccountData | undefined) {
    this._sender = value;
  }

  private _gettingSenderAccountDetails: boolean = false;
  public get gettingSenderAccountDetails(): boolean {
    return this._gettingSenderAccountDetails;
  }
  public set gettingSenderAccountDetails(value: boolean) {
    this._gettingSenderAccountDetails = value;
  }

  // receiver
  private _receiver?: BeneficiaryNIPAccountData;
  public get receiver(): BeneficiaryNIPAccountData | undefined {
    return this._receiver;
  }
  public set receiver(value: BeneficiaryNIPAccountData | undefined) {
    this._receiver = value;
  }

  private _gettingReceiverAccountDetails: boolean = false;
  public get gettingReceiverAccountDetails(): boolean {
    return this._gettingReceiverAccountDetails;
  }
  public set gettingReceiverAccountDetails(value: boolean) {
    this._gettingReceiverAccountDetails = value;
  }

  private _transferringFunds = false;
  public get transferringFunds() {
    return this._transferringFunds;
  }
  public set transferringFunds(value) {
    this._transferringFunds = value;
  }

  private _transferStatus: string = '';
  public get transferStatus(): string {
    return this._transferStatus;
  }
  public set transferStatus(value: string) {
    this._transferStatus = value;
  }

  /// Get VfdBanks
  async getVfdBanks() {
    this.gettingBanks = true;
    axios
      .get(`${BaseDirectories.API_BASE_URL}/vfd-nip/banks`, {
        headers: this.headers,
      })
      .then((res: any) => {
        // runInAction(() => {
        if (res.error) {
          this.gettingBanks = false;

          toast.error('unable to fetch banks');
          return;
        }
        logger.debug('res.data.data', res.data.data);
        this.gettingBanks = false;
        this.vfdBanks = res.data.data;
        // });
      })
      .catch((err) => {
        this.gettingBanks = false;
      });
  }

  // Get Beneficiary Details
  async getBeneficiaryDetails(beneficiaryEnquiryDto: VfdAccountEnquiryDto) {
    this.gettingReceiverAccountDetails = true;

    try {
      const res = await axios.get(
        `${BaseDirectories.API_BASE_URL}/vfd-nip/beneficiary-account-enquiry?accountnumber=${beneficiaryEnquiryDto.accountnumber}&bankcode=${beneficiaryEnquiryDto.bankcode}&transfer_type=${beneficiaryEnquiryDto.transfer_type}`,
        {
          headers: this.headers,
        },
      );

      if (res.data.code === 104) {
        runInAction(() => {
          this.gettingReceiverAccountDetails = false;
        });
        toast.error(res.data.message);
        return;
      }

      // Assuming that the response data is stored in `res.data`
      const responseData = res.data;

      logger.debug(`transfer responseData: `, responseData);

      runInAction(() => {
        this.receiver = toJS(responseData);
        this.gettingReceiverAccountDetails = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.gettingReceiverAccountDetails = false;
        toast.error(
          err?.message ??
            'An error occurred while fetching beneficiary details',
        );
      });

      logger.error(err);
    }
  }

  // Get Sender Details
  async getSenderDetails(accountNumber?: string) {
    this.gettingSenderAccountDetails = true;

    try {
      const res = await axios.get(
        `${BaseDirectories.API_BASE_URL}/vfd-nip/user-account-enquiry?accountNumber=${accountNumber}`,
        {
          headers: this.headers,
        },
      );

      if (res.data.code === 104) {
        runInAction(() => {
          this.gettingSenderAccountDetails = false;
          toast.error('Unable to fetch sender details');
        });
        return;
      }

      // Assuming that the response data is stored in `res.data`
      const responseData = res.data;

      runInAction(() => {
        this.sender = toJS(responseData); // If using MobX, we wrap with toJS
        logger.debug('getSenderDetails - [this.sender]:', this.sender);
        logger.debug(
          'getSenderDetails - [this.sender?.data.name]:',
          this.sender?.data?.client,
        );
        this.gettingSenderAccountDetails = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.gettingSenderAccountDetails = false;

        toast.error(
          err?.message ?? 'An error occurred while fetching sender details',
        );
      });
      logger.error(err);
    }
  }

  // Encode sender and beneficiary number as signature
  private async encodeSHA512ForSignature(input: string): Promise<string> {
    logger.debug(`encodeSHA512 - (input)[${input}]`);

    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-512', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  // Generate the transaction reference
  private generateReferenceNumber(): string {
    const randomNum = Math.floor(Math.random() * Math.pow(10, 40)).toString();
    const numericPart = randomNum.slice(0, 15).padEnd(15, '0');

    const referenceNumber = `Tradelenda-${numericPart}`;

    return referenceNumber;
  }

  // Utility function to handle API response statuses
  handleTransferStatus(status: string, message: string, data?: any) {
    switch (status) {
      // Successful transfers
      case '00': // Approved or Completed Successfully
        toast.success('Transfer approved and completed successfully.');
        this.transferStatus = '00';
        this.clearTransferData();
        break;

      case '26': // Successful
        toast.success('Transfer successful.');
        this.transferStatus = '26';
        this.clearTransferData();
        break;

      // Pending transfers (no reversal)
      case '01': // Status Unknown, Please wait for Settlement Report
      case '02': // Status Unknown, Please wait for Settlement Report
        toast.info(
          'Transfer status unknown, please wait for the settlement report.',
        );
        break;

      case '09': // Request Processing in Progress
        toast.info('Request processing in progress.');
        break;

      case '25': // Unable to Locate Record
        toast.info('Unable to locate record. Please try again later.');
        break;

      case '94': // Duplicate Transaction
        toast.info(
          'Duplicate transaction detected. Please check your records.',
        );
        break;

      case '96': // System Malfunction
        toast.error('System malfunction. Please try again later.');
        break;

      case '500': // Internal server error
        toast.error('Internal server error. Please try again later.');
        break;

      // Failed with Reversal Required
      case '03': // Invalid Sender
        toast.error('Invalid sender. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '05': // Do not Honor
        toast.error('Transaction not honored. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '06': // Dormant Account
        toast.error('Dormant account. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '07': // Invalid Account
        toast.error('Invalid account. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '08': // Account Name Mismatch
        toast.error('Account name mismatch. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '12': // Invalid Transaction
        toast.error('Invalid transaction. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '13': // Invalid Amount
        toast.error('Invalid amount specified. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '14': // Invalid Batch Number
        toast.error('Invalid batch number. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '15': // Invalid Session or Record ID
        toast.error('Invalid session or record ID. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '16': // Unknown Bank Code
        toast.error('Unknown bank code. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '17': // Invalid Channel
        toast.error(
          'Invalid channel used for the transaction. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case '18': // Wrong Method Call
        toast.error(
          'Wrong method call for the transaction. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case '21': // Failed with reversal
        toast.error('Transaction failed. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '30': // Format Error
        toast.error('Format error in transaction data. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '57': // Transaction not Permitted to Sender
        toast.error(
          'Transaction not permitted to the sender. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case '58': // Transaction not Permitted on Channel
        toast.error(
          'Transaction not permitted on this channel. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case '61': // Transaction Limit Exceeded
        toast.error('Transaction limit exceeded. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '65': // Exceeds Withdrawal Frequency
        toast.error('Withdrawal frequency exceeded. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '68': // Response Received Too Late
        toast.error('Response received too late. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '69': // Unsuccessful Account/Amount Block
      case '70': // Unsuccessful Account/Amount Block
        toast.error(
          'Unsuccessful account/amount block. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case '71': // Empty Mandate Reference Number
        toast.error('Empty mandate reference number. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '81': // Transaction Failed
        toast.error('Transaction failed. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '91': // Beneficiary Bank Not Available
        toast.error('Beneficiary bank not available. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '92': // Routing Error
        toast.error('Routing error. Reversing transaction.');
        this.reverseTransaction();
        break;

      case '97': // Timeout Waiting for response from Destination
        toast.error(
          'Timeout waiting for response from the destination bank. Reversing transaction.',
        );
        this.reverseTransaction();
        break;

      case 'null': // Failed with reversal
        toast.error('Transaction failed. Reversing transaction.');
        this.reverseTransaction();
        break;

      // Failed with no debit or no reversal required
      case '34': // Suspected Fraud (No Reversal)
        toast.error('Transaction suspected of fraud. Contact support.');
        break;

      case '35': // Contact Sending Bank (No Reversal)
        toast.error('Please contact the sending bank.');
        break;

      case '51': // No Sufficient Funds (No Debit)
        toast.error('Insufficient funds. Transaction failed.');
        break;

      case '63': // Security Violation (No Reversal)
        toast.error('Security violation. Transaction failed.');
        break;

      case '98': // Transaction Exists (No Debit)
        toast.error('Transaction already exists.');
        break;

      case '99': // Transaction Failed (No Debit)
        toast.error('Transaction failed.');
        break;

      // Handling unrecognized statuses
      default:
        toast.error(message);
        break;
    }

    if (data?.txnId) {
      logger.debug(`Transaction ID: ${data.txnId}`);
    }
  }

  // Utility function to clear transfer data after successful transactions
  clearTransferData() {
    this.sender = undefined;
    this.receiver = undefined;
    this.selectedVfdBank = undefined;
  }

  // Utility function to handle reversals
  reverseTransaction() {
    // Logic for reversing the transaction can be added here
    logger.debug('Reversing the transaction...');
  }

  // Transfer function with appended status handler
  async transfer(transferData: {
    amount: string;
    transferType: TransferType;
    remark: string;
    senderAccountNumber: string | undefined;
    recipientBankCode: string;
    limit: string;
    metaData: {
      userId: string;
      transactionPin: string;
    };
  }) {
    if (new Number(transferData.amount) > new Number(transferData.limit)) {
      toast.error('Single transfer limit exceeded');
      return;
    }

    // Early exit if critical data is missing
    if (!this.sender || !this.receiver) {
      toast.error('Sender or receiver details are missing');
      return;
    }

    this.transferringFunds = true;

    const {
      accountNo: senderAccountNo,
      accountId: senderAccountId,
      clientId: senderClientId,
      client: senderClient,
      bvn: senderBvn,
    } = this.sender.data;

    const {
      account: { number: receiverAccountNo, id: id },
      clientId: receiverClientId,
      name: receiverName,
      bvn: receiverBvn,
      bank: receiverBank,
    } = this.receiver.data;

    try {
      const hashedPin = await this.encodeSHA512ForSignature(
        `${senderAccountNo}${receiverAccountNo}`,
      );

      // Get unique reference
      const reference = this.generateReferenceNumber();

      const transferDto: TransferDto = {
        fromAccount: senderAccountNo ?? '',
        uniqueSenderAccountId: senderAccountId ?? '',
        fromClientId: senderClientId ?? '',
        fromClient: senderClient ?? '',
        fromSavingsId: senderAccountId ?? '',
        fromBvn: senderBvn ?? '',
        toClientId: receiverClientId ?? '',
        toClient: receiverName ?? '',
        toSavingsId: id ?? '',
        toSession: id ?? '',
        toBvn: receiverBvn ?? '',
        toAccount: receiverAccountNo ?? '',
        toBank: transferData.recipientBankCode ?? '',
        signature: hashedPin,
        amount: transferData.amount,
        remark: transferData.remark,
        transferType: transferData.transferType,
        reference: reference,
        metaData: transferData.metaData,
      };

      logger.debug(`transferDto: `, transferDto);

      const res = await axios.post(
        `${BaseDirectories.API_BASE_URL}/vfd-nip/transfer`,
        transferDto,
        {
          headers: this.headers,
        },
      );

      const { status, message, data, error } = res.data;

      logger.debug(res.data, 'res.data');

      this.logger.info(
        `User | Tranfer to bank | ${toJS(this.userProfile?.email)}`,
        res.data,
      );
      if (res.data.error) {
        // Handle transfer status using the new utility function
        runInAction(() => {
          this.handleTransferStatus(status ?? error, message, data);
          this.transferringFunds = false;
        });
      } else {
        runInAction(() => {
          toast.success('Transfer approved and completed successfully.');
          this.transferStatus = '00';
          this.clearTransferData();
        });
      }
      this.setSubmitting(false);
    } catch (err: any) {
      this.transferringFunds = false;
      this.transferStatus = '';
      toast.error('An error occurred while transferring funds');
      if (err.message) toast.error(err.message);
      logger.error(err);
    }
  }

  /// Get Beneficiary Details
  async transactionStatus() {
    axios
      .get(`${BaseDirectories.API_BASE_URL}/vfd-nip/banks`, {
        headers: this.headers,
      })
      .then((res: any) => {
        runInAction(() => {
          if (res.error) {
          }
        });
      });
  }

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };
}
