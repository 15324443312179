/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import axios from 'axios';
import { configure, makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import BaseDirectories from '../base directories/BaseDirectories';
import logger from '../common/logger/CustomLogger';
import { BrowserLogger } from '../common/logger/Logger';

configure({ enforceActions: 'always' });

type Message = {
  type: string;
  msg: any;
};

type ObjectType = {
  [key: string]: any;
};

type Profile = {
  [key: string]: any;
};

export class BillStore {
  loadingData = false;
  submitting = false;
  resending = false;
  transactions = [];
  transaction = {};
  billCategories: any[] = [];
  billCategory: ObjectType = {};
  networkProviders: ObjectType = {};
  dataProviders: ObjectType = {};
  electricityProviders: ObjectType = {};
  dataPlan: ObjectType = {};
  airtimePlan: ObjectType = {};
  bouquetsPlan: ObjectType = {};
  bouquetDetails: ObjectType = {};
  billsPlan: ObjectType = {};
  bouquetsProviders: ObjectType = {};
  electricityPlans: ObjectType = {};
  meterDetails: ObjectType = {};
  iucDetails: ObjectType = {};
  bankReceiverFullName = '';
  walletReceiverFullName = '';
  token: any = sessionStorage.getItem('accessToken') || '';
  urlParams: any = '';
  message: Message = {
    type: '',
    msg: '',
  };
  error = {
    type: 'error',
    msg: '',
  };
  success = {
    type: 'success',
    msg: '',
  };

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
    });
  }

  getNetworkProvider() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      command: 'getOperators',
      version: '5',
      country: 'NG',
      productType: '1',
      productCategory: '1',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-airtime-provider`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setNetworkProviders(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  // purchaseAirtime(data: any) {
  //   const headers = {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${this.token}`,
  //     'Content-Type': 'application/json',
  //   };

  //   runInAction(() => {
  //     this.setSubmitting(true);
  //   });

  //   axios
  //     .post(
  //       `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/purchase-airtime`,
  //       data,
  //       { headers },
  //     )
  //     .then((response: any) => {
  //       if (response.data.error) {
  //         runInAction(() => {
  //           toast.error(response.data.message);
  //           this.setMessage('error', 'Transfer failed!');
  //         });
  //       } else {
  //         runInAction(() => {
  //           this.setNetworkProviders(response.data.data);
  //           this.setMessage('success', 'Transfer successful!');
  //         });
  //       }
  //     })
  //     .catch((error: any) => {
  //       runInAction(() => {
  //         toast.error(error.response.message);
  //         this.setMessage('error', 'Transfer failed!');
  //       });
  //     })
  //     .finally(() => {
  //       runInAction(() => {
  //         this.setSubmitting(false);
  //       });

  //       setTimeout(() => {
  //         runInAction(() => {
  //           this.setMessage('', '');
  //         });
  //       }, 8000);
  //     });
  // }

  getAirtimePlansByProvider(operator: number) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'getOperatorProducts',
      operator: Number(operator),
      productCategory: '1',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-provider-products`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setAirtelPlans(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  purchaseAirtime(transactionDetails: any) {
    const parsesdOperator = Number(transactionDetails.id);
    const staticPayload = {
      version: '5',
      command: 'execTransaction',
      operator: parsesdOperator,
    };

    const payload = {
      ...staticPayload,
      ...transactionDetails,
    };

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
      this.setLoading(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/purchase-airtime`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage('error', 'Airtime Purchase failed!');
          });
        } else {
          runInAction(() => {
            this.setMessage('success', 'Airtime Purchase Successful!');
          });
        }
      })
      .catch((error: any) => {
        let errorMessage = 'Transfer failed!';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        runInAction(() => {
          toast.error(errorMessage);
          this.setMessage('error', errorMessage);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
          this.setLoading(false);
        });

        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }

  getDataProvider() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      command: 'getOperators',
      country: 'NG',
      version: '5',
      productType: '1',
      productCategory: '4.0',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-data-provider`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setDataProviders(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getDataPlanByProvider(operator: number) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'getOperatorProducts',
      operator: Number(operator),
      productCategory: '4.0',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-provider-products`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setDataPlans(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  purchaseDataPlan(transactionDetails: any) {
    const parsesdOperator = parseInt(transactionDetails.operator);
    const staticPayload = {
      version: '5',
      command: 'execTransaction',
      operator: parsesdOperator,
      // simulate: 1,
    };

    const payload = {
      ...transactionDetails,
      ...staticPayload,
    };

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
      this.setLoading(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/purchase-data`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage('error', 'Data Bundle Purchase failed!');
          });
        } else {
          runInAction(() => {
            this.setMessage('success', 'Data Bundle Purchase Successful!');
          });
        }
      })
      .catch((response: any) => {
        let errorMessage = 'Data Bundle purchase failed!';
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.name
        ) {
          errorMessage = response.data.message;
        }

        runInAction(() => {
          toast.error(errorMessage);
          this.setMessage('error', 'Data Bundle purchase failed!');
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
          this.setLoading(false);
        });

        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }

  getElectricityProviders() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      command: 'getOperators',
      country: 'NG',
      version: '5',
      productCategory: '8.1',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-electricity-provider`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setElectricityProviders(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getBIllsByProvider(operator: number) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'getOperatorProducts',
      operator: Number(operator),
      productCategory: '8.1',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-provider-products`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setBillPlans(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  lookupPrepaidAccount(transactionDetails: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'lookupAccount',
      accountId: transactionDetails.accountId,
      productId: Number(transactionDetails.productId),
    };

    runInAction(() => {
      this.setSubmitting(true);
    });
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/lookup-account`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setMeterDetails(response.data);
          });
        }
      })
      .catch((err: any) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  // verifyMeter(data: any) {
  //   const headers = {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${this.token}`,
  //     'Content-Type': 'application/json',
  //   };

  //   runInAction(() => {
  //     this.setSubmitting(true);
  //   });
  //   axios
  //     .post(
  //       `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/validate-meter`,
  //       data,
  //       { headers },
  //     )

  //     .then((response: any) => {
  //       if (response.data.error) {
  //         // do nothing
  //       } else {
  //         runInAction(() => {
  //           this.setMeterDetails(response.data.data);
  //         });
  //       }
  //     })
  //     .catch((error: any) => {
  //       // do nothing
  //     })
  //     .finally(() => {
  //       runInAction(() => {
  //         this.setSubmitting(false);
  //       });
  //     });
  // }

  purchaseElectricity(transactionDetails: any) {
    const parsesdOperator = String(transactionDetails.operator); //Mary
    // const parsesdOperator = parseInt(transactionDetails.operator);
    const staticPayload = {
      version: '5',
      command: 'execTransaction',
      operator: parsesdOperator.toString(),
      // simulate: 1,
    };
    transactionDetails.productId = parseInt(transactionDetails.productId);
    transactionDetails.amountOperator =
      transactionDetails.amountOperator.toString();

    const payload = {
      ...transactionDetails,
      ...staticPayload,
    };

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
      this.setLoading(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/purchase-power`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage('error', 'Electricity Bill Purchase failed!');
          });
        } else {
          runInAction(() => {
            this.setMessage('success', 'Electricity Bill Purchase Successful!');
          });
        }
      })
      .catch((error: any) => {
        // .catch((response: any) => {
        let errorMessage = 'Prepaid Bill purchase failed!';
        if (
          error.response &&
          error.response.data &&
          error.response.data.status &&
          error.response.data.status.name
        ) {
          errorMessage = error.response.data.message;
        }
        // if (
        //   response &&
        //   response.data &&
        //   response.data.status &&
        //   response.data.status.name
        // ) {
        //   errorMessage = response.data.message;
        // }

        runInAction(() => {
          toast.error(errorMessage);
          this.setMessage('error', errorMessage);
          // this.setMessage('error', 'Prepaid Bill purchase failed!');
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
          this.setLoading(false);
        });
        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }

  // getCableTvProvider(provider: string) {
  //   const headers = {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${this.token}`,
  //     'Content-Type': 'application/json',
  //   };

  //   this.setLoading(true);
  //   axios
  //     .get(
  //       `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/get-bouquets/{provider}?provider=${provider}`,
  //       { headers },
  //     )
  //     .then((response) => {
  //       if (response.data.error) {
  //         runInAction(() => {
  //           toast.error(response.data.message);
  //         });
  //       } else {
  //         runInAction(() => {
  //           this.setBouquets(response.data.data);
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       runInAction(() => {
  //         toast.error(err.response.message);
  //       });
  //     })
  //     .finally(() => {
  //       this.setLoading(false);
  //     });
  // }
  getCableTvProvider() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      command: 'getOperators',
      country: 'NG',
      version: '5',
      productCategory: '7.0',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-tv-cables`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setBouquets(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getBouquetsPlanByProvider(operator: number) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'getOperatorProducts',
      operator: operator,
      productCategory: '7.0',
    };

    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/get-provider-products`,
        payload,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setBouquetsPlans(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  lookupBouquetAccount(transactionDetails: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      version: '5',
      command: 'lookupAccount',
      accountId: transactionDetails.accountId,
      productId: Number(transactionDetails.productId),
    };

    runInAction(() => {
      this.setSubmitting(true);
    });
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/lookup-account`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setBouquetDetails(response.data);
          });
        }
      })
      .catch((err: any) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  purchaseTvBouquet(transactionDetails: any) {
    const parsesdOperator = parseInt(transactionDetails.operator);
    const staticPayload = {
      version: '5',
      command: 'execTransaction',
      operator: parsesdOperator,
      // simulate: 1,
    };

    const payload = {
      ...transactionDetails,
      ...staticPayload,
    };

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
      this.setLoading(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/normiworld-bill-payment/purchase-tv-bouquets`,
        payload,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage(
              'error',
              `${transactionDetails.bouquetTypes} Purchase failed!`,
            );
          });
        } else {
          runInAction(() => {
            this.setMessage(
              'success',
              `${transactionDetails.bouquetTypes} Purchase Successful!`,
            );
          });
        }
      })
      .catch((response: any) => {
        let errorMessage = `${transactionDetails.bouquetTypes} purchase failed!`;
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.name
        ) {
          errorMessage = response.data.message;
        }

        runInAction(() => {
          toast.error(errorMessage);
          this.setMessage(
            'error',
            `${transactionDetails.bouquetTypes} Bill purchase failed!`,
          );
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
          this.setLoading(false);
        });
        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }

  getAllTransaction() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/all-transactions`,
        { headers },
      )
      .then((response) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
          });
        } else {
          runInAction(() => {
            this.setTransactions(response.data);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          toast.error(err.response.message);
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  verifyIUC(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
    });
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/validate-iuc`,
        data,
        { headers },
      )

      .then((response: any) => {
        if (response.data.error) {
          // do nothing
        } else {
          runInAction(() => {
            this.setIUCDetails(response.data.data);
          });
        }
      })
      .catch((error: any) => {
        // do nothing
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  renewSubscription(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/subscription-renewal`,
        data,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage('error', 'Transfer failed!');
          });
        } else {
          runInAction(() => {
            this.setMessage('success', 'Transfer successful!');
          });
        }
      })
      .catch((error: any) => {
        runInAction(() => {
          toast.error(error.response.message);
          this.setMessage('error', 'Transfer failed!');
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });

        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }
  updateSubscription(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    runInAction(() => {
      this.setSubmitting(true);
    });

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/cydene-bill-payment/subscriptions-update`,
        data,
        { headers },
      )
      .then((response: any) => {
        if (response.data.error) {
          runInAction(() => {
            toast.error(response.data.message);
            this.setMessage('error', 'Transfer failed!');
          });
        } else {
          runInAction(() => {
            this.setMessage('success', 'Transfer successful!');
          });
        }
      })
      .catch((error: any) => {
        runInAction(() => {
          toast.error(error.response.message);
          this.setMessage('error', 'Transfer failed!');
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });

        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 8000);
      });
  }

  setTransactions = (res: any) => {
    this.transactions = res.data;
  };

  setIUCDetails = (res: any) => {
    this.iucDetails = res.data;
  };

  setLoading = (val: boolean) => {
    this.loadingData = val;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setResending = (val: boolean) => {
    this.resending = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setError = (err: string) => {
    this.error.msg = err;
  };

  setSuccess = (msg: string) => {
    this.success.msg = msg;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setToLocalStorage = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  clearFromLocalStorage = () => {
    window.sessionStorage.clear();
  };

  setNetworkProviders = (res: any) => {
    if (res && res.data.result) {
      this.networkProviders = Object.values(res.data.result);
    } else {
      logger.error('Invalid response:', res);
    }
  };

  setAirtelPlans = (res: any) => {
    this.airtimePlan = Object.values(res.data.result.products);
  };

  setDataProviders = (res: any) => {
    this.dataProviders = Object.values(res.data.result);
  };

  setDataPlans = (res: any) => {
    this.dataPlan = Object.values(res.data.result.products);
  };

  setMeterDetails = (res: any) => {
    this.meterDetails = Object.values(res.data.result);
  };

  setBouquetDetails = (res: any) => {
    this.bouquetDetails = Object.values(res.data.result);
  };

  setElectricityProviders = (res: any) => {
    this.electricityProviders = Object.values(res.data.result);
  };

  setDataPlan = (res: any) => {
    this.dataPlan = res.data;
  };

  setBouquets = (res: any) => {
    this.bouquetsProviders = Object.values(res.data.result);
  };

  setBouquetsPlans = (res: any) => {
    this.bouquetsPlan = Object.values(res.data.result.products);
  };

  setBillPlans = (res: any) => {
    this.billsPlan = Object.values(res.data.result.products);
  };
}
