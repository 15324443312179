import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './sections/Header';
import Footer from './sections/Footer';
import HackathonHS from './sections/HackathonHS';
import HackathonF from './sections/HackathonF';
import Newsletter from './sections/Newsletter';
import HTC from './sections/HTC';
import Blank from './sections/Blank';
// import { Outlet } from 'react-router';

const HackathonPage = () => {
  return (
    <>
      <div className="hackathon-page">
        <Helmet>
          {/* <title>Why Trade Lenda | Trade Lenda Hackathon!</title> */}
          <title>
            Trade Lenda Hackathon - Innovating the Future of SME Banking
          </title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content="Join the Trade Lenda Hackathon, a platform for developers and innovators to build solutions that will shape the future of SME banking and digital financial services."
          />
          <meta
            name="keywords"
            content="Trade Lenda hackathon, SME innovation, digital banking competition, developer events"
          />
        </Helmet>
        <Header />
        <>
          <HackathonHS />
          <HackathonF />
          <Blank height="40px" />
          <HTC />
          {/* <Outlet /> */}
        </>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default HackathonPage;
