/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';

const PasswordResetSuccessful = () => {
  const location: any = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="verify ">
        <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div>
        <div className="logo ">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
            alt=""
            style={{ width: '20%' }}
          />
        </div>

        <div className="verification-img my-5">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/verification.png`}
            alt=""
            style={{ width: '15%' }}
          />
        </div>
        <div className="container d-flex justify-content-center align-items-center">
          <div className="card py-3 px-2 d-flex justify-content-center align-items-center">
            <h3 className="mt-3">Password reset requested</h3>
            <p className="mobile-text p-3">
              We've sent you an email. If you do not see a password reset mail
              in your inbox, kindly check your spam mail.
            </p>
            <Button
              classes="primary-btn btn-lg mb-2"
              content="Go Back"
              onClick={() => {
                location.state?.email.includes('@tradelenda.com') &&
                location.state?.email !== 'test@tradelenda.com'
                  ? window.location.replace('https://legend.tradelenda.com')
                  : navigate('/login');
              }}
            />
          </div>
        </div>
        <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccessful;
