import { mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Button from '../../../components/ui/Button';
import LoanCalculator from './LoanCalculator';

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div className="container">
        <div className="row mb-5 justify-content-center">
          <div className="col-10">
            <h6>
              <Icon path={mdiCircleSmall} size={2} />
              <span>how it works</span>
            </h6>
            <h2>Access financial solution for your business</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center md-justify-content-between gap-lg-0 gap-5 mb-5">
          <div className="take-loan col-lg-6 col-md-8 col-sm-10 order-last">
            <div className="hero-text">
              <div className="hs-section">
                <div className="first-cont wwd-content">
                  <h3>Create an account</h3>
                  <p>
                    Create an Account with Trade Lenda and access funding to
                    grow your business
                  </p>
                </div>
                <div className="wwd-content">
                  <h3>Complete your profile</h3>
                  <p>
                    Complete your profile by telling about your business and
                    uploading documents to verify your business
                  </p>
                </div>
                <div className="wwd-content">
                  <h3>Apply for loan</h3>
                  <p>
                    Apply for our lending financial solution no long forms or
                    collateral
                  </p>
                </div>
                <div className="d-flex md-justify-content-center justify-content-center">
                  <Button
                    classes="white-btn btn-lg"
                    content="Take Loan"
                    route="/dashboard/loans"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-11">
            <LoanCalculator />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
