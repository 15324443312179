import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Values = () => {
  return (
    <section className="values">
      <div className="container">
        <div className="text-center">
          <h2>Our core values</h2>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-4">
              <div className="value text-center">
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/vector1.png`}
                    alt="Trade Lenda"
                  />
                </span>
                <h4>Professionalism</h4>
                <p>
                  Our goal is to pay attention to you and give you utmost
                  satisfaction.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="value text-center">
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/vector2.png`}
                    alt="Trade Lenda"
                    className="innovation"
                  />
                </span>
                <h4>Innovation</h4>
                <p>
                  We embrace new ideas, and strategic methods to improve your
                  experience
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="value text-center">
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/vector3.png`}
                    alt="Trade Lenda"
                  />
                </span>
                <h4>Service</h4>
                <p>
                  We focus on going beyond your imaginations to meet your
                  business needs.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4">
              <div className="value text-center">
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/vector4.png`}
                    alt="Trade Lenda"
                  />
                </span>
                <h4>Excellence</h4>
                <p>
                  We set and achieve ambitious goals. we are passionate about
                  people, process and our product.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="value text-center">
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/vector5.png`}
                    alt="Trade Lenda"
                    className="speed"
                  />
                </span>
                <h4>Speed</h4>
                <p>
                  Time is a money, so we stay ahead to help you take your
                  business to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
