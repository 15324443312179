import React from 'react';

const LoanPolicyPdf = () => {
  return (
    <div>
      <embed
        src="/LOAN POLICY.pdf"
        width="100%"
        height="100vh"
        style={{ height: '100vh' }}
      />
    </div>
  );
};

export default LoanPolicyPdf;
