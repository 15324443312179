import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Footer = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <footer className="lp-footer">
      <div className="lp-footer-container">
        <div className="row  align-items-center align-items-lg-start justify-content-center lgscrn-footer-features">
          <div className="col-lg-4 lp-ft-link-left">
            <Link to="/" className="logo">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/TradeLendaLOGO.png`}
                alt="Trade Lenda"
              />
            </Link>
            <div className="lp-footer-address  no-show-add">
              <h6>ADDRESS</h6>
              <p>Maryland, Ikeja, Lagos</p>
              {/* <a href="">info@tradelenda.com</a> */}
              <div className="d-flex gap-4">
                <img
                  src={`${BaseDirectories.ICONS_DIR}/nigeria.png`}
                  alt="Nigeria flag"
                  style={{ width: '10%' }}
                />
                <img
                  src={`${BaseDirectories.ICONS_DIR}/united-states.png`}
                  alt="USA flag"
                  style={{ width: '10%' }}
                />
                <img
                  src={`${BaseDirectories.ICONS_DIR}/canada.png`}
                  alt="Canada flag"
                  style={{ width: '10%' }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="lp-ft-flex">
              <div className="col-lg">
                <div className="row  ">
                  <div className="col ">
                    <h6>Products</h6>
                    <ul>
                      <li>
                        <Link to="/loan_for_business">Loans </Link>
                      </li>
                      <li>
                        <Link to="/login">Trade Lenda account</Link>
                      </li>
                      <li>
                        <Link to="/islamic_facility_financing">
                          Islamic Facility Financing
                        </Link>
                      </li>
                      <li>
                        <Link to="/asset_financing">Asset Financing</Link>
                      </li>
                      <li>
                        <Link to="/invest_with_us">Investments</Link>
                      </li>
                      <li>
                        <Link to="/insurance-preview">Insurance</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <h6>Company</h6>
                    <ul>
                      <li>
                        <Link to="/about_us">About us </Link>
                      </li>
                      <li>
                        <Link to="/media/video">Media</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/market_structure">Structured markets</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg">
                <div className="row">
                  <div className="col">
                    <h6>Support</h6>
                    <ul>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/faq">Knowledge base</Link>
                      </li>
                      <li>
                        <Link to="../faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="/support">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <h6>Legal</h6>
                    <ul>
                      <li>
                        <Link to="/T&C">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="../Privacy-policy"> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8 mobl-footer-features">
          <div className="logo-cont">
            <Link to="/" className="logo">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/TradeLendaLOGO.png`}
                alt="Trade Lenda"
              />
            </Link>
          </div>

          <div className="lp-inwy-accordion">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    PRODUCTS
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link to="/login">Trade Lenda account</Link>
                      </li>
                      <li>
                        <Link to="/market_structure">Structured markets</Link>
                      </li>
                      <li>
                        <Link to="/loan_for_business">Loans </Link>
                      </li>
                      <li>
                        <Link to="/islamic_facility_financing">
                          Islamic Facility Financing
                        </Link>
                      </li>
                      <li>
                        <Link to="/asset_financing">Asset Financing</Link>
                      </li>
                      <li>
                        <Link to="/invest_with_us">Investments</Link>
                      </li>
                      <li>
                        <Link to="/insurance-preview">Insurance</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo">
                    COMPANY
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link to="/about_us">About us </Link>
                      </li>
                      <li>
                        <Link to="/media/video">Media</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree">
                    SUPPORT
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link to="/faq">Knowledge base</Link>
                      </li>
                      <li>
                        <Link to="../faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="/support">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour">
                    LEGAL
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link to="/T&C">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="../Privacy-policy"> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="lp-footer-bs">
          <div className="footer-bs-txt">
            <p>
              Trade Lenda is a digital bank for Small and Medium Scale
              Enterprises (SMEs), empowering them through strategic financing to
              sustain business growth and contribute towards economic prosperity
              in line with the United Nations Sustainable Development Goal 1, 2,
              5,8 and 9. Our vision is to develop financial solutions for SMEs
              capable of delivering the next 1 million jobs across Africa by
              2027.
            </p>
            <p>You can reach us via email:</p>
            <p>
              General Inquiries and Partnerships:{' '}
              <strong>info@tradelenda.com </strong> <br />
              Investors Relations: <strong>invest@tradelenda.com</strong>
            </p>
            <p>
              All personal Data & Information are confidential and protected in
              line with NDPR data privacy.
            </p>
          </div>

          {/* <div className="lp-footer-address show-add">
            <h6>ADDRESS</h6>
            <p>Maryland, Ikeja, Lagos</p>
            <p>info@tradelenda.com</p>
            <p>reach us via Call +2348095448877 Or Chat +2347041170338</p>
          </div> */}
          <div className="copyright-sm-icons">
            <div>
              <p>&copy; {year} All rights reserved. Trade Lenda</p>
            </div>
            <div className="lp-footer-icons">
              <a
                href="https://www.linkedin.com/company/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} className="lp-fi" />
              </a>
              <a
                href="https://instagram.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="lp-fi" />
              </a>
              <a
                href="https://twitter.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="lp-fi" />
              </a>
              <a
                href="https://www.facebook.com/tradelenda"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="lp-fi" />
              </a>
            </div>
          </div>
          <div className="d-flex gap-4 d-lg-none justify-content-center mt-2">
            <img
              src={`${BaseDirectories.ICONS_DIR}/nigeria.png`}
              alt="Nigeria flag"
              style={{ width: '10%' }}
            />
            <img
              src={`${BaseDirectories.ICONS_DIR}/united-states.png`}
              alt="USA flag"
              style={{ width: '10%' }}
            />
            <img
              src={`${BaseDirectories.ICONS_DIR}/canada.png`}
              alt="Canada flag"
              style={{ width: '10%' }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
