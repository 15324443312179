/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  setOpenPaymentModal: (state: any) => void;
};

const InputAmountModal: FC<Props> = ({
  route,
  button,
  btnText1,
  onClick = () => {
    setOpenPaymentModal(false);
  },
  investmentData,
  setOpenPaymentModal,
}) => {
  const [investAmount, setInvestAmount] = useState('');
  const [fromWalletIdAccountNumber, setFromWalletIdAccountNumber] =
    useState('');
  const { investStore, walletStore } = useContext(StoreContext);
  const { message, submittingInvestment } = investStore;
  const { multipleWallet } = walletStore;
  const [errorMessage, setErrorMessage] = useState('');
  const [showBankSelection, setShowBankSelection] = useState(true);
  const navigate = useNavigate();

  const updateData = (e: any) => {
    const rawValue = e.target.value.replace(/,/g, '');
    if (/^\d*\.?\d*$/.test(rawValue)) {
      // Validate if input is a number
      const formattedValue = Number(rawValue).toLocaleString(); // Format with commas
      setErrorMessage('');
      setInvestAmount(formattedValue);
    } else {
      setErrorMessage('Enter a valid amount');
    }
  };

  const handleSubmit = () => {
    if (
      Number(investAmount) < Number(investmentData.minimumInvestmentAmount) ||
      Number(investAmount) <= 0
    ) {
      toast.error('Investment amount is below minimum investment');
    } else if (
      fromWalletIdAccountNumber == '' ||
      fromWalletIdAccountNumber == null ||
      fromWalletIdAccountNumber == undefined ||
      fromWalletIdAccountNumber.length < 10
    ) {
      toast.error('Wallet not selected!');
    } else {
      const removeCommaInAmount = investAmount.replace(/,/g, '');
      const investmentParams = {
        productCode: investmentData.productCode,
        investmentAmount: Number(removeCommaInAmount),
        fromWalletIdAccountNumber: fromWalletIdAccountNumber,
      };
      investStore.createInvestment(investmentParams);
    }
  };
  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment Created successfully.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [submittingInvestment, message]);

  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span
          onClick={() => {
            setOpenPaymentModal(false);
          }}
          className="close"
          role="button">
          <FaTimes />
        </span>
        <div className="row justify-content-center amount-box">
          {/* // BANK SELECTION */}
          {showBankSelection && (
            <div className="row justify-content-center invest-box">
              <h5
                className="my-3"
                style={{ fontSize: '20px', textTransform: 'uppercase' }}>
                Select a wallet
              </h5>
              <div className="col-12">
                {toJS(multipleWallet) && toJS(multipleWallet)?.length > 0
                  ? toJS(multipleWallet)?.map(
                      (walletData: any, index: number) => (
                        <div className="w-100 gap-2 mt-2" key={index}>
                          <h6
                            className="wallet-deets d-flex align-items-start my-3 color-heading"
                            style={{
                              fontSize: '14px',
                              textTransform: 'uppercase',
                            }}>
                            Wallet {index + 1}
                          </h6>
                          <div
                            className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets invest-selection"
                            onClick={() => {
                              if (walletData?.availableBalance < 100) {
                                toast.error('Amount must be greater than ₦100');
                              } else {
                                setFromWalletIdAccountNumber(
                                  walletData?.walletIdAccountNumber,
                                );
                                setErrorMessage('');
                                setInvestAmount('');
                                setShowBankSelection(false);
                              }
                            }}>
                            <h6>{walletData?.banker}</h6>
                            <p>
                              ₦{' '}
                              {walletData?.availableBalance != 'NaN' ||
                              walletData?.availableBalance != null ||
                              walletData?.availableBalance != undefined
                                ? walletData?.availableBalance
                                  ? new Intl.NumberFormat('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(walletData?.availableBalance)
                                  : '0.00'
                                : '0.00'}
                            </p>
                          </div>
                        </div>
                      ),
                    )
                  : null}
              </div>
            </div>
          )}
          {!showBankSelection && (
            <div className="row justify-content-center invest-box">
              <div className="col-12">
                <h5
                  className="my-3"
                  style={{ fontSize: '20px', textTransform: 'uppercase' }}>
                  Enter Amount
                </h5>
                <small
                  className=""
                  style={{
                    color: '#054b99',
                    alignSelf: 'center',
                    fontWeight: 'bold',
                  }}>
                  Wallet Balance: ₦{' '}
                  {toJS(multipleWallet) &&
                    toJS(multipleWallet).length > 0 &&
                    toJS(multipleWallet).map(
                      (walletData: any) =>
                        walletData.walletIdAccountNumber ===
                          fromWalletIdAccountNumber &&
                        new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(walletData?.availableBalance),
                    )}
                </small>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-white"
                      style={{
                        borderRight: '0',
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                      }}>
                      ₦
                    </span>
                  </div>
                  <Input
                    style={{ borderLeft: '0' }}
                    name="amount"
                    classes="form-control text-center"
                    type="text"
                    id="amount"
                    value={investAmount}
                    onChange={updateData}
                  />
                </div>
                {errorMessage !== '' ? (
                  <small className="text-danger">{errorMessage}</small>
                ) : null}
                <p>Minimum Investment</p>
                <p>
                  = ₦{' '}
                  {investmentData.minimumInvestmentAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentData.minimumInvestmentAmount)
                    : '0.00'}
                </p>
                {button && (
                  <div className="group-btn justify-content-center">
                    {btnText1 && (
                      <>
                        <Button
                          classes="primary-btn w-auto btn-lg waves-effect"
                          content="Back"
                          onClick={() => setShowBankSelection(true)}
                          type="button"
                        />
                        <Button
                          classes="primary-btn w-auto btn-lg waves-effect"
                          content={btnText1}
                          route={route}
                          type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(InputAmountModal);
