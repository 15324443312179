import BaseDirectories from '../../../base directories/BaseDirectories';
import Blank from './Blank';

const HackathonF = () => {
  const datas = [
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1y.png`,
      text: 'User-friendly interface for easy navigation',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1b.png`,
      text: 'Real-time inventory tracking and reporting',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1g.png`,
      text: 'Online access for customers to place orders',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2g.png`,
      text: 'An optimized order fulfillment flow',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2y.png`,
      text: 'Automated alerts for low stock, expirations, and discrepancies',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2b.png`,
      text: 'Customizable dashboards for personalized insights',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1b.png`,
      text: ' Ease of Integration with existing ERP and accounting systems',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon3g.png`,
      text: 'Data encryption and secure storage',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon3y.png`,
      text: 'User authentication and role-based permissions',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1g.png`,
      text: 'Multi-User access',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2y.png`,
      text: 'Should be dynamic and acceptable on multiple browsers.',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon3b.png`,
      text: 'Minimal Load Time.',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1y.png`,
      text: 'Scan-and-track functionality for easy inventory management',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2b.png`,
      text: 'Offline access for remote or warehouse use',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon3g.png`,
      text: 'Push notifications for critical updates',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon1b.png`,
      text: 'Barcode scanning and label printing',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon2g.png`,
      text: 'Available for iOS and Android',
    },
    {
      img: `${BaseDirectories.ICONS_DIR}/icon3y.png`,
      text: 'Regular security updates and patches',
    },
  ];
  return (
    <>
      <Blank height="40px" />
      <section className="feature-container">
        <div className="text-center text-cont">
          <h6>KEY FEATURES</h6>
          <h2>Web Application</h2>
          <p>The web application should have the following features:</p>
        </div>
        <div className="row datas">
          {datas.map((data, index) => (
            <div
              className="col-12 col-md-4 mb-4 text-center row justify-content-center align-content-center"
              key={index}>
              <div>
                <img src={data.img} alt="" style={{ width: '15%' }} />
              </div>
              <p className="text-wrap mt-2">{data.text}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default HackathonF;
