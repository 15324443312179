import React, { FC } from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import SignupForm from '../../components/forms/SignupForm';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

const UserSignup: FC = (_props) => {
  return (
    <div>
      <Helmet>
        {/* <title>SIGN UP | Create an account on Trade Lenda!</title> */}
        <title>
          Sign Up for Trade Lenda – Start Your SME Digital Banking Journey
        </title>
        <meta
          name="description"
          content="Sign up for Trade Lenda and gain access to SME-focused digital banking services like loans, bill payments, insurance, and investment options."
        />
        <meta
          name="keywords"
          content="Sign up, SME banking registration, digital banking account, Trade Lenda account"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="sign-up">
        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 signup-image-container">
              <h1>Create an account to get started</h1>
              <div className=" ">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/signupImage2.png`}
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-9 signup-form-container">
              <div className="d-md-none d-flex text-start mt-4 back-top">
                <Link to="/">
                  <Icon path={mdiArrowLeft} size={1} />
                </Link>
              </div>
              <Link to="/">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
                  alt=""
                  style={{
                    width: '15%',
                    marginBottom: '0',
                    marginTop: '50px',
                  }}
                />
              </Link>
              <div className="signup-form ">
                <h2>Sign Up</h2>
                <p className="signup-for-p">Create an account to get started</p>
                <SignupForm />

                <div>
                  <p className="mt-2">
                    Don't have an account?{' '}
                    <u style={{ color: '#054b99' }}>
                      <Link to="/login">Login</Link>
                    </u>
                  </p>
                </div>

                <div className="d-none d-md-block back">
                  <Link to="/">
                    <Icon path={mdiArrowLeft} size={1} />
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default UserSignup;
