import { Autoplay, FreeMode, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Footer from './Footer';
import Header from './Header';
import Newsletter from './Newsletter';
import Testimonials from './Testimonials';

const Career = () => {
  return (
    <div className="bg-white career">
      <Header />

      <section className="media-hero">
        {/* <div className="container"> */}
        <div className="">
          <div className="lp-team">
            <div className="container">
              <div className="row justify-content-center mb-5">
                <div className="text-content col-md-10 d-flex flex-column align-items-center">
                  <h6>CAREERS AT TRADELENDA</h6>
                  <h2>Build with us</h2>
                  <p>
                    Our team is passionate about work and we exhibit both
                    personal and professional pride in the products and services
                    we provide to customers. Join us today and be a part of
                    something truly special.
                  </p>
                  <Button
                    content="see open roles"
                    classes="primary-btn btn-lg mt-3"
                  />
                </div>
              </div>
            </div>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              grabCursor={true}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="lp-career-container mySwiper">
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic8.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic1.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic2.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic3.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic5.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic6.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic9.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic10.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic7.png`}
                    alt="Trade Lenda"
                    className="pe-0"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/* </div> */}
      </section>

      <Testimonials />

      <section className="work-with-us">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="text-content col-md-10 col-lg-8 d-flex flex-column align-items-center text-center">
              <h2>Work with the coolest people on the block</h2>
              <p>
                We currently do not have open roles. However, we appreciate your
                interest and would be happy to connect with you for potential
                future opportunities. Send us a mail{' '}
                <a href="mailto:careers@tradelenda.com">
                  careers@tradelenda.com
                </a>
              </p>
              <h4>Have questions about Joining Tradelenda?</h4>
              <h6>Read more about us on </h6>
              <a
                href="https://www.linkedin.com/company/tradelenda"
                target="_blank">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/linkedin-logo.png`}
                  alt="Linkedin logo"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />

      <Footer />
    </div>
  );
};

export default Career;
