import BaseDirectories from '../../../base directories/BaseDirectories';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Invest = () => {
  return (
    <section className="landing-page-hero invest-section">
      <div className="container">
        <div className=" align-items-center justify-content-center">
          <div className="row">
            <div className="col-md-4">
              <div className="card card1">
                <h3>Real-time Monitoring</h3>
                <p>
                  Enjoy convenient and transparent real-time monitoring of your
                  investment on our platform
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card2">
                <h3>Dedicated Managers</h3>
                <p>
                  Lenders get dedicated relationship manager to attend specially
                  to them
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card3">
                <h3>Secured Investments</h3>
                <p>
                  Investments are secure, as our borrower loans are backed by
                  insurance and physical collateral
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Section */}
        {/* Payment Section */}

        <div className="plans mt-lg-5 mt-2">
          <h1 style={{ textAlign: 'center' }}>plans That work for you</h1>

          <div
            className="d-flex flex-wrap gap-5 justify-content-center align-items-center"
            style={{ marginTop: '2rem' }}>
            <div className="">
              <div className="inwu-pay-card">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/red-frame.png`}
                  alt="Trade Lenda"
                />
                <div className="inwu-card-content">
                  <h5>BASIC LENDA </h5>
                  <div
                    className="inwu-card-content-upper"
                    style={{ background: 'rgb(244, 183, 64, 0.1)' }}>
                    <h4>₦50,000 </h4>
                    <h6>1.5% Per Month</h6>
                    <h6>Minimum Tenor - 3 month</h6>
                  </div>
                  <p>
                    The Lenda's D-lite plan ranges between 50,000- 999,999 at 3
                    months minimum tenor and 1.5% rate per month
                  </p>
                  {/* <Button
                    classes="primary-btn btn-lg me-lg-3"
                    content="Lend Now"
                    // route="/dashboard/invest"
                    route="/dashboard/invest"
                  /> */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="inwu-pay-card">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/blue-frame.png`}
                  alt="Trade Lenda"
                />
                <div className="inwu-card-content">
                  <h5>CLASSIC LENDA </h5>
                  <div
                    className="inwu-card-content-upper"
                    style={{ background: 'rgb(5, 75, 153, 0.1)' }}>
                    <h4>₦1,000,000 </h4>
                    <h6>2% Per Month</h6>
                    <h6>Minimum Tenor - 3 month</h6>
                  </div>
                  <p>
                    The Lenda's D-lite plan ranges between 1,000,000- 9,999,999
                    at 3 months minimum tenor and 2% rate per month
                  </p>
                  {/* <Button
                    classes="primary-btn btn-lg me-lg-3"
                    content="Lend Now"
                    route="/dashboard/invest"
                  /> */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="inwu-pay-card">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/green-frame.png`}
                  alt="Trade Lenda"
                />
                <div className="inwu-card-content">
                  <h5>ELITE LENDA</h5>
                  <div
                    className="inwu-card-content-upper"
                    style={{ background: '#ECF7EB' }}>
                    <h4 className="e-lite">₦10,000,000 </h4>
                    <h6>2.5% Per Month</h6>
                    <h6>Minimum Tenor - 3 month</h6>
                  </div>
                  <p>
                    The Lenda's E-lite plan ranges between 10,000,000-
                    49,999,999 at 3 months minimum tenor and 2.5% interest rate
                    per month
                  </p>
                  {/* <Button
                    classes="primary-btn btn-lg me-lg-3"
                    content="Lend Now"
                    // route="/dashboard/invest"
                    route="/dashboard/invest"
                  /> */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="inwu-pay-card">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/brown-frame.png`}
                  alt="Trade Lenda"
                />
                <div className="inwu-card-content">
                  <h5>DIAMOND Lenda</h5>
                  <div
                    className="inwu-card-content-upper"
                    style={{ background: 'rgb(244, 183, 64, 0.2)' }}>
                    <h4 className="platinum">₦50,000,000 </h4>
                    <h6>3% Per Month</h6>
                    <h6>Minimum Tenor - 3 months</h6>
                  </div>
                  <p>
                    The lenda's Patinum plan ranges between 50,000,000-
                    100,999,999 at 3 months minimum tenor and 3% Interest rate
                    per month
                  </p>
                  {/* <Button
                    classes="primary-btn btn-lg me-lg-3"
                    content="Lend Now"
                    // route="/dashboard/invest"
                    route="/dashboard/invest"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invest;
