import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Team = () => {
  return (
    <section className="team">
      <div className="lp-team mb-5">
        <h1>Meet our team</h1>
        <div className="lp-team-container mb-3">
          {team.map((teams, i: number) => (
            <div className="lp-team-card" key={i}>
              <div className="team-img">
                <img
                  src={teams.img}
                  alt={teams.name}
                  className="lp-team-card-img"
                  style={{ background: '#A0A3BD' }}
                />
              </div>
              <h4 style={{ fontWeight: 'bold' }}>{teams.name}</h4>
              <h5 style={{ color: '#44AB3B' }}>{teams.title}</h5>
              <a
                href={`https://${teams.linkedIn}`}
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} className="lp-fi" />
              </a>
            </div>
          ))}
        </div>
        <h1 style={{ color: '#054b99' }}>Advisor</h1>
        <div className="lp-team-container">
          {teamAdvisors.map((teams, i: number) => (
            <div className="lp-team-card" key={i}>
              <div className="team-img">
                <img
                  src={teams.img}
                  alt={teams.name}
                  className="lp-team-card-img"
                  style={{ background: '#A0A3BD' }}
                />
              </div>
              <h4 style={{ fontWeight: 'bold' }}>{teams.name}</h4>
              <h5>{teams.title}</h5>
              <a
                href={`https://${teams.linkedIn}`}
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} className="lp-fi" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const team = [
  {
    img: `${BaseDirectories.TEAM_DIR}/adeshina.png`,
    name: 'Adeshina Adewumi',
    title: ' CEO',
    linkedIn: 'linkedin.com/in/ajadewumi',
  },
  // {
  //   img: `${BaseDirectories.TEAM_DIR}/oyin.jpg`,
  //   name: 'Oyindamola Olaniyan',
  //   title: ' COO',
  // },
  {
    img: `${BaseDirectories.TEAM_DIR}/tosin.jpg`,
    name: 'OLUWATOSIN AYODELE',
    title: ' CTO',
    linkedIn: 'linkedin.com/in/tosan45',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/ogunwale.png`,
    name: 'DAMILOLA OGUNWALE',
    title: 'OPERATIONS LEAD',
    linkedIn: 'linkedin.com/in/oluwadamilola-ogunwale-472997115',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/John.png`,
    name: 'JOHN ERIMAKO',
    title: 'PROJECT LEAD',
    linkedIn: 'linkedin.com/in/john-erimako-a5723631',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/alerechi.png`,
    name: 'ONUOMA ALERECHI',
    title: 'BRAND STRATEGIST',
    linkedIn: 'linkedin.com/in/onuoma-alerechi',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/Amara.png`,
    name: 'AMARA OKEKE',
    title: 'SENIOR FINANCE OFFICER',
    linkedIn: 'linkedin.com/in/amara-okeke-aca-mba-91a26475',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/Anthony.png`,
    name: 'ANTHONY OLORUNKIYA',
    title: 'CREDIT ANALYST',
    linkedIn:
      'linkedin.com/in/anthony-olorunkiya-bsc-ed-mcib-mcilrm-ica-cfip-inv-942531180',
  },
  // {
  //   img: `${BaseDirectories.TEAM_DIR}/Bamidele.png`,
  //   name: 'BAMIDELE EFUNNUGA',
  //   title: 'SOFTWARE ENGINEER',
  //   linkedIn: 'linkedin.com/in/ajadewumi',
  // },
  {
    img: `${BaseDirectories.TEAM_DIR}/Abimbola.png`,
    name: 'ABIMBOLA FASAKIN',
    title: 'DIGITAL MARKETING MANAGER',
    linkedIn: 'linkedin.com/in/abimbola-fasakin-b17235184',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/Oluwakayode.png`,
    name: 'OLUWAKAYODE ADENIJI',
    title: 'UI/UX DESIGNER',
    linkedIn: 'linkedin.com/in/oluwakayode-adeniji',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/Charles.png`,
    name: 'CHARLES CHIJUKA',
    title: 'SOFTWARE ENGINEER',
    linkedIn: 'linkedin.com/in/charliedotcom',
  },
  // {
  //   img: `${BaseDirectories.TEAM_DIR}/Emmanuel.png`,
  //   name: 'EMMANUEL ABASIFREKE',
  //   title: 'SOFTWARE ENGINEER',
  //   linkedIn: 'linkedin.com/in/ajadewumi',
  // },
  {
    img: `${BaseDirectories.TEAM_DIR}/Tosin-Ogunwale.png`,
    name: 'TOSIN OGUNWALE',
    title: 'CUSTOMER SUPPORT',
    linkedIn: 'linkedin.com/in/oluwatosin-ogunwale-767301293',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/Chima-Joy.png`,
    name: 'JOY IFEANYI',
    title: 'Junior financial analyst',
    linkedIn: 'linkedin.com/in/joy-chima-b099b120a',
  },
];
const teamAdvisors = [
  // {
  //   img: `${BaseDirectories.TEAM_DIR}/ben.png`,
  //   name: 'Dr. ben peter ',
  //   title: ' ADVISOR',
  //   linkedIn: 'linkedin.com/in/joy-chima-b099b120a',
  // },
  {
    img: `${BaseDirectories.TEAM_DIR}/henrietta.png`,
    name: 'HENRIETTA ONWUEGBUZIE',
    title: ' ADVISOR',
    linkedIn: 'linkedin.com/in/dr-henrietta-onwuegbuzie-63866515',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/ehis.png`,
    name: 'EHIS UZENABOR',
    title: ' ADVISOR',
    linkedIn: 'linkedin.com/in/ehis-uzenabor',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/joy.png`,
    name: 'JOY JACK',
    title: ' ADVISOR',
    linkedIn: 'linkedin.com/in/joyjack',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/yemi.png`,
    name: 'yemi osinubi',
    title: ' ADVISOR',
    linkedIn: 'linkedin.com/in/yemi-osinubi-cfa',
  },
  {
    img: `${BaseDirectories.TEAM_DIR}/tomi.png`,
    name: 'tomi davies',
    title: ' ADVISOR',
    linkedIn: 'linkedin.com/in/tomidee',
  },
];

export default Team;
