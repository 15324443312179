import { mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import BlueCard from './sections/BlueCard';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Newsletter from './sections/Newsletter';
const ARM = () => {
  const [armReadMore, setARMReadMore] = useState(false);
  const clickARMReadMore = () => {
    setARMReadMore((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>ARM INVESTMENT | Trade Lenda Landing Page!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="features landing-page-hero">
        <div className="container">
          <div>
            <div className="group">
              <div className="murabaha-div">
                <div className="col-lg-6 facilities-img-div">
                  <img
                    className="murabaha-img"
                    src={`${BaseDirectories.IMAGES_DIR}/armLogo.png`}
                    alt="Trade Lenda"
                    style={{
                      backgroundColor: 'black',
                    }}
                  />

                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/murabaha_background.svg`}
                    alt="Trade Lenda"
                    className="facilities-img2"
                    style={{
                      position: 'relative',
                      // top: '10px',
                      // right: '25px',
                      width: '100%',
                      height: 'auto',
                      marginTop: '15%',
                      marginRight: '30%',
                      maxHeight: '350px',
                      maxWidth: '600px',
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="" style={{ maxWidth: '540px' }}>
                    <div className="">
                      <div className="facilities-text-align">
                        <h1 className="mb-3">A.R.M</h1>
                        <p style={{}}>
                          Established in 1994 as an asset management firm, ARM
                          offers wealth creation opportunities through a unique
                          blend of traditional asset management and alternative
                          investment services. We enable businesses to thrive,
                          and help our clients to maximize their returns and
                          realise their most important goals. For over two
                          decades, we have built a firm uniquely equipped to
                          achieve these objectives and our reputation for
                          quality research, investment expertise and value-added
                          services has endeared us to clients both locally and
                          internationally.
                        </p>
                        {armReadMore && (
                          <>
                            <div>
                              <h3>Our Core Values</h3>
                            </div>
                            <div className="mt-3">
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <span className="mt-3">
                                  <Icon path={mdiCircleSmall} size={1.2} />
                                </span>
                                <p>
                                  <h3>Ambition</h3>
                                  We are driven by our vision of the future: how
                                  things could be. It inspires us and keeps us
                                  focused on what really counts, working
                                  together to transform goals into reality.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <span className="mt-3">
                                  <Icon path={mdiCircleSmall} size={1.2} />
                                </span>
                                <p>
                                  <h3>Proximity</h3>
                                  Our success rests on two inter‐dependent
                                  factors – proximity to our clients and the
                                  marketplace – and close understanding of both
                                  our clients and the marketplace’s needs and
                                  aspirations and the challenges of fulfilling
                                  both.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <span className="mt-3">
                                  <Icon path={mdiCircleSmall} size={1.2} />
                                </span>
                                <p>
                                  <h3>Impact</h3>
                                  Our value is measured by the degree of
                                  positive change we bring about. We strive to
                                  make a difference to the people who believe in
                                  us ‐ our clients, our shareholders, our
                                  business partners, our staff.
                                </p>
                              </div>
                              <div className="d-flex align-items-start gap-3 mb-3">
                                <span className="mt-3">
                                  <Icon path={mdiCircleSmall} size={1.2} />
                                </span>
                                <p>
                                  <h3>Truth</h3>
                                  Honesty, trust and integrity are at the heart
                                  of everything we say and do. We keep our
                                  promises.
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-lg btn-outline-primary mt-3"
                          style={{ width: '113px', marginRight: '20px' }}
                          onClick={clickARMReadMore}>
                          {armReadMore ? 'less' : 'Read more'}
                        </button>
                        <Button
                          classes="primary-btn btn-lg mt-3"
                          content="Save Now"
                          route="/dashboard/invest"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlueCard />
      <GetStarted />
      <Newsletter />
      <Footer />
    </>
  );
};

export default ARM;
