/* eslint-disable @typescript-eslint/no-unused-vars */
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {
  child,
  equalTo,
  get,
  getDatabase,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  update,
} from 'firebase/database';

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
// };

const firebaseConfigDev = {
  apiKey: 'AIzaSyCjf2yeQk6DkOLiPbigFK1oi1slcmmFJQo',
  authDomain: 'tradelenda-staging.firebaseapp.com',
  databaseURL:
    'https://tradelenda-staging-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'tradelenda-staging',
  storageBucket: 'tradelenda-staging.appspot.com',
  messagingSenderId: '1046946781296',
  appId: '1:1046946781296:web:2915787620c19ca662fcb5',
  measurementId: 'G-1JEE8QKLC7',
};
const firebaseConfigProd = {
  apiKey: 'AIzaSyA-FQQY9cImFEd3BFkoqbNquoOEO8hnvhg',
  authDomain: 'tradelenda-app.firebaseapp.com',
  databaseURL:
    'https://tradelenda-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'tradelenda-app',
  storageBucket: 'tradelenda-app.appspot.com',
  messagingSenderId: '24130547176',
  appId: '1:24130547176:web:9c25693f5c2da2bc1cc85d',
  measurementId: 'G-4SS0SHYK0S',
};

// Initialize Firebase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = initializeApp(
  process.env.REACT_APP_ENV !== 'development'
    ? firebaseConfigProd
    : firebaseConfigDev,
);

// export const app = initializeApp(firebaseConfig);

export const auth = getAuth();

const messaging = getMessaging(app);

const database = getDatabase();

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};

// export const getOrRegisterServiceWorker = async () => {
//   if ('serviceWorker' in navigator) {
//     const serviceWorker = await window.navigator.serviceWorker.getRegistration(
//       '/firebase-push-notification-scope',
//     );
//     if (serviceWorker) return serviceWorker;
//     return window.navigator.serviceWorker.register(
//       '/firebase-messaging-sw.js',
//       {
//         scope: '/firebase-push-notification-scope',
//       },
//     );
//   }
//   // throw new Error('The browser doesn`t support service worker.');
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

// export const requestPermission = () => {

//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       if (process.env.REACT_APP_ENV === 'development') {

//       }

//       return getToken(messaging, {
//         vapidKey: process.env.REACT_APP_VAPIDKEY,
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             storeClientToken(currentToken);
//             if (process.env.REACT_APP_ENV === 'development') {
//
//             }
//           } else {
//             if (process.env.REACT_APP_ENV === 'development') {
//
//             }
//           }
//         })
//         .catch((err) => {
//           if (process.env.REACT_APP_ENV === 'development') {
//
//           }
//         });
//     } else {
//       if (process.env.REACT_APP_ENV === 'development') {
//
//       }
//     }
//   });
// };

// const storeClientToken = (token: string) => {
//   const userId = auth?.currentUser?.uid;
//   const dbRef = ref(database, '/notifications/' + userId);
//   // const dbRef = ref(database, '/notifications/' + userId);

//   if (userId != undefined) {
//     // onValue(dbRef, (snapshot) => {
//     get(child(dbRef, '/notifications/' + userId)).then((snapshot) => {
//       if (snapshot.exists()) {
//
//         const data = snapshot.val();
//         if (data?.clientToken !== token) {
//
//           update(dbRef, { clientToken: token });
//         }
//       } else {
//
//         set(dbRef, { clientToken: token });
//       }
//     });
//   }
// };
// requestPermission();
