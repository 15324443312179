import React from 'react';

const PaystackCallback = () => {
  return (
    <div className="paystack-callback">
      <h1>Congratulations!</h1>
      <p>
        You have successfully authorized a direct debit on your account and
        completed your loan application.
      </p>
      <p>Your loan will be disbursed shortly to your account.</p>
      <p>Thank you for choosing Trade Lenda.</p>
      <p>
        Please click here to <a href="https://tradelenda.com/login">Login</a>
      </p>
    </div>
  );
};

export default PaystackCallback;
