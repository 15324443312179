/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { StoreContext } from '../../mobx_stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';

type Props = {
  next: () => void;
  previous: any;
  setX: Function;
};

const businessType = {
  soleProprietorship: 'Sole Proprietorship',
  privateLimitedCompany: 'Private Limited Company',
  publicLimitedCompany: 'Public Limited Company',
  publicCompanyLimitedbyGuarantee: 'Public Company Limited by Guarantee',
  privateUnlimitedCompany: 'Private Unlimited Company',
  Others: 'Others',
};

const BusinessDetailsForm: FC<Props> = ({ next, previous, setX }) => {
  const { loansStore } = React.useContext(StoreContext);

  const { sending, loading, success, message } = loansStore;

  const [businessDetails, setBusinessDetails] = React.useState({
    businessType: '',
    businessName: '',
    positionInOrg: '',
    shareInOrg: '',
    rcNum: '',
    establishmentDate: '',
    businessAddress: '',
    country: 'Nigeria',
    state: '',
    city: '',
    ownedOrRented: '',
    NoOfOutlets: 0,
    totalEmployees: 0,
    salesMethod: '',
    industry: '',
    monthlySales: '',
    monthlyExpenses: '',
    businessDuration: '',
    womenLed: true,
    shariaCom: true,
    tin: '',
    registered: true,
    whenDidYouMoveToThisBusinessLocation: '',
  });

  const [orgDeets, setOrgDeets] = React.useState<any>({});
  // const orgDeets = allBusinessDetails.organizationDetails;

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  React.useEffect(() => {
    setOrgDeets(toJS(loansStore.loanUserdetails).organizationDetails);
  }, [loansStore.loanUserdetails]);

  React.useEffect(() => {
    setBusinessDetails({
      businessType:
        orgDeets && orgDeets?.businessType === undefined
          ? ''
          : orgDeets?.businessType,
      businessName:
        orgDeets && orgDeets?.businessName === undefined
          ? ''
          : orgDeets?.businessName,
      positionInOrg:
        orgDeets && orgDeets?.positionInOrg === undefined
          ? ''
          : orgDeets?.positionInOrg,
      shareInOrg:
        orgDeets && orgDeets?.shareInOrg === undefined
          ? ''
          : orgDeets?.shareInOrg,
      rcNum: orgDeets && orgDeets?.rcNum === undefined ? '' : orgDeets?.rcNum,
      establishmentDate:
        orgDeets && orgDeets?.establishmentDate === undefined
          ? ''
          : orgDeets?.establishmentDate?.substr(0, 10),
      businessAddress:
        orgDeets && orgDeets?.businessAddress === undefined
          ? ''
          : orgDeets?.businessAddress,
      country: 'Nigeria',
      state: orgDeets && orgDeets?.state === undefined ? '' : orgDeets?.state,
      city: orgDeets && orgDeets?.city === undefined ? '' : orgDeets?.city,
      ownedOrRented:
        orgDeets && orgDeets?.ownedOrRented === undefined
          ? ''
          : orgDeets?.ownedOrRented,
      NoOfOutlets:
        orgDeets && orgDeets?.NoOfOutlets === undefined
          ? 0
          : orgDeets?.NoOfOutlets,
      totalEmployees:
        orgDeets && orgDeets?.totalEmployees === undefined
          ? 0
          : orgDeets?.totalEmployees,
      salesMethod:
        orgDeets && orgDeets?.salesMethod === undefined
          ? ''
          : orgDeets?.salesMethod,
      industry:
        orgDeets && orgDeets?.industry === undefined ? '' : orgDeets?.industry,
      monthlySales:
        orgDeets && orgDeets?.monthlySales === undefined
          ? ''
          : orgDeets?.monthlySales,
      monthlyExpenses:
        orgDeets && orgDeets?.monthlyExpenses === undefined
          ? ''
          : orgDeets?.monthlyExpenses,
      businessDuration:
        orgDeets && orgDeets?.businessDuration === undefined
          ? ''
          : orgDeets?.businessDuration,
      womenLed:
        orgDeets && orgDeets?.womenLed === undefined
          ? true
          : orgDeets?.womenLed,
      shariaCom:
        orgDeets && orgDeets?.shariaCom === undefined
          ? true
          : orgDeets?.shariaCom,
      tin: orgDeets && orgDeets?.tin === undefined ? '' : orgDeets?.tin,
      registered:
        orgDeets && orgDeets?.registered === undefined
          ? true
          : orgDeets?.registered,
      whenDidYouMoveToThisBusinessLocation:
        orgDeets && orgDeets?.whenDidYouMoveToThisBusinessLocation === undefined
          ? ''
          : orgDeets?.whenDidYouMoveToThisBusinessLocation?.substr(0, 10),
    });
  }, [orgDeets]);

  const [state, setState] = useState([]);
  const [cityByState, setCitybyState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setState(toJS(loansStore.state));
    setCity(toJS(loansStore.city));
  }, [loansStore.state, loansStore.city]);

  React.useEffect(() => {
    if (businessDetails.state !== '') {
      setCitybyState(
        state.filter((state: string) => state === businessDetails.state),
      );
    }
  }, [businessDetails.state, state]);

  const stateCity = cityByState[0];

  useEffect(() => {
    loansStore.getState();
    loansStore.getCity(stateCity);
  }, [loansStore, stateCity]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setBusinessDetails((prevDetails: any) => {
      if (element.value === 'true') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.value === 'false') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  function validate(evt: any) {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleCreateBusinessDetails = (e: React.FormEvent) => {
    e.preventDefault();
    loansStore.createBusinessDetails(businessDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Please check your inputs then try again!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          businessDetails[input.name] === '' ||
          businessDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  const handleUpdateBusinessDetails = (e: React.FormEvent) => {
    e.preventDefault();
    loansStore.updateBusinessDetails(businessDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Please check your inputs then try again!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          businessDetails[input.name] === '' ||
          businessDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  React.useEffect(() => {
    if (success === 'Business details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Business details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  const handleSubmit =
    orgDeets === undefined
      ? handleCreateBusinessDetails
      : handleUpdateBusinessDetails;

  return (
    <div>
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}

      <form action="" onSubmit={handleSubmit}>
        <div className="py-5 px-4 bg-white rounded mt-4">
          {sending && <Spinner />}

          {loading ? (
            <div>
              <div className="row">
                <div className="col-md-6 left-side pe-md-3 pe-lg-4">
                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>

                <div className="col-md-6 right-side ps-md-3 ps-lg-4">
                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 pe-md-3 pe-lg-4 left-side">
                  <div className="col-md-12">
                    <label htmlFor="typeOfBusiness" className="mb-1 mt-3">
                      Type of business
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="businessType"
                      id="businessType"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.businessType}
                      required>
                      <option value="">Select type of business</option>
                      <option value={businessType.privateLimitedCompany}>
                        {businessType.privateLimitedCompany}
                      </option>
                      <option value={businessType.privateUnlimitedCompany}>
                        {businessType.privateUnlimitedCompany}
                      </option>
                      <option value={businessType.publicLimitedCompany}>
                        {businessType.publicLimitedCompany}
                      </option>
                      <option
                        value={businessType.publicCompanyLimitedbyGuarantee}>
                        {businessType.publicLimitedCompany}
                      </option>
                      <option value={businessType.soleProprietorship}>
                        {businessType.soleProprietorship}
                      </option>
                      <option value={businessType.Others}>
                        {businessType.Others}
                      </option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="businessName" className="mb-1 mt-3">
                      Name of business
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="businessName"
                      classes="form-control"
                      type="text"
                      id="businessName"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.businessName}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="registered" className="mb-1 mt-3">
                      Is the business registered?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="registered"
                      id="registered"
                      className="form-control"
                      onChange={handleInputChange}
                      required
                      defaultValue={
                        orgDeets?.registered === undefined
                          ? ''
                          : orgDeets?.registered
                      }>
                      <option value="">Select option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="positionInOrg" className="mb-1 mt-3">
                      Position in company
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="positionInOrg"
                      classes="form-control"
                      type="text"
                      id="positionInOrg"
                      defaultValue={orgDeets?.positionInOrg}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="shareInOrg" className="mb-1 mt-3">
                      Share in company
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="shareInOrg"
                      classes="form-control"
                      type="text"
                      id="shareInOrg"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.shareInOrg}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="rcNum" className="mb-1 mt-3">
                      RC/BN Number
                    </label>
                    <Input
                      name="rcNum"
                      classes="form-control"
                      type="text"
                      id="rcNum"
                      defaultValue={orgDeets?.rcNum}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="dateOfEstablishment" className="mb-1 mt-3">
                      Date of establishment
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="establishmentDate"
                      classes="form-control"
                      type="date"
                      id="establishmentDate"
                      defaultValue={orgDeets?.establishmentDate?.substr(0, 10)}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="businessAddress" className="mb-1 mt-3">
                      Business Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="businessAddress"
                      classes="form-control"
                      type="text"
                      id="businessAddress"
                      defaultValue={orgDeets?.businessAddress}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="country" className="mb-1 mt-3">
                      Country
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.country}
                      required>
                      <option value={businessDetails.country}>Nigeria</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="state" className="mb-1 mt-3">
                        State
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        name="state"
                        id="state"
                        className="form-control"
                        onChange={handleInputChange}
                        defaultValue={orgDeets?.state}
                        required>
                        <option value="">Select State</option>
                        {state.map((state: string, i: number) => (
                          <option value={state} key={i}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="city" className="mb-1 mt-3">
                        LGA
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        name="city"
                        id="city"
                        className="form-control"
                        onChange={handleInputChange}
                        defaultValue={orgDeets?.city}
                        required>
                        <option value="">Select LGA</option>
                        {city.map((city: string, i: number) => (
                          <option value={city} key={i}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label
                      htmlFor="whenDidYouMoveToThisBusinessLocation"
                      className="mb-1 mt-3">
                      When did you move to this address?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="whenDidYouMoveToThisBusinessLocation"
                      classes="form-control"
                      type="date"
                      id="whenDidYouMoveToThisBusinessLocation"
                      onChange={handleInputChange}
                      required
                      defaultValue={orgDeets?.whenDidYouMoveToThisBusinessLocation?.substr(
                        0,
                        10,
                      )}
                    />
                  </div>
                </div>

                <div className="col-md-6 ps-md-3 ps-lg-4 right-side">
                  <div className="col-md-12">
                    <label htmlFor="ownedOrRented" className="mb-1 mt-3">
                      Is your business location owned or rented?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="ownedOrRented"
                      id="ownedOrRented"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.ownedOrRented}
                      required>
                      <option value="">Select option</option>
                      <option value="Owned">Owned</option>
                      <option value="Rented">Rented</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="isWomenLed" className="mb-1 mt-3">
                      Is your business women led?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="womenLed"
                      id="womenLed"
                      className="form-control"
                      onChange={handleInputChange}
                      required
                      defaultValue={
                        orgDeets?.womenLed === undefined
                          ? ''
                          : orgDeets?.womenLed
                      }>
                      <option value="">Select option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="shariaCom" className="mb-1 mt-3">
                      Is your business sharia compliant?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="shariaCom"
                      id="shariaCom"
                      className="form-control"
                      onChange={handleInputChange}
                      required
                      defaultValue={
                        orgDeets?.shariaCom === undefined
                          ? ''
                          : orgDeets?.shariaCom
                      }>
                      <option value="">Select option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="NoOfOutlets" className="mb-1 mt-3">
                      Number of outlet(branches)
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="NoOfOutlets"
                      classes="form-control"
                      type="text"
                      id="numbersOnly"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.NoOfOutlets}
                      onKeyPress={validate}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="totalEmployees" className="mb-1 mt-3">
                      Number of employees
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="totalEmployees"
                      classes="form-control"
                      type="text"
                      id="numbersOnly"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.totalEmployees}
                      onKeyPress={validate}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="salesMethod" className="mb-1 mt-3">
                      How do you sell?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="salesMethod"
                      id="salesMethod"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.salesMethod}
                      required>
                      <option value="">Select option</option>
                      <option value="I sell online (Jumia, Konga etc)">
                        I sell online (Jumia, Konga etc)
                      </option>
                      <option value="I distribute FMCG Goods">
                        I distribute FMCG Goods
                      </option>
                      <option value="I buy and sell Agro-Commodities">
                        I buy and sell Agro-Commodities
                      </option>
                      <option value="I have a physical shop">
                        I have a physical shop
                      </option>
                      <option value="Others">Others</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="monthlySales" className="mb-1 mt-3">
                      Average monthly sales
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="monthlySales"
                      id="monthlySales"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.monthlySales}
                      required>
                      <option value="">Select option</option>
                      <option value="Less than ₦10,000">
                        Less than ₦10,000{' '}
                      </option>
                      <option value="₦10,000 to ₦100,000">
                        ₦10,000 to ₦100,000{' '}
                      </option>
                      <option value="₦100,000 to ₦500,000">
                        ₦100,000 to ₦500,000{' '}
                      </option>
                      <option value="₦500,000 to ₦1,000,000 ">
                        {' '}
                        ₦500,000 to ₦1,000,000{' '}
                      </option>
                      <option value="Above ₦1,000,000">
                        {' '}
                        Above ₦1,000,000{' '}
                      </option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="monthlyExpenses" className="mb-1 mt-3">
                      Average monthly expenses
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="monthlyExpenses"
                      id="monthlyExpenses"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.monthlyExpenses}
                      required>
                      <option value="">Select option</option>
                      <option value="Less than ₦10,000">
                        Less than ₦10,000{' '}
                      </option>
                      <option value="₦10,000 to ₦100,000">
                        ₦10,000 to ₦100,000{' '}
                      </option>
                      <option value="₦100,000 to ₦500,000">
                        ₦100,000 to ₦500,000{' '}
                      </option>
                      <option value="₦500,000 to ₦1,000,000 ">
                        {' '}
                        ₦500,000 to ₦1,000,000{' '}
                      </option>
                      <option value="Above ₦1,000,000">
                        {' '}
                        Above ₦1,000,000{' '}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="businessDuration" className="mb-1 mt-3">
                      How long have you been in business
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="businessDuration"
                      id="businessDuration"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.businessDuration}
                      required>
                      <option value="">Select option</option>
                      <option value="0-1 years">0-1 years</option>
                      <option value="1-3 years">1-3 years</option>
                      <option value="3-5 years">3-5 years</option>
                      <option value="5-10 years">5-10 years</option>
                      <option value="10+ years">10+ years</option>
                    </select>
                  </div>
                  {/* <div className="col-md-12">
                    <label htmlFor="MERMAT" className="mb-1 mt-3">
                      MERMAT
                    </label>
                    <Input
                      name="MERMAT"
                      classes="form-control"
                      type="text"
                      id="MERMAT"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.MERMAT}
                    />
                  </div> */}
                  <div className="col-md-12">
                    <label htmlFor="tin" className="mb-1 mt-3">
                      TIN (Tax Identification Number)
                      {/* <span style={{ color: 'red' }}> *</span> */}
                    </label>
                    <Input
                      name="tin"
                      classes="form-control"
                      type="text"
                      id="tin"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.tin}
                      // required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="industry" className="mb-1 mt-3">
                      Industry
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="industry"
                      id="industry"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={orgDeets?.industry}
                      required>
                      <option value="">Select industry</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Autos"> Autos </option>
                      <option value="Agency Banking">Agency Banking</option>
                      <option value="Beauty products">Beauty products</option>
                      <option value="Consulting Services">
                        Consulting Services
                      </option>
                      <option value="Education">Education</option>
                      <option value="Electronics">Electronics</option>
                      <option value="Fashion">Fashion</option>
                      <option value="Food and Beverages">
                        Food and Beverages
                      </option>
                      <option value="Furniture and Fittings">
                        Furniture and Fittings
                      </option>
                      <option value="Health and Pharma Products">
                        Health and Pharma Products
                      </option>
                      <option value="Home Services">Home Services</option>
                      <option value="House Appliances">House Appliances</option>
                      <option value="Industrial goods">Industrial goods</option>
                      <option value="Logistics">Logistics</option>
                      <option value="Media and Entertainment">
                        Media and Entertainment
                      </option>
                      <option value="Office Supplies">Office Supplies</option>
                      <option value="Packaging and Plastics">
                        Packaging and Plastics
                      </option>
                      <option value="Personal Care">Personal Care</option>
                      <option value="Professional Services">
                        Professional Services
                      </option>
                      <option value="Technology Services">
                        Technology Services
                      </option>
                      <option value="Utility Services">Utility Services</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Button
                      classes="primary-btn btnn"
                      content="Previous"
                      onClick={() => {
                        previous();
                        setX(-1000);
                      }}
                    />
                    <Button
                      classes="primary-btn btnn"
                      content={
                        orgDeets === undefined
                          ? 'Save and Continue'
                          : 'Update Details'
                      }
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default observer(BusinessDetailsForm);
