/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useStep } from 'react-hooks-helper';
import UpgradeDocumentsDetails from './form/UpgradeDocumentsDetails';
import UpgradeDocumentsUpload from './form/UpgradeDocumentsUpload';
import { motion } from 'framer-motion';
import logger from '../../../common/logger/CustomLogger';

interface Step {
  id: string;
}

type Props = {
  setShowProfileModal: (state: boolean) => void;
  input?: any; // Optional input prop
};

const UpgradeDetailsForm: FC<Props> = ({ setShowProfileModal }) => {
  const [x, setX] = useState(0); // Tracks the animation direction
  const { step, navigation } = useStep({
    steps: [{ id: 'document details' }, { id: 'document upload' }],
  });
  const { id } = step as Step; // Ensure 'id' is accessible

  const goToStep = (targetId: string, direction: number) => {
    setX(direction); // Set animation direction
    if (navigation.go) {
      navigation.go(targetId); // Navigate to the specific step
    } else {
      logger.error('Navigation function is not defined.');
    }
  };

  return (
    <div className="upgrade-arm-form">
      <div
        className="upgrade-profile-multistep mt-0 pt-0"
        style={{
          scrollBehavior: 'smooth',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <div className="step-progress">
              {/* Step Progress Indicators */}
              <button
                type="button"
                disabled={id === 'document details'}
                onClick={() => {
                  goToStep('document upload', 1000);
                }}
                className={`step ${id === 'document details' ? 'active' : ''}`}></button>
              <button
                type="button"
                disabled={id === 'document upload'}
                onClick={() => {
                  goToStep('document details', -1000);
                }}
                className={`step ${id === 'document upload' ? 'active' : ''}`}></button>

              {/* Animated Step Components */}
              <motion.div
                key={id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="">
                {id === 'document details' && (
                  <UpgradeDocumentsDetails
                    x={x}
                    next={() => goToStep('document upload', 1000)} // Slide forward
                  />
                )}
                {id === 'document upload' && (
                  <UpgradeDocumentsUpload
                    x={x}
                    previous={() => goToStep('document details', -1000)} // Slide backward
                    setShowProfileModal={setShowProfileModal}
                  />
                )}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(UpgradeDetailsForm);
