import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';

const InsuranceCard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInsurance, setIsInsurance] = useState(true);

  return (
    <div className="mt-4">
      {!isInsurance ? (
        <div className="bg-white no-guarantor">
          <div className="empty-state-img">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/no-loans.png`}
              alt="No Insurance"
            />
          </div>
          <p>You do not have an insurance yet! </p>
        </div>
      ) : (
        <div className="bg-white guarantor-list">
          <h5>MY INSURANCE</h5>

          <div className="bg-white table-responsive">
            <table className="table table-striped responsive-table mb-0">
              <thead>
                <tr>
                  <th>Type of insurance</th>
                  <th>Reason</th>
                  <th>Duration</th>
                  <th>Detail</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Car cover</td>
                  <td>Reason</td>
                  <td>Duration</td>
                  <td>Detail</td>
                  <td className="approved">Approved</td>
                </tr>
                <tr>
                  <td>Car cover</td>
                  <td>Reason</td>
                  <td>Duration</td>
                  <td>Detail</td>
                  <td className="pending">Pending</td>
                </tr>
                <tr>
                  <td>Car cover</td>
                  <td>Reason</td>
                  <td>Duration</td>
                  <td>Detail</td>
                  <td className="rejected">Unapproved</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="navigation">
            <button
              className="btn prev disabled navigation-btn"
              title="Previous">
              <Icon path={mdiChevronLeft} size={1} />
            </button>
            <Button
              content="1"
              title="Page 1"
              classes="active prev navigation-btn"
            />
            <Button
              content="2"
              title="Page 2"
              classes="active prev navigation-btn"
            />
            <button className="btn next navigation-btn" title="Next">
              <Icon path={mdiChevronRight} size={1} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceCard;
