/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import BaseDirectories from '../../../../base directories/BaseDirectories';
import { StoreContext } from '../../../../mobx_stores/RootStore';

interface params {
  investmentName: string;
  description: string;
  interestRate: string;
  amountRange: string;
}

const InvestWithTradelenda = ({ setShowInvestWithTradelendaModal }) => {
  const navigate = useNavigate();
  const { investStore } = useContext(StoreContext);
  const { lendaPlans, setTradelendaInvestments } = investStore;
  const [plans, setPlans] = useState<any>(toJS(lendaPlans));

  useEffect(() => {
    setPlans(toJS(lendaPlans));
  }, [setTradelendaInvestments]);

  const handleViewPlan = (data: params) => {
    navigate('/dashboard/invest/lenda_plan_view', {
      state: {
        investmentName: data.investmentName,
        description: data.description,
        interestRate: data.interestRate,
        amountRange: data.amountRange,
      },
    });
  };

  return (
    <div>
      <div className="arm-details-modal s-w-arm">
        <div className="bg-white notification-box ">
          <span
            onClick={() => {
              setShowInvestWithTradelendaModal(false);
            }}
            className="close"
            role="button">
            <FaTimes />
          </span>
          <div className="title ">
            <span className="t-header my-4">
              <h3>TradeLenda Lending Plans</h3>
            </span>
          </div>

          {plans ? (
            <div className="main-content">
              {plans.map((plans: any, id: string) => (
                <div
                  className="product-group d-flex my-3 p-2"
                  style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    textAlign: 'start',
                  }}
                  onClick={() => handleViewPlan(plans)}
                  key={id}>
                  <span
                    className="d-flex align-items-center"
                    style={{ width: '45%' }}>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/FlowerBasic.png`}
                      alt=""
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <h6 className="ms-4 ms-md-2">{plans.investmentName}</h6>
                  </span>
                  <span
                    className="d-flex align-items-center"
                    style={{ width: 'auto' }}>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/Arrow_Up.png`}
                      alt=""
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <h6 className="ms-4 ms-md-2">{plans.interestRate}%</h6>
                  </span>

                  <span
                    className="d-flex align-items-center d-none d-md-block"
                    style={{ width: '40%' }}>
                    ₦{' '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(plans?.amountRange?.minAmount)}
                    {' - '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(plans?.amountRange?.maxAmount)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="rq-loan-details">
              <div
                className="product-group d-flex my-3 p-2"
                style={{
                  cursor: 'pointer',
                  alignItems: 'center',
                }}>
                <span className="d-flex align-items-center ">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/FlowerBasic.png`}
                    alt=""
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>

                <span className="d-flex align-items-center ms-2 d-none d-md-block">
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>
                <span className="d-flex align-items-center">
                  <h6 className="ms-4 ms-md-2">
                    <Skeleton baseColor="#eaeff5" width={200} height={45} />
                  </h6>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestWithTradelenda;
