import React from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';

const Error301 = () => {
  return (
    <div>
      <div className="error_class">
        <div className="err-text">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/301.png`}
            alt="Trade Lenda"
          />
        </div>
        <div className="err-img-500">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/301error.png`}
            alt="Trade Lenda"
          />
          <h1>we have moved permanently</h1>
          <p>Redirecting...</p>
        </div>
      </div>
    </div>
  );
};

export default Error301;
