/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { motion } from 'framer-motion';
import { FC } from 'react';
import BankDetailsForm from '../../../../components/forms/BankDetailsForm';
import ScrollToTop from '../../../../components/ScrollToTop';

type Props = {
  next: () => void;
  previous: any;
  x: number;
  setX: Function;
};

const BankDetails: FC<Props> = ({ next, previous, x, setX }) => {
  return (
    <ScrollToTop>
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}>
        <div className="bg-white">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 col-md-9 col-sm-10 bg-primary-blue mt-4 rounded">
              <h4>BANK DETAILS</h4>
              <p>
                Industry regulation requires us to collect this information to
                verify your identity.
              </p>
            </div>
          </div>
          <BankDetailsForm next={next} previous={previous} setX={setX} />
        </div>
      </motion.div>
    </ScrollToTop>
  );
};

export default BankDetails;
