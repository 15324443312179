/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Button from '../ui/Button';

type Props = {
  next: () => void;
  setX: Function;
};

const accountType = {
  Personal: 'Personal',
  Business: 'Business',
  Merchant: 'Merchant',
};

const AccountTypeForm: FC<Props> = ({ next, setX }) => {
  const [updateProfile, setUpdateProfile] = React.useState({
    accountType: '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUpdateProfile((prevDetails: any) => {
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const inputs = document.querySelectorAll('.form-control');
    if (
      updateProfile.accountType !== null &&
      updateProfile.accountType !== undefined &&
      updateProfile.accountType !== ''
    ) {
      localStorage.setItem('accountTypeSession', updateProfile.accountType);
      setTimeout(() => {
        if (localStorage.getItem('accountTypeSession')) {
          next();
          setX(1000);
        }
      }, 1000);
    } else {
      inputs.forEach((input: any) => {
        input.style.border = '2px solid #ff8989';
        input.focus();
        setTimeout(() => {
          input.style.border = '1px solid #ced4da';
        }, 50000);
      });
    }
  };

  return (
    <div className="">
      <form
        action=""
        onSubmit={handleFormSubmit}
        className="row justify-content-center">
        <div className="py-2 px-4 rounded mt-4 col-md-7">
          <div className="row">
            <div className="col-md-12 left-side pe-md-3 pe-lg-4 text-center">
              {updateProfile.accountType === 'Personal' && (
                <em>
                  <strong>Personal Account</strong> gives you access to a wallet
                  with normal day to day transaction between other wallets and
                  interswitch transaction to other banks with zero charges for
                  all this transaction.
                </em>
              )}
              {updateProfile.accountType === 'Business' && (
                <em>
                  <strong>Business Account</strong> gives you access to a wallet
                  with normal day to day transaction between other wallets and
                  interswitch transaction to other banks with zero charges for
                  all this transaction and access to flexible loans.
                </em>
              )}
              {updateProfile.accountType === 'Merchant' && (
                <em>
                  <strong>Merchant Account</strong> gives you access to a wallet
                  with normal day to day transaction between other wallets and
                  interswitch transaction to other banks with zero charges for
                  all this transaction and access to goods and services supply
                  for other clients.
                </em>
              )}
            </div>

            <div className="col-md-12 ps-md-3 ps-lg-4 row justify-content-center">
              <div className="col-md-12">
                <label htmlFor="accountType" className="mb-1 mt-3">
                  Account Type
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <select
                  name="accountType"
                  id="accountType"
                  className="form-control"
                  onChange={handleInputChange}
                  defaultValue={localStorage
                    .getItem('accountTypeSession')
                    ?.toString()}
                  required>
                  <option value="">Select account type</option>
                  {Object.keys(accountType).map((account: any, i: number) => (
                    <option key={i} value={accountType[account]}>
                      {account}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-8">
              <div className="profile-btnn btn">
                <Button
                  classes="primary-btn"
                  content={'Save and Continue'}
                  type="submit"
                  onClick={() => {
                    setX(1000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(AccountTypeForm);
