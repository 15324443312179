import {
  mdiCalendarBlankOutline,
  mdiClose,
  mdiCreditCardOutline,
  mdiInformationOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { FC } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';

type Props = {
  close?: () => void;
};

const AddCard: FC<Props> = ({ close }) => {
  return (
    <div className="add-card-modal">
      <div className="bg-white add-card-box">
        <span className="close" onClick={close}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <h5>Add card</h5>
        <hr />
        <form>
          <div className="row">
            <div className="col-md-12 input-box">
              <label htmlFor="cardNumber" className="mb-1 mt-3">
                Card number
              </label>
              <div>
                <Input
                  name="cardNumber"
                  value="XXXX XXXX XXXX XXXX"
                  classes="form-control"
                  type="text"
                  id="cardNumber"
                />
                <Icon
                  path={mdiCreditCardOutline}
                  size={1}
                  className="input-icon"
                  title="Close"
                />
              </div>
            </div>

            <div className="col-md-6 input-box">
              <label htmlFor="expiryDate" className="mb-1 mt-3">
                Expiry date
              </label>
              <div>
                <Input
                  name="expiryDate"
                  value=""
                  classes="form-control"
                  type="text"
                  id="expiryDate"
                />
                <Icon
                  path={mdiCalendarBlankOutline}
                  size={1}
                  className="input-icon calendar-icon"
                />
              </div>
            </div>
            <div className="col-md-6 input-box">
              <label htmlFor="cvv" className="mb-1 mt-3">
                CVV/CVC
              </label>
              <div>
                <Input
                  name="cvv"
                  value=""
                  classes="form-control"
                  type="text"
                  id="cvv"
                />
                <Icon
                  path={mdiInformationOutline}
                  size={1}
                  className="input-icon"
                />
              </div>
            </div>

            <div className="col-md-12 input-box">
              <label htmlFor="cardHolderName" className="mb-1 mt-3">
                Cardholder name
              </label>
              <div>
                <Input
                  name="cardHolderName"
                  value="John Doe"
                  classes="form-control"
                  type="text"
                  id="cardHolderName"
                />
              </div>
            </div>

            <div className="col-md-12 input-box my-4">
              <Input
                name="saveCard"
                value="XXXXXXXXXX XXXX XXXX"
                classes="me-2"
                type="checkbox"
                id="saveCard"
              />
              <label htmlFor="saveCard" className="">
                Save card
              </label>
            </div>
          </div>
          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Add card"
          />
        </form>
      </div>
    </div>
  );
};

export default AddCard;
