import { MdArrowBack, MdArrowDropUp, MdCheck } from 'react-icons/md';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';

const EthicalFund = () => {
  return (
    <div className="m-m-fund container">
      <span
        className="back"
        role="button"
        onClick={() => window.history.back()}>
        <MdArrowBack />
        <p>Back</p>
      </span>

      <div>
        <h5>Discovery Balance Fund</h5>
        <span className="d-flex y-date">
          <p>Yield on 4th Dec 2022: </p>
          <span className="indicator" style={{ color: '#44ab3b' }}>
            <MdArrowDropUp /> 12.25%
          </span>
        </span>

        <span className="risk">High Risk</span>
      </div>

      <div className=" content-cont  mt-5 row">
        <div className="left col-md-6">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/performanceChart.png`}
            alt=""
            style={{ width: '70%' }}
          />
        </div>

        <div className="right col-md-6">
          <h6 className="mb-3">Fund Factsheet</h6>
          <p>
            Suitable for Islamic investors with a high-risk appetite who want to
            grow their capital over the long-term by investing according to the
            principles of Islamic finance and ethical values.
          </p>
          <div className="list-group">
            <span>
              <MdCheck />
              <p>
                Invests in Equities (40%-65%), Fixed income securities
                (15%-30%), Real Estate (15% - 30%) and Cash (1%-10%).
              </p>
            </span>

            <span>
              <MdCheck />
              <p>
                The fund invests only in investments screened by a Shari’ah
                Advisory Board.
              </p>
            </span>
            <span>
              <MdCheck />
              <p>Helps you achieve long-term capital growth.</p>
            </span>
            <span>
              <MdCheck />
              <p>
                Minimum initial investment is N10,000; minimum additional
                investment is N5,000.
              </p>
            </span>
          </div>

          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Invest Now"
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default EthicalFund;
