/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  FC,
  useRef,
} from 'react';
import { FaTimes } from 'react-icons/fa';
import UpgradeDetailsForm from './UpgradeDetailsForm';

interface ModalProps {
  setShowProfileModal: (state: any) => void;
}

const UpgradeARMDetails: FC<ModalProps> = (
  { setShowProfileModal },
  input: any,
) => {
  const modalRef = useRef(null);

  return (
    <div className="upgrade-details-form" ref={modalRef}>
      <div className="bg-white notification-box">
        <span
          onClick={() => {
            setShowProfileModal(false);
          }}
          className="close"
          role="button">
          <FaTimes />
        </span>
        {/* <h4>Kindly fill in this form to continue</h4> */}
        <div className="col-12">
          <UpgradeDetailsForm
            input={input}
            setShowProfileModal={setShowProfileModal}
          />
        </div>
      </div>
    </div>
  );
};

export default UpgradeARMDetails;
