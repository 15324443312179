import React from 'react';
import Button from '../../../components/ui/Button';

const GetStarted = () => {
  return (
    <section className="get-started">
      <div className="container">
        <div className="row align-items-center text-lg-start text-center">
          <div className="col-lg-9">
            <div>
              <p>Try Trade Lenda now</p>
              <h2 className="my-4">Ready to get started?</h2>
              <p>Take your business to the next level with Trade Lenda</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <Button
                classes="green-btn btn-lg"
                content="Create An Account"
                route="/sign_up"
              />
            </div>
          </div>
        </div>
        <div className="d-flex col align-items-center justify-content-center text-center">
          <div className="col-lg-9">
            <div>
              <h2 className="my-4">Let’s grow together</h2>
              <p>
                Join the coolest team who are passionate about their work and
                exhibit both personal and professional pride in the products and
                services we provide to customers.
              </p>
              <Button
                classes="primary-btn btn-lg"
                content="See Open Roles"
                route="/sign_up"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
