/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/no-unused-vars */
import { faBars, faBell, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  mdiAccountGroup,
  mdiChartBox,
  mdiCog,
  mdiContentCopy,
  mdiFlowerTulip,
  mdiLogout,
  mdiMenuDown,
  mdiViewDashboard,
  mdiWallet,
} from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { clearReduxStateAction } from '../../../redux/root-reducer';
import { LogOut } from '../../../utils/firebase/AuthFirestore';

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [headerScroll, setHeaderScroll] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);

  //Profile store context
  const { profileStore, authStore, loansStore } = useContext(StoreContext);

  //Destucture profileStore and authStore to get the needed observables
  const { profile } = profileStore;
  const { user } = authStore;
  const { loanUserdetails } = loansStore;
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // const navigate = useNavigate();

  const changeHeaderBackground = () => {
    if (window.scrollY >= 40) {
      setHeaderScroll(true);
    } else {
      setHeaderScroll(false);
    }
  };

  window.addEventListener('scroll', changeHeaderBackground);

  function toggleNavbar() {
    setIsOpen(!isOpen);
  }

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const useOutsideClick = (newRef: any, btnRef: any) => {
    const [outsideClick, setOutsideClick] = useState<null | any>(null);

    useEffect(() => {
      const handleClickOutside = (e: any) => {
        if (
          !newRef?.current?.contains(e.target) &&
          !btnRef?.current?.contains(e.target)
        ) {
          setOutsideClick(true);
        } else {
          setOutsideClick(false);
        }

        // setOutsideClick(null);
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [newRef, btnRef]);

    return outsideClick;
  };

  const newRef = useRef(null);
  const btnRef = useRef(null);
  const clickedOutside = useOutsideClick(newRef, btnRef);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clickedOutside && toggleDropdown) {
      setToggleDropdown(false);
    }
  }, [clickedOutside, toggleDropdown]);

  const handleLogOut = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await LogOut();
    dispatch(clearReduxStateAction());
    window.location.href = '/login';
    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 500);
  };

  const [referralCode, setReferralCode] = useState('');

  const referralCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setReferralCode(profile?.personalReferalCode);
  }, [profile?.personalReferalCode]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setReferralCode('Copied!');
        setTimeout(() => {
          setReferralCode(profile?.personalReferalCode);
        }, 2000);
      })
      .catch(() => {
        return;
      });
  };

  // Determine the class name based on the location
  const headerContainerClassName =
    location.pathname === '/dashboard/overview'
      ? 'd-flex justify-content-between align-items-center header-container'
      : 'd-flex justify-content-end align-items-center header-container';

  return (
    <header
      className={headerScroll ? 'dashboard-header scroll' : 'dashboard-header'}>
      <div className={headerContainerClassName}>
        {location.pathname === '/dashboard/overview' && (
          <div className="d-lg-block d-none test">
            <h3 className="greeting">Welcome {toJS(profile).firstName}</h3>
            <p>Let’s boost your business today</p>
          </div>
        )}
        {loading && <Spinner />}
        <div className="right-header-content">
          <button>
            <FontAwesomeIcon icon={faBell} />
          </button>
          {profile && profile.firstName !== undefined && (
            <div
              className="user-details position-relative"
              onClick={handleToggleDropdown}
              ref={btnRef}>
              <div className="user-img">
                <img
                  src={
                    user?.photoURL || `${BaseDirectories.IMAGES_DIR}/user.png`
                  }
                  alt=""
                  // style={{ width: '10%' }}
                />
              </div>
              <p className="username">
                {profile.firstName + ' ' + profile.lastName}
              </p>
              <Icon path={mdiMenuDown} size={2} />
              <div
                className={
                  toggleDropdown
                    ? 'profile-dropdown show-dropdown'
                    : 'profile-dropdown'
                }>
                <ul ref={newRef} className="p-0">
                  <li onClick={handleToggleDropdown}>
                    <Link to="settings/account_settings">Profile</Link>
                  </li>
                  <span className="profile-dropdown-divider"></span>
                  <li onClick={handleToggleDropdown}>
                    <button onClick={handleLogOut}>Log Out</button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mobile-header">
        <img
          src={`${BaseDirectories.LOGOS_DIR}/TradeLendaLOGO.png`}
          alt="Trade Lenda"
          style={{ width: '15%' }}
        />
        <div className="hamburger-icon" onClick={toggleNavbar}>
          {isOpen ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        {/* {isOpen && ( */}
        <div className={isOpen ? 'mobile-menu active' : 'mobile-menu'}>
          <div className="">
            {referralCode && (
              <div
                className="referral-code-box"
                style={{ color: '#4e4b66', fontSize: '13px' }}>
                <span>Personal referral code:</span>
                <div style={{ backgroundColor: '#06A77D' }}>
                  <p ref={referralCodeRef}>{referralCode}</p>{' '}
                  <span
                    onClick={copyToClipboard}
                    className="copy-button"
                    role="button"
                    title="Copy">
                    <Icon path={mdiContentCopy} size={0.7} />
                  </span>
                </div>
              </div>
            )}
            <ul onClick={toggleNavbar}>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="overview">
                  <Icon path={mdiViewDashboard} vertical size={1} />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to={
                    loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loans'
                  }>
                  <Icon path={mdiChartBox} size={1} />
                  <span>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loans'}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="guarantors/all_guarantors">
                  <Icon path={mdiAccountGroup} size={1} />
                  <span>Guarantors</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="invest">
                  <Icon path={mdiFlowerTulip} size={1} />
                  <span>Invest</span>
                </NavLink>
              </li>
              {/* New  */}
              {/* <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="invoice"
                >
                  <Icon path={mdiShieldCheck} size={1} />
                  <span>Invoice</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="bookkeeping"
                >
                  <Icon path={mdiBookOpenBlankVariant} size={1} />
                  <span>Bookkeeping</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="wallet">
                  <Icon path={mdiWallet} size={1} />
                  <span>Wallet</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'sidenav-link active' : 'sidenav-link'
                  }
                  to="settings/account_settings">
                  <Icon path={mdiCog} size={1} />
                  <span>Settings</span>
                </NavLink>
              </li>
            </ul>
            <div>
              <button type="button" onClick={handleLogOut}>
                <Icon
                  path={mdiLogout}
                  title="User Profile"
                  size={1}
                  horizontal
                />
                <span>Log out</span>
              </button>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </header>
  );
};

export default Header;
