/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import Spinner from '../../../../components/ui/Spinner';
import { StoreContext } from '../../../../mobx_stores/RootStore';

type Props = {
  next: () => void;
  previous: any;
  x: number;
  setX: Function;
};

const GuarantorInformation: FC<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const location: any = useLocation();
  const { guarantorStore, loansStore } = useContext(StoreContext);
  const [guarantorDetails, setGuarantorDetails] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    country: 'Nigeria',
    occupation: '',
    roleInCompany: '',
  });

  const guarantorState = location.state;
  const { submitting, message } = guarantorStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (guarantorState) {
      setGuarantorDetails({
        title:
          guarantorState && guarantorState?.title === undefined
            ? ''
            : guarantorState.title,
        email:
          guarantorState && guarantorState?.email === undefined
            ? ''
            : guarantorState.email,
        firstName:
          guarantorState && guarantorState?.firstName === undefined
            ? ''
            : guarantorState.firstName,
        lastName:
          guarantorState && guarantorState?.lastName === undefined
            ? ''
            : guarantorState.lastName,
        phoneNumber:
          guarantorState && guarantorState?.phoneNumber === undefined
            ? ''
            : guarantorState.phoneNumber,
        gender:
          guarantorState && guarantorState?.gender === undefined
            ? ''
            : guarantorState.gender,
        dob:
          guarantorState && guarantorState?.dob === undefined
            ? ''
            : guarantorState.dob?.substr(0, 10),
        address:
          guarantorState && guarantorState?.address === undefined
            ? ''
            : guarantorState.address,
        country:
          guarantorState && guarantorState?.country === undefined
            ? 'Nigeria'
            : guarantorState.country,
        state:
          guarantorState && guarantorState?.state === undefined
            ? ''
            : guarantorState.state,
        city:
          guarantorState && guarantorState?.city === undefined
            ? ''
            : guarantorState.city,
        roleInCompany:
          guarantorState && guarantorState?.roleInCompany === undefined
            ? ''
            : guarantorState.roleInCompany,
        occupation:
          guarantorState && guarantorState?.occupation === undefined
            ? ''
            : guarantorState.occupation,
      });
    }
  }, [guarantorState]);

  const [state, setState] = useState([]);
  const [cityByState, setCitybyState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setState(toJS(loansStore.state));
    setCity(toJS(loansStore.city));
  }, [loansStore.state, loansStore.city]);

  useEffect(() => {
    if (guarantorDetails.state !== '') {
      setCitybyState(
        state.filter((state: string) => state === guarantorDetails.state),
      );
    }
  }, [guarantorDetails.state, state]);

  const stateCity = cityByState[0];

  useEffect(() => {
    loansStore.getState();
    loansStore.getCity(stateCity);
  }, [loansStore, stateCity]);

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;

    setGuarantorDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });
  };

  const handleUpdateGuarantor = (e: FormEvent) => {
    e.preventDefault();

    if (guarantorState && guarantorState._id !== undefined) {
      guarantorStore.updateGuarantor(guarantorDetails, guarantorState._id);
    }
  };

  if (message.type === 'success') {
    navigate('/verification_successful');
  }

  return (
    <>
      {submitting && <Spinner />}
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-12">
          <div className="bg-white p-sm-5 p-4">
            <h4 className="guarantor-form-heading mb-2">Submit Information</h4>
            <p className="mb-0">Almost there! fill your information below</p>

            <form
              className="add-guarantor-form"
              onSubmit={handleUpdateGuarantor}>
              <div className="row justify-content-center">
                <div className="col-md-12 left-side ">
                  <div className="col-md-12">
                    <label htmlFor="title" className="mb-1 mt-3">
                      Title
                    </label>
                    <select
                      onChange={handleInputChange}
                      name="title"
                      id="title"
                      className="form-control"
                      value={guarantorDetails.title}>
                      <option value="">Title</option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                      <option value="Chief">Chief</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="firstName" className="mb-1 mt-3">
                        First name
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="firstName"
                        classes="form-control"
                        type="text"
                        id="firstName"
                        value={guarantorDetails.firstName}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="lastName" className="mb-1 mt-3">
                        Last name
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="lastName"
                        classes="form-control"
                        type="text"
                        id="lastName"
                        value={guarantorDetails.lastName}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="gender" className="mb-1 mt-3">
                        Gender
                      </label>
                      <select
                        onChange={handleInputChange}
                        value={guarantorDetails.gender}
                        name="gender"
                        id="gender"
                        className="form-control">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="dateOfBirth" className="mb-1 mt-3">
                        Date of birth
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="dob"
                        classes="form-control"
                        type="date"
                        id="dateOfBirth"
                        value={guarantorDetails.dob}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="email" className="mb-1 mt-3">
                        Email address
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="email"
                        classes="form-control"
                        type="text"
                        id="email"
                        value={guarantorDetails.email}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phoneNumber" className="mb-1 mt-3">
                        Mobile number
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="phoneNumber"
                        classes="form-control"
                        type="text"
                        id="phoneNumber"
                        value={guarantorDetails.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="address" className="mb-1 mt-3">
                      Address
                    </label>
                    <Input
                      onChange={handleInputChange}
                      name="address"
                      classes="form-control"
                      type="text"
                      id="address"
                      value={guarantorDetails.address}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="country" className="mb-1 mt-3">
                        Country
                      </label>
                      <select
                        onChange={handleInputChange}
                        value={guarantorDetails.country}
                        name="country"
                        id="country"
                        className="form-control">
                        <option value="Nigeria">Nigeria</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="state" className="mb-1 mt-3">
                        State
                      </label>
                      <select
                        name="state"
                        id="state"
                        className="form-control"
                        onChange={handleInputChange}
                        value={guarantorDetails.state}>
                        <option value="">Select State</option>
                        {state.map((state: string, i: number) => (
                          <option value={state} key={i}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="city" className="mb-1 mt-3">
                        LGA
                      </label>
                      <select
                        name="city"
                        id="city"
                        className="form-control"
                        onChange={handleInputChange}
                        value={guarantorDetails.city}>
                        <option value="">Select City</option>
                        {city.map((city: string, i: number) => (
                          <option value={city} key={i}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="occupation" className="mb-1 mt-3">
                        Occupation
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="occupation"
                        classes="form-control"
                        type="text"
                        id="occupation"
                        value={guarantorDetails.occupation}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="role" className="mb-1 mt-3">
                        Role in the company
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="roleInCompany"
                        classes="form-control"
                        type="text"
                        id="role"
                        value={guarantorDetails.roleInCompany}
                      />
                    </div>
                  </div>
                  {message.msg !== '' && (
                    <div
                      className={
                        message.type === 'success'
                          ? ' alert alert-success mb-0'
                          : 'alert alert-danger mb-0'
                      }
                      role="alert">
                      {message.msg}
                    </div>
                  )}
                </div>
                <div className="row">
                  <Button
                    classes={`${
                      submitting
                        ? 'primary-btn-disabled btn-lg mt-4 w-100'
                        : 'primary-btn btn-lg mt-4 w-100'
                    }`}
                    content="Submit"
                    type="submit"
                    disabled={submitting}
                    // onClick={() => setSuccess(true)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(GuarantorInformation);
