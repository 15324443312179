/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { StoreContext } from '../../../../mobx_stores/RootStore';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  setOpenInvestmentModal: (state: any) => void;
  disabled?: boolean;
  minAmount?: number;
  amount?: number;
};

const durationData = [
  { value: '', label: 'Select Option' },
  { value: '3 Months', label: '3 Months' },
  { value: '6 Months', label: '6 Months' },
  { value: '12 Months', label: '12 Months' },
];

const InputLendaAmountModal: FC<Props> = ({
  route,
  button,

  onClick = () => {
    setOpenInvestmentModal(false);
  },
  investmentData,
  setOpenInvestmentModal,
}) => {
  const [investmentDetails, setInvestmentDetails] = useState({
    investmentType: investmentData?.investmentName,
    investmentTenor: '',
    investmentAmount: '0',
    transactionPin: '',
    fromWalletIdAccountNumber: '',
  });
  const { investStore, walletStore } = useContext(StoreContext);
  const { multipleWallet } = walletStore;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { message, submittingInvestment } = investStore;
  const [pinToggle, setPinToggle] = useState(false);
  const [showBankSelection, setShowBankSelection] = useState(true);

  const updateData = (element: any) => {
    const e = element.target as HTMLInputElement;
    if (e.name === 'investmentAmount') {
      const rawValue = e.value.replace(/,/g, '');
      if (/^\d*\.?\d*$/.test(rawValue)) {
        const formattedValue = Number(rawValue).toLocaleString();
        setErrorMessage('');
        setInvestmentDetails((prevDetails: any) => {
          return {
            ...prevDetails,
            [e.name]: formattedValue,
          };
        });
      } else {
        setErrorMessage('Enter a valid amount');
      }
    } else {
      setInvestmentDetails((prevDetails: any) => {
        return {
          ...prevDetails,
          [e.name]: e.value,
        };
      });
    }
  };

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleInvestNow = () => {
    const removeCommaInAmount = investmentDetails.investmentAmount.replace(
      /,/g,
      '',
    );
    if (
      Number(removeCommaInAmount) <
        Number(investmentData?.amountRange?.minAmount) ||
      Number(removeCommaInAmount) <= 0
    ) {
      toast.error('Investment amount is below minimum allowed investment');
      return;
    }
    if (
      Number(removeCommaInAmount) >
      Number(investmentData?.amountRange?.maxAmount)
    ) {
      toast.error('Investment amount is above maximum allowed investment');
      return;
    }

    if (
      investmentDetails.fromWalletIdAccountNumber == '' ||
      investmentDetails.fromWalletIdAccountNumber == null ||
      investmentDetails.fromWalletIdAccountNumber == undefined ||
      investmentDetails.fromWalletIdAccountNumber.length < 10
    ) {
      toast.error('Wallet not selected!');
      return;
    }
    const param = {
      investmentType: investmentDetails.investmentType,
      investmentTenor: investmentDetails.investmentTenor,
      investmentAmount: removeCommaInAmount,
      transactionPin: investmentDetails.transactionPin,
      fromWalletIdAccountNumber: investmentDetails.fromWalletIdAccountNumber,
    };

    investStore.createLendaInvestment(param);
  };

  const disableIt =
    investmentDetails?.investmentAmount.length > 1 &&
    investmentDetails?.investmentTenor !== '' &&
    investmentDetails?.investmentType !== '' &&
    investmentDetails?.transactionPin.length == 4;

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment Created successfully.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [message, submittingInvestment]);

  return (
    <div className="notification-modal">
      <div
        className="bg-white notification-box"
        style={{
          // textAlign: 'left',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px',
          alignContent: 'center',
          display: 'flex',
          // padding: '10px',
        }}>
        <span
          onClick={() => {
            setOpenInvestmentModal(false);
          }}
          className="close"
          role="button">
          <FaTimes />
        </span>
        {/* Investment Form */}
        <div className="row justify-content-center amount-box">
          {/* // BANK SELECTION */}
          {showBankSelection && (
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -200, opacity: 0 }}
              className="sliding-input">
              <div className="row justify-content-center invest-box">
                <h5
                  className="my-3"
                  style={{ fontSize: '20px', textTransform: 'uppercase' }}>
                  Select a wallet
                </h5>
                <div className="col-12">
                  {toJS(multipleWallet) && toJS(multipleWallet)?.length > 0
                    ? toJS(multipleWallet)?.map(
                        (walletData: any, index: number) => (
                          <div className="w-100 gap-2 mt-2" key={index}>
                            <h6
                              className="wallet-deets d-flex align-items-start my-3 color-heading"
                              style={{
                                fontSize: '14px',
                                textTransform: 'uppercase',
                              }}>
                              Wallet {index + 1}
                            </h6>
                            <div
                              className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets invest-selection"
                              onClick={() => {
                                if (walletData?.availableBalance < 100) {
                                  toast.error(
                                    'Amount must be greater than ₦100',
                                  );
                                } else {
                                  setInvestmentDetails({
                                    investmentType:
                                      investmentData?.investmentName,
                                    investmentTenor: '',
                                    investmentAmount: '0',
                                    transactionPin: '',
                                    fromWalletIdAccountNumber:
                                      walletData?.walletIdAccountNumber,
                                  });
                                  setShowBankSelection(false);
                                }
                              }}>
                              <h6>{walletData?.banker}</h6>
                              <p>
                                ₦{' '}
                                {walletData?.availableBalance != 'NaN' ||
                                walletData?.availableBalance != null ||
                                walletData?.availableBalance != undefined
                                  ? walletData?.availableBalance
                                    ? new Intl.NumberFormat('en-US', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(walletData?.availableBalance)
                                    : '0.00'
                                  : '0.00'}
                              </p>
                            </div>
                          </div>
                        ),
                      )
                    : null}
                </div>
              </div>
            </motion.div>
          )}
          {!showBankSelection && (
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -200, opacity: 0 }}
              className="sliding-input">
              <div className="row justify-content-center invest-box">
                <div className="col-12">
                  <h5
                    className="my-2"
                    style={{
                      fontSize: '20px',
                    }}>
                    Investment Tenor
                  </h5>
                  <select
                    name="investmentTenor"
                    className="form-control"
                    id="investmentTenor"
                    onChange={updateData}
                    required={true}>
                    {durationData.map((item, key) => (
                      <option value={item.value} key={key}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <h5
                    className="my-2"
                    style={{
                      fontSize: '20px',
                    }}>
                    Enter amount
                  </h5>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        style={{
                          borderRight: '0',
                          borderTopRightRadius: '0',
                          borderBottomRightRadius: '0',
                        }}>
                        ₦
                      </span>
                    </div>
                    <Input
                      style={{ borderLeft: '0' }}
                      name="investmentAmount"
                      classes="form-control text-center required"
                      type="text"
                      id="investmentAmount"
                      value={investmentDetails.investmentAmount}
                      onChange={updateData}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                    }}>
                    {errorMessage !== '' ? (
                      <small
                        className="text-danger"
                        style={{ fontSize: '14px' }}>
                        {errorMessage}
                      </small>
                    ) : null}
                    <p style={{ fontSize: '14px' }}>
                      Investment Range = ₦{' '}
                      {investmentData?.amountRange
                        ? new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 9,
                          }).format(investmentData?.amountRange?.minAmount)
                        : '0.00'}{' '}
                      -{' '}
                      {investmentData?.amountRange
                        ? new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 9,
                          }).format(investmentData?.amountRange?.maxAmount)
                        : '0.00'}
                    </p>
                  </div>

                  <h5
                    className="my-2"
                    style={{
                      fontSize: '20px',
                    }}>
                    Transaction Pin
                  </h5>
                  <div className="col-md-12 input-box justify-content-center ">
                    <div>
                      <OtpInput
                        value={investmentDetails.transactionPin}
                        onChange={(text) => {
                          setInvestmentDetails((prevDetails: any) => {
                            return {
                              ...prevDetails,
                              ['transactionPin']: text,
                            };
                          });
                        }}
                        numInputs={4}
                        inputStyle="pin-style"
                        containerStyle="pin-container-style"
                        inputType={pinToggle ? 'tel' : 'password'}
                        shouldAutoFocus={true}
                        renderInput={(props) => (
                          <input onKeyPress={validateNumber} {...props} />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-2 justify-content-center align-content-center mb-3">
                    <input
                      type="checkbox"
                      onChange={handlePinToggle}
                      name="showPin"
                      id="show-pin"
                    />
                    <label htmlFor="show-pin">Show pin</label>
                  </div>
                  {button && (
                    <div className="group-btn justify-content-center">
                      {
                        <>
                          <Button
                            classes="primary-btn w-auto btn-lg waves-effect"
                            content="Back"
                            onClick={() => setShowBankSelection(true)}
                            type="button"
                          />
                          <Button
                            classes="primary-btn w-auto btn-lg waves-effect"
                            content="Invest Now"
                            route={route}
                            type="submit"
                            onClick={() => {
                              handleInvestNow();
                            }}
                            disabled={!disableIt}
                          />
                        </>
                      }
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(InputLendaAmountModal);
