/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { motion } from 'framer-motion';
import * as React from 'react';
import ScrollToTop from '../../../../components/ScrollToTop';
import NextOfKinForm from '../../../../components/forms/NextOfKinForm';

interface INextOfKinProps {
  next: () => void;
  previous: any;
  x: number;
  setX: Function;
}

const NextOfKin: React.FunctionComponent<INextOfKinProps> = ({
  next,
  previous,
  x,
  setX,
}) => {
  return (
    <ScrollToTop>
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
        className="">
        <div className="bg-white">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 col-md-9 col-sm-10 bg-primary-blue mt-4 rounded">
              <h4>NEXT OF KIN DETAILS</h4>
              <p>Tell us who to call in case of emergencies</p>
            </div>
          </div>
          <NextOfKinForm next={next} previous={previous} setX={setX} />
        </div>
      </motion.div>
    </ScrollToTop>
  );
};

export default NextOfKin;
