/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import FAQ from './sections/FAQ';
import Footer from './sections/Footer';
import Header from './sections/Header';
import Invest from './sections/Invest';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import GetStarted from './sections/GetStarted';
import Blank from './sections/Blank';
import Newsletter from './sections/Newsletter';
import { Helmet } from 'react-helmet-async';

const InvestWithUs = () => {
  return (
    <div className="bg-white invest-with-us">
      <Helmet>
        <title>Invest With Us | Trade Lenda Investment Services!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />

      <section className="landing-page-hero">
        <div className="hero-section">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6 hero-text-container">
                <div className="hero-text">
                  <h1>
                    Lend to the supply chain to earn market leading returns
                  </h1>
                  <p>
                    By lending to businesses that drive growth and innovation in
                    the industry, you can earn attractive returns while
                    contributing to a better future.
                  </p>
                  <div>
                    <Button
                      classes="yellow-btn btn-lg me-lg-3"
                      content="Lend Now"
                      route="/dashboard/invest"
                      // route="#"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/invest-hero-img2.png`}
                    alt="Invest with us hero picture"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hero-second">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="second-text">
              <h1>Saving and Investment Plans</h1>
            </div>
            <div className="row justify-content-center align-items-start invest-container">
              <div className="col-lg-3 invest-card">
                <h2 className="text">Save with ARM</h2>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/investor-woman.png`}
                  alt="Investor woman picture"
                />
                <p>
                  Diversify your investment portfolio, Get your money working
                  for you and earn attractive returns on your investment
                </p>
                <Button
                  classes="primary-btn btn-lg me-lg-3"
                  content="Invest Now"
                  // route="/dashboard/invest"
                  route="/dashboard/invest"
                />
              </div>
              <div className="col-lg-3 invest-card">
                <h2 className="text">Lend with Trade Lenda</h2>
                <img src={`${BaseDirectories.IMAGES_DIR}/inImg3.png`} alt="" />
                <p>
                  Our insurance provides peace of mind, knowing that your
                  valuable goods are protected during every step of the journey.
                </p>
                <Button
                  classes="primary-btn btn-lg me-lg-3"
                  content="Invest Now"
                  // route="/dashboard/invest"
                  route="/dashboard/invest"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Invest />

      {/* <section className="landing-page-hero earn">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="hero-text">
                <h1>Save with ARM and earn daily returns.</h1>
                <p>
                  Diversify your investment portfolio, Get your money working
                  for you and earn attractive returns on your investment
                </p>
                <div>
                  <Button
                    classes="primary-btn btn-lg me-lg-3"
                    content="Save Now"
                    // route="/dashboard/invest"
                    route="/a_r_m"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-img">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/investor-woman.png`}
                  alt="Investor woman picture"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <GetStarted /> */}
      <Blank height="60px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default InvestWithUs;
