/* eslint-disable @typescript-eslint/ban-types */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import TawkTo from 'tawkto-react';
import './App.scss';
import Notification from './components/Notification';
import MainRoutes from './MainRoutes';

const PropertyID = '62308fc6a34c2456412b29b5';
const TawkID = '1fu6r38rm';

function App() {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tawk = new TawkTo(PropertyID, TawkID);
    (function () {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/62308fc6a34c2456412b29b5/1fu6r38rm';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode?.insertBefore(s1, s0);
    })();
  }, []);

  datadogLogs.init({
    clientToken:
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
      'pub49ec726738cbd6ae24739bbadeb081ef',
    site: process.env.REACT_APP_DATADOG_SITE_URL || 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    silentMultipleInit: true,
    service: 'landing-page',
    env: 'production',
    forwardConsoleLogs: 'all',
  });

  datadogRum.init({
    applicationId: '6d8431d9-cc7f-4497-8191-dff089045b7c',
    clientToken: 'pub49ec726738cbd6ae24739bbadeb081ef',
    site: 'datadoghq.com',
    service: 'tradelenda-landing-page',
    sampleRate: 100,
    silentMultipleInit: true,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();

  return (
    <div className="App">
      <Notification />
      <MainRoutes />
    </div>
  );
}

export default App;
