import React from 'react';

const StatsSection = () => {
  return (
    <section className="stats-section">
      <div className="container">
        <div className="row gap-lg-0 gap-4 justify-content-between">
          <div className="col-lg-4 col-xl-3 col-md-12 col-12 d-flex justify-content-center">
            <div className="stat">
              <h3>100k+</h3>
              <p>
                Over 100,000 SMEs and <br /> merchants on our platform.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3 col-md-12 col-12 d-flex justify-content-center">
            <div className="stat">
              {/* $ */}
              <h3>N6B+</h3>
              <p>Over N6Billion disbursement</p>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3 col-md-12 col-12 d-flex justify-content-center">
            <div className="stat">
              <h3>1-6hours</h3>
              <p>
                Average time of Daily <br /> disbursement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
