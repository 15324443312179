import { mdiArrowRight, mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import WhatWeDoCard from './WhatWeDoCard';

const cardData = [
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/wc-financing.png`,
    altText: 'working capital financing',
    title: 'Working Capital Financing',
    text: 'We can help you get the working capital you need to succeed. Apply now and expand your business.',
    link: '/loan_for_business',
  },
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/lpo-financing.png`,
    altText: 'local purchase order financing',
    title: 'Local Purchase Order Financing',
    text: 'We can help you take on larger orders and grow your business. Apply now and see the difference.',
    link: '/loan_for_business',
  },
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/id-financing.png`,
    altText: 'invoice discount finance',
    title: 'Invoice Discount Finance',
    text: 'We can help you improve your cash flow and keep your business running smoothly. Apply now and grow your business.',
    link: '/loan_for_business',
  },
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/murbaha_img.png`,
    altText: 'murabaha capital financing',
    title: 'Murabaha Islamic Financing',
    text: 'Murabaha is an Islamic financing structure in which the seller and buyer agree to the cost and markup of an asset or inventories.',
    link: '/islamic_facility_financing',
  },
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/musharakah_card2.jpg`,
    altText: 'musharakah capital financing',
    title: 'Musharakah Islamic Financing',
    text: 'Musharakah represents a collaborative partnership arrangement between the customer and Trade Lenda.',
    link: '/islamic_facility_financing',
  },
  {
    imgSrc: `${BaseDirectories.IMAGES_DIR}/asset_financing_windmill.svg`,
    altText: 'asset financing',
    title: 'Asset Financing',
    text: 'Our comprehensive asset financing solutions help you obtain the equipment and resources you need while maintaining your financial stability.',
    link: '/asset_financing',
  },
];

const WhatWeDo = () => {
  AOS.init();

  return (
    <div className="what-we-do">
      {/* Backed Section */}
      {/* Backed Section */}

      <section
        data-aos="fade-right"
        data-aos-offset="70"
        data-aos-delay="0"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true">
        <div className="container d-flex flex-column justify-content-center align-content-center">
          {/* Swiper Starts  */}
          <div className="row justify-content-center text-center">
            <Swiper
              spaceBetween={30}
              observeParents={true}
              observer={true}
              loop={true}
              effect={'fade'}
              fadeEffect={{
                crossFade: true,
              }}
              centeredSlides={true}
              autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
              pagination={false}
              navigation={false}
              modules={[EffectFade, Autoplay, Pagination, Navigation]}
              className="mySwiper">
              <SwiperSlide>
                <div className="d-flex align-items-center justify-content-center gap-md-0 mb-5 wwd-group">
                  <div className="col-xl-5 col-lg-6 col-md-7">
                    <div className="hero-text">
                      <div className="hs-section">
                        <div className="first-cont wwd-content">
                          <h3>Fast-moving consumer goods (FMCG)</h3>
                          <p>
                            Get access to financial solutions that can help you
                            keep your shelves stocked and respond to market
                            demand quickly.
                          </p>
                        </div>
                        <Link to="/login">
                          get access to finance now{' '}
                          <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-5 col-md-5 order-first order-md-last">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/FCMG.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex align-items-center justify-content-center gap-md-0 mb-5 wwd-group">
                  <div className="col-xl-5 col-lg-6 col-md-7">
                    <div className="hero-text">
                      <div className="hs-section">
                        <div className="wwd-content">
                          <h3>clean energy</h3>
                          <p>
                            Our project financing options provide the funds you
                            need to invest in new clean energy projects, such as
                            solar and wind power
                          </p>
                        </div>
                        <Link to="/login">
                          get access to finance now{' '}
                          <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-5 order-first order-md-last">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/cleanenergy.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex align-items-center justify-content-center gap-md-0 mb-5 wwd-group">
                  <div className="col-xl-5 col-lg-6 col-md-7">
                    <div className="hero-text">
                      <div className="hs-section">
                        <div className="wwd-content">
                          <h3>agric and agro-allied commodities</h3>
                          <p>
                            Get the funding and management solutions you need to
                            grow your agricultural and agro-commodities business
                          </p>
                        </div>
                        <Link to="/login">
                          get access to finance now{' '}
                          <Icon path={mdiArrowRight} size={1} />
                        </Link>
                        {/* <div className="wwd-content">
                        <h3>general merchandize commerce</h3>
                        <p>
                          We offer flexible financing solutions to help you
                          manage your operations and grow your business.
                        </p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-5 order-first order-md-last">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/agro.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex align-items-center justify-content-center gap-md-0 mb-5 wwd-group">
                  <div className="col-xl-5 col-lg-6 col-md-7">
                    <div className="hero-text">
                      <div className="hs-section">
                        <div className="wwd-content">
                          <h3>general merchandize commerce</h3>
                          <p>
                            We offer flexible financing solutions to help you
                            manage your operations and grow your business.
                          </p>
                        </div>
                        <Link to="/login">
                          get access to finance now{' '}
                          <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-5 order-first order-md-last">
                 <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/general-merch.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>  */}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {/* Swiper ends  */}
          <div className="row">
            <div className="col-lg-4 col-md-6 col-md-5 order-first order-md-last">
              <div className="hero-img">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/FCMG.png`}
                  alt="Trade Lenda"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last">
              <div className="hero-img">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/agro.png`}
                  alt="Trade Lenda"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last">
              <div className="hero-img">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/general-merch.png`}
                  alt="Trade Lenda"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section2">
        <div className="container circle pb-4">
          <div className="row mb-5 justify-content-center align-content-center">
            <div className="col-12">
              <h6 className="text-center">
                <Icon path={mdiCircleSmall} size={2} />
                <span>WHAT WE DO</span>
              </h6>
              <h2>
                Business finance for {''}
                <span style={{ color: '#06A77D' }} className="fw-bold">
                  MSMEs
                </span>
              </h2>
              <p className="description text-center">
                We make access to B2B financing simple and easy. <br /> No
                collaterals or long forms.
              </p>
            </div>
          </div>
          <div className="row justify-content-center mb-5">
            <div className="col-lg-7 col-md-10">
              <h2>Get easy and instant loan for your business</h2>
            </div>
          </div>
          <div className="carded ">
            <div className="row row-cols-1 row-cols-lg-2 g-4">
              {cardData.map((card, index) => (
                <WhatWeDoCard
                  key={index}
                  imgSrc={card.imgSrc}
                  altText={card.altText}
                  title={card.title}
                  text={card.text}
                  link={card.link}
                />
              ))}
            </div>
          </div>

          {/* <div className="row row-cols-1 row-cols-lg-2 g-4">
            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/wc-financing.png`}
                      className="card-img-top h-100"
                      alt="working capital financing "
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">
                          working capital financing{' '}
                        </h5>
                        <p className="card-text mt-0">
                          We can help you get the working capital you need to
                          succeed. Apply now and expand your business
                        </p>
                        <Link to="/loan_for_business">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/lpo-financing.png`}
                      className="card-img-top h-100"
                      alt="local  purchase order financing"
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">
                          local purchase order financing
                        </h5>
                        <p className="card-text mt-0">
                          We can help you take on larger orders and grow your
                          business. Apply now and see the difference
                        </p>
                        <Link to="/loan_for_business">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/id-financing.png`}
                      className="card-img-top h-100"
                      alt="invoice discount finance"
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">invoice discount finance</h5>
                        <p className="card-text mt-0">
                          We can help you improve your cash flow and keep your
                          business running smoothly. Apply now and grow your
                          business
                        </p>
                        <Link to="/loan_for_business">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/murbaha_img.png`}
                      className="card-img-top h-100"
                      alt="murabaha capital financing "
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">
                          Murabaha Islamic financing
                        </h5>
                        <p className="card-text mt-0">
                          Murabaha is an islamic financing structure in which
                          the seller and buyer agree to the cost and markup of
                          an asset or inventories.
                        </p>
                        <Link to="/islamic_facility_financing">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/musharakah_card2.jpg`}
                      className="card-img-top h-100"
                      alt="musharakah capital financing "
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">
                          Musharakah Islamic Financing
                        </h5>
                        <p className="card-text mt-0">
                          Musharakah represents a collaborative partnership
                          arrangement between the customer and Trade Lenda.
                        </p>
                        <Link to="/islamic_facility_financing">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card p-3 h-90">
                <div className="d-flex flex-column flex-md-row gap-2">
                  <div className="col-md-6">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/asset_financing_windmill.svg`}
                      className="card-img-top h-100"
                      alt="musharaka capital financing "
                      style={{
                        minHeight: '232px',
                        maxHeight: '280px',
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-body px-2 pb-0">
                      <div className="align-items-start">
                        <h5 className="card-title">Asset Financing</h5>
                        <p className="card-text m-0">
                          Our comprehensive asset financing solutions help you
                          obtain the equipment and resources you need while
                          maintaining your financial stability.
                        </p>
                        <Link to="/asset_financing">
                          Learn More <Icon path={mdiArrowRight} size={1} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row mt-5">
            <div className="col-12 text-center">
              <Button
                content="Apply Now"
                classes="btn primary-btn btn-lg"
                route="/sign_up"
              />
            </div>
          </div>
          {/* <div className="d-flex justify-content-end">
            <div className="element"></div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default WhatWeDo;
