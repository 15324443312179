import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import { auth } from '../../firebase/Firebase';
import Confetti from 'react-confetti';
import { LogOut } from '../../utils/firebase/AuthFirestore';
import Spinner from '../../components/ui/Spinner';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/auth.action';
import { persistor } from '../../redux';
import { clearReduxStateAction } from '../../redux/root-reducer';

const windowSize = {
  width: 1024,
  height: 700,
};

const EmailVerificationSuccess = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    false || window.sessionStorage.getItem('userStatus') === 'true',
  );
  const navigate = useNavigate();

  const [useWindowSize, setUseWindowsSize] = useState(windowSize);
  const { width, height } = useWindowSize;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setIsLoggedIn(JSON.parse(window.sessionStorage.getItem('userStatus')!));
  }, [auth.currentUser]);

  useEffect(() => {
    const interval = setInterval(async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setIsLoggedIn(JSON.parse(window.sessionStorage.getItem('userStatus')!));
      if (auth.currentUser?.emailVerified && isLoggedIn) {
        if (
          auth.currentUser?.email?.includes('@tradelenda.com') &&
          auth.currentUser?.email !== 'test@tradelenda.com'
        ) {
          window.location.replace('https://legend.tradelenda.com');
        } else {
          navigate('/dashboard/overview');
        }
        clearInterval(interval);
        return () => clearInterval(interval);
      }
      // window.location.reload();
      await auth.currentUser?.reload();
    }, 2000);
    return () => clearInterval(interval);
  }, [navigate, isLoggedIn]);

  async function handleLogOut() {
    setLoading(true);
    await LogOut();
    dispatch(clearReduxStateAction());
    window.location.href = '/login';
    setTimeout(async () => {
      setLoading(false);
      window.location.reload();
      dispatch(logout());
      persistor.pause();
      await persistor.flush().then(async () => {
        return await persistor.purge();
      });
    }, 500);
  }

  useEffect(() => {
    setInterval(() => {
      setUseWindowsSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 500);
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <div className="verification-success">
        <Confetti width={width} height={height} gravity={0.1} />
        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="logo ">
          <Link to="/">
            <img
              src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
              alt=""
              style={{ width: '18%' }}
            />
          </Link>
        </div>
        <div className="container">
          <div className="verification-img mt-5">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/verification2.png`}
              alt=""
              style={{ width: '15%' }}
            />
          </div>
          <h2>Verification Successful</h2>
          {auth.currentUser?.emailVerified && isLoggedIn && (
            <Button
              content="Go To Dashboard"
              onClick={() => window.location.reload()}
              // route="/dashboard/overview"
              classes="primary-btn btn-lg  col-12 mb-5 mx-3"
            />
          )}
          {!auth.currentUser?.emailVerified && !isLoggedIn && (
            <Button
              content="Log In"
              // route="/login"
              type="button"
              onClick={handleLogOut}
              classes="primary-btn btn-lg  col-12 mb-5 mx-3"
            />
          )}
        </div>

        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </>
  );
};

export default EmailVerificationSuccess;
