/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';
import RedemptionModal from './RedemptionModal';
import TopUpModal from './TopUpModal';

interface LocationState {
  [key: string]: any;
}

const InvestmentDetails = () => {
  const location: LocationState = useLocation();
  const { investStore } = useContext(StoreContext);
  const [investmentDetail, setInvestmentDetail] = useState<any>();
  const [portfolioDetail, setPortfolioDetail] = useState<any>();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  useEffect(() => {
    investStore.getSingleInvestment(
      location?.state?.MembershipId,
      location?.state?.ProductCode,
    );
    investStore.setInvestment;
  }, [investStore, location]);

  useEffect(() => {
    const interval = setInterval(() => {
      investStore.getSingleInvestmentWithoutLoading(
        location?.state?.MembershipId,
        location?.state?.ProductCode,
      );
      investStore.setInvestment;
      if (toJS(investStore.investment)) {
        setInvestmentDetail(
          toJS(investStore?.investment?.getArmUserInvestment[0]),
        );
        setPortfolioDetail(toJS(investStore?.investment?.portfolio[0]));
      }
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (toJS(investStore.investment)) {
      setInvestmentDetail(
        toJS(investStore?.investment?.getArmUserInvestment[0]),
      );
      setPortfolioDetail(toJS(investStore?.investment?.portfolio[0]));
    }
  }, [investStore.investment, location]);

  const handleTopUP = () => {
    setOpenPaymentModal(true);
  };

  const handleInvestmentRedemption = () => {
    // first check if account is updated
    investStore.checkIfAccountIsUpdated(location?.state?.MembershipId);
    setOpenOtpModal(true);
  };

  return (
    <div className="loans">
      {investStore.loadingPlans && <Spinner />}

      {openPaymentModal && (
        <TopUpModal
          button={true}
          route="/dashboard/invest"
          investmentData={investmentDetail}
          setOpenPaymentModal={setOpenPaymentModal}
        />
      )}

      {openOtpModal && (
        <RedemptionModal
          button={true}
          route="/dashboard/invest"
          investmentData={investmentDetail}
          portfolioData={portfolioDetail}
          setOpenOtpModal={setOpenOtpModal}
        />
      )}

      <div className="section-head">Investment Details</div>
      <div className="loans-container">
        <Link to="" onClick={() => window.history.back()}>
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="rq-loan-details">
          <h5>Active Investment</h5>
          <div className="d-flex flex-column flex-md-row">
            <div className="box-1">
              <div className="ld-box">
                <h5>Investment Plan</h5>
                <p>{investmentDetail?.productCode || 'NIL'}</p>
              </div>

              <div className="ld-box">
                <h5>Investment ID</h5>
                <p>{portfolioDetail?.investmentId || 'NIL'}</p>
              </div>

              <div className="ld-box">
                <h5>Investment Commencement Date</h5>
                <p>
                  {moment(investmentDetail?.createdAt).format('MM-D-YYYY') ||
                    'NIL'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Membership ID</h5>
                <p>{investmentDetail?.membershipId || 'NIL'}</p>
              </div>
              <div className="ld-box">
                <h5>Total Investment Amount</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.investmentAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.investmentAmount)
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Account Balance</h5>
                <p>
                  ₦{' '}
                  {portfolioDetail?.accountBalance
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(portfolioDetail?.accountBalance)
                    : '0.00'}
                </p>
              </div>
            </div>

            <div className="box-1">
              <div className="ld-box">
                <h5>Referrer ID</h5>
                <p style={{ textTransform: 'capitalize', fontSize: '17px' }}>
                  {investmentDetail?.leadId || 'NIL'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Investment Status</h5>
                <p style={{ textTransform: 'capitalize' }}>
                  {' '}
                  {investmentDetail?.redemptionStatus == 'REDEMPTION_NONE'
                    ? 'ACTIVE'
                    : investmentDetail?.redemptionStatus == 'REDEMPTION_PENDING'
                      ? 'PENDING REDEMPTION'
                      : investmentDetail?.redemptionStatus ==
                          'PARTIAL_REDEMPTION'
                        ? 'PARTIAL REDEMPTION'
                        : investmentDetail?.redemptionStatus ==
                            'COMPLETE_REDEMPTION'
                          ? 'COMPLETE REDEMPTION'
                          : investmentDetail?.redemptionStatus ==
                              'REDEMPTION_FAILED'
                            ? 'REDEMPTION FAILED'
                            : 'REDEMPTION SUCCESSFUL'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Pending Top-Up</h5>
                <p>
                  ₦{' '}
                  {investmentDetail?.topUpAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.topUpAmount)
                    : '0.00'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Total Redeemed Amount</h5>
                <p>
                  ₦
                  {investmentDetail?.redemptionAmount
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(investmentDetail?.redemptionAmount)
                    : '0.00'}
                </p>
              </div>
              <div className="ld-box">
                <h5>Accrued Interest</h5>
                <p>
                  ₦{' '}
                  {portfolioDetail?.accruedInterest
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(portfolioDetail?.accruedInterest)
                    : '0.00'}
                </p>
              </div>

              <div className="ld-box">
                <h5>Tracking ID</h5>
                <p>{investmentDetail?.trackingId || 'NIL'}</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row ms-4">
            <div className="ld-box m-2">
              <Button
                content="Top Up"
                classes="secondary-btn btn-lg bg-white"
                onClick={() => handleTopUP()}
                disabled={
                  investmentDetail?.redemptionStatus ===
                    'REDEMPTION_SUCCESSFUL' ||
                  investmentDetail?.topUpAmount !== 0
                    ? true
                    : false
                }
              />
            </div>
            <div className="ld-box m-2">
              <Button
                content="Redeem Investment"
                classes="secondary-btn btn-lg bg-white"
                onClick={() => handleInvestmentRedemption()}
                disabled={
                  investmentDetail?.redemptionStatus ===
                    'REDEMPTION_SUCCESSFUL' ||
                  investmentDetail?.topUpAmount !== 0
                    ? true
                    : false
                }
              />
            </div>

            <div className="ld-box m-2">
              <Link
                to="/dashboard/invest/transactions"
                state={{
                  MembershipId: investmentDetail?.membershipId,
                }}>
                <Button
                  content="View Statement"
                  classes="secondary-btn btn-lg bg-white"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(InvestmentDetails);
