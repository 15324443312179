import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Guarantors from '../guarantors/guarantors';
import GetInsured from '../insurance/GetInsured';
import Insurance from '../insurance/Insurance';
import Loans from '../loans/Loans';
import RequestForLoan from '../loans/RequestForLoan';
import Error404 from '../../../components/ui/Error404';
import AddGuarantors from '../guarantors/AddGuarantors';
import AllGuarantors from '../guarantors/AllGuarantors';
import GuarantorDetails from '../guarantors/GuarantorDetails';
import Overview from '../overview/Overview';
import UserWallet from '../wallet/UserWallet';
import Settings from '../settings/Settings';
import AccountSettings from '../settings/AccountSettings';
import Security from '../settings/Security';
import NotificationSettings from '../settings/NotificationSettings';
import Integration from '../settings/Integration';
import LifeAssuranceForm from '../insurance/LifeAssuranceForm';
import HealthInsuranceForm from '../insurance/HealthInsuranceForm';
import FutureInsurance from '../insurance/FutureInsurance';
import LoanDetails from '../loans/LoanDetails';
import UserDetailsMultiform from '../loans/UserDetailsMultiform';
import InvestOverview from '../invest/InvestOverview';
import ComingSoon from '../../../components/ui/ComingSoon';
import OfferLetter from '../loans/OfferLetter';
import Pin from '../settings/Pin';
import MoneyMarketFund from '../invest/MoneyMarketFund';
import DiscoveryBalanceFund from '../invest/DiscoveryBalanceFund';
import AggressiveGrowthFund from '../invest/AggressiveGrowthFund';
import EthicalFund from '../invest/EthicalFund';
import EuroBond from '../invest/EuroBond';
import InvestmentDetails from '../invest/InvestmentDetails';
import TransactionDetails from '../invest/TransactionDetails';
import LendaPlanView from '../invest/lenda/LendaPlanView';
import LendaInvestmentDetails from '../invest/lenda/LendaInvestmentDetails';
import LendaTransactionDetails from '../invest/lenda/LendaTransactionDetails';
import OfferLetterMurabaha from '../loans/OfferLetterMurabaha';
import OfferLetterMusharakah from '../loans/OfferLetterMusharakah';
import DeleteAccount from '../settings/DeleteAccount';
import AllInvoices from '../invoices/AllInvoices';
import Invoices from '../invoices/invoices';
import CreateInvoice from '../invoices/CreateInvoice';
import InvoiceFormOne from '../invoices/form/InvoiceFormOne';
import AllBookkeeping from '../bookkeeping/AllBookkeeping';
import Bookkeeping from '../bookkeeping/BookKeeping';
const Main = () => {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/coming_soon" element={<ComingSoon />} />

        <Route path="/overview" element={<Overview />} />
        <Route path="/invest" element={<InvestOverview />} />
        <Route path="/loans" element={<Loans />} />
        <Route path="/loans/request_for_loan" element={<RequestForLoan />} />
        <Route path="/loans/loan_details" element={<LoanDetails />} />
        <Route path="/facility" element={<Loans />} />
        <Route
          path="/facility/request_for_facility"
          element={<RequestForLoan />}
        />
        <Route path="/facility/facility_details" element={<LoanDetails />} />
        <Route path="/invest/money_market_fund" element={<MoneyMarketFund />} />
        <Route path="/invest/details" element={<InvestmentDetails />} />
        <Route path="/euro_bond_fund" element={<EuroBond />} />
        <Route path="/ethical_fund" element={<EthicalFund />} />
        <Route path="/invest/transactions" element={<TransactionDetails />} />
        <Route path="/invest/lenda_plan_view" element={<LendaPlanView />} />
        <Route
          path="/invest/lenda_transactions"
          element={<LendaTransactionDetails />}
        />
        <Route
          path="/invest/lenda_details"
          element={<LendaInvestmentDetails />}
        />
        <Route
          path="/aggressive_growth_fund"
          element={<AggressiveGrowthFund />}
        />
        <Route
          path="/Discovery_balance_fund"
          element={<DiscoveryBalanceFund />}
        />

        <Route
          path="/loans/user_personal_details"
          element={<UserDetailsMultiform />}
        />
        <Route
          path="/facility/user_personal_details"
          element={<UserDetailsMultiform />}
        />
        <Route
          path="/wallet/user_personal_details"
          element={<UserDetailsMultiform />}
        />
        <Route path="/loans/:id/offer_letter" element={<OfferLetter />} />
        {/* <Route path="/facility/:id/offer_letter" element={<OfferLetter />} /> */}
        <Route
          path="/facility/:id/offer_letter_murabaha"
          element={<OfferLetterMurabaha />}
        />
        <Route
          path="/facility/:id/offer_letter_musharakah"
          element={<OfferLetterMusharakah />}
        />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/insurance/car_insurance" element={<GetInsured />} />
        <Route
          path="/insurance/life_assurance"
          element={<LifeAssuranceForm />}
        />
        <Route
          path="/insurance/health_insurance"
          element={<HealthInsuranceForm />}
        />
        <Route
          path="/insurance/future_insurance"
          element={<FutureInsurance />}
        />
        <Route path="/guarantors/*" element={<Guarantors />}>
          <Route path="add_guarantor" element={<AddGuarantors />} />
          <Route path="all_guarantors" element={<AllGuarantors />} />
          <Route path=":name" element={<GuarantorDetails />} />
          <Route path="*" element={<Error404 />} />
        </Route>

        {/* Invoice */}
        <Route path="/invoices/*" element={<Invoices />}>
          <Route path="all_invoices" element={<AllInvoices />} />
          <Route path="create_invoice" element={<CreateInvoice />} />
          <Route path="invoice1" element={<InvoiceFormOne />} />
        </Route>

        <Route path="/bookkeeping/*" element={<Bookkeeping />}>
          <Route path="all_bookkeeping" element={<AllBookkeeping />} />
        </Route>

        <Route path="/wallet" element={<UserWallet />} />

        <Route path="/settings" element={<Settings />}>
          <Route path="account_settings" element={<AccountSettings />} />
          <Route path="security" element={<Security />} />
          <Route
            path="notification_settings"
            element={<NotificationSettings />}
          />
          <Route path="create-pin" element={<Pin />} />
          <Route path="coming_soon" element={<ComingSoon />} />
          <Route path="integrations" element={<Integration />} />
          <Route path="delete_account" element={<DeleteAccount />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Main;
