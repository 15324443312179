/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { StoreContext } from '../../mobx_stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';

type Props = {
  next: () => void;
  previous: any;
  setX: Function;
};

const BankDetailsForm: FC<Props> = ({ next, previous, setX }) => {
  const { loansStore } = React.useContext(StoreContext);

  const { sending, loading, success, credrails, message } = loansStore;

  const [bankDetails, setBankDetails] = React.useState({
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    hasOnlineBanking: true,
    wasLoanTakenWithinTheLast12Months: true,
    loanAmount: '',
    email: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [credailsDetails, setCredailsDetails] = React.useState({
    email: '',
    bankAccountName: '',
    bankAccountNumber: '',
    bankName: '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setBankDetails((prevDetails: any) => {
      if (element.value === 'true') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.value === 'false') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
    setCredailsDetails((prevDetails: any) => {
      if (element.id === '') {
        return { ...prevDetails };
      } else {
        return { ...prevDetails, [element.id]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const [allBankDetails, setAllBankDetails] = React.useState<any>({});
  // const [bankList, setBankList] = useState([]);

  React.useEffect(() => {
    setAllBankDetails(toJS(loansStore.loanUserdetails));
  }, [loansStore]);

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
    loansStore.getCredailsBanks();
  }, [loansStore]);

  const nokDeets = allBankDetails.bankDetails;

  React.useEffect(() => {
    setBankDetails({
      bankName:
        allBankDetails && nokDeets?.bankName === undefined
          ? ''
          : nokDeets?.bankName,
      bankAccountName:
        allBankDetails && nokDeets?.bankAccountName === undefined
          ? ''
          : nokDeets?.bankAccountName,
      bankAccountNumber:
        allBankDetails && nokDeets?.bankAccountNumber === undefined
          ? ''
          : nokDeets?.bankAccountNumber,
      wasLoanTakenWithinTheLast12Months:
        allBankDetails &&
        nokDeets?.wasLoanTakenWithinTheLast12Months === undefined
          ? true
          : nokDeets?.wasLoanTakenWithinTheLast12Months,
      hasOnlineBanking:
        allBankDetails && nokDeets?.hasOnlineBanking === undefined
          ? true
          : nokDeets?.hasOnlineBanking,
      loanAmount:
        (allBankDetails && nokDeets?.loanAmount === undefined) ||
        nokDeets?.loanAmount === null
          ? '0'
          : nokDeets?.loanAmount,
      email:
        allBankDetails && nokDeets?.email === undefined ? '' : nokDeets?.email,
    });
  }, [allBankDetails, nokDeets]);

  const handleCreateBankDetails = (e: React.FormEvent) => {
    e.preventDefault();
    loansStore.createBankDetails(bankDetails);
    // loansStore.createCredailsDetails(credailsDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          (input.value === '' ||
            input.value === 'NULL' ||
            bankDetails[input.name] === '' ||
            bankDetails[input.name] === 'NULL') &&
          input.name !== 'loanAmount'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 30000);
        }
      });
    }
  };

  const handleUpdateBankDetails = (e: React.FormEvent) => {
    e.preventDefault();
    loansStore.updateBankDetails(bankDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          (input.value === '' ||
            input.value === 'NULL' ||
            bankDetails[input.name] === '' ||
            bankDetails[input.name] === 'NULL') &&
          input.name !== 'loanAmount'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 30000);
        }
      });
    }
  };

  React.useEffect(() => {
    if (success === 'Bank details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Bank details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!bankDetails.hasOnlineBanking) {
      toast.error('Please create an online bank account then try again!');
    } else {
      allBankDetails.bankDetails === undefined
        ? handleCreateBankDetails(e)
        : handleUpdateBankDetails(e);
    }
  };

  return (
    <div>
      <form action="" onSubmit={handleSubmit}>
        <div className="py-5 px-4 bg-white rounded mt-4">
          {sending && <Spinner />}

          {loading ? (
            <div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 pe-md-3 pe-lg-4">
                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 pe-md-3 pe-lg-4">
                  <div className="col-md-12">
                    <div
                      className="d-flex justify-content-center align-items-center gap-2"
                      style={{
                        background: 'aliceblue',
                        padding: '8px 10px',
                        borderRadius: '10px',
                      }}>
                      <Icon
                        path={mdiInformationOutline}
                        size={2}
                        color="#676767"
                      />
                      <p
                        className="mb-0"
                        style={{ fontSize: '13px', lineHeight: '20px' }}>
                        Please make sure the bank account you're connecting is
                        your company or business account, not your personal bank
                        account.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="bankName" className="mb-1 mt-3">
                      Bank name
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="bankName"
                      id="bankName"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={nokDeets?.bankName}
                      required>
                      <option value="">Select option</option>
                      {toJS(credrails).map((name: any, i: number) => (
                        <option value={name} key={i}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="bankAccountName" className="mb-1 mt-3">
                      Bank account name
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="bankAccountName"
                      classes="form-control"
                      type="text"
                      id="bankAccountName"
                      defaultValue={nokDeets?.bankAccountName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="bankAccountNumber" className="mb-1 mt-3">
                      Bank account number
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="bankAccountNumber"
                      classes="form-control"
                      type="text"
                      id="bankAccountNumber"
                      defaultValue={nokDeets?.bankAccountNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="bankAccountNumber" className="mb-1 mt-3">
                      Bank email address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="email"
                      classes="form-control"
                      type="text"
                      id="email"
                      defaultValue={nokDeets?.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="hasOnlineBanking" className="mb-1 mt-3">
                      Has online banking
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="hasOnlineBanking"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={
                        nokDeets?.hasOnlineBanking === undefined
                          ? ''
                          : nokDeets?.hasOnlineBanking
                      }>
                      <option value="">Select option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="takenALoan" className="mb-1 mt-3">
                      Have you taken a loan in the last 12 months?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="wasLoanTakenWithinTheLast12Months"
                      className="form-control"
                      onChange={handleInputChange}
                      required
                      defaultValue={
                        nokDeets?.wasLoanTakenWithinTheLast12Months ===
                        undefined
                          ? ''
                          : nokDeets?.wasLoanTakenWithinTheLast12Months
                      }>
                      <option value="">Select option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="howMuch" className="mb-1 mt-3">
                      If yes how much?
                    </label>
                    <Input
                      name="loanAmount"
                      classes="form-control"
                      type="text"
                      defaultValue={
                        nokDeets?.loanAmount ? nokDeets?.loanAmount : '0'
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Button
                      classes="primary-btn btnn"
                      content="Previous"
                      onClick={() => {
                        previous();
                        setX(-1000);
                      }}
                    />
                    <Button
                      classes="primary-btn btnn"
                      content={
                        allBankDetails.bankDetails === undefined
                          ? 'Save and Continue'
                          : 'Update Details'
                      }
                      type="submit"
                      // disabled={!bankDetails.hasOnlineBanking}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default observer(BankDetailsForm);
