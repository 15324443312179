/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { toast } from 'react-toastify';

import { mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from '@mdi/react';
import { auth } from '../../firebase/Firebase';
import { LogIn } from '../../utils/firebase/AuthFirestore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUserDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]:
          element.name === 'email'
            ? element.value.trim().toLowerCase()
            : element.value.trim(),
      };
    });
  };

  const navigate = useNavigate();

  const location: any = useLocation();

  const SignIn = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (
      userDetails.email.includes('@tradelenda.com') &&
      auth.currentUser?.email !== 'test@tradelenda.com'
    ) {
      window.location.replace('https://legend.tradelenda.com');
    } else {
      try {
        const res = await LogIn(userDetails);
        if (res.error === false) {
          setLoading(false);
          setTimeout(() => setLoading(true), 800);

          toast.success('Login Successful!');

          setTimeout(() => {
            if (!auth.currentUser?.emailVerified) {
              toast.dismiss();
              navigate('/email_verification');
            } else {
              if (location?.state?.from?.pathname?.length > 0) {
                toast.dismiss();
                navigate(location?.state?.from?.pathname, { state: null });
              } else {
                toast.dismiss();
                navigate('/dashboard/overview');
              }
            }
            setLoading(false);
          }, 3000);
        } else {
          setLoading(false);
          if (res.data.message === 'Firebase: Error (auth/user-not-found).') {
            toast.error('User not found', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            });
          } else if (
            res.data.message === 'Firebase: Error (auth/wrong-password).'
          ) {
            toast.error('Invalid password', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            });
          } else if (
            res.data.message === 'Firebase: Error (auth/invalid-email).'
          ) {
            toast.error('Invalid email', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            });
          } else if (
            res.data.message ===
            'Firebase: Error (auth/network-request-failed).'
          ) {
            toast.error(
              'Network Request Failed. Please check your network connection',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                pauseOnFocusLoss: false,
              },
            );
          } else if (
            res.data.message ===
            'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).'
          ) {
            toast.error(
              'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                pauseOnFocusLoss: false,
              },
            );
          } else {
            toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                pauseOnFocusLoss: false,
              },
            );
          }
        }
      } catch (err) {}
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
          zIndex: 999999,
        }}
        toastOptions={{
          duration: 2000,
          style: {
            gap: '10px',
            maxWidth: 380,
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '60px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}
      {/* {response && (
        <Toaster
          position="top-center"
          reverseOrder={true}
          containerStyle={{
            top: 50,
          }}
          toastOptions={{
            duration: 5000,
            style: {
              gap: '10px',
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {t.type !== 'loading' && (
                    <button
                      onClick={() => toast.dismiss(t.id)}
                      style={{ width: '50px', background: 'white' }}
                    >
                      {icon}
                    </button>
                  )}
                  {message}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      )} */}
      <form className="login-form" onSubmit={SignIn}>
        <div className="col-12 mb-2">
          <label htmlFor="email" className="col-12 mb-2 mt-1 text-white">
            Email Address
          </label>
          <Input
            name="email"
            classes="form-control-sm mb-2 mt-0.5 col-12"
            type="text"
            id="email"
            value={userDetails.email.trim()}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-12 mb-2 position-relative">
          <label htmlFor="password" className="col-12 mb-2 mt-0.5 text-white">
            Password
          </label>
          <Input
            name="password"
            classes="form-control-sm mb-1 mt-0.5 col-12"
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={userDetails.password.trim()}
            onChange={handleInputChange}
            autoComplete="false"
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}>
            <Icon path={showPassword ? mdiEyeOff : mdiEye} vertical size={1} />
          </span>
        </div>
        <div className="col-12 mb-3 remember-me-cont ">
          <div className=" rmb-me mb-3 mt-3">
            <Input
              name="checkbox"
              classes="checkbox  "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label ms-2 text-white"
              htmlFor="flexCheckDefault">
              Remember Me
            </label>
          </div>
          <span>
            <Link to="/forgot_password" className="text-white">
              Forgot Password?
            </Link>
          </span>
        </div>

        <Button
          classes="btn text-white font-weight-bolder col-12 mb-2 border border-white border-3"
          content="Log In"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Login;
