/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable react-hooks/exhaustive-deps */

import { faBars, faBell, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  mdiAccountDetails,
  // mdiDesktopTowerMonitor,
  // mdiFlowerTulip,
  // mdiShieldCheck,
  mdiBagChecked,
  mdiChartLineVariant,
  mdiCreditCard,
  mdiFrequentlyAskedQuestions,
  mdiInformationVariant,
  mdiMultimedia,
  mdiNotebook,
  mdiPoll,
  mdiSquareEditOutline,
} from '@mdi/js';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import { StoreContext } from '../../../mobx_stores/RootStore';
import Dropdown from './Dropdown';
import DropdownMobile from './DropDownMobile';

const profile = JSON.parse(window.sessionStorage.getItem('user') || '{}');

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [mobileProductDropDown, setMobileProductDropDown] =
    React.useState(false);
  const [mobileCompanyDropDown, setMobileCompanyDropDown] =
    React.useState(false);
  const [mobileSupportDropDown, setMobileSupportDropDown] =
    React.useState(false);

  //Profile and auth store context
  const { authStore } = useContext(StoreContext);

  function toggleNavbar() {
    setIsOpen(!isOpen);
  }

  function handleMobileProductDropDown() {
    // setIsOpen((prev) => !prev);
    setMobileCompanyDropDown(false);
    setMobileSupportDropDown(false);
    setMobileProductDropDown((prev) => !prev);
  }

  function handleMobileCompanyDropDown() {
    // setIsOpen((prev) => !prev);
    setMobileProductDropDown(false);
    setMobileSupportDropDown(false);
    setMobileCompanyDropDown((prev) => !prev);
  }

  function handleMobileSupportDropDown() {
    // setIsOpen((prev) => !prev);
    setMobileProductDropDown(false);
    setMobileCompanyDropDown(false);
    setMobileSupportDropDown((prev) => !prev);
  }

  const [user, setUser] = React.useState<any>(profile);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setUser(JSON.parse(window.sessionStorage.getItem('user')!));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const queryString = require('query-string');
  const parsed = queryString.parse(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (parsed.mode === 'resetPassword') {
      navigate('/reset_password', { state: parsed });
      // <Navigate to="/reset_password" />;
    }
    if (parsed.mode === 'verifyEmail') {
      navigate('/email_verification', { state: parsed });
      // <Navigate to="/reset_password" />;
    }
  }, [parsed.mode]);

  const productLinks = [
    // {
    //   icon: mdiPoll,
    //   text: 'Loan for business',
    //   url: '/loan_for_business',
    //   comingSoon: false,
    // },
    // {
    //   icon: mdiDesktopTowerMonitor,
    //   text: 'Digital banking',
    //   url: '/',
    //   comingSoon: false,
    // },
    // {
    //   icon: mdiFlowerTulip,
    //   text: 'Investment',
    //   url: '/invest_with_us',
    //   comingSoon: false,
    // },
    {
      icon: mdiPoll,
      text: 'FINANCE',
      url: '/loan_for_business',
      description:
        'Inventory financing, Asset financing, Working Capital, Local Purchase order and Invoice Discounting.',
      comingSoon: false,
    },

    {
      icon: mdiPoll,
      text: 'INSURANCE',
      url: '/insurance-preview',
      description:
        'Health Insurance, Goods-in-Transit Insurance, Fire and Burglary insurance (Shop insurance)',
      comingSoon: false,
    },
    {
      icon: mdiPoll,
      text: 'SAVINGS AND INVESTMENTS',
      url: '/invest_with_us',
      description: 'Save with ARM and Lend with Trade Lenda',
      comingSoon: false,
    },
    {
      icon: mdiCreditCard,
      text: 'PAYMENTS',
      url: '/sign_up',
      description: 'Free Transfers and Bills Payment',
      comingSoon: false,
    },
    // {
    //   icon: mdiOfficeBuilding,
    //   text: 'Islamic Facility Financing',
    //   url: '/islamic_facility_financing',
    //   ComingSoon: false,
    // },
    // {
    //   icon: mdiCash,
    //   text: 'Asset Financing',
    //   url: '/asset_financing',
    //   ComingSoon: false,
    // },
    // {
    //   icon: mdiClockPlus,
    //   text: 'A.R.M',
    //   url: '/a_r_m',
    //   comingSoon: false,
    // },
  ];

  const companyLinks = [
    {
      icon: mdiInformationVariant,
      text: 'About us',
      url: '/about_us',
      comingSoon: false,
    },
    {
      icon: mdiBagChecked,
      text: 'Career',
      url: '/careers',
      comingSoon: false,
    },
    {
      icon: mdiMultimedia,
      text: 'Media',
      url: '/media',
      comingSoon: false,
    },
    {
      icon: mdiChartLineVariant,
      text: 'Market structure',
      url: '/market_structure',
      comingSoon: false,
    },
  ];

  const supportLinks = [
    {
      icon: mdiAccountDetails,
      text: 'Contact us',
      url: '/support',
      comingSoon: false,
    },
    {
      icon: mdiFrequentlyAskedQuestions,
      text: 'FAQ',
      url: '/faq',
      comingSoon: false,
    },
    {
      icon: mdiSquareEditOutline,
      text: 'Terms and condition',
      url: '/T&C',
      comingSoon: false,
    },
    {
      icon: mdiNotebook,
      text: 'Knowledge base',
      url: '/faq',
      comingSoon: false,
    },
  ];

  return (
    <div>
      <header className="landing-page-header">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <Link to="/" className="logo">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/TradeLendaLOGO.png`}
                alt="Trade Lenda"
              />
            </Link>
            <div className="nav-links d-flex align-items-center">
              <div className="harmbuger" onClick={toggleNavbar}>
                {isOpen ? (
                  <FontAwesomeIcon icon={faXmark} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </div>
              <ul className=" d-flex align-items-center">
                {/* <li>
                  <Button
                    classes="white-btn btn-lg"
                    content="IWD"
                    route="/application"
                  />
                </li> */}
                <li>
                  <span className="navlink">
                    Product
                    <Dropdown heading="Products" links={productLinks} />
                  </span>
                </li>
                <li>
                  <NavLink
                    to="/business"
                    className={({ isActive }) =>
                      isActive ? 'navlink active' : 'navlink'
                    }>
                    Business
                  </NavLink>
                </li>
                <li>
                  <span className="navlink">
                    Company
                    <Dropdown heading="Company" links={companyLinks} />
                  </span>
                </li>
                <li>
                  <NavLink
                    to="/hackathon"
                    className={({ isActive }) =>
                      isActive ? 'navlink active' : 'navlink'
                    }>
                    Hackathon
                  </NavLink>
                </li>
                <li>
                  <span className="navlink">
                    Support
                    <Dropdown heading="Support" links={supportLinks} />
                  </span>
                </li>
                {/* No Blog on the new Landing Page  */}
                {/* <li>
                  <NavLink
                    to="/blog"
                    className={({ isActive }) =>
                      isActive ? 'navlink active' : 'navlink'
                    }
                  >
                    Blog
                  </NavLink>
                </li> */}
                <>
                  {user ? (
                    <div className="d-flex justify-content-end align-items-center header-container">
                      <div className="right-header-content">
                        <Button
                          classes="primary-btn btn-sm me-lg-3 mx-3"
                          content="Dashboard"
                          route="/dashboard/overview"
                        />
                        <button>
                          <FontAwesomeIcon icon={faBell} />
                        </button>
                        {user && user.firstName !== undefined && (
                          <div className="user-details">
                            <div className="user-img">
                              <img
                                src={
                                  authStore.user?.photoURL ||
                                  `${BaseDirectories.IMAGES_DIR}/user.png`
                                }
                                alt=""
                              />
                            </div>
                            <p className="username">{user.firstName}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <li>
                        <Link to="/login" className="navlink">
                          Log In
                        </Link>
                      </li>
                      <li>
                        <Button
                          classes="green-white-btn btn-lg"
                          content="Sign Up"
                          route="/sign_up"
                        />
                      </li>
                    </>
                  )}
                </>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {isOpen && (
        <div className="lp-mobile-bckgrd">
          <div className="lp-mobile-menue">
            <div className="container ">
              {user && user.firstName !== undefined && (
                <div className="d-flex align-items-center gap-3 py-3 ">
                  <img
                    src={
                      authStore.user?.photoURL ||
                      `${BaseDirectories.IMAGES_DIR}/user.png`
                    }
                    alt=""
                    style={{ width: '10%' }}
                  />
                  <p className="mb-0">{user.firstName}</p>
                </div>
              )}
              <ul>
                {/* <li>
                  <Button
                    classes="primary-btn btn-lg"
                    content="IWD"
                    route="/application"
                  />
                </li> */}
                <li>
                  <Button
                    classes="primary-btn btn-lg"
                    content="Dashboard"
                    route="/dashboard/overview"
                  />
                </li>
                <li onClick={handleMobileProductDropDown}>
                  Products
                  {mobileProductDropDown && (
                    <DropdownMobile links={productLinks} />
                  )}
                </li>
                <li onClick={() => setIsOpen((prev) => !prev)}>
                  <Link to="/business" className="navlink">
                    Business
                  </Link>
                </li>
                <li onClick={handleMobileCompanyDropDown}>
                  Company
                  {mobileCompanyDropDown && (
                    <DropdownMobile links={companyLinks} />
                  )}
                </li>
                <li onClick={() => setIsOpen((prev) => !prev)}>
                  <Link to="/hackathon" className="navlink">
                    Hackathon
                  </Link>
                </li>
                <li onClick={handleMobileSupportDropDown}>
                  Support
                  {mobileSupportDropDown && (
                    <DropdownMobile links={supportLinks} />
                  )}
                </li>

                {/* <li onClick={() => setIsOpen((prev) => !prev)}>
                  <Link to="/blog" className="navlink">
                    Blog
                  </Link>
                </li> */}

                <>
                  {!user && (
                    <div className="btn-div flex-column align-items-start">
                      <Link to="/login" className="navlink">
                        Log In
                      </Link>
                      <Button
                        classes="primary-btn btn-lg"
                        content="Sign Up"
                        route="/sign_up"
                      />
                    </div>
                  )}
                </>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Header);
