/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { StoreContext } from '../../../../mobx_stores/RootStore';

const defaultBouquet = [
  {
    serviceType: 'GOTV',
    shortName: 'nine-mobile-bundle',
    name: '9Mobile Bundle',
    billerId: '1',
    productId: '1',
  },
  {
    serviceType: 'DSTV',
    shortName: 'nine-mobile-bundle',
    name: '9Mobile Bundle',
    billerId: '1',
    productId: '1',
  },
];

const defaultPlan = [
  {
    name: 'MTN N100 100MB - (24 Hours)',
    allowance: null,
    validity: null,
    price: 100,
    datacode: 'mtn-10mb-100',
  },
  {
    name: 'GLO N100 100MB - (24 Hours)',
    allowance: null,
    validity: null,
    price: 200,
    datacode: 'glo-10mb-100',
  },
];

type FullDetails = {
  customerName: string;
  accountStatus: string;
  customerNumber: string;
};

const CableTvPurchase = ({ setOpenCableTvPurchaseModal }) => {
  const [showBankSelection, setShowBankSelection] = useState(true);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    fromWalletIdAccountNumber: '',
    serviceType: '',
    amount: '',
    msisdn: '',
    transactionPin: '',
    accountId: '',
    productId: '',
  });
  const { billStore, walletStore } = useContext(StoreContext);
  const { submitting, message } = billStore;
  const { multipleWallet } = walletStore;

  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');

  const [pin, setPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [bouquetTypes, setBouquetTypes] = useState<any>();
  const [fullDetails, setFullDetails] = useState<FullDetails>({
    customerName: '',
    accountStatus: '',
    customerNumber: '',
  });

  useEffect(() => {
    billStore.getCableTvProvider();
  }, [billStore]);

  useEffect(() => {
    if (billStore.bouquetsProviders) {
      const providerDetails = Object.values(billStore.bouquetsProviders)
        .filter((provider) => typeof provider === 'object' && provider !== null)
        .map((provider: any) => ({
          id: provider.id,
          name: provider.name,
        }));
      setServiceTypes(toJS(providerDetails));
    }
  }, [billStore.bouquetsProviders]);

  useEffect(() => {
    if (billStore.bouquetsPlan) {
      const bouquetsPlan = Object.values(billStore.bouquetsPlan)
        .filter((plan) => typeof plan === 'object' && plan !== null)
        .map((plan: any) => ({
          id: plan.id,
          name: plan.name,
          amount: plan.price.user,
        }));
      setBouquetTypes(bouquetsPlan);
    }
  }, [billStore.bouquetsPlan]);

  useEffect(() => {
    if (billStore.bouquetDetails) {
      const customerNumber = billStore.bouquetDetails[2];
      const accountStatus = billStore.bouquetDetails[1];
      const customerName = billStore.bouquetDetails[3];
      setFullDetails({ customerName, accountStatus, customerNumber });
    }
  }, [billStore.bouquetDetails]);

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val =
      element.name === 'amount'
        ? element.value.length === 0
          ? element.value
          : parseInt(element.value)
        : element.value;

    setTransactionDetails((prevDetails: any) => {
      const updatedDetails = { ...prevDetails, [element.name]: val };

      if (element.name === 'serviceType') {
        // Find the corresponding operator ID based on selected serviceType
        const selectedService = serviceTypes.find(
          (service: any) => service.id === val,
        );
        if (selectedService) {
          updatedDetails.operator = Number(selectedService.id);
          // Fetch data plans for the selected operator
          billStore.getBouquetsPlanByProvider(Number(selectedService.id));
        }
      }

      if (element.name === 'bouquetTypes') {
        // Update amount based on selected DataType
        const selectedOption = bouquetTypes.find(
          (option: any) => option.name === element.value,
        );
        if (selectedOption) {
          updatedDetails.amount = selectedOption.amount;
          updatedDetails.productId = selectedOption.id;
          updatedDetails.serviceType = selectedOption.name;
        }
      }
      return updatedDetails;
    });
  };

  useEffect(() => {
    setTransactionDetails((prevDetails: any) => {
      return { ...prevDetails, transactionPin: pin };
    });
  }, [pin]);

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const handleTransaction = (e: any) => {
    e.preventDefault();
    if (transactionDetails?.msisdn !== undefined) {
      if (isValidPhoneNumber(transactionDetails?.msisdn)) {
        const { serviceType, amount, msisdn, transactionPin, accountId } =
          transactionDetails;
        if (
          serviceType === '' ||
          amount === '' ||
          msisdn === '' ||
          accountId === '' ||
          transactionPin === ''
        ) {
          toast.error('All input fields are required!');
          return;
        }
        // if (transactionDetails?.fromWalletIdAccountNumber[0] == '9') {
        //   if (
        //     Number(toJS(wallet.availableBalance)) <
        //     Number(transactionDetails.amount)
        //     // Number(toJS(wallet.availableBalance)) ===
        //     // Number(transactionDetails.amount)
        //   ) {
        //     toast.error(
        //       'You dont have enough balance to process this transaction!',
        //     );
        //     return;
        //   }
        // } else if (transactionDetails?.fromWalletIdAccountNumber[0] == '4') {
        //   if (
        //     Number(toJS(seerbitWalletBallance)) <
        //     Number(transactionDetails.amount)
        //     // Number(toJS(seerbitWalletBallance)) ===
        //     // Number(transactionDetails.amount)
        //   ) {
        //     toast.error(
        //       'You dont have enough balance to process this transaction!',
        //     );
        //     return;
        //   }
        // }

        // billStore.purchaseTvBouquet(transactionDetails);

        if (toJS(multipleWallet) && toJS(multipleWallet).length > 0) {
          toJS(multipleWallet).forEach((walletData: any) => {
            if (
              walletData.walletIdAccountNumber ===
              transactionDetails?.fromWalletIdAccountNumber
            ) {
              if (
                Number(walletData.availableBalance) <
                Number(transactionDetails.amount)
              ) {
                toast.error(
                  'You don’t have enough balance to process this transaction!',
                );
                return;
              }
            }
            billStore.purchaseTvBouquet(transactionDetails);
          });
        }
      } else {
        toast.error('Invalid phone number, check and try again.');
        return;
      }
    }
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (message.type === 'success') {
      setTransactionStatus(message.msg);
      setShowTransactionStatus(true);
      setTimeout(() => {
        setOpenCableTvPurchaseModal(false);
        walletStore.getAllTransactions();
      }, 6000);
    } else {
      setShowTransactionStatus(false);
    }
  }, [message.msg, message.type]);

  const validate = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /^[0-9]{0,30}$/;
    if (!regex.test(transactionDetails.accountId) || !regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleCardVerification = async () => {
    if (!transactionDetails.accountId) {
      toast.error('Please input Card Number to verify account details');
      return;
    }
    await billStore.lookupBouquetAccount(transactionDetails);

    setShowAmountInput(true);
  };

  return (
    <div className="bill-modal">
      <div className="bg-white modal-box">
        <span
          className="close"
          onClick={() => {
            setOpenCableTvPurchaseModal(false);
          }}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>

        <h4 className="mb-2">Cable TV Subscription</h4>
        <hr
          className="bg-secondary"
          style={{ borderWidth: '2px', borderColor: 'gray' }}
        />
        {showAmountInput && (
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0 }}
            className="sliding-input">
            <div className="mb-3 amount text-center">
              <h6>₦{transactionDetails.amount.toLocaleString() || 0}.00</h6>
            </div>
          </motion.div>
        )}
        {!showTransactionStatus ? (
          <form className="bill-payment-form" onSubmit={handleTransaction}>
            <div className="row justify-content-center">
              <>
                <div className="row justify-content-center account-box">
                  {/* // BANK SELECTION */}
                  {showBankSelection && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input">
                      <div className="row justify-content-center banks-box">
                        <p className="mb-4 color-heading">Select a wallet</p>
                        <div className="col-12">
                          {toJS(multipleWallet) &&
                          toJS(multipleWallet)?.length > 0
                            ? toJS(multipleWallet)?.map(
                                (walletData: any, index: number) => (
                                  <div className="w-100 gap-2 mt-2" key={index}>
                                    <h6 className="wallet-deets d-flex align-items-start my-3 color-heading">
                                      Wallet {index + 1}
                                    </h6>
                                    <div
                                      className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets bank-selection"
                                      onClick={() => {
                                        if (
                                          walletData?.availableBalance < 100
                                          // walletData?.availableBalance === 100
                                        ) {
                                          toast.error(
                                            'Amount must be greater than ₦100',
                                          );
                                        } else {
                                          setTransactionDetails(
                                            (prevDetails) => {
                                              return {
                                                ...prevDetails,
                                                fromWalletIdAccountNumber:
                                                  walletData?.walletIdAccountNumber,
                                              };
                                            },
                                          );
                                          setShowBankSelection(false);
                                          setShowAmountInput(true);
                                        }
                                      }}>
                                      <h6>{walletData?.banker}</h6>
                                      <p>
                                        ₦{' '}
                                        {walletData?.availableBalance !=
                                          'NaN' ||
                                        walletData?.availableBalance != null ||
                                        walletData?.availableBalance !=
                                          undefined
                                          ? walletData?.availableBalance
                                            ? new Intl.NumberFormat('en-US', {
                                                style: 'decimal',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(
                                                walletData?.availableBalance,
                                              )
                                            : '0.00'
                                          : '0.00'}
                                      </p>
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {!showBankSelection && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input">
                      <div className="col-12 styled-phone-input">
                        <label
                          htmlFor="phone"
                          className="mb-1 mt-2 text-start d-block">
                          Mobile Number
                        </label>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="NG"
                          onChange={(e: string) =>
                            setTransactionDetails((prevDetails: any) => {
                              return { ...prevDetails, msisdn: e };
                            })
                          }
                          className="form-control p-3"
                          id="msisdn"
                          disabled={submitting ? true : false}
                          value={transactionDetails.msisdn}
                          rules={{ required: true }}
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-10 mt-4">
                        <Button
                          classes="primary-btn btn-md px-4 mx-2"
                          content="Back"
                          type="button"
                          onClick={() => {
                            setShowBankSelection(true);
                          }}
                        />
                      </div>
                    </motion.div>
                  )}

                  {!showBankSelection &&
                    transactionDetails.msisdn !== undefined &&
                    isValidPhoneNumber(transactionDetails?.msisdn) && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input">
                        <div className="col-12">
                          <label htmlFor="serviceType" className="mb-1 mt-3">
                            Select Bouquet
                          </label>
                          <select
                            name="serviceType"
                            id="serviceType"
                            className="form-control p-3"
                            onChange={handleInputChange}
                            defaultValue={transactionDetails.serviceType}
                            required
                            disabled={submitting ? true : false}>
                            <option value="">Select Bouquet</option>
                            {serviceTypes && serviceTypes.length > 0
                              ? serviceTypes.map(
                                  (serviceType: any, index: number) => (
                                    <option key={index} value={serviceType.id}>
                                      {serviceType.name}
                                    </option>
                                  ),
                                )
                              : // : null
                                defaultBouquet.map(
                                  (serviceType: any, index: number) => (
                                    <option
                                      key={index}
                                      value={serviceType.serviceType}>
                                      {serviceType.serviceType}
                                    </option>
                                  ),
                                )}
                          </select>
                        </div>
                      </motion.div>
                    )}

                  {!showBankSelection &&
                    transactionDetails.serviceType !== undefined &&
                    transactionDetails.serviceType !== '' && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input">
                        <div className="col-12">
                          <label htmlFor="bouquetTypes" className="mb-1 mt-3">
                            Bouquet Plan
                          </label>
                          <select
                            name="bouquetTypes"
                            id="bouquetTypes"
                            className="form-control p-3"
                            onChange={handleInputChange}
                            defaultValue={transactionDetails.productId}
                            required
                            disabled={submitting ? true : false}>
                            <option value="">Select plan</option>
                            {bouquetTypes &&
                            bouquetTypes !== null &&
                            bouquetTypes !== undefined &&
                            bouquetTypes &&
                            bouquetTypes.length > 0
                              ? bouquetTypes.map(
                                  (bouquetTypes: any, index: number) => (
                                    <option
                                      key={index}
                                      value={bouquetTypes.name}
                                      accessKey={bouquetTypes.amount}>
                                      {bouquetTypes.name}
                                    </option>
                                  ),
                                )
                              : defaultPlan.map(
                                  (dataTypes: any, index: number) => (
                                    <option
                                      key={index}
                                      value={dataTypes.datacode}
                                      accessKey={dataTypes.price}>
                                      {dataTypes.name}
                                    </option>
                                  ),
                                )}
                          </select>
                        </div>
                      </motion.div>
                    )}

                  {!showBankSelection &&
                    transactionDetails.serviceType !== undefined &&
                    transactionDetails.serviceType !== '' &&
                    transactionDetails.accountId !== '' && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input">
                        <div className="col-12 mt-3 mb-0">
                          <h6
                            className=""
                            style={{
                              color:
                                fullDetails.accountStatus === 'Suspended'
                                  ? 'red'
                                  : '#064b99',
                              textAlign: 'center',
                            }}>
                            {fullDetails.customerName}
                          </h6>
                          <p
                            className=""
                            style={{
                              color:
                                fullDetails.accountStatus === 'Suspended'
                                  ? 'red'
                                  : '#064b99',
                              textAlign: 'center',
                            }}>
                            {fullDetails.accountStatus}
                          </p>
                        </div>
                      </motion.div>
                    )}

                  {transactionDetails.serviceType !== undefined &&
                    transactionDetails.serviceType !== '' && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input">
                        <div className="col-12">
                          <label htmlFor="accountId" className="mb-1 mt-1">
                            Card Number
                          </label>
                          <Input
                            onKeyPress={validate}
                            // maxLength={13}
                            onPaste={validate}
                            autoComplete="off"
                            onChange={(e: ChangeEvent) => {
                              handleInputChange(e);
                            }}
                            name="accountId"
                            classes="form-control p-3"
                            type="text"
                            id="accountId"
                            value={transactionDetails.accountId}
                            disabled={submitting ? true : false}
                          />
                          {/* Conditional rendering based on the presence of customerName */}
                          {!fullDetails.customerName && (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn mt-3"
                                onClick={handleCardVerification}
                                disabled={submitting}
                                style={{
                                  backgroundColor: '#064b99',
                                  color: 'white',
                                  textAlign: 'center',
                                }}>
                                Validate AccountID
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  {!showBankSelection &&
                    transactionDetails.accountId !== undefined &&
                    transactionDetails.accountId !== '' &&
                    transactionDetails.accountId.length >= 5 &&
                    fullDetails?.customerName !== '' && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input">
                        <div className="col-12 input-box">
                          <label htmlFor="showPin" className="mb-1 mt-3">
                            Transaction Pin
                          </label>
                          <div className="">
                            <OtpInput
                              value={pin}
                              onChange={setPin}
                              numInputs={4}
                              inputStyle="pin-style"
                              containerStyle="pin-container-style"
                              inputType={pinToggle ? 'tel' : 'password'}
                              // shouldAutoFocus={true}
                              renderInput={(props) => (
                                <input
                                  onKeyPress={validateNumber}
                                  {...props}
                                  disabled={submitting ? true : false}
                                />
                              )}
                            />
                          </div>
                          <div className="d-flex gap-2 justify-content-start align-items-center mb-3">
                            <input
                              type="checkbox"
                              onChange={handlePinToggle}
                              name="showPin"
                              id="show-pin"
                            />
                            <label htmlFor="show-pin">Show pin</label>
                          </div>
                          <p style={{ fontSize: '14px', textAlign: 'left' }}>
                            Don't have a pin yet? Create one{' '}
                            <Link
                              style={{ color: 'blue' }}
                              to="/dashboard/settings/create-pin">
                              here
                            </Link>{' '}
                          </p>
                        </div>
                      </motion.div>
                    )}
                </div>

                <div className="row justify-content-start mt-2">
                  <div className="col-11">
                    <div className="group-btn justify-content-start">
                      {!showBankSelection &&
                        transactionDetails.transactionPin !== '' &&
                        transactionDetails.transactionPin.length === 4 && (
                          <motion.div
                            initial={{ x: -200, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: -200, opacity: 0 }}
                            className="sliding-input">
                            <Button
                              classes="primary-btn btn-md"
                              disabled={submitting}
                              content={`${
                                submitting ? 'Processing...' : 'Proceed'
                              }`}
                              type="submit"
                            />
                          </motion.div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </form>
        ) : (
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0 }}
            className="sliding-input">
            <div>
              <h6
                className={
                  message.type === 'success'
                    ? ' alert alert-success mb-0 text-center m-3'
                    : 'alert alert-danger mb-0 text-center m-3'
                }
                role="alert">
                {transactionStatus}
              </h6>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default observer(CableTvPurchase);
