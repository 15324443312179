/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { FC } from 'react';
import Input from '../../../components/ui/Input';

type Props = {
  onChange?: any;
};

const ToggleSwitch: FC<Props> = ({ onChange }) => {
  return (
    <div>
      <div className=" fund-wallet-switch-container p-0 mb-3">
        <label className="btn-switch mt-4">
          <Input
            type="checkbox"
            classes="switch"
            name="color_mode"
            id="color_mode"
            value="1"
            onChange={onChange}
          />
          <label
            htmlFor="color_mode"
            data-on="Transfer"
            data-off=" Fund Wallet"
            className="btn-switch-inner"></label>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
