/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from 'react';

import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button } from '@mui/material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
} from 'material-react-table';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Spinner from '../../../../components/ui/Spinner';
import { StoreContext } from '../../../../mobx_stores/RootStore';

interface LocationState {
  [key: string]: any;
}

const LendaTransactionDetails = () => {
  const location: LocationState = useLocation();
  const { investStore } = useContext(StoreContext);
  // const [transactionDetails, setTransactionDetails] = useState<any>();
  const [dataSet, setDataSet] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    investStore.getLendaTransactionHistory(
      location?.state?.TrackingId,
      pagination.pageIndex,
      pagination.pageSize,
    );
    investStore.setTransactions;
    // setTransactionDetails(toJS(investStore.lendaTransactionsDetail));
    if (rowCount === 0) {
      setRowCount(toJS(investStore.lendaTransactionsDetail)?.count);
    }
    setPageCount(toJS(investStore.lendaTransactionsDetail)?.maxPages);
    if (toJS(investStore.lendaTransactionsDetail)?.transaction) {
      const filteredColumns = toJS(
        investStore.lendaTransactionsDetail,
      )?.transaction.map((item: any) => {
        return {
          investmentPlan: item.investmentPlan,
          createdAt: moment(item.createdAt).format('MM-D-YYYY'),
          initialInvestmentAmount: item.initialInvestmentAmount,
          topUpAmount: item.topUpAmount,
          redemptionAmount: item.redemptionAmount,
          description: item.description,
          investmentStatus: item.investmentStatus,
        };
      });
      setDataSet(filteredColumns);
    }
  }, []);

  useEffect(() => {
    investStore.getLendaTransactionHistory(
      location?.state?.TrackingId,
      pagination.pageIndex,
      pagination.pageSize,
    );

    investStore.setTransactions;
    // setTransactionDetails(toJS(investStore.lendaTransactionsDetail));
    if (rowCount === 0) {
      setRowCount(toJS(investStore.lendaTransactionsDetail)?.count);
    }
    setPageCount(toJS(investStore.lendaTransactionsDetail)?.maxPages);
    if (toJS(investStore.lendaTransactionsDetail)?.transaction) {
      const filteredColumns = toJS(
        investStore.lendaTransactionsDetail,
      )?.transaction.map((item: any) => {
        return {
          investmentPlan: item.investmentPlan,
          createdAt: moment(item.createdAt).format('MM-D-YYYY'),
          initialInvestmentAmount: item.initialInvestmentAmount,
          topUpAmount: item.topUpAmount,
          redemptionAmount: item.redemptionAmount,
          description: item.description,
          investmentStatus: item.investmentStatus,
        };
      });
      setDataSet(filteredColumns);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: 'createdAt',
        id: 'createdAt',
        header: 'Transaction Date',
        Cell: ({ cell }) => (
          <span>{moment(cell.getValue<number>()).format('MM-D-YYYY')}</span>
        ),
      },
      {
        accessorKey: 'initialInvestmentAmount',
        id: 'initialInvestmentAmount',
        header: 'Investment Amount',
        Cell: ({ cell }) => (
          <span>
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(cell.getValue<number>())}
          </span>
        ),
      },
      {
        accessorKey: 'topUpAmount',
        id: 'topUpAmount',
        header: 'Top-Up Amount',
        Cell: ({ cell }) => (
          <span>
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(cell.getValue<number>())}
          </span>
        ),
      },
      {
        accessorKey: 'redemptionAmount',
        id: 'redemptionAmount',
        header: 'Redemption Amount',
        Cell: ({ cell }) => (
          <span>
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(cell.getValue<number>())}
          </span>
        ),
      },
      {
        accessorKey: 'investmentPlan',
        id: 'investmentPlan',
        header: 'Investment Plan',
        Cell: ({ cell }) => (
          <span>{cell.getValue<string>().toUpperCase()}</span>
        ),
      },
      {
        accessorKey: 'description',
        id: 'description',
        header: 'Description',
        Cell: ({ cell }) => (
          <span>{cell.getValue<string>().toUpperCase()}</span>
        ),
      },
    ],
    [],
  );

  const handleExportData = () => {
    const csvOptions = {
      filename: 'InvestmentSummary',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(dataSet);
  };

  return (
    <div className="loans">
      {investStore.loadingPlans && <Spinner />}

      <div className="section-head">Investment Details</div>
      <div className="loans-container">
        <Link to="" onClick={() => window.history.back()}>
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="rq-loan-details">
          <h5>Investment Transaction Details</h5>
          {toJS(investStore.lendaTransactionsDetail) !== null && (
            <MaterialReactTable
              manualPagination
              columns={columns}
              data={
                toJS(investStore.lendaTransactionsDetail)
                  ? toJS(investStore.lendaTransactionsDetail)?.transaction
                  : []
              }
              onPaginationChange={setPagination}
              positionGlobalFilter="right"
              globalFilterFn="contains"
              positionToolbarAlertBanner="bottom"
              enableRowNumbers
              rowCount={rowCount}
              pageCount={pageCount}
              rowNumberMode="static" //default
              initialState={{
                pagination: { pageSize: 10, pageIndex: 0 },
                // columnVisibility: { required: false, description: false },
                density: 'spacious',
                showGlobalFilter: true,
              }}
              state={{
                pagination,
              }}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    p: '0.5rem',
                  }}>
                  {dataSet !== null ? (
                    <Button
                      color="primary"
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="contained">
                      Export
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="primary"
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="contained">
                      Export
                    </Button>
                  )}
                </Box>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(LendaTransactionDetails);
