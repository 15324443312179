/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ProtectedRoute from './components/ProtectedRoute';
import ScrollToTop from './components/ScrollToTop';
import Error301 from './components/ui/Error301';
import Error403 from './components/ui/Error403';
import Error404 from './components/ui/Error404';
import Error500 from './components/ui/Error500';
import { auth, onAuthStateChangedListener } from './firebase/Firebase';
import { SetAllAccessTokens, StoreContext } from './mobx_stores/RootStore';
import EmailVerificationSuccess from './pages/authentication/EmailVerificationSuccess';
import ForgotPassword from './pages/authentication/ForgotPassword';
import PasswordResetSuccessful from './pages/authentication/PasswordResetSuccessful';
import ResetPassword from './pages/authentication/ResetPassword';
import UserLogin from './pages/authentication/UserLogin';
import SignUp from './pages/authentication/UserSignup';
import VerificationSuccess from './pages/authentication/VerificationSuccess';
import VerifyAccount from './pages/authentication/VerifyAccount';
import ProfileMultistepForm from './pages/authentication/profile/ProfileMultistepForm';
import Dashboard from './pages/dashboard/Dashboard';
import GuarantorMultistepForm from './pages/dashboard/guarantors/GuarantorMultistepForm';
import ARM from './pages/landingPage/ARM';
import AboutUs from './pages/landingPage/AboutUs';
import AssetFinancing from './pages/landingPage/AssetFinancing';
import BlogPage from './pages/landingPage/BlogPage';
import BlogPostPage from './pages/landingPage/BlogPostPage';
import Business from './pages/landingPage/Business';
import Facilities from './pages/landingPage/Facilities';
import HackathonPage from './pages/landingPage/HackathonPage';
import Home from './pages/landingPage/Home';
import InsurancePreview from './pages/landingPage/InsurancePreview';
import InvestWithUs from './pages/landingPage/InvestWithUs';
import MediaPage from './pages/landingPage/MediaPage';
import PaystackCalback from './pages/landingPage/PaystackCalback';
import ProductPage from './pages/landingPage/ProductPage';
import StructuredMarkets from './pages/landingPage/StructuredMarkets';
import SupportPage from './pages/landingPage/SupportPage';
import Application from './pages/landingPage/sections/Application';
import Career from './pages/landingPage/sections/Career';
import Contact from './pages/landingPage/sections/Contact';
import FAQ from './pages/landingPage/sections/FAQ';
import LoanTerms from './pages/landingPage/sections/LoanTerms';
import MediaVideo from './pages/landingPage/sections/MediaVideo';
import Privacy from './pages/landingPage/sections/Privacy';
import TandC from './pages/landingPage/sections/TandC';
import { setcurrentUser } from './redux/user/user.action';

const MainRoutes = () => {
  // Profile store context
  const { profileStore, authStore } = useContext(StoreContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useState<null | any>(null);
  const [activeUser, setActiveUser] = React.useState(
    false || window.sessionStorage.getItem('userStatus') === 'true',
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async (res: any) => {
      if (res) {
        res?.getIdToken(true).then((idToken: string) => {
          window.sessionStorage.setItem('accessToken', idToken);
          SetAllAccessTokens(idToken);
          try {
            authStore.setUser(res);
            profileStore.getProfile();
            // requestPermission();
          } catch (error) {
          } finally {
          }
        });
        if (res) {
          if (res.emailVerified) {
            window.sessionStorage.setItem('userStatus', 'true');
            setActiveUser(true);
          }
        } else {
          window.sessionStorage.setItem('userStatus', 'false');
          setActiveUser(false);
        }
        return res ? setUser(res) : setUser(null);
      }
      dispatch(setcurrentUser(res));
    });
    return unsubscribe;
  }, [dispatch]);
  useEffect(() => {
    const checkAndRenewToken = async () => {
      const userData: any = auth.currentUser;
      if (userData) {
        try {
          await userData.getIdToken(true).then((idToken: string) => {
            window.sessionStorage.setItem('accessToken', idToken);
            SetAllAccessTokens(idToken);
          });
        } catch (err) {}
      }
    };
    const intervalCheck = setInterval(checkAndRenewToken, 600000);
    return () => clearInterval(intervalCheck);
  }, []);
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route element={<PrivateRoute activeUser={activeUser} />}>
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route
                path="/create_profile"
                element={<ProfileMultistepForm />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/login" element={<UserLogin />} />
              <Route path="/sign_up" element={<SignUp />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
            </Route>

            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<Home />} />
            <Route path="/business" element={<Business />} />
            {/* Hackathon start */}
            <Route path="/hackathon" element={<HackathonPage />} />
            {/* <Route path="/hackathon/hsign_up" element={<HSignupForm />} /> */}
            {/* Hackathon ends */}
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/view/:slug" element={<BlogPostPage />} />
            <Route path="/loan_for_business" element={<ProductPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/invest_with_us" element={<InvestWithUs />} />
            <Route path="/application" element={<Application />} />
            <Route
              path="/islamic_facility_financing"
              element={<Facilities />}
            />
            <Route path="/asset_financing" element={<AssetFinancing />} />
            <Route path="/a_r_m" element={<ARM />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/paystack_callback" element={<PaystackCalback />} />

            {/* <Route path="/T&C/?query=view" element={<LoanPolicyPdf />} /> */}
            <Route path="/T&C" element={<TandC />} />
            <Route path="Privacy-policy" element={<Privacy />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="media" element={<MediaPage />} />
            <Route path="careers" element={<Career />} />
            <Route path="insurance-preview" element={<InsurancePreview />} />
            <Route path="market_structure" element={<StructuredMarkets />} />

            {/* <Route path="/blog" element={<BlogPageRedirect />} /> */}
            <Route path="/media/video" element={<MediaVideo state={true} />} />
            <Route
              path="/email_verification_successful"
              element={<EmailVerificationSuccess />}
            />
            {/* <Route path="/terms_of_loan" element={<LoanPolicyPdf />} /> */}
            <Route path="/terms_of_loan" element={<LoanTerms />} />
            <Route
              path="/reset_password_successful"
              element={<PasswordResetSuccessful />}
            />
            <Route
              path="/verification_successful"
              element={<VerificationSuccess />}
            />
            <Route path="/email_verification" element={<VerifyAccount />} />

            <Route path="/reset_password/*" element={<ResetPassword />} />
            <Route
              path="/guarantor/verify_information/:id"
              element={<GuarantorMultistepForm />}
            />
            <Route path="*" element={<UserLogin />} />
            <Route path="/403" element={<Error403 />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="/301" element={<Error301 />} />
          </Routes>
        </ScrollToTop>
      </Router>
      {/* )} */}
    </div>
  );
};
export default MainRoutes;
