import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';

import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import InsuranceCard from './InsuranceCard';

const Insurance = () => {
  return (
    <div className="insurance">
      <div className="section-head">Insurance</div>
      <div className="insurance-container">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          grabCursor={true}
          // effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          modules={[Autoplay, Pagination, Navigation, EffectCreative]}
          className="mySwiper-insur">
          <SwiperSlide>
            <div className="insurance-fs">
              <div className="insurance-fs-container">
                <div className="left">
                  <h2>Insure your vehicle with our auto insurance </h2>
                  <Button
                    content="Get Insured"
                    title="Get Insured"
                    classes="secondary-btn btn-lg bg-white"
                    route="/dashboard/insurance/car_insurance"
                  />
                </div>
                <div className="right">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/sport-sedan.png`}
                    alt="Trade Lenda"
                    className="right-img"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="insurance-fs" style={{ background: '#054B99' }}>
              <div className="insurance-fs-container">
                <div className="left">
                  <h2>Assurance for your life and family </h2>
                  <Button
                    content="Get Insured"
                    title="Get Insured"
                    classes="secondary-btn btn-lg bg-white"
                    route="/dashboard/insurance/life_assurance"
                  />
                </div>
                <div className="right">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/assured-fam.png`}
                    className="right-img"
                    alt="Trade Lenda"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="insurance-fs"
              style={{
                background:
                  'linear-gradient(114.44deg, #EB0055 0%, #FFFA80 100%)',
              }}>
              <div className="insurance-fs-container">
                <div className="left">
                  <h2>Health insurance for every budget </h2>
                  <Button
                    content="Get Insured"
                    title="Get Insured"
                    classes="secondary-btn btn-lg bg-white text-danger border border-danger"
                    route="/dashboard/insurance/health_insurance"
                  />
                </div>
                <div className="right">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/woman-posing.png`}
                    alt="Trade Lenda"
                  />
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/man-posing.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="insurance-fs"
              style={{
                background:
                  'linear-gradient(114.44deg, #44AB3B 55.56%, rgba(249, 155, 28, 0.03) 100%)',
              }}>
              <div className="insurance-fs-container">
                <div className="left">
                  <h2>get insured, Face the future with confidence </h2>
                  <Button
                    content="Get Insured"
                    title="Get Insured"
                    classes="secondary-btn btn-lg bg-white text-success border border-success"
                    route="/dashboard/insurance/future_insurance"
                  />
                </div>
                <div className="right">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/woman-posing.png`}
                    alt="Trade Lenda"
                  />
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/man-posing.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <InsuranceCard />
      </div>
    </div>
  );
};

export default Insurance;
