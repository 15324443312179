import React, { useState } from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import AddCard from './AddCard';

const Integration = () => {
  const [cardSaved, setCardSaved] = useState(false);
  const [toggleCardModal, setToggleCardModal] = useState(false);

  return (
    <div className="integration">
      <h3>Integrations</h3>
      <div className="form-box px-4">
        {!cardSaved && (
          <p className="text-center mb-5">
            Update your card details for seamless transactions
          </p>
        )}
        <div className="row justify-content-center mt-4">
          <div className="col-lg-8 col-md-11">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6>Card details</h6>
              <Button
                classes="secondary-btn btn-md px-3 py-2"
                content="Add Card"
                onClick={() => setToggleCardModal(true)}
              />
            </div>
            {!cardSaved ? (
              <div className="card-box">
                <div>
                  <Button
                    classes="primary-btn px-3 py-2"
                    content=""
                    onClick={() => setCardSaved(true)}
                    icon="fa-solid fa-plus"
                  />
                  <p>Add debit card</p>
                </div>
              </div>
            ) : (
              <div className="card-box-active">
                <div className="d-flex justify-content-between">
                  <p>XXXXXXXX XXXX 12234</p>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/mastercard.png`}
                    alt="Mastercard logo"
                    className="mastercard"
                  />
                </div>
                <p className="expiry-date">12/24</p>
              </div>
            )}
          </div>
        </div>
        {toggleCardModal && <AddCard close={() => setToggleCardModal(false)} />}
      </div>
    </div>
  );
};

export default Integration;
