/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {
  Autoplay,
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
} from 'swiper';

import 'aos/dist/aos.css';

import { mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import HeroSlider from './HeroSlider';
import Media from './Media';

const HeroSection = () => {
  const [watchVideo, setWatchVideo] = useState(false);
  return (
    <section className="landing-page-hero d-flex flex-column">
      {watchVideo && <Media close={() => setWatchVideo(false)} />}
      <div className="container">
        <div className="hero-container-wrapper">
          <div className="hs-container2">
            <h1 className="fw-normal text-center" style={{ maxWidth: '45rem' }}>
              Driving Financial Inclusion for{' '}
              <span style={{ color: '#06A77D' }} className="fw-bold">
                MSMEs
              </span>
            </h1>

            <div className="hs-container3 text-center">
              <HeroSlider />
              {/* <img
                  style={{
                    padding: '20px',
                  }}
                  src={`${BaseDirectories.IMAGES_DIR}/Investment-hero-img.png`}
                  alt="Investment Hero"
                /> */}

              <h1 className="fw-normal" style={{ maxWidth: '30rem' }}>
                Digital bank for{' '}
                <span style={{ color: '#06A77D' }} className="fw-bold">
                  MSMEs
                </span>
              </h1>
              <div className="d-flex justify-content-center">
                <Button
                  classes="white-btn btn-lg"
                  content="Create An Account"
                  route="/sign_up"
                />
              </div>
              <a
                href="#"
                role="button"
                onClick={() => setWatchVideo(true)}
                className="docu-link d-block text-center mt-3">
                <Icon path={mdiPlayCircle} size={1} />
                Watch Our Documentary
              </a>
            </div>
            {/* <div className="col-lg-6">
                <div className="hero-img">
                  <img
                    style={{
                      borderRadius: '10px',
                    }}
                    src={`${BaseDirectories.IMAGES_DIR}/hero_0.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </div> */}
          </div>
        </div>
      </div>
      <div className="marque-container">
        <div className="hero-img">
          <Swiper
            className="swiper-container"
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            freeMode={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: 'swiper-pagination', clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[
              EffectCoverflow,
              Autoplay,
              FreeMode,
              Pagination,
              Navigation,
            ]}>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/SDG_1.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/SDG_2.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/SDG_3.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/SDG_4.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/SDG_5.png`}
                alt="slide_image"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
