/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { useLocation } from 'react-router';
import LoanPolicyPdf from './LoanPolicyPdf';
import { Helmet } from 'react-helmet-async';

const TandC = () => {
  const location = useLocation();

  const queryString = require('query-string');
  const query = queryString.parse(location?.search);

  return (
    <>
      <Helmet>
        <title>Terms and conditions | Trade Lenda</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {query.view ? (
        <LoanPolicyPdf />
      ) : (
        <>
          <Header />
          <div className="lp-tandc">
            <h3>Terms and conditions</h3>

            <div className="md:p-7 md:mr-6">
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                The following terms of use (“Agreement”) govern your use of
                TradeLenda.com (the “Site”) and/or any of the services (the
                “Services”) offered by Trade Lenda COM Ltd (“Trade Lenda”) on
                and through our Site, including use of any the Site's
                information and data, and participation in any public areas
                which may exist in the Site (the “Public Areas”). Please read
                the requirements of this Agreement carefully. Your use of the
                Site and/or any of the Services constitutes your agreement to
                comply with the terms of this Agreement. If you cannot agree to
                and comply with this Agreement and its requirements, you are
                expressly prohibited from use of the Site and/or the Services
                and must exit the Site.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Use of Site</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                You may view and use the Site and/or any information contained
                therein only in accordance with the terms of this Agreement. You
                may not modify, distribute, publish, license, create derivative
                works from, transfer or sell any information or content
                contained on the Site. The Site may be used only as contemplated
                by this Agreement. You agree to use the Site only for lawful
                purposes and you acknowledge that your failure to do so may
                subject you to civil and criminal liability all as determined by
                applicable law. If you wish to use our Services, you will need
                to provide us with certain information, including information
                about your identity, finances, and business performance. You
                will also need to create a user ID and a password to protect
                your account information. With your permission, we may also
                access your banking information, obtain a credit report,
                retrieve bank account data, or collect other third party data
                about you (collectively, the “Personal Information”). You may
                also invite friends or colleagues to use the Services through
                our referral program page. When you invite others to join Trade
                Lenda by using our referral page, we send them a onetime email
                for that referral. By using our referral program, you warrant
                (i) to only send invitations to people you know; (ii) not to
                misrepresent your relationship with Trade Lenda or make any
                warranty or representation on behalf of Trade Lenda; and (iii)
                to comply at all times with this Agreement and applicable law.
                You may also invite clients to use the Services through our
                qualify program page. When you invite others to join Trade Lenda
                by using our qualify page, we send them a one-time email for
                that referral. By using our referral program, you warrant (i) to
                only send qualify requests for people you know; (ii) not to
                misrepresent your relationship with Trade Lenda or make any
                warranty or representation on behalf of Trade Lenda; and (iii)
                to comply at all times with this Agreement and applicable law.
                As a registered user (“User”) of the Site, you represent and
                warrant that: (i) you are 18 years of age or older; (ii) the
                Personal Information is true, accurate, current and complete;
                (iii) you will maintain and update Personal Information to keep
                it true, accurate, current, and complete; and; (iv) the Personal
                Information belongs to you and does not infringe or violate any
                patents, copyrights, trademarks or other intellectual property,
                proprietary or privacy rights of any third party; and (v) you
                own or has obtain the right to all of the intellectual property
                rights subsisting in the Personal Information provided, and has
                the right to provide Trade Lenda the license granted herein to
                the Information, (vi) the Company Information is true, accurate,
                current and complete; (vii) you will maintain and update Company
                Information to keep it true, accurate, current, and complete;
                and; (viii) the Company Information belongs to you and does not
                infringe or violate any patents, copyrights, trademarks or other
                intellectual property, proprietary or privacy rights of any
                third party; and (ix) you own or has obtain the right to all of
                the intellectual property rights subsisting in the Company
                Information provided, and has the right to provide Trade Lenda
                the license granted herein to the Information.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Changes to Agreement and Additional Rules of Usage
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                Trade Lenda reserves the right, at any time, to modify, alter or
                update this Agreement. The date of the most recent revision will
                appear on this page. Your continued access to the Site and use
                of the Services by you will constitute your acceptance of any
                changes or revisions to this Agreement. Trade Lenda also
                reserves the right to post, from time to time, additional rules
                of usage that apply to specific parts of the Site, which may be
                posted in the relevant parts of the Site, and will be
                identified. Your continued use of the Site constitutes your
                agreement to comply with these additional rules.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Disclaimer</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                All information (hereinafter, the “Information”) posted on the
                Site is subject to change or removal. Trade Lenda may make
                changes to the materials, products, features and services
                available at the Site at any time. You may not use the
                Information for any commercial purpose, including without
                limitation: (I) distribution (electronically or otherwise); (II)
                resale; or (III) display, etc. Information, Services, and other
                material available through the Site are furnished “As Is”, “at
                Your Own Risk” and “As Available”, without any warranty
                whatsoever. Although Trade Lenda has attempted to provide
                accurate Information on the Site, Trade Lenda assumes no
                responsibility for the accuracy, merchantability or completeness
                of the Information, Services or other material at the Site. The
                Information and all other materials on the Site are provided for
                general information purposes only and do not constitute
                professional advice of any kind. All other warranties, express
                or implied, including any warranties of merchantability, or
                non-infringement of intellectual property are specifically
                excluded and disclaimed. Trade Lenda does not warrant that the
                Information will meet user's requirements and that the operation
                of the Services will be uninterrupted or error free, or that the
                Site or the server that makes it available are free of viruses
                or bugs. Trade Lenda's obligations with respect to its products
                and services are governed solely by the agreements under which
                they are provided and nothing on the Site should be construed to
                alter such agreements. The materials and Services at the Site
                may be out of date and Trade Lenda makes no commitment to update
                the materials and Services at the Site.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Limitation of Liability
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                In no event will Trade Lenda, its suppliers, or other third
                parties mentioned at the Site be liable for any damages
                whatsoever (including, without limitation, those resulting from
                lost profits, lost savings, lost data, business interruption or
                other special, consequential or incidental damages) arising out
                of or relating to the use, inability to use, or the results of
                use of the Site, any websites or other content linked to the
                Site, or any materials, information, product or service
                contained at any or all such sites, whether based on warranty,
                contract, tort or any other legal theory and whether or not
                Trade Lenda has been advised of the possibility of such loss or
                damages. If your use of the materials, Information or Services
                from the Site results in the need for servicing, repair of
                correction of equipment or data, you assume all costs thereof.
                For the avoidance of doubt, User is responsible for maintaining
                and protecting all of the information provided, transmitted,
                uploaded, imported or made accessible by using the Site. Trade
                Lenda will not be liable for any loss or corruption of such
                information, or for any costs or expenses associated with
                backing-up or restoring any of such information.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Privacy Policy</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                In addition to reviewing this Agreement, you should also review
                our Privacy Policy (“Privacy Policy”), incorporated by reference
                into this Agreement, to better understand how we collect and use
                personal information. Your use of the Site and the Services
                constitutes your agreement to its terms and conditions.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Intellectual Property</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                The Site, all information and intellectual property referenced
                on the Site, including, without limitation, patents, copyrights,
                logos, trademarks and trade secrets are legally owned by Trade
                Lenda or third parties. These rights apply to the Information,
                computer code of the Site, graphical design, products, company
                names, etc. All contents of the Site are protected by copyright
                and trademark rights, except as specifically permitted herein.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Links to Third-Party Websites and/or Third Party Software
                Products
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                Links on the Site to third-party websites and/or software
                products (such as an invoicing or accounting software product
                you are using) are provided as a convenience. If you use these
                links or software products, you might leave the Site. Some of
                the third parties software products may require you to have, and
                actually be signed-in to, an active user account for such
                third-party software products. Trade Lenda has not reviewed any
                of these third-party sites and/or the applications and/or
                software products and does not control and is not responsible
                and/or liable for any of these sites or their content. Thus,
                Trade Lenda does not endorse or make any representations about
                them, or any information, software or other products or
                materials found on them, or any results that may be obtained
                from using them. If you decide to access any of the third-party
                websites and/or applications and/or software products linked to
                the Site, you do this entirely at your own risk and Trade Lenda
                assumes no liability for such use.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Ownership of the Site and Information
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                The Site is expressly owned and operated by Trade Lenda. Unless
                otherwise noted, the design and content features on the Site,
                including information and other materials, illustrations,
                product layout and design, icons, navigational buttons, images,
                artwork, graphics, photography, text, data, audio sound,
                software and the like, as well as the selection, assembly and
                arrangement thereof (collectively: the “Information”), are owned
                by Trade Lenda or its affiliates or are licensed by Trade Lenda
                from third parties. The Site, each as a whole and in part, and
                the Information is protected by copyright, trademark, service
                mark, trade name, and other intellectual property and other
                proprietary rights, and all other applicable rights are reserved
                and User shall abide by those laws. By furnishing Information,
                Trade Lenda does not grant any licenses to or transfer any title
                rights for any copyrights, patents or any other intellectual
                property rights.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Site Availability</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                Trade Lenda shall not be responsible for any disruption to the
                Site, regardless of length. Trade Lenda will not be liable for
                losses related to User's inability to access content due to
                disruptions of the Site. Trade Lenda shall be excused from its
                performance of any obligations under this Agreement which are
                prevented or interfered with due to acts or conditions beyond
                the reasonable control of Trade Lenda.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">Indemnification</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                You agree to indemnify Trade Lenda and its affiliates,
                directors, employees, agents, representatives and third party
                service providers, and to defend and hold each of them harmless,
                from any and all claims, actions, liabilities, damages, and
                costs (collectively, “Claims”) which may arise from your
                unauthorized use of the Site, Services or Information obtained
                through use of the Site and/or your breach of this Agreement.
                Trade Lenda reserves the right to assume the exclusive control
                of any such defense of any such Claim.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                The Security of the Site
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                We maintain physical, electronic, and procedural safeguards and
                personnel policies consistent with applicable laws that are
                designed to guard the Site, our systems and the Information
                provided by our Users. Unfortunately, no data transmission over
                the Internet can be guaranteed to be absolutely secure. In the
                event of a breach of the confidentiality or security of your
                personal information, we will reasonably attempt to notify you
                as necessary so you can take appropriate protective steps.
                Unless you indicate otherwise, we may notify you under such
                circumstances using your most current email address on record
                with us.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Electronic Communications
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                When you visit the Site or send e-mails, you communicate with us
                electronically. You consent to receive communications from us
                electronically. We will communicate with you by e-mail or
                posting notices on the Site. You agree that all agreements,
                notices, disclosures, and other communications we provide to you
                electronically or posted on the Site satisfy any legal
                requirement that such communications be in writing if such
                requirements are applicable.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">
                Jurisdiction and Governing Law
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6 mb-3">
                Trade Lenda makes no representation that the content, the
                Information, or the Services offered through the Site are
                appropriate, available or legal in any particular location.
                Those who choose to access the content, the Information, and the
                Services offered through the Site do so on their own initiative
                and are responsible for compliance with local laws, if and to
                the extent local laws are applicable. Those who access the Site
                from locations outside Nigeria are responsible for compliance
                with applicable local laws. Any claim relating to the Site or
                use of the Site or the Services will be governed by and
                interpreted in accordance with the laws of the The Federal
                Republic of Nigeria, without reference to its conflict-of-laws
                principles. Any dispute arising out of or related to your use of
                the Site will be brought in, and you hereby consent to the
                exclusive jurisdiction and venue in, the competent courts in
                Nigeria.
              </p>
              <p className="text-xxs md:text-xs text-gray07 mt-6 mb-3">
                You hereby agree to waive all defenses of lack of personal
                jurisdiction and forum nonconveniens and agree that process may
                be served in a manner authorized by applicable law or court
                rule. If any term or provision of this legal notice is for any
                reason held to be invalid, such invalidity shall not affect any
                other term or provision, and this legal notice shall be
                interpreted as if such term or provision had never been
                contained in this legal notice. The User understands and agrees
                that, in addition to monetary damages, Trade Lenda shall be
                entitled to equitable relief where appropriate upon User’s
                breach of any portion of this Agreement.
              </p>

              <p>
                User agrees that no joint venture, partnership, employment or
                agency relationship exists between User and Trade Lenda as a
                result of this Agreement and/or User's use of the Site. User may
                not assign any part of its rights and obligations under this
                Agreement without Trade Lenda's prior written consent. No waiver
                of any obligation or right of either party shall be effective
                unless in writing, executed by the party against whom it is
                being enforced. The terms of this Agreement are severable and
                may be construed to the extent of their enforceability in light
                of the parties' mutual intent. The titles and subtitles in this
                Agreement are used for convenience only and are not to be
                considered in construing it.
              </p>
            </div>
            <div className="md:p-7 md:mr-6">
              <p className="text-xs text-gray07 mt-6">General</p>
              <p className="text-xxs md:text-xs text-gray07 mt-6">
                Trade Lenda may revise these Terms of Use at any time by
                updating this page on the Site and in such event your continued
                use of the Site, shall constitute your acceptance of such
                updated Terms of Use. Accordingly, you should visit this page
                from time to time to review the then-current Terms of Use
                because they are binding on you. Certain provisions of these
                Terms of Use may be superseded by expressly designated legal
                notices or terms located on particular pages at the Site. This
                Terms of Use Agreement is Effective as of: July 2nd, 2021.
              </p>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default TandC;
