import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import { Invoice, InvoiceStatus } from '../../../mobx_stores/InvoiceStore';
import { StoreContext } from '../../../mobx_stores/RootStore';

const AllInvoices = () => {
  const { invoiceStore } = useContext(StoreContext);

  useEffect(() => {
    invoiceStore.getInvoices();

    const invoice = invoiceStore.invoices;
    // const drafts = invoices.filter(
    //   (invoice: Invoice) => invoice.status === InvoiceStatus.DRAFT,
    // );
    // setAllDraft(drafts);
    const drafts = invoice.filter((invoice: Invoice) =>
      invoice.status.includes(InvoiceStatus.DRAFT),
    );
    const pending = invoice.filter((invoice: Invoice) =>
      invoice.status.includes(InvoiceStatus.PENDING),
    );
    const processing = invoice.filter((invoice: Invoice) =>
      invoice.status.includes(InvoiceStatus.PROCESSING),
    );
    const paid = invoice.filter((invoice: Invoice) =>
      invoice.status.includes(InvoiceStatus.PAID),
    );
    const overdue = invoice.filter((invoice: Invoice) =>
      invoice.status.includes(InvoiceStatus.OVERDUE),
    );

    setAllDraft(drafts);
    setAllPending(pending);
    setAllProcessing(processing);
    setAllPaid(paid);
    setAllOverdue(overdue);
    setAllInvoices(invoice);
  }, [invoiceStore.invoices]);

  const [allInvoices, setAllInvoices] = useState<any>([]);
  const [allDraft, setAllDraft] = useState<any>([]);
  const [allPending, setAllPending] = useState<any>([]);
  const [allProcessing, setAllProcessing] = useState<any>([]);
  const [allOverdue, setAllOverdue] = useState<any>([]);
  const [allPaid, setAllPaid] = useState<any>([]);

  const [headerText, setHeaderText] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  function activeHeader(head: any) {
    setHeaderText(head.target.innerHTML);
    setIsOpen(!isOpen);
  }
  const { loading } = invoiceStore;
  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 767 ? false : true);
  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    return () => window.removeEventListener('resize', handleResize);

    // const invoices = invoiceStore.invoices;
    // setAllInvoices(invoices);
  }, [screen]);

  return (
    <div>
      {loading ? (
        <div>
          <div className="bg-white guarantor-list">
            <div className="d-flex m-0 pt-4 guarantor-heade ">
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
            </div>

            <div className="bg-white table-responsive">
              <table className="table table-striped responsive-table mb-0">
                <thead>
                  <tr>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th className="mobile-tr">
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th className="mobile-tr">
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th className="mobile-tr">
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td className="mobile-tr">
                      <Skeleton width={150} height={25} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td className="mobile-tr">
                      <Skeleton width={150} height={25} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="navigation">
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="section-head">Invoice</h4>
          <div className="search-group">
            {!mobile ? (
              <div className="search-box">
                <Icon path={mdiMagnify} size={1} className="search-icon" />
                <input type="text" placeholder="Search" />
              </div>
            ) : (
              <h4 className="section-head2">Invoices</h4>
            )}
            <Button
              content="Create New Invoice"
              title="Add Invoice"
              classes="primary-btn btn-lg add-btn"
              route="/dashboard/invoices/create_invoice"
            />
          </div>
          {mobile && (
            <div className="search-group">
              <div className="search-box">
                <Icon path={mdiMagnify} size={1} className="search-icon" />
                <input type="text" placeholder="Search" />
              </div>
            </div>
          )}
          <div className="loans-container">
            <div className="guarantor-list">
              {mobile &&
                (!isOpen ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={toggleMenu}
                    className="mobile-menu-icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className="mobile-menu-icon"
                  />
                ))}
              <Tabs selectedTabClassName="selected">
                {mobile ? (
                  <>
                    <TabList
                      className={
                        'd-flex flex-column flex-md-row m-0 p-0 guarantor-heade automatic'
                      }>
                      {!isOpen && (
                        <div className="guarantors-header-link">
                          <h5>{headerText === '' ? 'Invoices' : headerText}</h5>
                        </div>
                      )}{' '}
                      <div className={isOpen ? '' : 'hidden-header'}>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5 className="">All Invoice</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Draft</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Pending</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Processing</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Paid</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Overdue</h5>
                        </Tab>
                      </div>
                    </TabList>
                  </>
                ) : (
                  <TabList
                    className={'d-flex m-0 p-0 guarantor-heade automatic'}>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">All Invoices</h5>
                    </Tab>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">Draft</h5>
                    </Tab>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">Pending</h5>
                    </Tab>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">Processing</h5>
                    </Tab>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">Paid</h5>
                    </Tab>
                    <Tab className="guarantors-header-link">
                      <h5 className="m-0">Overdue</h5>
                    </Tab>
                  </TabList>
                )}
                <TabPanel>
                  {allInvoices.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
                <TabPanel>
                  {allDraft.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
                <TabPanel>
                  {allPending.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
                <TabPanel>
                  {allProcessing.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
                <TabPanel>
                  {allPaid.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
                <TabPanel>
                  {allOverdue.length === 0 ? (
                    <div className="bg-white no-guarantor">
                      <div className="empty-state-img">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/Invoice.png`}
                          alt="Trade Lenda"
                        />
                      </div>
                      <p>You do not have payment yet! </p>
                      <p>Once you have any payment, information appear here </p>
                      <Button
                        content="Create New Invoice"
                        title="Add Invoice"
                        classes="primary-btn btn-lg add-btn add-btn2"
                        route="/dashboard/invoices/create_invoice"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(AllInvoices);
