/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiCalculator } from '@mdi/js';
import Icon from '@mdi/react';
import { ChangeEvent, useState } from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';

const LoanCalculator = () => {
  const [monthlyPayment, setMonthlyPayment] = useState<any>(0);
  const [totalPayment, setTotalPayment] = useState<any>(0);
  const [showError, setShowError] = useState(false);
  const [loanDetails, setLoanDetails] = useState({
    loanType: '',
    loanCategory: '',
    amount: '',
    duration: '1',
    interest: '6%',
  });

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;

    setLoanDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });
  };

  const calculateLoan = () => {
    const amount = parseInt(loanDetails.amount);
    const interest = parseInt(loanDetails.interest);
    const duration = parseInt(loanDetails.duration);

    if (!amount || amount < 50000) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
      return;
    }

    const monthlyInterest = (interest / 100) * amount;
    const totalPayment = monthlyInterest * duration + amount;
    const monthlyPayment = totalPayment / duration;

    setMonthlyPayment(Number(monthlyPayment.toFixed(2)).toLocaleString());
    setTotalPayment(Number(totalPayment.toFixed(2)).toLocaleString());
  };

  const validDurations = ['1', '2', '3', '4', '5', '6'];
  const duration = validDurations.includes(loanDetails.duration)
    ? loanDetails.duration
    : '';

  let loanCategory = loanDetails.loanCategory;

  loanCategory =
    loanCategory === 'Working Capital'
      ? 'Working Capital'
      : loanCategory === 'Purchase Order Financing'
        ? 'Purchase Order Financing'
        : loanCategory === 'Invoice Discounting'
          ? 'Invoice Discounting'
          : '';

  return (
    <div className="loan-calculator">
      <div className="loan-calculator-container">
        <h6>
          <span className="calculator-icon">
            <Icon path={mdiCalculator} size={1.2} />
          </span>
          <span>LOAN CALCULATOR</span>
        </h6>

        {monthlyPayment.length > 0 ? (
          <div className="row g-3 loan-result">
            <div className="col-12">
              <p>
                The result of the evaluation may differ in real time situations
                due to risk assessment{' '}
              </p>
            </div>
            <div className="col-12">
              <div className="monthly-payment">
                <h2>₦{monthlyPayment.toLocaleString()}</h2>
                <p>Your monthly payback budget</p>
              </div>
            </div>
            <div className="col-12">
              <div className="total-payment">
                <h2>₦{totalPayment.toLocaleString()}</h2>
                <p>Total payback budget</p>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center gap-3 mt-4">
              <Button
                type="button"
                content="Take Loan"
                classes="primary-btn btn-lg px-3"
                route="/dashboard/loans"
              />
              <Button
                type="button"
                content="Try Again"
                classes="secondary-btn btn-lg px-3"
                onClick={() => {
                  setMonthlyPayment(0);
                  setTotalPayment(0);
                }}
              />
            </div>
          </div>
        ) : (
          <form className="row g-3">
            <div className="col-12">
              <p>
                The result of the evaluation may differ in real time situations
                due to risk assessment{' '}
              </p>
            </div>
            <div className="col-12">
              <label htmlFor="type" className="form-label">
                Select loan type
              </label>
              <select
                name="loanType"
                defaultValue="Monthly"
                id="type"
                className="form-control">
                {/* <option value="Weekly">Weekly</option> */}
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            <div className="col-12">
              <label htmlFor="category" className="form-label">
                Select loan Category
              </label>
              <select
                name="loanCategory"
                defaultValue={loanCategory}
                onChange={handleInputChange}
                id="category"
                className="form-control">
                <option value="Working Capital">Working Capital</option>
                <option value="Purchase Order Financing">
                  Purchase Order Financing
                </option>
                <option value="Invoice Discounting">Invoice Discounting</option>
              </select>
            </div>
            <div className="col-sm-6">
              <label htmlFor="amount" className="form-label">
                Loan Amount (₦)
              </label>
              <input
                type="number"
                name="amount"
                value={loanDetails.amount.toLocaleString()}
                onChange={handleInputChange}
                className="form-control"
                id="amount"
                placeholder="₦50,000.00"
              />
              {showError && (
                <p
                  style={{ color: '#ed2e7e', fontSize: '13px' }}
                  className="mb-0 mt-2">
                  Loan starts from ₦50,000.00{' '}
                </p>
              )}
            </div>
            <div className="col-sm-6">
              <label htmlFor="duration" className="form-label">
                Loan Duration
              </label>
              <select
                name="duration"
                defaultValue={duration}
                onChange={handleInputChange}
                id="duration"
                className="form-control">
                <option value="1">1 month</option>
                <option value="2">2 months</option>
                <option value="3">3 months</option>
                <option value="4">4 months</option>
                <option value="5">5 months</option>
                <option value="6">6 months</option>
              </select>
            </div>
            <div className="col-12">
              <label htmlFor="interest" className="form-label">
                Interest (%)
              </label>
              <input
                type="text"
                name="interest"
                className="form-control"
                value={loanDetails.interest}
                readOnly={true}
                id="interest"
                placeholder="10%"
              />
            </div>
            <div className="col-12 text-center mt-4">
              <Button
                type="button"
                content="Calculate Loan"
                classes="white-btn btn-lg px-4"
                onClick={calculateLoan}
              />
              <img
                src={`${BaseDirectories.ICONS_DIR}/calc-loan.png`}
                alt="Trade Lenda"
                style={{
                  width: '30px',
                  marginTop: '40px',
                }}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoanCalculator;
