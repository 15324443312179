/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { mdiFileEdit } from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { toast } from 'react-toastify';
import BaseDirectories from '../../base directories/BaseDirectories';
import { StoreContext } from '../../mobx_stores/RootStore';
import UpdateDocumentsModal from '../../pages/dashboard/settings/UpdateDocumentsModal';
import Button from '../ui/Button';
import Spinner from '../ui/Spinner';

const SettingsDocumentUploadForm = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [imagesrc] = React.useState('');
  const [file, setFile] = React.useState<any>(null);
  // const [uploading, setUploading] = React.useState(false);
  const [documentName, setDocumentName] = React.useState('');
  const [openModal, setOpenModal] = useState(false);
  const [done, setDone] = useState(false);
  const [formDetails, setFormDetails] = React.useState({
    validIdentificationType: '',
    validIdentification: '',
    utilityBill: '',
    signature: '',
    passport: '',
    bankStatement: '',
    seal: '',
    cac7: '',
    cac2: '',
    cacCertificate: '',
    lpoFile: '',
    proformaFile: '',
    MERMAT: '',
    others: '',
    othersName: '',
    personalPhoto: '',
    identityCard: '',
  });

  const { loansStore } = useContext(StoreContext);

  const {
    sending,
    success,
    loanUserdetails,
    uploadProgress,
    documentS3Url,
    uploadSuccess,
    uploading,
  } = loansStore;

  React.useEffect(() => {
    setFormDetails({
      validIdentificationType:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.validIdentificationType ===
          undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.validIdentificationType,
      validIdentification:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.validIdentification ===
          undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.validIdentification,
      utilityBill:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.utilityBill === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.utilityBill,
      signature:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.signature === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.signature,
      passport:
        toJS(loanUserdetails) && toJS(loanUserdetails)?.passport === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.passport,
      bankStatement:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.bankStatement === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.bankStatement,
      seal:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.seal === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.seal,
      cac7:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.cac7 === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.cac7,
      cac2:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.cac2 === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.cac2,
      cacCertificate:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.cacCertificate === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.cacCertificate,
      lpoFile:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.lpoFile === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.lpoFile,
      proformaFile:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.proformaFile === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.proformaFile,
      MERMAT:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.MERMAT === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.MERMAT,
      others:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.others === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.others,
      othersName:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.othersName === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.othersName,
      personalPhoto:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.personalPhoto === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.personalPhoto,
      identityCard:
        toJS(loanUserdetails) &&
        toJS(loanUserdetails)?.loanDocumentDetails?.identityCard === undefined
          ? ''
          : toJS(loanUserdetails)?.loanDocumentDetails?.identityCard,
    });
  }, [loanUserdetails]);

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  const handleOpenModal = (name: string) => {
    setOpenModal(!openModal);
    setDocumentName(name);
  };

  const handleFileChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;

    if (element?.files) {
      setFile(element?.files[0]);
      setDocumentName(element.name);
    }
  };

  const uploadFile = () => {
    if (file) {
      if (file?.size > 3 * 1024 * 1000) {
        toast.error(
          'File size is too large! Size should not be more than 3MB.',
        );
      } else {
        const formData = new FormData();

        formData.append('file', file);
        loansStore.documentUpload(formData, documentName);
      }
    }
  };

  React.useEffect(() => {
    if (uploadSuccess) {
      setFormDetails((deets: any) => {
        return {
          ...deets,
          [documentName]: documentS3Url,
        };
      });
      setDone(true);
    }
  }, [uploadSuccess]);

  async function urlToFile() {
    const randomString = Math.random().toString(36).substring(2, 15);

    const blob = await fetch(imagesrc).then((it) => it.blob());
    const newFile = new File([blob], `${randomString}.jpg`, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
    setFile(newFile);
  }

  React.useEffect(() => {
    if (imagesrc) {
      urlToFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesrc]);

  React.useEffect(() => {
    if (success === 'Document details updated successfully') {
      setDone(false);

      setTimeout(() => {
        setOpenModal(false);
        loansStore.getLoanUserDetails();
      }, 3000);
    }
  }, [success]);

  const handleUpdateDocs = () => {
    loansStore.updateDocumentsDetails(formDetails);
  };

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const images = toJS(loanUserdetails)?.loanDocumentDetails && [
    ...Object.values(toJS(loanUserdetails)?.loanDocumentDetails),
  ];

  return (
    <div className="document-settings">
      {sending && <Spinner />}
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          leftArrowComponent={<></>}
          rightArrowComponent={<></>}
          onClose={closeImageViewer}
        />
      )}

      {openModal && (
        <UpdateDocumentsModal
          handleFileChange={handleFileChange}
          handleUpdateDocs={handleUpdateDocs}
          documentName={documentName}
          uploading={uploading}
          uploadProgress={uploadProgress}
          // s3UploadFunction={s3UploadFunction}
          uploadFile={uploadFile}
          close={() => {
            setFile(null);
            setDocumentName('');
            setFormDetails((deets: any) => {
              return {
                ...deets,
                [documentName]: '',
              };
            });
            setDone(false);
            setOpenModal(false);
          }}
          done={done}
        />
      )}
      <div className="container">
        {toJS(loanUserdetails).loanDocumentDetails !== undefined ? (
          <div className="row">
            {toJS(loanUserdetails).loanDocumentDetails !== undefined &&
              Object.keys(toJS(loanUserdetails).loanDocumentDetails).map(
                (doc, i) =>
                  toJS(loanUserdetails).loanDocumentDetails[doc] !== ''
                    ? doc !== 'validIdentificationType' &&
                      doc !== 'validIdentification' &&
                      doc !== 'others' &&
                      doc !== 'othersName' && (
                        <div
                          className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-4"
                          key={i}>
                          <div className="document-card">
                            <div className="img p-3 d-flex align-items-start justify-content-center flex-column gap-2">
                              {toJS(loanUserdetails).loanDocumentDetails[
                                doc
                              ].includes('amazonaws') &&
                                (toJS(loanUserdetails).loanDocumentDetails[
                                  doc
                                ].includes('.jpeg') ||
                                  toJS(loanUserdetails).loanDocumentDetails[
                                    doc
                                  ].includes('.jpg') ||
                                  toJS(loanUserdetails).loanDocumentDetails[
                                    doc
                                  ].includes('.png')) && (
                                  <img
                                    src={
                                      toJS(loanUserdetails).loanDocumentDetails[
                                        doc
                                      ].replace('om//', 'om/') || undefined
                                    }
                                    alt=""
                                  />
                                )}
                              {toJS(loanUserdetails).loanDocumentDetails[
                                doc
                              ].includes('amazonaws') &&
                                toJS(loanUserdetails).loanDocumentDetails[
                                  doc
                                ].includes('.pdf') && (
                                  <img
                                    src={`${BaseDirectories.ICONS_DIR}/pdficon.png`}
                                    alt={doc}
                                  />
                                )}
                            </div>
                            <div className="document-details">
                              <div className="name-row">
                                <h5>
                                  {doc
                                    .replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })}
                                </h5>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleOpenModal(doc)}>
                                  <Icon
                                    path={mdiFileEdit}
                                    size={1}
                                    color={'#054b99'}
                                    className="goto-icon me-2 "
                                    title={`Change ${doc
                                      .replace(/([A-Z])/g, ' $1')
                                      .replace(/^./, function (str) {
                                        return str.toUpperCase();
                                      })}`}
                                  />
                                </span>
                              </div>
                              {!toJS(loanUserdetails).loanDocumentDetails[
                                doc
                              ].includes('.pdf') && (
                                <Button
                                  classes="primary-btn btn-sm px-4 cancel"
                                  content="Preview"
                                  onClick={() => {
                                    openImageViewer(i);
                                  }}
                                />
                              )}

                              {toJS(loanUserdetails).loanDocumentDetails[
                                doc
                              ].includes('.pdf') && (
                                <a
                                  href={
                                    toJS(loanUserdetails).loanDocumentDetails[
                                      doc
                                    ].replace('om//', 'om/') || undefined
                                  }
                                  className="primary-btn btn-sm btn px-4 cancel d-flex align-items-center"
                                  target="_blank">
                                  Preview
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    : doc !== 'others' &&
                      doc !== 'othersName' &&
                      doc !== 'validIdentificationType' &&
                      doc !== 'validIdentification' && (
                        <div
                          className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-4"
                          key={i}>
                          <div className="document-card">
                            <div className="img p-3 d-flex align-items-start justify-content-center flex-column gap-2">
                              <h3>No File</h3>
                            </div>
                            <div className="document-details">
                              <div className="name-row">
                                <h5>
                                  {doc
                                    .replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })}
                                </h5>
                              </div>
                              {!toJS(loanUserdetails).loanDocumentDetails[
                                doc
                              ].includes('.pdf') && (
                                <Button
                                  classes="primary-btn btn-sm px-4 cancel"
                                  content="Add File"
                                  onClick={() => handleOpenModal(doc)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ),
              )}
          </div>
        ) : (
          <h5>
            No documents to preview yet. You can upload documents when
            requesting for loans.
          </h5>
        )}
      </div>
    </div>
  );
};

export default observer(SettingsDocumentUploadForm);
