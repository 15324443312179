/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { StoreContext } from '../../../mobx_stores/RootStore';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  Data?: any;
  walletAccountNumber?: string;
  setOpenTransactionModal: (state: any) => void;
};

type Params = {
  amount: number;
  otp: number;
  reason: string;
};

const TransactionModal: FC<Props> = ({
  onClick = () => {
    setOpenTransactionModal(false);
  },
  Data,
  walletAccountNumber,
  setOpenTransactionModal,
}) => {
  const [details, setDetails] = useState<Params>({
    amount: 0,
    otp: 0,
    reason: '',
  });
  const { investStore } = useContext(StoreContext);

  const updateData = (e: any) => {
    const element = e.target as HTMLInputElement;
    setDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]: element.value,
      };
    });
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <div className="notification-modal">
      <div
        className="bg-white notification-box"
        style={{
          maxWidth: '810px',
          textAlign: 'left',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          fontSize: '12px',
          alignContent: 'flex-start',
          display: 'flex',
          padding: '20px',
        }}>
        <span
          onClick={() => {
            setOpenTransactionModal(false);
          }}
          className="close"
          role="button">
          <FaTimes />
        </span>

        <div className="rq-loan-details">
          <br />
          <div className="d-flex justify-content-between ps-4 pe-4">
            <h4 className="text-primary">Detailed Summary</h4>
          </div>
          <hr />
          <ul
            className="px-4 py-4"
            style={width < 600 ? { columns: '1' } : { columns: '2' }}>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  From Account Number
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.fromWalletAccountNumber
                    ? Data.fromWalletAccountNumber
                    : Data.fromAccount}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Account Name
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.fromAccountName}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Bank Name</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.fromBankName}
                </p>
              </div>
            </li>

            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Date</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {moment(Data?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                </p>
              </div>
            </li>
            {/* <hr /> */}
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  To Account Number
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.toWalletAccountNumber
                    ? Data.toWalletAccountNumber
                    : Data.toAccount}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Account Name
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.toAccountName ? Data.toAccountName : 'NIL'}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Bank Name</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.toBankName}
                </p>
              </div>
            </li>
            {/* <hr /> */}
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Reference ID
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.transactionRef}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Amount</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>{`₦ ${
                  Data.debit
                    ? Number(toJS(Data.debit)).toLocaleString()
                    : Number(toJS(Data.credit)).toLocaleString()
                }`}</p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Transaction Type
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.transactionType}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Transaction
                </strong>

                {walletAccountNumber === Data.toWalletAccountNumber ||
                walletAccountNumber === Data.toAccount ? (
                  <p
                    className="lead mark text-success"
                    style={{
                      backgroundColor: '#eff4ff',
                      borderRadius: '5px',
                    }}>
                    Credit
                  </p>
                ) : (
                  <p
                    className="lead mark text-danger"
                    style={{
                      backgroundColor: '#eff4ff',
                      borderRadius: '5px',
                    }}>
                    Debit
                  </p>
                )}
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Status</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.transactionStatus}
                </p>
              </div>
            </li>
            {Data.reversalTransactionStatus ? (
              <li>
                <div className="ld-box">
                  <strong className="blockquote text-secondary">
                    Reversal Status
                  </strong>
                  <p
                    className="lead mark"
                    style={{
                      backgroundColor: '#eff4ff',
                      borderRadius: '5px',
                    }}>
                    {Data.reversalTransactionStatus}
                  </p>
                </div>
              </li>
            ) : (
              <></>
            )}
            {Data.reversalTransactionRef ? (
              <li>
                <div className="ld-box">
                  <strong className="blockquote text-secondary">
                    Reversal Reference ID
                  </strong>
                  <p
                    className="lead mark"
                    style={{
                      backgroundColor: '#eff4ff',
                      borderRadius: '5px',
                    }}>
                    {Data.reversalTransactionRef}
                  </p>
                </div>
              </li>
            ) : (
              <li>
                <div className="ld-box">
                  <strong className="blockquote text-secondary">
                    Reversal Reference ID
                  </strong>
                  <p
                    className="lead mark"
                    style={{
                      backgroundColor: '#eff4ff',
                      borderRadius: '5px',
                    }}>
                    NIL
                  </p>
                </div>
              </li>
            )}

            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Narration</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}>
                  {Data.narration}
                </p>
              </div>
            </li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default observer(TransactionModal);
