import { mdiCircleSmall, mdiWalletOutline } from '@mdi/js';
import Icon from '@mdi/react';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Debt = () => {
  return (
    <>
      <div className=" bg-white pt-2 ps-4 w-25">
        <p className="my-3">
          <Icon path={mdiWalletOutline} size={1} />
          YOU'LL GET
        </p>
        <div className="mt-3 py-0 mb-lg-4">
          <h4 className="text-primary">N300,400</h4>
          <p className="text-uppercase mt-3">16% Increase</p>
        </div>
      </div>
      <div className="bg-white table-responsive">
        <table className="table table-striped head-color responsive-table mb-0">
          <thead
            style={{
              borderBottom: '1px solid black',
            }}>
            <tr>
              <th>
                <input type="checkbox" className="" />
              </th>
              <th>Product</th>
              <th>Customer</th>
              <th>Purchase Date</th>
              <th>Status</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>Fruit Package</td>
              <td>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/user1.png`}
                  alt=""
                  style={{
                    width: '12%',
                    borderRadius: '50px',
                    // paddingRight: '10px',
                  }}
                  className="mx-2"
                />
                <span>John Doe</span>
              </td>
              <td>07 Aug 4:32pm</td>
              <td>
                <Icon path={mdiCircleSmall} size={1} className="" /> Paid
              </td>
              <td>N20,000.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Debt;
