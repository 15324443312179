/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../../components/ui/Button';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import { setLoanDetails } from '../../../../redux/loanDetail/loan.details.action';
import { selectCurrentLoanDetails } from '../../../../redux/loanDetail/loan.details.selector';
import UpdateDocumentsModal from '../../settings/UpdateDocumentsModal';

type Props = {
  setShowProfileModal: (state: any) => void;
  next: () => void;
  x: number;
  setX: any;
  previous: any;
};

let defaultDocuments = {
  validIdentificationType: 'Drivers License',
  validIdentification: '0123456789',
  utilityBill: '',
  signature: '',
  passport: '',
  bankStatement: '',
  seal: '',
  cac7: '',
  cac2: '',
  cacCertificate: '',
  lpoFile: '',
  proformaFile: '',
  MERMAT: '',
  others: '',
  othersName: '',
  personalPhoto: '',
  identityCard: '',
};

const DocumentsUpload: FC<Props> = ({ x, previous, setShowProfileModal }) => {
  const { loansStore } = React.useContext(StoreContext);
  const { profileStore } = useContext(StoreContext);
  const { submitting } = profileStore;
  const currentLoanDetails = useSelector(selectCurrentLoanDetails);
  const { loanDocumentDetails } = currentLoanDetails;
  const [openModal, setOpenModal] = useState(false);
  const [done, setDone] = useState(false);
  const {
    sending,
    uploadProgress,
    documentS3Url,
    uploadSuccess,
    uploading,
    success,
  } = loansStore;

  const dispatch = useDispatch();

  defaultDocuments =
    loanDocumentDetails != null ? loanDocumentDetails : defaultDocuments;

  const [formDetails, setFormDetails] = React.useState(defaultDocuments);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [armDetails, setArmDetails] = useState<any>({
    kycLevel: 'Tier-1',
  });

  // S3 Function
  const [file, setFile] = React.useState<any>(null);
  const [documentName, setDocumentName] = React.useState('');

  const handleCloseModal = () => {
    setShowProfileModal(false);
    setTimeout(() => {
      loansStore.getLoanUserDetails();
      dispatch(setLoanDetails(toJS(loansStore.loanUserdetails)));
    }, 3000);
  };

  const handleFileChange = (e: React.FormEvent) => {
    e.preventDefault();
    const element = e.target as HTMLInputElement;
    if (element?.files) {
      setFile(element?.files[0]);
      setDocumentName(element?.name);
    }
  };

  const isNotReady =
    formDetails.identityCard === '' ||
    formDetails.personalPhoto === '' ||
    formDetails.utilityBill === '';

  const uploadFile = () => {
    if (file) {
      if (file?.size > 3 * 1024 * 1000) {
        toast.error(
          'File size is too large! Size should not be more than 3MB.',
        );
      } else {
        const formData = new FormData();

        formData.append('file', file);
        loansStore.documentUpload(formData, documentName);
      }
    }
  };

  useEffect(() => {
    if (uploadSuccess) {
      setFormDetails((deets: any) => {
        return {
          ...deets,
          [documentName]: documentS3Url,
        };
      });
      setDone(true);
    }
  }, [uploadSuccess]);

  const handleCreateDocs = () => {
    loansStore.updateArmDocumentsDetails(formDetails);
  };

  useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  useEffect(() => {
    dispatch(setLoanDetails(toJS(loansStore.loanUserdetails)));
  }, [loansStore.loanUserdetails]);

  const handleModalExit = () => {
    loansStore.getLoanUserDetails();
    loansStore.setLoanUserDetails;
    handleCloseModal();
  };

  const handleOpenModal = (name: string) => {
    setOpenModal(!openModal);
    setDocumentName(name);
  };

  useEffect(() => {
    if (success === 'Document Updated successfully') {
      setDone(false);

      setTimeout(() => {
        setOpenModal(false);
        loansStore.getLoanUserDetails();
      }, 3000);
    }
  }, [success]);

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className="">
      <div className="update-arm-form px-2">
        {openModal && (
          <UpdateDocumentsModal
            handleFileChange={handleFileChange}
            handleUpdateDocs={handleCreateDocs}
            documentName={documentName}
            uploading={uploading}
            uploadProgress={uploadProgress}
            uploadFile={uploadFile}
            close={() => {
              setFile(null);
              setDocumentName('');
              setFormDetails((deets: any) => {
                return {
                  ...deets,
                  [documentName]: '',
                };
              });
              setDone(false);
              setOpenModal(false);
            }}
            done={done}
          />
        )}
        <h4>DOCUMENT UPLOAD</h4>
        <p>
          Industry regulation requires us to collect this information to begin
          investment.
        </p>
        <div>
          <div className="row">
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              {/* Profile upload */}
              <div className="col-md-12 input-box">
                <label htmlFor="personalPhoto" className="mb-1 mt-3">
                  Profile Photograph
                </label>
                <span style={{ color: 'red' }}> *</span>
                <div className="document-card">
                  <div className="document-details">
                    <div className="name-row">
                      <h5
                        style={{
                          backgroundColor: '#44ab3b',
                          color: '#44ab3b',
                          borderRadius: 10,
                        }}>
                        {formDetails?.personalPhoto &&
                          formDetails?.personalPhoto
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, function (str) {
                              return str.toLocaleLowerCase();
                            })}
                      </h5>
                      {formDetails?.personalPhoto &&
                      formDetails?.personalPhoto !== '' ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          <strong> ✓ </strong>
                        </span>
                      ) : null}
                    </div>
                    <Button
                      classes="primary-btn btn-sm px-4 cancel"
                      content="Upload"
                      onClick={() => {
                        handleOpenModal('personalPhoto');
                        setDocumentName('personalPhoto');
                      }}
                    />
                  </div>
                </div>
                {/* Information for Bill */}
                <div className="col-md-12 mt-2">
                  <div
                    className="d-flex flex-wrap justify-content-left align-items-left gap-2"
                    style={{
                      background: 'aliceblue',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      flexDirection: 'column',
                    }}>
                    <div>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        File format JPEG, PNG Size 3mb max
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              {/* Utilities Bill upload */}
              <div className="col-md-12 input-box">
                <label htmlFor="utilityBill" className="mb-1 mt-3">
                  Utility Bill
                </label>
                <span style={{ color: 'red' }}> *</span>
                <div className="document-card">
                  <div className="document-details">
                    <div className="name-row">
                      <h5
                        style={{
                          backgroundColor: '#44ab3b',
                          color: '#44ab3b',
                          borderRadius: 10,
                        }}>
                        {formDetails?.utilityBill &&
                          formDetails?.utilityBill
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, function (str) {
                              return str.toLocaleLowerCase();
                            })}
                      </h5>
                      {formDetails?.utilityBill &&
                      formDetails?.utilityBill !== '' ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          <strong> ✓ </strong>
                        </span>
                      ) : null}
                    </div>
                    <Button
                      classes="primary-btn btn-sm px-4 cancel"
                      content="Upload"
                      onClick={() => {
                        handleOpenModal('utilityBill');
                        setDocumentName('utilityBill');
                      }}
                    />
                  </div>
                </div>
                {/* Information for Bill */}
                <div className="col-md-12 mt-2">
                  <div
                    className="d-flex flex-wrap justify-content-left align-items-left gap-2"
                    style={{
                      background: 'aliceblue',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      flexDirection: 'column',
                    }}>
                    <div>
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        <strong>Proof Of Address (Utility bill)</strong>
                      </p>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        Name the file utiltiyBill.png | .jpg | .jpeg | .pdf
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        It must not be more than 3 month from the time you
                        registered{' '}
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        It must carry the address you entered
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        It must be clear and legible
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        File format JPEG, PNG, PDF Size 3mb max
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              {/* Signature upload */}
              {armDetails.kycLevel !== '' &&
              armDetails.kycLevel === 'Tier-3' ? (
                <div className="col-md-12 input-box">
                  <label htmlFor="identityCard" className="mb-1 mt-3">
                    Signature
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <div className="document-card">
                    <div className="document-details">
                      <div className="name-row">
                        <h5
                          style={{
                            backgroundColor: '#44ab3b',
                            color: '#44ab3b',
                            borderRadius: 10,
                          }}>
                          {formDetails?.signature &&
                            formDetails?.signature
                              .replace(/([A-Z])/g, ' $1')
                              .replace(/^./, function (str) {
                                return str.toLocaleLowerCase();
                              })}
                        </h5>
                        {formDetails?.signature &&
                        formDetails?.signature !== '' ? (
                          <span style={{ color: 'green', fontWeight: 'bold' }}>
                            <strong> ✓ </strong>
                          </span>
                        ) : null}
                      </div>
                      <Button
                        classes="primary-btn btn-sm px-4 cancel"
                        content="Upload"
                        onClick={() => {
                          handleOpenModal('signature');
                          setDocumentName('signature');
                        }}
                      />
                    </div>
                  </div>
                  {/* Information for Bill */}
                  <div className="col-md-12 mt-2">
                    <div
                      className="d-flex flex-wrap justify-content-left align-items-left gap-2"
                      style={{
                        background: 'aliceblue',
                        padding: '8px 10px',
                        borderRadius: '10px',
                        flexDirection: 'column',
                      }}>
                      <div>
                        <li className="mb-0" style={{ fontSize: '12px' }}>
                          File format JPEG, PNG Size 3mb max
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Identity upload */}
              <div className="col-md-12 input-box">
                <label htmlFor="identityCard" className="mb-1 mt-3">
                  Identity Card
                </label>
                <span style={{ color: 'red' }}> *</span>
                <div className="document-card">
                  <div className="document-details">
                    <div className="name-row">
                      <h5
                        style={{
                          backgroundColor: '#44ab3b',
                          color: '#44ab3b',
                          borderRadius: 10,
                        }}>
                        {formDetails?.identityCard &&
                          formDetails?.identityCard
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, function (str) {
                              return str.toLocaleLowerCase();
                            })}
                      </h5>
                      {formDetails?.identityCard &&
                      formDetails?.identityCard !== '' ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          <strong> ✓ </strong>
                        </span>
                      ) : null}
                    </div>
                    <Button
                      classes="primary-btn btn-sm px-4 cancel"
                      content="Upload"
                      onClick={() => {
                        handleOpenModal('identityCard');
                        setDocumentName('identityCard');
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div
                    className="d-flex flex-wrap justify-content-left align-items-left gap-2"
                    style={{
                      background: 'aliceblue',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      flexDirection: 'column',
                    }}>
                    <div>
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        <strong>
                          Identity Verification (Upload Identity Card)
                        </strong>
                      </p>

                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        It must be valid at the time of upload
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        It must be clear and legible
                      </li>
                      <li className="mb-0" style={{ fontSize: '12px' }}>
                        File format JPEG, PNG, PDF Size 3mb max
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="profile-btn d-md-flex flex-wrap flex-md-nowrap"
            style={{ width: '100%' }}>
            <Button
              classes="primary-btn"
              content={'Previous'}
              type="submit"
              onClick={() => {
                previous(1000);
              }}
              disabled={submitting || sending ? true : false}
            />
            <Button
              classes="primary-btn"
              content={
                submitting || sending ? 'Submitting...' : 'Save and Exit'
              }
              type="submit"
              disabled={isNotReady}
              onClick={() => {
                handleModalExit();
              }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default observer(DocumentsUpload);
