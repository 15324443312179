/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';
import logger from '../common/logger/CustomLogger';

configure({ enforceActions: 'always' });

type Message = {
  type: string;
  msg: any;
};

type Profile = {
  [key: string]: any;
};

interface InvestmentParams {
  membershipId: string;
  productCode: string;
}

export class InvestStore {
  loading = false;
  loadingPlans = false;
  submitting = false;
  submittingInvestment = false;
  submittingTradelenda = false;
  token: any = sessionStorage.getItem('accessToken') || '';
  armPlans: any = [];
  armPlanYield: any = [];
  lendaPlans: any = [];
  userInvestments = [];
  userLendaInvestments = [];
  investment: any = '';
  lendaInvestment: any = '';
  transactionsDetail: any = '';
  lendaTransactionsDetail: any = '';
  urlParams: any = '';
  success = '';
  error = '';
  clientDetailId = '';
  message: Message = {
    type: '',
    msg: '',
  };

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
    });
  }

  getInvestments() {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/investment/arm-products`, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get All Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setArmInvestments(res.data.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get All Investment | ${toJS(this.userProfile?.email)}`,
          err,
        );
        // toast.error(`Failed to Get Investments Plans #${err.response.status}`);

        runInAction(() => {
          this.setError('Failed to Get Investments Plans');
          this.setMessage('error', 'Failed to Get Investments Plans');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  getInvestmentYield(productCode: string) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/arm-product-yield?productCode=${productCode}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get Investment Yield | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setArmInvestmentYield(res.data.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get Investment Yield | ${toJS(this.userProfile?.email)}`,
          err,
        );

        runInAction(() => {
          this.setError('Failed to Get ARM Get Investment Yield');
          this.setMessage('error', 'Failed to Get ARM Get Investment Yield');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  createInvestment(data: any) {
    toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSubmitting(true);
    this.setSubmittinginvestment(true);

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/investment/create-arm-investment`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Create Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        logger.debug('__RES__', res);
        if (!res.data.error) {
          this.setSuccess('Investment Created successfully.');

          // runInAction(() => {
          this.setSubmitting(false);
          this.setMessage('success', 'Investment Created successfully.');
          toast.success(
            'Investment created successfully, you will be notified once it is active.',
          );
          this.setSubmittinginvestment(false);
          runInAction(() => {
            setTimeout(() => {
              this.setSuccess('');
              this.setMessage('', '');
            }, 10000);
          });
          // });
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          this.setSubmitting(false);
          toast.error(
            res.data.message ? res.data.message : 'Investment Creation Failed',
          );
          setTimeout(() => {
            this.setMessage('', '');
            this.setSubmittinginvestment(false);
          }, 4000);
          runInAction(() => {
            this.setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        toast.error(
          err?.data?.message
            ? err?.data?.message
            : 'Investment Creation Failed',
        );
        this.setError('Investment Creation Failed : ' + err);
        this.setMessage('Investment Creation Failed', err);
        setTimeout(() => {
          this.setMessage('', '');
          this.setSubmittinginvestment(false);
        }, 6000);
        runInAction(() => {
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmittinginvestment(false);
          this.setSubmitting(false);
        });
      });
  }

  getUsersInvestment() {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/investment/active-investments`, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | ARM Create Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setUserInvestments(res.data.data);
        runInAction(() => {
          this.loadingPlans = false;
        });
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Create Investment | ${toJS(this.userProfile?.email)}`,
          err,
        );
        // toast.error(`Investment Creation Failed #${err.response.status}`);
        this.setError('Failed to get active investments');
        this.setMessage('error', 'Failed to get active investments');
        setTimeout(() => {
          this.setMessage('', '');
        }, 4000);
        this.setSubmitting(false);
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  getUsersInvestmentWithoutReload() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${BaseDirectories.API_BASE_URL}/investment/active-investments`, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get User Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setUserInvestments(res.data.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get User Investment | ${toJS(this.userProfile?.email)}`,
          err,
        );
      });
  }

  getSingleInvestment(membershipId: string, productCode: string) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/single-investment/?membershipId=${membershipId}&productCode=${productCode}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get Single Investment Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );

        if (res.data.error) {
          toast.error(`Failed to Get Investment Detail | ${res.data.message}`);
        } else {
          this.setInvestment(res.data);
        }
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get Single Investment Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
        toast.error(`Failed to Get Investment Detail | ${err.response.status}`);
      })
      .finally(() => {
        this.setLoadingPlans(false);
      });
  }

  getSingleInvestmentWithoutLoading(membershipId: string, productCode: string) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/single-investment/?membershipId=${membershipId}&productCode=${productCode}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get Single Investment Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );

        if (res.data.error) {
        } else {
          this.setInvestment(res.data);
        }
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get Single Investment Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
      });
  }

  topUpInvestment(data: any) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoadingPlans(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/investment/topup-arm-investment`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Top-Up Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (!res.data.error) {
          this.setSuccess('Investment Top-Up successful.');
          this.setMessage('success', 'Investment Top-Up successful.');
          setTimeout(() => {
            this.setSubmittinginvestment(false);
            this.setLoadingPlans(false);
            toast.success('Investment Top-Up successful.');
          }, 4000);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        } else {
          this.setError(res.data.message);
          this.setMessage('success', res.data.message);
          setTimeout(() => {
            this.setLoadingPlans(false);
            this.setSubmittinginvestment(false);
            toast.error(`${res.data.message ? res.data.message : ''}`);
          }, 4000);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        }
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Top-Up Investment | ${toJS(this.userProfile?.email)}`,
          err,
        );
        this.setError('Investment Top-Up Failed : ' + err);
        this.setMessage('Investment Top-Up Failed', err);
        toast.error(`${err.response.message}`);
        setTimeout(() => {
          this.setLoadingPlans(false);
          this.setSubmittinginvestment(false);
        }, 4000);
        setTimeout(() => {
          runInAction(() => {
            this.setSuccess('');
            this.setMessage('', '');
          });
        }, 6000);
      });
  }

  getOTP(membershipId: string) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/otp/?membershipId=${membershipId}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get OTP | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.status === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(
            `Failed to send OTP #${res.status ? res.status : '0000'}`,
          );
        }
      })
      .catch((err) => {
        // toast.error(`Failed to send OTP #${err.response.status}`);
        toast.error(`Failed to send OTP #${err.response.status}`);
        this.logger.info(
          `User | ARM Get OTP | ${toJS(this.userProfile?.email)}`,
          err,
        );
      })
      .finally(() => {
        this.setLoadingPlans(false);
      });
  }

  redeemInvestment(data: any) {
    // toast.dismiss();
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoadingPlans(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/investment/redeem-arm-investment`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Redeem Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );

        if (!res.data.error) {
          this.setSuccess('Investment redeemption successful.');
          this.setMessage('success', 'Investment redeemption successful.');
          setTimeout(() => {
            this.setLoadingPlans(false);
            this.setSubmittinginvestment(false);
            toast.success('Investment redeemption successful.');
          }, 4000);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          setTimeout(() => {
            toast.error(res.data.message);
            this.setLoadingPlans(false);
            this.setSubmittinginvestment(false);
          }, 4000);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        }
      })
      .catch((err) => {
        this.setMessage('success', 'Investment Redemption Failed');
        setTimeout(() => {
          toast.error(`Investment Redemption Failed`);
          this.setLoadingPlans(false);
          this.setSubmittinginvestment(false);
        }, 4000);
        setTimeout(() => {
          runInAction(() => {
            this.setSuccess('');
            this.setMessage('', '');
          });
        }, 6000);
      })
      .finally(() => {
        setTimeout(() => {
          runInAction(() => {
            this.setSuccess('');
            this.setMessage('', '');
            this.setLoadingPlans(false);
            this.setSubmittinginvestment(false);
          });
        }, 6000);
      });
  }

  getTransactions(data: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/transactions/?membershipId=${data}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Get All transactions | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setTransactions(res.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Get All transactions | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(`Failed to Get Transactions #${err.response.status}`);

        runInAction(() => {
          this.setError('v');
          this.setMessage('error', 'Failed to Get Transactions');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  getLendaInvestmentsPlans() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/lenda-investment-plans`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Tradelenda Get All Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );
        this.setTradelendaInvestments(res.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | Tradelenda Get All Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
        // toast.error(`Failed to Get Investments Plans #${err.response.status}`);

        runInAction(() => {
          this.setError('Failed to Get Investments Plans');
          this.setMessage('error', 'Failed to Get Investments Plans');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  createLendaInvestment(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSubmitting(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/create-user-lenda-investment`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Create Lenda Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (!res.data.error) {
          this.setSubmitting(false);
          this.setMessage('success', 'Investment Created successfully.');
          toast.success('Investment Created successfully.');
          this.setSubmittinginvestment(false);
          runInAction(() => {
            setTimeout(() => {
              this.setSuccess('');
              this.setMessage('', '');
            }, 10000);
          });
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          this.setSubmitting(false);
          toast.error(`Investment Creation Failed | ${res.data.message}`);
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          runInAction(() => {
            this.setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        toast.error(`Investment Creation Failed | ${err.message}`);
        this.setError('Investment Creation Failed : ' + err);
        this.setMessage('Investment Creation Failed', err);
        setTimeout(() => {
          this.setMessage('', '');
          this.setSubmittinginvestment(false);
        }, 6000);
        runInAction(() => {
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmittinginvestment(false);
          this.setSubmitting(false);
        });
      });
  }

  getUserLenderInvestments() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/All-lenda-investments`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Lenda Get All User Invetments | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );
        this.setUserLendaInvestments(res.data);
      })
      .catch((err: any) => {
        this.logger.info(
          `User | Lenda Get All User Invetments | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
        // toast.error(`Failed to Get Investments`);

        runInAction(() => {
          this.setError('Failed to Get Investments');
          this.setMessage('error', 'Failed to Get Investments');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  getUserLenderInvestmentsWithoutLoading() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/All-lenda-investments`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Lenda Get All User Invetments | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );
        this.setUserLendaInvestments(res.data);
      })
      .catch((err: any) => {
        this.logger.info(
          `User | Lenda Get All User Invetments | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );

        runInAction(() => {
          this.setError('Failed to Get Investments');
          this.setMessage('error', 'Failed to Get Investments');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
        });
      });
  }

  getSingleUserLenderInvestments(investmentId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/single-lenda-investment?id=${investmentId}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Lenda Get Single Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );

        if (res.status != 200) {
          toast.error(`Failed to Get Investment Detail | ${res.data.message}`);
        } else {
          this.setLendaInvestment(res.data);
        }
      })
      .catch((err) => {
        this.logger.info(
          `User | Lenda Get Single Investment | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
        toast.error(`Failed to Get Investment Detail | ${err.response.status}`);
      })
      .finally(() => {
        this.setLoadingPlans(false);
      });
  }

  topUpLendaInvestment(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSubmitting(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/topUp-lenda-investment`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Top-Up Lenda Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (!res.data.error) {
          this.setSubmitting(false);
          this.setMessage('success', 'Investment Top-Up successful.');
          toast.success('Investment Top-Up successful.');
          this.setSubmittinginvestment(false);
          runInAction(() => {
            setTimeout(() => {
              this.setSuccess('');
              this.setMessage('', '');
            }, 10000);
          });
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          this.setSubmitting(false);
          toast.error(`Investment Top-Up Failed | ${res.data.message}`);
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          runInAction(() => {
            this.setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        toast.error(`Investment Top-Up Failed | ${err.message}`);
        this.setError('Investment Top-Up Failed : ' + err);
        this.setMessage('Investment Top-Up Failed', err);
        setTimeout(() => {
          this.setMessage('', '');
          this.setSubmittinginvestment(false);
        }, 6000);
        runInAction(() => {
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmittinginvestment(false);
          this.setSubmitting(false);
        });
      });
  }

  getLendaOTP(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSubmitting(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/initiate-redemption-to-get-otp`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | GET Lenda Investment OTP | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (!res.data.error) {
          this.setSubmitting(false);
          this.setMessage('success', res.data.message);
          toast.success(res.data.message);
          this.setSubmittinginvestment(false);
          runInAction(() => {
            setTimeout(() => {
              this.setSuccess('');
              this.setMessage('', '');
            }, 10000);
          });
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          this.setSubmitting(false);
          toast.error(`Get OTP Failed | ${res.data.message}`);
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          runInAction(() => {
            this.setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        toast.error(`Get OTP Failed | ${err.message}`);
        this.setError('Get OTP Failed : ' + err);
        this.setMessage('Get OTP Failed', err);
        setTimeout(() => {
          this.setMessage('', '');
          this.setSubmittinginvestment(false);
        }, 6000);
        runInAction(() => {
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmittinginvestment(false);
          this.setSubmitting(false);
        });
      });
  }

  redeemLendaInvestment(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setSubmitting(true);
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/complete-redemption`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Redeem Lenda Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (!res.data.error) {
          this.setSubmitting(false);
          this.setMessage('success', 'Investment redeemption successful.');
          toast.success(res.data.message);
          this.setSubmittinginvestment(false);
          runInAction(() => {
            setTimeout(() => {
              this.setSuccess('');
              this.setMessage('', '');
            }, 10000);
          });
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          this.setSubmitting(false);
          toast.error(`Redeem investment Failed | ${res.data.message}`);
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          runInAction(() => {
            this.setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        toast.error(`Redeem investment Failed | ${err.message}`);
        this.setError('Redeem investment Failed : ' + err);
        this.setMessage('Redeem investment Failed', err);
        setTimeout(() => {
          this.setMessage('', '');
          this.setSubmittinginvestment(false);
        }, 6000);
        runInAction(() => {
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmittinginvestment(false);
          this.setSubmitting(false);
        });
      });
  }

  getLendaTransactionHistory(trackingId: string, page: number, limit: number) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    this.setLoadingPlans(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/lenda-investment/user-investment-transaction-history?trackingId=${trackingId}&page=${page}&limit=${limit}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | LENDA Get All transactions | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );
        this.setLendaTransactions(res.data);
      })
      .catch((err) => {
        this.logger.info(
          `User | LENDA Get All transactions | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
        toast.error(`Failed to Get Transactions #${err.response.status}`);

        runInAction(() => {
          this.setError('Failed to Get Transactions');
          this.setMessage('error', 'Failed to Get Transactions');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loadingPlans = false;
        });
      });
  }

  checkIfAccountIsUpdated(membershipId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/investment/update-bank-account/?membershipId=${membershipId}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Check if account is updated | ${toJS(
            this.userProfile?.email,
          )}`,
          res.data,
        );
      })
      .catch((err) => {
        this.logger.info(
          `User | ARM Check if account is updated  | ${toJS(
            this.userProfile?.email,
          )}`,
          err,
        );
      });
  }

  /// Static headers
  private _headers: any = {
    accept: 'application/json',
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json',
    'cf-cache-status-check': BaseDirectories.FCP,
  };
  public get headers(): any {
    return this._headers;
  }
  public set headers(value: any) {
    this._headers = value;
  }
  upgradeInvestment(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setSubmittinginvestment(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/investment/upgrade-arm-client`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | ARM Upgrade Investment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );

        if (!res.data.error) {
          this.setSuccess('Investment upgrade successful.');
          this.setMessage('success', 'Investment upgrade successful.');
          setTimeout(() => {
            this.setSubmittinginvestment(false);
            toast.success('Investment upgrade successful.');
          }, 500);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        } else {
          this.setError(res.data.message);
          this.setMessage('error', res.data.message);
          setTimeout(() => {
            toast.error(res.data.message);
            this.setSubmittinginvestment(false);
          }, 4000);
          setTimeout(() => {
            runInAction(() => {
              this.setSuccess('');
              this.setMessage('', '');
            });
          }, 6000);
        }
      })
      .catch((err) => {
        console.debug('Investment Catch Error:', err);
        this.setMessage('error', 'Investment upgrade failed');
        setTimeout(() => {
          toast.error(`Investment upgrade failed`);
          this.setSubmittinginvestment(false);
        }, 4000);
        setTimeout(() => {
          runInAction(() => {
            this.setSuccess('');
            this.setMessage('', '');
          });
        }, 6000);
      })
      .finally(() => {
        setTimeout(() => {
          runInAction(() => {
            this.setSuccess('');
            this.setMessage('', '');
            this.setSubmittinginvestment(false);
          });
        }, 6000);
      });
  }

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setError = (err: string) => {
    this.error = err;
  };

  setSuccess = (msg: string) => {
    this.success = msg;
  };
  setLoadingPlans = (val: boolean) => {
    this.loadingPlans = val;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setArmInvestments = (res: any) => {
    this.armPlans = res;
  };

  setArmInvestmentYield = (res: any) => {
    this.armPlanYield = res;
  };

  setTradelendaInvestments = (res: any) => {
    this.lendaPlans = res;
  };

  setSubmittradelend = (val: boolean) => {
    this.submittingTradelenda = val;
  };
  setUserInvestments = (res: any) => {
    this.userInvestments = res;
  };

  setClientDetailId = (potentialClientId: string) => {
    this.clientDetailId = potentialClientId;
  };

  setInvestment = (res: any) => {
    this.investment = res.data;
  };

  setTransactions = (res: any) => {
    this.transactionsDetail = res;
  };

  setSubmittinginvestment = (val: boolean) => {
    this.submittingInvestment = val;
  };

  setUserLendaInvestments = (res: any) => {
    this.userLendaInvestments = res;
  };
  setLendaInvestment = (res: any) => {
    this.lendaInvestment = res;
  };

  setLendaTransactions = (res: any) => {
    this.lendaTransactionsDetail = res;
  };
}
