/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/ban-types */
import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { StoreContext } from '../../mobx_stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';

type Props = {
  x: number;
  disabled: boolean;
  setDisabled: Function;
};

const businessType = {
  soleProprietorship: 'Sole Proprietorship',
  privateLimitedCompany: 'Private Limited Company',
  publicLimitedCompany: 'Public Limited Company',
  publicCompanyLimitedbyGuarantee: 'Public Company Limited by Guarantee',
  privateUnlimitedCompany: 'Private Unlimited Company',
};

const SettingsBusinessDetailsForm: FC<Props> = ({
  x,
  disabled,
  setDisabled,
}) => {
  const { profileStore, loansStore } = useContext(StoreContext);

  const { submitting } = profileStore;

  const { loanUserdetails, loading, success, sending, message } = loansStore;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disabledObj, setDisabledObj] = useState<any>({});

  const [businessDetails, setBusinessDetails] = useState<any>({
    businessType: '',
    businessName: '',
    positionInOrg: '',
    shareInOrg: '',
    rcNum: '',
    establishmentDate: '',
    businessAddress: '',
    country: 'Nigeria',
    state: '',
    city: '',
    ownedOrRented: '',
    NoOfOutlets: 0,
    totalEmployees: 0,
    salesMethod: '',
    industry: '',
    monthlySales: '',
    monthlyExpenses: '',
    businessDuration: '',
    womenLed: true,
    shariaCom: true,
    tin: '',
    registered: true,
    whenDidYouMoveToThisBusinessLocation: '',
  });

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  const bizDetails = loanUserdetails.organizationDetails;

  React.useEffect(() => {
    setBusinessDetails({
      businessType:
        bizDetails && bizDetails?.businessType === undefined
          ? ''
          : bizDetails?.businessType,
      businessName:
        bizDetails && bizDetails?.businessName === undefined
          ? ''
          : bizDetails?.businessName,
      positionInOrg:
        bizDetails && bizDetails?.positionInOrg === undefined
          ? ''
          : bizDetails?.positionInOrg,
      shareInOrg:
        bizDetails && bizDetails?.shareInOrg === undefined
          ? ''
          : bizDetails?.shareInOrg,
      rcNum:
        bizDetails && bizDetails?.rcNum === undefined ? '' : bizDetails?.rcNum,
      establishmentDate:
        bizDetails && bizDetails?.establishmentDate === undefined
          ? ''
          : bizDetails?.establishmentDate?.substr(0, 10),
      businessAddress:
        bizDetails && bizDetails?.businessAddress === undefined
          ? ''
          : bizDetails?.businessAddress,
      country: 'Nigeria',
      state:
        bizDetails && bizDetails?.state === undefined ? '' : bizDetails?.state,
      city:
        bizDetails && bizDetails?.city === undefined ? '' : bizDetails?.city,
      ownedOrRented:
        bizDetails && bizDetails?.ownedOrRented === undefined
          ? ''
          : bizDetails?.ownedOrRented,
      NoOfOutlets:
        bizDetails && bizDetails?.NoOfOutlets === undefined
          ? 0
          : bizDetails?.NoOfOutlets,
      totalEmployees:
        bizDetails && bizDetails?.totalEmployees === undefined
          ? 0
          : bizDetails?.totalEmployees,
      salesMethod:
        bizDetails && bizDetails?.salesMethod === undefined
          ? ''
          : bizDetails?.salesMethod,
      industry:
        bizDetails && bizDetails?.industry === undefined
          ? ''
          : bizDetails?.industry,
      monthlySales:
        bizDetails && bizDetails?.monthlySales === undefined
          ? ''
          : bizDetails?.monthlySales,
      monthlyExpenses:
        bizDetails && bizDetails?.monthlyExpenses === undefined
          ? ''
          : bizDetails?.monthlyExpenses,
      businessDuration:
        bizDetails && bizDetails?.businessDuration === undefined
          ? ''
          : bizDetails?.businessDuration,
      womenLed:
        bizDetails && bizDetails?.womenLed === undefined
          ? true
          : bizDetails?.womenLed,
      shariaCom:
        bizDetails && bizDetails?.shariaCom === undefined
          ? true
          : bizDetails?.shariaCom,
      tin: bizDetails && bizDetails?.tin === undefined ? '' : bizDetails?.tin,
      registered:
        bizDetails && bizDetails?.registered === undefined
          ? true
          : bizDetails?.registered,
      whenDidYouMoveToThisBusinessLocation:
        bizDetails &&
        bizDetails?.whenDidYouMoveToThisBusinessLocation === undefined
          ? ''
          : bizDetails?.whenDidYouMoveToThisBusinessLocation?.substr(0, 10),
    });
  }, [bizDetails]);

  const [state, setState] = useState([]);
  const [cityByState, setCitybyState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setState(toJS(loansStore.state));
    setCity(toJS(loansStore.city));
  }, [loansStore.state, loansStore.city]);

  React.useEffect(() => {
    if (businessDetails.state !== '') {
      setCitybyState(
        state.filter((state: string) => state === businessDetails.state),
      );
    }
  }, [businessDetails.state, state]);

  const stateCity = cityByState[0];

  useEffect(() => {
    loansStore.getState();
    loansStore.getCity(stateCity);
  }, [loansStore, stateCity]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;

    setBusinessDetails((prevDetails: any) => {
      if (element.value === 'true') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.value === 'false') {
        return { ...prevDetails, [element.name]: JSON.parse(element.value) };
      }
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  function validate(evt: any) {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleCreateBusinessDetails = () => {
    loansStore.createBusinessDetails(businessDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          input.value === null ||
          businessDetails[input.name] === '' ||
          businessDetails[input.name] === 'NULL' ||
          businessDetails[input.name] === null
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  const handleUpdateBusinessDetails = () => {
    loansStore.updateBusinessDetails(businessDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          input.value === null ||
          businessDetails[input.name] === '' ||
          businessDetails[input.name] === 'NULL' ||
          businessDetails[input.name] === null
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  const submit = () => {
    loanUserdetails.organizationDetails === undefined
      ? handleCreateBusinessDetails()
      : handleUpdateBusinessDetails();
  };

  if (message.type === 'success') {
    setDisabled(true);
  }

  if (success.length > 0) {
    setDisabled(true);
  }

  useEffect(() => {
    if (bizDetails) {
      Object.keys(bizDetails).forEach((biz) => {
        if (!disabled) {
          if (bizDetails[biz] === '') {
            // disabledObj[biz] = false;
            setDisabledObj((prev: any) => {
              return { ...prev, [biz]: false };
            });
          } else {
            // disabledObj[biz] = true;
            setDisabledObj((prev: any) => {
              return { ...prev, [biz]: true };
            });
          }
        } else {
          setDisabledObj((prev: any) => {
            return { ...prev, [biz]: true };
          });
        }
      });
    }
  }, [bizDetails, disabled]);

  return (
    <>
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}
      {(submitting || sending) && <Spinner />}
      {loading ? (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.5 }}
            animate={{ x: 0 }}
            className="">
            <div className="row px-md-5 px-4 justify-content-center">
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
          {/* <div className="row justify-content-center text-center mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
                <span className="btn-lg px-4" style={{ border: 'none' }}>
                  <Skeleton width={150} height={44} />
                </span>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.5 }}
            animate={{ x: 0 }}
            className="">
            <div className="row px-md-5 px-4 justify-content-center">
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="typeOfBusiness" className="mb-1 mt-3">
                    Type of business
                  </label>
                  <select
                    name="businessType"
                    id="businessType"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.businessType}>
                    <option value="">Select type of business</option>
                    <option value={businessType.privateLimitedCompany}>
                      {businessType.privateLimitedCompany}
                    </option>
                    <option value={businessType.privateUnlimitedCompany}>
                      {businessType.privateUnlimitedCompany}
                    </option>
                    <option value={businessType.publicLimitedCompany}>
                      {businessType.publicLimitedCompany}
                    </option>
                    <option
                      value={businessType.publicCompanyLimitedbyGuarantee}>
                      {businessType.publicLimitedCompany}
                    </option>
                    <option value={businessType.soleProprietorship}>
                      {businessType.soleProprietorship}
                    </option>
                    {/* <option value={businessType.others}>Others</option> */}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="businessName" className="mb-1 mt-3">
                    Name of business
                  </label>
                  <Input
                    name="businessName"
                    classes="form-control"
                    type="text"
                    id="businessName"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.businessName}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="registered" className="mb-1 mt-3">
                    Is the business registered?
                  </label>
                  <select
                    name="registered"
                    id="registered"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={
                      bizDetails?.registered === undefined
                        ? ''
                        : bizDetails?.registered
                    }>
                    <option value="">Select option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="positionInOrg" className="mb-1 mt-3">
                    Position in company
                  </label>
                  <Input
                    name="positionInOrg"
                    classes="form-control"
                    type="text"
                    id="positionInOrg"
                    disabled={disabled}
                    defaultValue={bizDetails?.positionInOrg}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="shareInOrg" className="mb-1 mt-3">
                    Share in company
                  </label>
                  <Input
                    name="shareInOrg"
                    classes="form-control"
                    type="text"
                    id="shareInOrg"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.shareInOrg}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="rcNum" className="mb-1 mt-3">
                    RC/BN Number
                  </label>
                  <Input
                    name="rcNum"
                    classes="form-control"
                    type="text"
                    id="rcNum"
                    disabled={disabled}
                    defaultValue={bizDetails?.rcNum}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="dateOfEstablishment" className="mb-1 mt-3">
                    Date of establishment
                  </label>
                  <Input
                    name="establishmentDate"
                    classes="form-control"
                    type="date"
                    id="establishmentDate"
                    disabled={disabled}
                    defaultValue={bizDetails?.establishmentDate?.substr(0, 10)}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="businessAddress" className="mb-1 mt-3">
                    Business Address
                  </label>
                  <Input
                    name="businessAddress"
                    classes="form-control"
                    type="text"
                    id="businessAddress"
                    disabled={disabled}
                    defaultValue={bizDetails?.businessAddress}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="country" className="mb-1 mt-3">
                    Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.country}>
                    <option value={businessDetails.country}>Nigeria</option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="state" className="mb-1 mt-3">
                    State
                  </label>
                  <select
                    name="state"
                    id="state"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.state}>
                    <option value="">Select State</option>
                    {state.map((state: string, i: number) => (
                      <option value={state} key={i}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="city" className="mb-1 mt-3">
                    LGA
                  </label>
                  <select
                    name="city"
                    id="city"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.city}>
                    <option value="">Select LGA</option>
                    {city.map((city: string, i: number) => (
                      <option value={city} key={i}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label
                    htmlFor="whenDidYouMoveToThisBusinessLocation"
                    className="mb-1 mt-3">
                    When did you move to this address?
                  </label>
                  <Input
                    name="whenDidYouMoveToThisBusinessLocation"
                    classes="form-control"
                    type="date"
                    id="whenDidYouMoveToThisBusinessLocation"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.whenDidYouMoveToThisBusinessLocation?.substr(
                      0,
                      10,
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="industry" className="mb-1 mt-3">
                    Industry
                  </label>
                  <select
                    name="industry"
                    id="industry"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.industry}>
                    <option value="">Select industry</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Autos"> Autos </option>
                    <option value="Agency Banking">Agency Banking</option>
                    <option value="Beauty products">Beauty products</option>
                    <option value="Consulting Services">
                      Consulting Services
                    </option>
                    <option value="Education">Education</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Food and Beverages">
                      Food and Beverages
                    </option>
                    <option value="Furniture and Fittings">
                      Furniture and Fittings
                    </option>
                    <option value="Health and Pharma Products">
                      Health and Pharma Products
                    </option>
                    <option value="Home Services">Home Services</option>
                    <option value="House Appliances">House Appliances</option>
                    <option value="Industrial goods">Industrial goods</option>
                    <option value="Logistics">Logistics</option>
                    <option value="Media and Entertainment">
                      Media and Entertainment
                    </option>
                    <option value="Office supplies">Office supplies</option>
                    <option value="Packaging and Plastics">
                      Packaging and Plastics
                    </option>
                    <option value="Personal Care">Personal Care</option>
                    <option value="Professional Services">
                      Professional Services
                    </option>
                    <option value="Technology Services">
                      Technology Services
                    </option>
                    <option value="Utility Services">Utility Services</option>
                    {/* <option value="Others">Others</option> */}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="ownedOrRented" className="mb-1 mt-3">
                    Is your business location owned or rented?
                  </label>
                  <select
                    name="ownedOrRented"
                    id="ownedOrRented"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.ownedOrRented}>
                    <option value="">Select option</option>
                    <option value="Owned">Owned</option>
                    <option value="Rented">Rented</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="isWomenLed" className="mb-1 mt-3">
                    Is your business women led?
                  </label>
                  <select
                    name="womenLed"
                    id="womenLed"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={
                      bizDetails?.womenLed === undefined
                        ? ''
                        : bizDetails?.womenLed
                    }>
                    <option value="">Select option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="shariaCom" className="mb-1 mt-3">
                    Is your business sharia compliant?
                  </label>
                  <select
                    name="shariaCom"
                    id="shariaCom"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={
                      bizDetails?.shariaCom === undefined
                        ? ''
                        : bizDetails?.shariaCom
                    }>
                    <option value="">Select option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="NoOfOutlets" className="mb-1 mt-3">
                    Number of outlet(branches)
                  </label>
                  <Input
                    name="NoOfOutlets"
                    classes="form-control"
                    type="text"
                    id="numbersOnly"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.NoOfOutlets}
                    onKeyPress={validate}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="totalEmployees" className="mb-1 mt-3">
                    Number of employees
                  </label>
                  <Input
                    name="totalEmployees"
                    classes="form-control"
                    type="text"
                    id="numbersOnly"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.totalEmployees}
                    onKeyPress={validate}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="salesMethod" className="mb-1 mt-3">
                    How do you sell?
                  </label>
                  <select
                    name="salesMethod"
                    id="salesMethod"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.salesMethod}>
                    <option value="">Select option</option>
                    <option value="I sell online (Jumia, Konga etc)">
                      I sell online (Jumia, Konga etc)
                    </option>
                    <option value="I distribute FMCG Goods">
                      I distribute FMCG Goods
                    </option>
                    <option value="I buy and sell Agro-Commodities">
                      I buy and sell Agro-Commodities
                    </option>
                    <option value="I have a physical shop">
                      I have a physical shop
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="monthlySales" className="mb-1 mt-3">
                    Average monthly sales
                  </label>
                  <select
                    name="monthlySales"
                    id="monthlySales"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.monthlySales}>
                    <option value="">Select option</option>
                    <option value="Less than ₦10,000">
                      Less than ₦10,000{' '}
                    </option>
                    <option value="₦10,000 to ₦100,000">
                      ₦10,000 to ₦100,000{' '}
                    </option>
                    <option value="₦100,000 to ₦500,000">
                      ₦100,000 to ₦500,000{' '}
                    </option>
                    <option value="₦500,000 to ₦1,000,000 ">
                      {' '}
                      ₦500,000 to ₦1,000,000{' '}
                    </option>
                    <option value="Above ₦1,000,000"> Above ₦1,000,000 </option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="monthlyExpenses" className="mb-1 mt-3">
                    Average monthly expenses
                  </label>
                  <select
                    name="monthlyExpenses"
                    id="monthlyExpenses"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.monthlyExpenses}>
                    <option value="">Select option</option>
                    <option value="Less than ₦10,000">
                      Less than ₦10,000{' '}
                    </option>
                    <option value="₦10,000 to ₦100,000">
                      ₦10,000 to ₦100,000{' '}
                    </option>
                    <option value="₦100,000 to ₦500,000">
                      ₦100,000 to ₦500,000{' '}
                    </option>
                    <option value="₦500,000 to ₦1,000,000 ">
                      {' '}
                      ₦500,000 to ₦1,000,000{' '}
                    </option>
                    <option value="Above ₦1,000,000"> Above ₦1,000,000 </option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="businessDuration" className="mb-1 mt-3">
                    How long have you been in business
                  </label>
                  <select
                    name="businessDuration"
                    id="businessDuration"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.businessDuration}>
                    <option value="">Select option</option>
                    <option value="0-1 years">0-1 years</option>
                    <option value="1-3 years">1-3 years</option>
                    <option value="3-5 years">3-5 years</option>
                    <option value="5-10 years">5-10 years</option>
                    <option value="10+ years">10+ years</option>
                  </select>
                </div>
                {/* <div className="col-md-12 input-box">
                  <label htmlFor="MERMAT" className="mb-1 mt-3">
                    MERMAT
                  </label>
                  <Input
                    name="MERMAT"
                    classes="form-control"
                    type="text"
                    id="MERMAT"
                    disabled={
                      (bizDetails?.MERMAT !== undefined ||
                        bizDetails?.MERMAT !== null) &&
                      bizDetails?.MERMAT?.length > 0
                    }
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.MERMAT}
                  />
                </div> */}
                <div className="col-md-12 input-box">
                  <label htmlFor="tin" className="mb-1 mt-3">
                    TIN (Tax Identification Number)
                  </label>
                  <Input
                    name="tin"
                    classes="form-control"
                    type="text"
                    id="tin"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={bizDetails?.tin}
                  />
                </div>
              </div>
            </div>
          </motion.div>
          <div className="row justify-content-center text-center mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
                {!disabled && (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Cancel"
                    onClick={() => setDisabled(true)}
                  />
                )}
                <Button
                  classes="primary-btn btn-lg px-4"
                  content={disabled ? 'Edit Profile' : 'Save'}
                  onClick={() => {
                    if (disabled) {
                      setDisabled(false);
                    } else {
                      submit();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(SettingsBusinessDetailsForm);
