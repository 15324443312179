/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ /* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { motion } from 'framer-motion';
import CreatePinForm from '../../../../components/forms/CreatePinForm';

type Props = {
  next: any;
  previous: any;
  x: number;
  setX: (val: number) => void;
};

const CreatePin: FC<Props> = ({ next, previous, x, setX }) => {
  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className="">
      <div className="row justify-content-center mt-4">
        <div className="col-lg-7 col-xl-5 col-md-8 col-sm-10 bg-primary-blue mt-4 rounded">
          <h4 className="guarantor-form-heading mb-2 text-center">PIN</h4>
          <p className="mb-4 text-center">Create transaction Pin</p>
        </div>
      </div>
      <CreatePinForm next={next} setX={setX} previous={previous} />
    </motion.div>
  );
};

export default observer(CreatePin);
