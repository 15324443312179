import { mdiAccount, mdiCashMultiple, mdiFileDocument } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useStep } from 'react-hooks-helper';
import BaseDirectories from '../../../base directories/BaseDirectories';
import SettingsBusinessDetailsForm from '../../../components/forms/SettingsBusinessDetailsForm';
import SettingsDocumentUploadForm from '../../../components/forms/SettingsDocumentUploadForm';
import { StoreContext } from '../../../mobx_stores/RootStore';
// import SettingsFinancialDetailsForm from '../../../components/forms/SettingsFinancialDetailsForm';
import SettingsArmDetailsForm from '../../../components/forms/SettingsArmDetailsForm';
import SettingsPersonalDetailsForm from '../../../components/forms/SettingsPersonalDetailsForm';

interface Step {
  id: string;
}

const AccountSettings = () => {
  const [x, setX] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  // const [formData, setForm] = useForm(profileData);
  const { index, step, navigation } = useStep({
    steps: [
      { id: 'personal details' },
      { id: 'business details' },
      { id: 'ARM details' },
      // { id: 'financial details' },
      { id: 'documents' },
    ],
  });

  const { id } = step as Step;

  //Profile store context
  const { profileStore, authStore } = useContext(StoreContext);

  //Destucture profileStore and authStore to get the needed observables
  const { profile } = profileStore;
  const { user } = authStore;

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <div className="account-settings">
      {/* <h3>Account settings</h3> */}
      {/* <hr className="text-blue border-2" /> */}
      <div className="blue-line"></div>
      <div className="form-box">
        <div className="account-img">
          <img
            src={user?.photoURL || `${BaseDirectories.IMAGES_DIR}/user.png`}
            alt="Account pic"
          />
          <span className="online-status"></span>
        </div>
        <h4 className="text-center">My Account</h4>
        {/* <div> */}
        <div className="account-form-navigation">
          <span
            style={{
              left: `calc(100%/4 * ${index} + ${
                width < 550 ? `3.5%` : `3.5%`
              })`,
            }}></span>
          <button
            className={index === 0 ? 'active' : ''}
            onClick={() => {
              if (index > 0) {
                setX(-300);
              } else {
                setX(300);
              }
              navigation.go?.('personal details');
            }}
            title="Personal details">
            {width < 550 ? <Icon path={mdiAccount} size={1} /> : 'Personal'}
          </button>
          <button
            className={index === 1 ? 'active' : ''}
            onClick={() => {
              if (index > 1) {
                setX(-300);
              } else if (index < 1) {
                setX(300);
              }
              navigation.go?.('business details');
            }}
            title="Business details">
            {width < 550 ? (
              <Icon path={mdiCashMultiple} size={1} />
            ) : (
              'Business'
            )}
          </button>
          <button
            className={index === 2 ? 'active' : ''}
            onClick={() => {
              if (index > 1) {
                setX(-300);
              } else if (index < 2) {
                setX(300);
              }
              navigation.go?.('ARM details');
            }}
            title="ARM details">
            {width < 550 ? <Icon path={mdiCashMultiple} size={1} /> : 'ARM'}
          </button>

          <button
            className={index === 3 ? 'active' : ''}
            onClick={() => {
              if (index < 3) {
                setX(300);
              } else {
                setX(-300);
              }
              navigation.go?.('documents');
            }}
            title="Documents">
            {width < 550 ? (
              <Icon path={mdiFileDocument} size={1} />
            ) : (
              'Documents'
            )}
          </button>
        </div>
        <hr />

        {id === 'personal details' && (
          <SettingsPersonalDetailsForm
            x={x}
            disabled={disabled}
            setDisabled={setDisabled}
            profile={profile}
          />
        )}
        {id === 'business details' && (
          <SettingsBusinessDetailsForm
            x={x}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        )}
        {id === 'ARM details' && (
          <SettingsArmDetailsForm
            x={x}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        )}
        {id === 'documents' && (
          <SettingsDocumentUploadForm
          // x={x}
          // disabled={disabled}
          // setDisabled={setDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default observer(AccountSettings);
