/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
// import { requestPermission, onMessageListener } from '../firebase/Firebase';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styled from 'styled-components';

function Notification() {
  // useEffect(() => {
  //   requestPermission();
  //   const unsubscribe = onMessageListener().then((payload: any) => {

  //     toast.info(
  //       <ToastifyNotification
  //         title={payload?.notification?.title}
  //         body={payload?.notification?.body}
  //         icon={payload?.notification?.image}
  //         link={payload?.fcmOptions?.link}
  //       />,
  //       {
  //         autoClose: false,
  //         closeOnClick: false,
  //         position: toast.POSITION.TOP_LEFT,
  //       },
  //     );
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  const ToastifyNotification = ({ title, body, icon, link }) => (
    <div className="push-notification">
      <div>
        <img src={`${icon}`} style={{ paddingBottom: '15px' }} />
      </div>
      <div>
        <h2 className="push-notification-title">{title}</h2>
        <p className="push-notification-text">{body}</p>
        <p className="push-notification-text">
          {link ? (
            <a href={link} target="blank" className="text-primary mt-2">
              view more.
            </a>
          ) : null}
        </p>
      </div>
    </div>
  );
  return (
    <div>
      {/* <ToastContainer hideProgressBar autoClose={60000} /> */}
      <ToastContainer
        hideProgressBar
        autoClose={3000}
        transition={Slide}
        enableMultiContainer
        className="toast-message"
        style={{
          width: 'auto',
          maxWidth: '80%',
          paddingLeft: '5px',
          zIndex: '9999999999',
        }}
      />
    </div>
  );
}
export default Notification;
