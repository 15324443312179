import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { BlogStore } from '../../mobx_stores/BlogStore';
import '../../styles/sass/landing-page-sass/BlogPostPage.scss';
import Footer from './sections/Footer';

interface LocationState {
  id: number;
  category: string | undefined;
  title: string;
  image: string;
  post: string;
  fullContent: string;
}

const BlogPostPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const post = state?.post || 'Default value';
  const title = state?.title || 'Default value';
  const image = state?.image || 'Default value';
  const blogStore = new BlogStore();

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <>
      <div className="post-view">
        <Helmet>
          <title>Why Trade Lenda | Trade Lenda Blog!</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <div className="breadcrumbs">
          <div className="breadcrumb-links">
            <Link to="/blog">Back to Blog</Link>
          </div>
          <div className="categories">
            <span>{state?.category}</span>
          </div>
        </div>

        <div className="blog-article">
          <h2>{title}</h2>
          <img src={image} alt={title} />
          <div dangerouslySetInnerHTML={{ __html: post }} />
        </div>

        <section className="related-posts-header">
          <h3>Related {state.category} Posts</h3>
          <div className="related-posts-grid">
            {state?.category &&
              blogStore
                .getRelatedPosts(state.category, state.id)
                .map((relatedPost) => (
                  <div key={relatedPost.id} className="related-posts">
                    <img src={relatedPost.image} alt={relatedPost.title} />
                    <h4>{relatedPost.title}</h4>
                    <Link
                      to={`/blog/view/${relatedPost.slug}`}
                      state={{
                        id: relatedPost.id,
                        post: relatedPost.fullContent,
                        title: relatedPost.title,
                        category: relatedPost.category,
                        image: relatedPost.image,
                      }}>
                      View more
                    </Link>
                  </div>
                ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default BlogPostPage;
