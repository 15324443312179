import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiArrowRight } from '@mdi/js';

const FinanceOption = () => {
  return (
    <section className="financeOption">
      <div className="container">
        <div className="row mb-5 justify-content-center heading">
          <div className="col-10">
            <h2>Our Finance Options</h2>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="card card1">
              <div className="align-items-start">
                <h2 className="card-title">Inventory {''} Financing</h2>

                <img
                  src={`${BaseDirectories.IMAGES_DIR}/wc-financing.png`}
                  className="card-img-top h-100"
                  alt="working capital financing "
                />

                <p>
                  We can help you get the working capital you need to succeed.
                  Apply now and expand your business
                </p>
                <Link to="/loan_for_business">
                  Learn More <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card1">
              <div className="align-items-start">
                <h2 className="card-title">
                  {' '}
                  Asset <br /> Financing
                </h2>

                <img
                  src={`${BaseDirectories.IMAGES_DIR}/lpo-financing.png`}
                  className="card-img-top h-100"
                  alt="working capital financing "
                />

                <p>
                  We can help you take on larger orders and grow your business.
                  Apply now and see the difference
                </p>
                <Link to="/asset_financing">
                  Learn More <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card1">
              <div className="align-items-start">
                <h2 className="card-title">
                  Working <br /> Capital{' '}
                </h2>

                <img
                  src={`${BaseDirectories.IMAGES_DIR}/id-financing.png`}
                  className="card-img-top h-100"
                  alt="working capital financing "
                />

                <p>
                  We can help you improve your cash flow and keep your business
                  running smoothly. Apply now and grow your business
                </p>
                <Link to="/loan_for_business">
                  Learn More <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card1">
              <div className="align-items-start">
                <h2 className="card-title">Local Purchase Order {''}</h2>

                <img
                  src={`${BaseDirectories.IMAGES_DIR}/wc-financing.png`}
                  className="card-img-top h-100"
                  alt="working capital financing "
                />

                <p>
                  We can help you get the working capital you need to succeed.
                  Apply now and expand your business
                </p>
                <Link to="/loan_for_business">
                  Learn More <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card1">
              <div className="align-items-start">
                <h2 className="card-title">Invoice {''}Discounting</h2>

                <img
                  src={`${BaseDirectories.IMAGES_DIR}/lpo-financing.png`}
                  className="card-img-top h-100"
                  alt="working capital financing "
                />

                <p>
                  We can help you take on larger orders and grow your business.
                  Apply now and see the difference
                </p>
                <Link to="/loan_for_business">
                  Learn More <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-6">
            <div className="card card2">
              <span>02</span>
              <h3>quick finance</h3>
              <p>
                Access financial solution in your account within 6hours of loan
                application
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card3">
              <span>03</span>
              <h3>psychometric analysis</h3>
              <p>
                We determine your eligibility for loan through your bank
                statement to serve you better
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card4">
              <span>04</span>
              <h3>low interest rates</h3>
              <p>
                We charge as low as 5% interest rate depending on loan amount.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card card5">
              <span>05</span>
              <h3>ethical financing</h3>
              <p>
                We offer ethical financing solutions for individuals who
                practice sharia law.
              </p>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6">
            <div className="card card6">
              <span>06</span>
              <h3>flexible and payment </h3>
              <p>
                Our users have the liberty to payback their loans with flexible
                options
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default FinanceOption;
