/* eslint-disable @typescript-eslint/ban-types */
import { motion } from 'framer-motion';
import { FC } from 'react';
import ScrollToTop from '../../../../components/ScrollToTop';
import LoansPersonalDetailsForm from '../../../../components/forms/LoansPersonalDetailsForm';

type Props = {
  next: () => void;
  x: number;
  setX: Function;
};

const PersonalDetails: FC<Props> = ({ next, x, setX }) => {
  return (
    <ScrollToTop>
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
        className="">
        <div className="bg-white">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 col-md-9 col-sm-10 bg-primary-blue mt-4 rounded">
              <h4>PERSONAL DETAILS</h4>
              <p>
                Industry regulation requires us to collect this information to
                verify your identity.
              </p>
            </div>
          </div>
          <LoansPersonalDetailsForm next={next} setX={setX} />
        </div>{' '}
      </motion.div>
    </ScrollToTop>
  );
};

export default PersonalDetails;
