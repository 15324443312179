/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import data from '../data.json';

type Props = {
  x: number;
  next: () => void;
};

const UpgradeDocumentsDetails: FC<Props> = ({ x, next }) => {
  const { loansStore } = React.useContext(StoreContext);
  const { profileStore } = useContext(StoreContext);
  const { message, submitting } = profileStore;
  const { sending, loanUserdetails } = loansStore;

  const [armDetails, setArmDetails] = useState<any>({
    idType:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.idType
        ? loanUserdetails?.armUserBankDetails?.idType
        : '',
    issueDateOfId:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.issueDateOfId
        ? loanUserdetails?.armUserBankDetails?.issueDateOfId
        : '',
    expiryDateOfId:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.expiryDateOfId
        ? loanUserdetails?.armUserBankDetails?.expiryDateOfId
        : '',
    utilityBillIdType:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.utilityBillIdType
        ? loanUserdetails?.armUserBankDetails?.utilityBillIdType
        : '',
    expiryDateOfUtilityBill:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.expiryDateOfUtilityBill
        ? loanUserdetails?.armUserBankDetails?.expiryDateOfUtilityBill
        : '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setArmDetails((prevDetails: any) => {
      if (element.type === 'number') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
  };

  const handleUpdateArmDetails = (e: React.FormEvent) => {
    e.preventDefault();
    profileStore.updateArmDocumenDetails(armDetails);
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Document updated successfully'
    ) {
      next();
    }
  }, [message.type, message.msg]);

  return (
    <motion.div
      className="page"
      initial={{ x: x > 0 ? 1000 : -1000 }} // Slide in from the right/left
      animate={{ x: 0 }} // Center
      exit={{ x: x > 0 ? -1000 : 1000 }} // Slide out to the left/right
      transition={{ duration: 0.5, ease: 'easeInOut' }} // Smooth transition
    >
      <div className="upgrade-arm-form px-2">
        {/* {submitting ? <Spinner /> : null} */}
        <h4>DOCUMENT DETAILS</h4>
        <p>
          Industry regulation requires us to collect this information to begin
          investment.
        </p>
        <div>
          <form action="" onSubmit={handleUpdateArmDetails}>
            <div className="row">
              <div className="col-md-6 left-side pe-md-3 pe-lg-4">
                <div className="col-md-12 input-box">
                  <label htmlFor="idType" className="mb-1 mt-3">
                    Identity Type
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="idType"
                    className="form-control"
                    id="idType"
                    onChange={handleInputChange}
                    required
                    disabled={submitting || sending ? true : false}
                    defaultValue={armDetails.idType}>
                    <option value="">Select option</option>
                    {data.idTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="issueDateOfId" className="mb-1 mt-3">
                    Identity Card Date Of Issue
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <Input
                    name="issueDateOfId"
                    classes="form-control"
                    type="date"
                    id="issueDateOfId"
                    onChange={handleInputChange}
                    required
                    disabled={submitting || sending ? true : false}
                    defaultValue={armDetails.issueDateOfId}
                  />
                </div>
              </div>
              <div className="col-md-6 left-side pe-md-3 pe-lg-4">
                <div className="col-md-12 input-box">
                  <label htmlFor="utilityBillIdType" className="mb-1 mt-3">
                    Type Of Utility Bill
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="utilityBillIdType"
                    className="form-control"
                    id="utilityBillIdType"
                    onChange={handleInputChange}
                    required
                    disabled={submitting || sending ? true : false}
                    defaultValue={armDetails.utilityBillIdType}>
                    <option value="">Select bill type</option>
                    {data.utilityBillTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                {armDetails.idType !== 'NIN' && (
                  <div className="col-md-12 input-box">
                    <label htmlFor="expiryDateOfId" className="mb-1 mt-3">
                      Identity Card Date Of Expiry
                    </label>
                    <span style={{ color: 'red' }}> *</span>
                    <Input
                      name="expiryDateOfId"
                      classes="form-control"
                      type="date"
                      id="expiryDateOfId"
                      onChange={handleInputChange}
                      required
                      disabled={submitting || sending ? true : false}
                      defaultValue={armDetails.expiryDateOfId}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="profile-btn d-md-flex flex-wrap flex-md-nowrap"
              style={{ width: '100%' }}>
              <div className="profile-btn" style={{ width: '100%' }}>
                <Button
                  classes="primary-btn"
                  disabled={sending}
                  content={
                    sending || sending ? 'Submitting...' : 'Save and Continue'
                  }
                  type="submit"
                  // onClick={next}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default observer(UpgradeDocumentsDetails);
