/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';

const DeleteAccount = () => {
  const { profileStore } = useContext(StoreContext);
  const { submitting, message } = profileStore;
  const redirect = useNavigate();
  const [reason, setReason] = useState<string>('');
  const handleDeleteRequest = async (e: any) => {
    e.preventDefault();
    profileStore.deleteAccountRequest(reason);
  };

  useEffect(() => {
    if (message.type === 'success') {
      redirect('/dashboard/settings/account_settings');
    }
  }, [message.type]);
  return (
    <div className="notification-settings">
      {submitting && <Spinner />}
      <h3>Delete Account Profile</h3>
      <div className="form-box px-4">
        <div className="row justify-content-center mt-4">
          <h5 className="text-danger text-center mb-4">
            <Icon
              path={mdiInformationOutline}
              size={1.2}
              color="#df4759"
              className="me-2"
            />
            Once request is processed your account can no longer be recovered.
          </h5>
          <form onSubmit={handleDeleteRequest}>
            <div className="row mb-4">
              <div className="col-md-12 input-box ">
                <label htmlFor="reason" className="mb-1 mt-3">
                  Why do you want to delete your account?
                </label>
                <div>
                  <Input
                    name="reason"
                    value={reason}
                    placeholder="Enter a reason"
                    classes="form-control"
                    required
                    type="text"
                    id="reason"
                    onChange={(e: any) => {
                      setReason(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <Button
              classes="primary-btn btn-lg btn-block w-100 px-4"
              content="Process Request"
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default observer(DeleteAccount);
