import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Marquee from 'react-fast-marquee';

const MarqueeComponent = () => {
  AOS.init();

  return (
    <div className="what-we-do">
      <div className="hs-backed">
        <Marquee speed={30} gradientColor={[239, 240, 246]} pauseOnHover={true}>
          <div className="hs-backed-imgs">
            <img
              src={`${BaseDirectories.LOGOS_DIR}/farmer1st.png`}
              alt="Trade Lenda"
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/onekiosk.png`}
              alt="Trade Lenda"
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/buffex.png`}
              alt="Trade Lenda"
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/smartTeller.png`}
              alt="Trade Lenda"
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/arm.png`}
              alt="Trade Lenda"
              style={{ paddingTop: '30px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/terminal.png`}
              alt="Trade Lenda"
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/boi.png`}
              alt="Trade Lenda"
              style={{ width: '280px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/eazipay.png`}
              alt="Trade Lenda"
              style={{ width: '200px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/fintech.png`}
              alt="Trade Lenda"
              style={{ width: '211px', paddingBottom: '10px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/payslice.png`}
              alt="Trade Lenda"
              style={{ width: '211px', paddingTop: '10px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/paystack.png`}
              alt="Trade Lenda"
              style={{ width: '211px', paddingTop: '10px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/zenith.png`}
              alt="Trade Lenda"
              style={{ width: '160px', padding: '0px' }}
            />
            <img
              src={`${BaseDirectories.LOGOS_DIR}/providus.png`}
              alt="Trade Lenda"
              style={{
                width: '235px',
                paddingBottom: '15px',
                paddingRight: '50px',
              }}
            />
          </div>
        </Marquee>
      </div>
    </div>
  );
};
export default MarqueeComponent;
