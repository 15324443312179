import { useEffect, useState } from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';

const HeroSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = [
    {
      id: 1,
      text: 'Business Loan',
      img: `${BaseDirectories.ICONS_DIR}/hero-business.png`,
      className: 'slider1',
      color: '#054b99',
      height: '58px',
    },
    {
      id: 2,
      text: 'Investment',
      img: `${BaseDirectories.ICONS_DIR}/hero-investment.png`,
      className: 'slider2',
      color: '#00ba88',
      height: '59px',
    },
    {
      id: 3,
      text: 'Payment',
      img: `${BaseDirectories.ICONS_DIR}/hero-payment.png`,
      className: 'slider3',
      color: '#f4b740',
      height: '60px',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="container">
      <div className="hero-slider-container">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className="slider"
            style={{
              backgroundColor: slide.color,
              height: slide.height,
              zIndex: slides.length - index,
              opacity: activeIndex === index ? 1 : 0,
              transform:
                activeIndex === index ? 'translateY(10%)' : 'translateY(10%)',
              transition: 'opacity 1s, transform 1s',
            }}>
            <div className="card-content">
              <img className="icon" src={slide.img} alt={slide.text} />
              <p className="text">{slide.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
