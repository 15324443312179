import React from 'react';
import { Outlet } from 'react-router';

const Guarantors = () => {
  return (
    <div className="guarantors">
      <Outlet />
    </div>
  );
};

export default Guarantors;
