/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  datadogLogs,
  Logger,
} from '@datadog/browser-logs';

export class BrowserLogger {
  private logger: Logger;
  private context: string;

  constructor(context: string) {
    this.logger = datadogLogs.logger;
    this.context = context;
  }

  info(message: string, metadata: Record<string, any>) {
    try {
      metadata['context'] = this?.context;
      metadata['title'] = message;
      return this.logger.info(message, metadata);
    } catch (err) {}
  }
  warn(message: string, metadata: Record<string, any>) {
    try {
      metadata['context'] = this?.context;
      metadata['title'] = message;
      return this.logger.warn(message, metadata);
    } catch (err) {}
  }
  debug(message: string, metadata: Record<string, any>) {
    try {
      metadata['context'] = this?.context;
      metadata['title'] = message;
      return this.logger.debug(message, metadata);
    } catch (err) {}
  }
  error(message: string, metadata: Record<string, any>) {
    try {
      metadata['context'] = this?.context;
      metadata['title'] = message;
      return this.logger.error(message, metadata);
    } catch (err) {}
  }
}
