function SignatureWithH4({ image, name }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}>
      <img
        src={image}
        alt="Signature"
        style={{
          maxWidth: '200px',
          marginBottom: '-100px',
        }}
      />
      <h4>______________________</h4>
      <h4
        style={{
          fontSize: '16px',
        }}>
        {name}
      </h4>
    </div>
  );
}

export default SignatureWithH4;
