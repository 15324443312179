import React from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Blank from './sections/Blank';
import Footer from './sections/Footer';
import Header from './sections/Header';
import Newsletter from './sections/Newsletter';
import { Helmet } from 'react-helmet-async';
import BlueCard from './sections/BlueCard';
import StatsSection from './sections/StatsSection';

const InsurancePreview = () => {
  return (
    <div className="bg-white insurance-preview">
      <Helmet>
        <title>Insurance Preview | Trade Lenda Insurance Preview!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section>
        <div className="landing-page-hero">
          <div className="hero-cont">
            <div className="container">
              <div className="row d-flex justify-content-end iph-container">
                <div className="hero-image"></div>
                <div className="hero-textt">
                  <h1>Protect your business, get insured</h1>
                  <p>
                    We offer you an opportunity to secure everything that
                    matters in your business
                  </p>
                  <Button
                    classes="primary-btn btn-lg"
                    content="Get Insured"
                    route="/arm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Blank height="50px" />
      <section className="container">
        <div className="second-section">
          <div className="text-center">
            <h1>Our Insurance Options</h1>
          </div>
          <div className="row">
            <section className="content-sect fourth col-lg-4">
              <div className="d-flex flex-column sect-flx-group">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/inImg4.png`}
                    alt=""
                  />
                </div>
                <div className="sect-flx-txt hero-text">
                  <div>
                    <h3>Health Insurance</h3>
                    <p>
                      Worried about the financial burden of unexpected events
                      like illness, accident or death? Protect your family's
                      financial stability with Credit Life Assurance
                    </p>
                  </div>
                  <Button
                    classes="primary-btn btn-lg"
                    content="Get Insured"
                    route="/arm"
                  />
                </div>
              </div>
            </section>

            <section className="content-sect third col-lg-4">
              <div className="d-flex flex-column sect-flx-group">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/inImg3.png`}
                    alt=""
                  />
                </div>
                <div className="sect-flx-txt hero-text">
                  <div>
                    <h3>Goods-in-Transit Insurance</h3>
                    <p>
                      Our insurance provides peace of mind, knowing that your
                      valuable goods are protected during every step of the
                      journey.
                    </p>
                  </div>
                  <Button
                    classes="primary-btn btn-lg"
                    content="Insure Your Business"
                    route="/arm"
                  />
                </div>
              </div>
            </section>

            <section className="content-sect second col-lg-4">
              <div className="d-flex flex-column sect-flx-group">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/inImg2.png`}
                    alt=""
                  />
                </div>
                <div className="sect-flx-txt hero-text">
                  <div>
                    <h3>Fire and Burglary insurance (Shop insurance)</h3>
                    <p>
                      Don't let the fear of burglars or theft keep you up at
                      night. Protect your business and valuables with our
                      comprehensive burglary Insurance
                    </p>
                  </div>
                  <Button
                    classes="primary-btn btn-lg"
                    content="Insure Your Business"
                    route="/arm"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* <section className="content-sect first">
        <div className="container">
          <div className="row sect-flx-group ">
            <div className="sect-flx-txt hero-text col-lg-5">
              <div>
                <h1>Secure Your Future with Credit Life Assurance</h1>
                <p>
                  Worried about the financial burden of unexpected events like
                  illness, accident or death? Protect your family's financial
                  stability with Credit Life Assurance
                </p>
              </div>

              <Button
                classes="primary-btn btn-lg"
                content="Insure Your Business"
                route="/arm"
              />
            </div>
            <div className="col-lg-5">
              <div className="hero-img">
                <img src={`${BaseDirectories.IMAGES_DIR}/inImg1.png`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <BlueCard />
      <StatsSection />
      <Blank height="150px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default InsurancePreview;
