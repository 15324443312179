import React from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from './Button';

const ComingSoon = () => {
  return (
    <div className="pb-3">
      <div className="error_class">
        <div className="err-text">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/404.png`}
            alt="Trade Lenda"
          />
        </div>
        <div className="err-img-500">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/404error.png`}
            alt="Trade Lenda"
          />
          <h1>Coming Soon</h1>
          <p>This page is under construction, please bear with us.</p>
          <Button
            classes="primary-btn btn-lg me-lg-3 mx-3"
            content="Go Back"
            onClick={() => window.history.back()}
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
