/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  heading: string;
  links: any;
};

const Dropdown: FC<Props> = ({ heading, links }) => {
  return (
    <div className="header-dropdown">
      <h6>
        <Icon path={mdiCircleSmall} size={2} />
        <span>{heading}</span>
      </h6>
      <div className="dropdown-links">
        {links &&
          links.map(
            (link: any, i: number) => (
              // link.url.includes('http') ? (
              <NavLink
                to={link.url}
                key={i}
                onClick={close}
                className="dropdown-link">
                <div className="d-flex align-items-start gap-3">
                  <span className="link-icon">
                    <Icon path={link.icon} size={0.8} />
                  </span>

                  <div>
                    <span className="link-text" style={{ fontWeight: 'bold' }}>
                      {link.text}
                    </span>
                    <div className="sample">
                      <p className="link-description">{link.description}</p>
                    </div>
                  </div>
                </div>
                {link.comingSoon && (
                  <span className="badge rounded-pill text-bg-light">
                    Coming Soon
                  </span>
                )}
              </NavLink>
            ),
            // ) : (
            // ),
          )}
      </div>
    </div>
  );
};

export default Dropdown;
