const levels = {
  info: { color: 'blue', prefix: '[INFO]' },
  warn: { color: 'orange', prefix: '[WARN]' },
  error: { color: 'red', prefix: '[ERROR]' },
  debug: { color: 'green', prefix: '[DEBUG]' },
};

// Get the current environment and log level
const currentEnv = process.env.REACT_APP_ENV || 'production';
const logLevel = process.env.REACT_APP_LOG_LEVEL || 'info';

// Log level hierarchy
const levelPriority = { error: 0, warn: 1, info: 2, debug: 3 };

/**
 * Check if a log level should be logged based on the current environment and log level.
 * @param {string} level - Log level
 * @returns {boolean} - Whether to log the message
 */
const shouldLog = (level: string) => {
  return (
    currentEnv === 'development' ||
    levelPriority[level] <= levelPriority[logLevel]
  );
};

/**
 * Custom log function
 * @param {string} level - Log level (info, warn, error, debug)
 * @param {string} message - Log message
 * @param {object} [meta] - Additional metadata
 */
const log = (level: string, message: string, meta = null) => {
  if (!shouldLog(level)) return;

  const { color, prefix } = levels[level] || {};
  const timestamp = new Date().toISOString();

  if (!color) {
    console.log(`[${timestamp}] ${message}`, meta || '');
    return;
  }

  // Log the formatted message
  console.log(
    `%c${prefix} [%c${timestamp}%c] %c${message}`,
    `color: ${color}; font-weight: bold;`,
    'color: gray;',
    `color: ${color}; font-weight: bold;`,
    'color: inherit;',
  );

  // Log additional metadata, if provided
  if (meta) {
    console.log('%cAdditional Info:', 'color: gray; font-style: italic;', meta);
  }
};

// Shorthand methods for specific log levels
const logger = {
  info: (msg: string, meta?: any) => log('info', msg, meta),
  warn: (msg: string, meta?: any) => log('warn', msg, meta),
  error: (msg: string, meta?: any) => log('error', msg, meta),
  debug: (msg: string, meta?: any) => log('debug', msg, meta),
};

export default logger;
