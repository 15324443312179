/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { FC } from 'react';

type Props = {
  name: string;
  val: any;
  handleChange: any;
};

const ToggleSwitch: FC<Props> = ({ name, val, handleChange }) => {
  return (
    <>
      <label
        htmlFor={name}
        className={val[name] ? `switch-toggle-bg` : `switch-toggle-bg false`}>
        <input
          type="checkbox"
          name={name}
          onChange={handleChange}
          id={name}
          value={val[name]}
        />
        <span className={val[name] ? undefined : `false`}></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
