/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { FC } from 'react';

type Props = {
  name: string;
  classes: string;
  type: string;
  accept?: string;
  id?: string;
  onChange?: any;
  onKeyPress?: any;
  onPaste?: any;
  onFocus?: any;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  readonly?: boolean;
  style?: any;
  minLength?: number;
  maxLength?: number;
  defaultValue?: string;
  min?: any;
  max?: any;
  autoComplete?: string;
  checked?: boolean;
};

const Input: FC<Props> = (props) => {
  const {
    name,
    classes,
    type,
    accept,
    id,
    onChange,
    onKeyPress,
    onFocus,
    onPaste,
    value,
    disabled,
    placeholder,
    required,
    readonly,
    style,
    maxLength,
    minLength,
    defaultValue,
    min,
    max,
    autoComplete,
    checked,
    ...otherProps
  } = props;

  return (
    <>
      <input
        name={name}
        type={type}
        id={id}
        accept={accept}
        className={classes}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        onFocus={onFocus}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        style={style}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        min={min}
        max={max}
        autoComplete={autoComplete}
        checked={checked}
        {...otherProps}
      />
    </>
  );
};

export default Input;
