import React from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from './Button';

const Error500 = () => {
  return (
    <div>
      <div className="error_class">
        <div className="err-text">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/500.png`}
            alt="Trade Lenda"
          />
        </div>
        <div className="err-img-500">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/500error.png`}
            alt="Trade Lenda"
          />
          <h1>internal server error</h1>
          <p>We are sorry you do not have permission to access this page.</p>
          <Button
            classes="primary-btn btn-lg me-lg-3 mx-3"
            content="Go Back"
            onClick={() => window.history.back()}
          />
        </div>
      </div>
    </div>
  );
};

export default Error500;
