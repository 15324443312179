/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import ReactToPrint from 'react-to-print';
import BaseDirectories from '../../../base directories/BaseDirectories';
import SignatureWithH4 from '../../../components/SignatureWithH4';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';

// import { ComponentToPrint } from './ComponentToPrint';

interface LocationState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const OfferLetter = () => {
  const [allLoans, setAllLoans] = useState<any>([]);
  const [loanDetails, setLoanDetails] = useState<any>({});
  const [loanState, setLoanState] = useState<any>({});
  const [userGuarantor, setUserGuarantor] = useState<any>({});
  const location: LocationState = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const converter = require('number-to-words');
  const componentRef: MutableRefObject<any> = useRef();

  const { loansStore, guarantorStore } = useContext(StoreContext);
  const { loading, message, loanUserdetails } = loansStore;
  const { guarantors } = guarantorStore;

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  useEffect(() => {
    // allLoans.filter((a: any) => a._id === location?.state?.loan?._id)[0];
    setLoanDetails(
      allLoans.filter((a: any) => a._id === location?.state?.loan?._id)[0],
    );
    setLoanState(location?.state?.loanState);
  }, [location, loansStore, allLoans]);

  useEffect(() => {
    const unsub = () => {
      guarantorStore.getGuarantors();
      guarantorStore.setGuarantors;
      setUserGuarantor(guarantors[0]);
    };
    return () => unsub();
  }, [location]);

  useEffect(() => {
    loansStore.getLoanById(location?.state?.loan?._id);
    loansStore.getAllLoans();
  }, [loansStore, location]);

  useEffect(() => {
    setAllLoans(toJS(loansStore.loans));
  }, [loansStore.loans]);

  const handleAcceptLoan = () => {
    if (loanDetails._id.length > 0) {
      loansStore.acceptLoan(loanDetails._id);
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getCurrentDate = () => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${date} ${monthList[month]}, ${year}`;
  };

  useEffect(() => {
    if (message.msg === 'You have successfully accepted loan.') {
      loansStore.getAllLoans();
      loansStore.getLoanById(loanDetails._id);
    }
  }, [message.type]);

  return (
    <div className="">
      {loading && <Spinner />}
      <div className="loans-container bg-white offer-letter pb-4">
        <div className="bg-white p-md-5 p-sm-4 p-3" ref={componentRef}>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6>{getCurrentDate()}</h6>
              <img
                style={{ width: '120px' }}
                src={`${BaseDirectories.LOGOS_DIR}/logo1.png`}
                alt=""
              />
            </div>
            <h6 className="mb-1">THE MANAGING DIRECTOR</h6>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessName},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessAddress},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.city},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.state}.
            </p>
          </div>
          <h6 className="my-4">
            Attention: {`${loanDetails?.firstName} ${loanDetails?.lastName}`}
          </h6>
          <p>Dear Sir/Ma,</p>
          <div>
            <h5
              className="text-center"
              style={{ textTransform: 'uppercase', justifyContent: 'center' }}>
              {loanDetails?.reason} AGREEMENT
            </h5>
            <p
              className="mb-3 text-center"
              style={{ textTransform: 'uppercase', justifyContent: 'center' }}>
              (MURABAHA STRUCTURE)
            </p>
            <p>
              <b style={{ textTransform: 'uppercase' }}>
                THIS {loanDetails?.reason} AGREEMENT
              </b>{' '}
              (this “Agreement”) is made on {getCurrentDate()}
            </p>
            <p>
              <b>BETWEEN</b>
            </p>
            <p>
              <b>TRADE LENDA COM LIMITED RC 1793019</b>, a company incorporated
              under the laws of the Federal Republic of Nigeria and having its
              registered head office at 9B Ike Asogwa Close, Maryland, Ikeja,
              Lagos State (hereinafter referred to as{' '}
              <b>“TRADE LENDA or FINANCIER”</b> which expression shall, where
              the context so admits, include its successors in title and
              assigns), of the first part.
            </p>
            <p>
              <b>AND</b>
            </p>
            <p>
              <b>{`${loanDetails?.firstName} ${loanDetails?.lastName}`}</b> of{' '}
              <b>
                {loanDetails?.loanDetails?.organizationDetails?.businessName}
              </b>{' '}
              (hereinafter referred to as <b>“Client”</b>) which expression
              shall where the context so admits include its successors-in-title,
              principal officers and authorized assigns of the second part.
            </p>
          </div>

          <div>
            <h5 className="my-3">
              <b>WHEREAS:</b>
            </h5>
            <div
              className="d-flex justify-content-between term-group align-items-center mb-3"
              style={{ lineHeight: 2 }}>
              <ol type="A">
                <li>
                  Trade Lenda is a company which offers credit to customers to
                  finance contracts involving the supply or delivery of visible
                  items or products with specified contract agreements.
                </li>
                <li>
                  The Client is an end user customer looking to purchase assets
                  from a third party for either personal or business purposes.
                </li>
                <li>
                  The Financier desires to finance the purchase of{' '}
                  <u>asset/inventory</u> for the client from the desired third
                  party and to sell to the customer at the original price
                  inclusive of a prearranged markup, in furtherance of the
                  Client‘s business or personal use. (“Murabaha structure”) in
                  accordance with the law and the terms and conditions of this
                  Agreement.
                </li>
                <li>
                  The Client is willing to accept the financing offered by the
                  Financier in accordance with the terms and conditions agreed
                  herein.
                </li>
                <li>
                  The Customer and the Financier have prior knowledge of this
                  agreement and have carefully ensured that the terms expressed
                  reflect the intention of the parties and have consequently
                  agreed to abide by its content.
                </li>
              </ol>
            </div>
            <h5 className="my-3">
              <b>NOW IT IS HEREBY MUTUALLY AGREED</b> as follows:
            </h5>
            <div
              className="d-flex justify-content-between term-group align-items-center mb-3"
              style={{ lineHeight: 2 }}>
              <ol className="nestedOl">
                <li>
                  <p className="underline-text">
                    OBLIGATION OF PARTIES, TENOR AND PAYMENT TERMS
                  </p>
                  <ol>
                    <li>
                      The Financier will facilitate the procurement of the
                      assets/inventory to the tune of{' '}
                      <b>
                        {loanDetails?.amount &&
                          converter.toWords(loanDetails?.amount)}{' '}
                        naira (₦
                        {loanDetails?.amount?.toLocaleString()}){' '}
                      </b>
                      {'  '}
                      being the cost price of the asset/inventory from a third
                      party after the date of the party last signing this
                      agreement (Effective Date) based on the agreed tenor and
                      rate between parties with consideration to the payment
                      plan in schedule 1 of this agreement.
                    </li>
                    <li>
                      The cost price of the assets/inventory to be purchased by
                      the Financier and the mark up to be taken by the Financier
                      will be expressly disclosed to the client in schedule 2 of
                      this agreement.
                    </li>
                    <li>
                      The Financier will receive delivery of the
                      assets/inventory from the third party after purchase and
                      will immediately sell the assets/inventory to the client
                      with the markup price.
                    </li>
                    <li>
                      The Financier will not withhold possession of the
                      assets/inventory from the client in lieu of the full
                      payment of the debt. The Financier can, however, delay the
                      registration of the assets/inventory in the financier’s
                      name until full and final payment is made.
                    </li>
                    <li>
                      The term of the financing shall be{' '}
                      {loanDetails?.loanTenor
                        ? Number(loanDetails?.loanTenor.split(' ')[0]) / 30
                        : 'NIL'}{' '}
                      months <b>(Term) </b> commencing from the date the
                      assets/inventory is purchased from the third party on
                      behalf of the client.
                    </li>
                    <li>
                      Parties agree that the assets to be procured by the Client
                      will be procured by the Financier under Trade Lenda’s
                      Company name and sold to the client afterwards.
                    </li>
                    <li>
                      The client agrees to set up a Trade Lenda Account through
                      which payments for the assets/inventory will be made to
                      the financier.
                    </li>
                    <li>
                      The client agrees to make payments of the cost price and
                      the financier’s profit mark-up in accordance with the
                      agreed payment plan and tenor as provided for in schedule
                      1 of this agreement.
                    </li>
                    <li>
                      The client shall promptly pay the financier on or before
                      the agreed expiry date through the client’s Trade Lenda
                      Account which will be set up by the client.{' '}
                    </li>
                    <li>
                      The client agrees to promptly pay the financing amount
                      within the term and agrees that failure to pay the
                      financing within the stipulated tenor shall amount to a
                      default on this agreement. Nothing in this agreement shall
                      preclude the client from making an early payment of the
                      financing before its maturity date.{' '}
                    </li>
                    <li>
                      The client hereby represents that it is a duly formed and
                      validly existing entity with the capacity to enter into
                      this agreement.{' '}
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">DEFAULT</p>
                  <ol>
                    <li>
                      Where the client defaults in making payment of the cost
                      price and mark-up fee upon the same becoming due and
                      payable, the Financier shall have the sole right to impose
                      a penalty fee of <mark>1% </mark> which will be used
                      strictly for charitable purposes as advised by the
                      Financier’s Shariah Adviser.
                    </li>
                    <li>
                      In the event of a default by the Client and in so far as
                      the client is unable to prove that the default is due to
                      severe hardship on the client, the Client will be{' '}
                      <mark>prosecuted </mark> to the full extent of sharia law.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">MISCELLANEOUS</p>
                  <ol>
                    <li>
                      This agreement shall be deemed terminated upon full
                      payment of the financing amount by the Client to the
                      Financier.
                    </li>
                    <li>
                      No waiver or amendment to this Agreement shall be binding
                      upon the Client and the Financier unless it is in writing
                      and duly executed by both Parties.
                    </li>
                    <li>
                      No term shall survive at the expiry or termination of this
                      Agreement unless expressly provided.
                    </li>
                    <li>
                      No delay or failure by either party to exercise any of its
                      powers, rights or remedies under this Agreement will
                      operate as a waiver of them, nor will any single or
                      partial exercise of any such powers, rights or remedies
                      preclude any other or future exercise of them.
                    </li>
                    <li>
                      3.5. The Client shall not assign its rights, obligations,
                      or liabilities under this Agreement to any third Party
                      without the prior written approval of the Financier first
                      sought and obtained, and such approval shall not be
                      unreasonably withheld.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">NOTICES</p>
                  <ol>
                    <li>
                      Any notice or demand or information required to be given
                      under this agreement shall be in writing and should be
                      delivered personally or sent via electronic email to such
                      known addresses of the Client and Financier at the time of
                      execution of this contract. The Client must inform the
                      Financier of any change in address.
                    </li>
                    <li>
                      A Notice given in accordance with this agreement will be
                      effective upon receipt by the Party to which it is given
                      and if by personal delivery or, if emailed, upon automated
                      confirmation of receipt of the notice.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">
                    GOVERNING LAW AND DISPUTE RESOLUTION
                  </p>
                  <ol>
                    <li>
                      The construction, performance, execution and enforcement
                      of this Agreement and any dispute of whatsoever nature
                      arising in connection with this Agreement or performance
                      under it, including any remedy thereof, shall be governed
                      exclusively by the laws of the Federal Republic of
                      Nigeria, and the Shariah Commercial Law as interpreted by
                      the Financier’s Shariah Adviser’s Advisory Committee of
                      Experts (ACE).
                    </li>
                    <li>
                      In the event of any disputes, controversy or claim arising
                      out of, in relation to and/or in connection with this
                      Agreement or the duties or obligations to be observed and
                      performed in this Agreement, or the breach thereof, the
                      Parties hereto shall endeavor to resolve same between them
                      amicably.
                    </li>
                    <li>
                      If the Parties fail to resolve any dispute within thirty
                      (30) days, then such dispute shall be determined by
                      arbitration administered by the Lagos State Multi Door
                      Courthouse.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="mb-1 text-justify" style={{ textAlign: 'justify' }}>
            <p className="mb-0">
              <b>IN WITNESS WHEREOF</b> the parties hereto have set their hands
              and seals the day and year first above written.
            </p>
          </div>
          <div className="mb-5 text-justify" style={{ textAlign: 'justify' }}>
            <p className="mb-5">
              Signed by the authorized representatives of{' '}
              <b>TRADE LENDA COM LIMITED</b>
            </p>
            <div className="d-flex justify-content-between text-center signature">
              <div
                className="dir"
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center -100px',
                  height: '70px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  lineHeight: '0px',
                }}>
                <SignatureWithH4
                  image={`${BaseDirectories.IMAGES_DIR}/Adeshina Signature-01.png`}
                  name={`Adeshina Adewunmi`.toUpperCase()}
                />
              </div>
            </div>
          </div>

          <div className="mb-5 text-justify" style={{ textAlign: 'justify' }}>
            <p>
              Signed by the authorized representatives of{' '}
              <b>
                {loanDetails?.loanDetails?.organizationDetails?.businessName.toUpperCase()}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between text-center signature">
            <div
              className="dir"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center -100px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                lineHeight: '0px',
              }}>
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}>
                <span className="pt-4 pb-0">{`${loanDetails?.firstName} ${loanDetails?.lastName}`}</span>

                <h4>______________________</h4>
              </p>
              <h5>DIRECTOR</h5>
            </div>
            <div
              className="sec"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center -104px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}>
                <span className="pt-4 pb-0">{`${
                  userGuarantor?.firstName
                    ? userGuarantor?.firstName
                    : loanDetails?.firstName
                } ${
                  userGuarantor?.lastName
                    ? userGuarantor?.lastName
                    : loanDetails?.lastName
                }`}</span>
                <h4>______________________</h4>
                <h5>DIRECTOR/GUARANTOR</h5>
              </p>
            </div>
          </div>
          <div
            className="mt-5 mb-1 text-justify"
            style={{ textAlign: 'justify' }}>
            <table>
              <tr>
                <th>
                  <mark>MURABAHA TENOR</mark>
                </th>
                <th>
                  <mark>AMOUNT TO BE PAID</mark>
                </th>
              </tr>
              <tr>
                <td>
                  {loanDetails?.loanTenor
                    ? Number(loanDetails?.loanTenor.split(' ')[0]) / 30
                    : 'NIL'}{' '}
                  Months
                </td>
                <td>
                  ₦{' '}
                  {location?.state?.loanState?.totalrepaymentAmount?.toLocaleString() ||
                    0}
                </td>
              </tr>
              {/* <tr>
                <td> </td>
                <td> </td>
              </tr> */}
            </table>
          </div>
          <div
            className="mt-5 mb-1 text-justify"
            style={{ textAlign: 'justify' }}>
            <b>Payment Plan</b>
            <p>
              Payment can be made in full on or before the expiry date from the
              date of receipt of the assets/inventory.
            </p>
          </div>
        </div>
        <div className="row justify-content-center text-center mt-4">
          <div className="col-lg-8 col-md-8">
            <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
              {loanDetails?.approvalStatus === 'approved' &&
                // !toJS(loansStore.loanId).acceptLoanOffer &&
                // authorized &&
                !toJS(loansStore.loanId).acceptLoanOffer && (
                  <Button
                    disabled={toJS(loansStore.loanId).acceptLoanOffer}
                    classes="primary-btn btn-lg px-4"
                    content={
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? 'Accept Facility'
                        : 'Accept Loan'
                    }
                    onClick={handleAcceptLoan}
                  />
                )}
              <ReactToPrint
                trigger={() => (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Print"
                  />
                )}
                content={() => componentRef.current}
              />
              {(loanState?.acceptLoanOffer ||
                loanState?.acceptLoanOfferDate?.length > 0 ||
                toJS(loansStore.loanId).acceptLoanOffer) && (
                <Button
                  classes="btn-success btn-lg px-4"
                  disabled={true}
                  content="Accepted"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OfferLetter);
