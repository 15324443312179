/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import Spinner from '../../../../components/ui/Spinner';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import logger from '../../../../common/logger/CustomLogger';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  setOpenPaymentModal: (state: any) => void;
};

const TopUpModal: FC<Props> = ({
  onClick = () => {
    setOpenPaymentModal(false);
  },
  investmentData,
  setOpenPaymentModal,
}) => {
  const { investStore, walletStore } = useContext(StoreContext);
  const { multipleWallet } = walletStore;
  const { message, submitting, submittingInvestment, lendaPlans } = investStore;
  const [errorMessage, setErrorMessage] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  const navigate = useNavigate();
  const [investData, setInvestData] = useState<any>();
  const [investmentDetails, setInvestmentDetails] = useState({
    id: investmentData?._id,
    topUpAmount: '0',
    transactionPin: '',
    fromWalletIdAccountNumber: '',
  });
  const [showBankSelection, setShowBankSelection] = useState(true);
  useEffect(() => {
    const val = toJS(lendaPlans).filter(
      (plan: any) => plan?.investmentName === investmentData.investmentType,
    );
    setInvestData(val);
  }, []);

  const updateData = (element: any) => {
    const e = element.target as HTMLInputElement;
    if (e.name === 'topUpAmount') {
      const rawValue = e.value.replace(/,/g, '');
      if (/^\d*\.?\d*$/.test(rawValue)) {
        const formattedValue = Number(rawValue).toLocaleString();
        setErrorMessage('');
        setInvestmentDetails((prevDetails: any) => {
          return {
            ...prevDetails,
            [e.name]: formattedValue,
          };
        });
      } else {
        setErrorMessage('Enter a valid amount');
      }
    } else {
      setInvestmentDetails((prevDetails: any) => {
        return {
          ...prevDetails,
          [e.name]: e.value,
        };
      });
    }
  };

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleSubmit = () => {
    // console.log('Testing');
    const removeCommaInAmount = investmentDetails.topUpAmount.replace(/,/g, '');
    if (
      Number(removeCommaInAmount) <
        Number(investmentData?.amountRange?.minAmount) ||
      Number(removeCommaInAmount) <= 0
    ) {
      toast.error('Investment amount is below minimum allowed investment');
      return;
    }
    if (
      Number(removeCommaInAmount) >
      Number(investmentData?.amountRange?.maxAmount)
    ) {
      toast.error('Investment amount is above maximum allowed investment');
      return;
    }
    if (
      investmentDetails.fromWalletIdAccountNumber == '' ||
      investmentDetails.fromWalletIdAccountNumber == null ||
      investmentDetails.fromWalletIdAccountNumber == undefined ||
      investmentDetails.fromWalletIdAccountNumber.length < 10
    ) {
      toast.error('Wallet not selected!');
      return;
    }
    const param = {
      id: investmentDetails.id,
      topUpAmount: removeCommaInAmount,
      transactionPin: investmentDetails.transactionPin,
      fromWalletIdAccountNumber: investmentDetails.fromWalletIdAccountNumber,
    };

    logger.debug('param', param);
    //Mary uncomment this out
    investStore.topUpLendaInvestment(param);
  };

  const disableIt =
    investmentDetails?.topUpAmount.length > 1 &&
    investmentDetails?.transactionPin.length == 4;

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment Top-Up successful.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [submittingInvestment, message]);

  return (
    <div className="m-m-fund container">
      {submitting || submittingInvestment ? <Spinner /> : <></>}
      <div className="notification-modal">
        <div
          className="bg-white notification-box"
          style={{
            // textAlign: 'left',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            alignContent: 'center',
            display: 'flex',
            // padding: '10px',
          }}>
          <span
            onClick={() => {
              setOpenPaymentModal(false);
            }}
            className="close"
            role="button">
            <FaTimes />
          </span>

          {/* Investment Form */}
          <div className="row justify-content-center amount-box">
            {/* // BANK SELECTION */}
            {showBankSelection && (
              <motion.div
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -200, opacity: 0 }}
                className="sliding-input">
                <div className="row justify-content-center invest-box">
                  <h5
                    className="my-3"
                    style={{ fontSize: '20px', textTransform: 'uppercase' }}>
                    Select a wallet
                  </h5>
                  <div className="col-12">
                    {toJS(multipleWallet) && toJS(multipleWallet)?.length > 0
                      ? toJS(multipleWallet)?.map(
                          (walletData: any, index: number) => (
                            <div className="w-100 gap-2 mt-2" key={index}>
                              <h6
                                className="wallet-deets d-flex align-items-start my-3 color-heading"
                                style={{
                                  fontSize: '14px',
                                  textTransform: 'uppercase',
                                }}>
                                Wallet {index + 1}
                              </h6>
                              <div
                                className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets invest-selection"
                                onClick={() => {
                                  if (walletData?.availableBalance < 100) {
                                    toast.error(
                                      'Amount must be greater than ₦100',
                                    );
                                  } else {
                                    setInvestmentDetails({
                                      id: investmentData?._id,
                                      topUpAmount: '0',
                                      transactionPin: '',
                                      fromWalletIdAccountNumber:
                                        walletData?.walletIdAccountNumber,
                                    });
                                    setShowBankSelection(false);
                                  }
                                }}>
                                <h6>{walletData?.banker}</h6>
                                <p>
                                  ₦{' '}
                                  {walletData?.availableBalance != 'NaN' ||
                                  walletData?.availableBalance != null ||
                                  walletData?.availableBalance != undefined
                                    ? walletData?.availableBalance
                                      ? new Intl.NumberFormat('en-US', {
                                          style: 'decimal',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(walletData?.availableBalance)
                                      : '0.00'
                                    : '0.00'}
                                </p>
                              </div>
                            </div>
                          ),
                        )
                      : null}
                  </div>
                </div>
              </motion.div>
            )}
            {!showBankSelection && (
              <motion.div
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -200, opacity: 0 }}
                className="sliding-input">
                <div className="row justify-content-center invest-box">
                  <div className="col-12">
                    <h5
                      className="my-2"
                      style={{
                        fontSize: '20px',
                      }}>
                      Enter amount
                    </h5>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text bg-white"
                          style={{
                            borderRight: '0',
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                          }}>
                          ₦
                        </span>
                      </div>
                      <Input
                        style={{ borderLeft: '0' }}
                        name="topUpAmount"
                        classes="form-control text-center required"
                        type="text"
                        id="topUpAmount"
                        value={investmentDetails.topUpAmount}
                        onChange={updateData}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                      }}>
                      {errorMessage !== '' ? (
                        <small
                          className="text-danger"
                          style={{ fontSize: '14px' }}>
                          {errorMessage}
                        </small>
                      ) : null}
                      <p style={{ fontSize: '14px' }}>
                        Investment Range = ₦{' '}
                        {investData && investData[0]?.amountRange
                          ? new Intl.NumberFormat('en-US', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 9,
                            }).format(investData[0]?.amountRange?.minAmount)
                          : '0.00'}{' '}
                        -{' '}
                        {investData && investData[0]?.amountRange
                          ? new Intl.NumberFormat('en-US', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 9,
                            }).format(investData[0]?.amountRange?.maxAmount)
                          : '0.00'}
                      </p>
                    </div>

                    <h5
                      className="my-2"
                      style={{
                        fontSize: '20px',
                      }}>
                      Transaction Pin
                    </h5>
                    <div className="col-md-12 input-box justify-content-center ">
                      <div>
                        <OtpInput
                          value={investmentDetails.transactionPin}
                          onChange={(text) => {
                            setInvestmentDetails((prevDetails: any) => {
                              return {
                                ...prevDetails,
                                ['transactionPin']: text,
                              };
                            });
                          }}
                          numInputs={4}
                          inputStyle="pin-style"
                          containerStyle="pin-container-style"
                          inputType={pinToggle ? 'tel' : 'password'}
                          shouldAutoFocus={true}
                          renderInput={(props) => (
                            <input onKeyPress={validateNumber} {...props} />
                          )}
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center align-content-center mb-3">
                      <input
                        type="checkbox"
                        onChange={handlePinToggle}
                        name="showPin"
                        id="show-pin"
                      />
                      <label htmlFor="show-pin">Show pin</label>
                    </div>
                    <div className="group-btn justify-content-center">
                      <Button
                        classes="primary-btn w-auto btn-lg waves-effect"
                        content="Back"
                        onClick={() => setShowBankSelection(true)}
                        type="button"
                      />
                      <Button
                        classes="primary-btn w-auto btn-lg waves-effect"
                        content="Top-UP Investment"
                        // route={route}
                        type="submit"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={!disableIt}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TopUpModal);
