import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/ui/Button';

const FutureInsurance = () => {
  return (
    <div className="insurance">
      <div className="section-head">Insurance</div>
      <div className="insurance-container">
        <Link to="/dashboard/insurance">
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="get-insured">
          <h4>GET INSURED</h4>

          <form action="">
            <div className="box">
              <div className="box-1">
                <label htmlFor="Reason">
                  What do you need the insurance for?{' '}
                </label>
                <select name="" id="">
                  <option value="">Future Insurance </option>
                </select>

                <label htmlFor="Cover type">Cover Type </label>
                <select name="" id="">
                  <option value="">Car Cover</option>
                </select>

                <label htmlFor="Car type">Car Type </label>
                <select name="" id="">
                  <option value="">Sedan</option>
                </select>

                <label htmlFor="plate number">Car Plate Number </label>
                <input
                  type="text"
                  placeholder="Plate Number"
                  value=""
                  name=""
                />

                <label htmlFor="Car Year">Car Year </label>
                <input type="text" placeholder="2000" value="" name="" />
              </div>

              <div className="box-2">
                <label htmlFor="document">Upload Car Document </label>
                <div className="file-input">
                  <div>
                    <input name="id" type="file" id="id" />
                    <p>
                      Drag your file here or <label htmlFor="id">browse</label>
                    </p>
                  </div>
                  <p className="format">
                    File format JPEG, PNG, PDF Size 15mb max
                  </p>
                </div>

                <label htmlFor="Car Images">Upload Car Images </label>
                <div className="file-input">
                  <div>
                    <input name="id" type="file" id="id" />
                    <p>
                      Drag your file here or <label htmlFor="id">browse</label>
                    </p>
                  </div>
                  <p className="format">
                    File format JPEG, PNG, PDF Size 15mb max
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                content="Submit"
                title="Submit"
                classes="primary-btn btn-lg w-50"
                // onClick={}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FutureInsurance;
