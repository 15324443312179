/* eslint-disable no-console */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

import axios from 'axios';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
// import { toast } from 'react-hot-toast';
import { toast } from 'react-toastify';
import BankList from '../bank_list_offline.json';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';
import logger from '../common/logger/CustomLogger';

configure({ enforceActions: 'always' });

type Message = {
  type: string;
  msg: any;
};

type WalletAccountDetails = {
  _id: string;
  userId: string;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  email: string;
  bvn: string;
  profilePicture: string | null;
  nextOfKinFullName: string;
  nextOfKinEmail: string | null;
  nextOfKinPhoneNumber: string | null;
  nextOfKinAddress: string | null;
  stateOfOrigin: string;
  nin: string;
  blacklist: boolean;
  doa: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

type Profile = {
  [key: string]: any;
};

type ObjectType = {
  [key: string]: any;
};

export enum PayoutAction {
  //(Primary wallet)
  PrimaryPayOut = 'Primary_payout',
  //(Sub wallet)
  SecondaryPayout = 'Secondary_payout',
}

type AccountDetails = {
  accountNo: string;
  accountBalance: string;
  accountId: string;
  client: string;
  clientId: string;
  savingsProductName: string;
};

export class WalletStore {
  loadingData = false;
  loadingWallet = false;
  submitting = false;
  changing = false;
  showModal = false;
  resending = false;
  gettingBanks = false;
  walletError: any = [];
  transactions: any = [];
  creditTransactions: any = [];
  debitTransactions: any = [];
  transaction = {};
  banks = [];
  seerbitBanks = [];
  wallet: any = {};
  multipleWallet: any = {};
  walletAccountDetails: WalletAccountDetails | null = null;
  billCategories: any[] = [];
  billCategory: ObjectType = {};
  billField: ObjectType = {};
  bankReceiverFullName = '';
  walletReceiverFullName = '';
  token: any = sessionStorage.getItem('accessToken') || '';
  urlParams: any = '';
  message: Message = {
    type: '',
    msg: '',
  };
  error = {
    type: 'error',
    msg: '',
  };
  success = {
    type: 'success',
    msg: '',
  };
  fullDetails: any[] = [];
  seerbitWalletBallance = '0.00';

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  // VfdAccountDetails States
  // getter
  vfdAccountDetails?: AccountDetails | null;
  vfdAccountBallance = '0.00';

  // setter
  setVfdAccountDetails(accountDetails: AccountDetails) {
    this.vfdAccountDetails = accountDetails;
  }

  setVfdAccountBallance(balance: string) {
    this.vfdAccountBallance = balance;
  }

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
    });
  }

  getVfdBalance(accountNumber: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/vfd-wallet-balance/${accountNumber}`,
        {
          headers,
        },
      )
      .then((res: any) => {
        runInAction(() => {
          const accountData = res.data?.data;
          logger.debug('accountData:', accountData);

          // Early return on error
          if (res.data?.status !== '00') {
            logger.debug('Unable to fetch VFD balance');
            return;
          }

          try {
            const accountData = toJS(res.data?.data);

            this.setVfdAccountDetails({ ...accountData });
            logger.debug(
              'this.setVfdAccountDetails:',
              toJS(this.vfdAccountDetails),
            );

            this.setVfdAccountBallance(accountData.accountBalance);
            logger.debug(
              'this.setVfdAccountBallance:',
              toJS(this.vfdAccountBallance),
            );

            // TODO:  Remove static price before movin g to prod
            const updatedWallets = this.multipleWallet.map(
              (wallet: { banker: string; accountBalance: any }) =>
                wallet.banker === 'VFD'
                  ? {
                      ...wallet,
                      availableBalance: accountData.accountBalance,
                    }
                  : wallet,
            );

            this.setMultipleWallet([...updatedWallets]);
            logger.debug(
              'Multiple wallets updated:',
              toJS(this.multipleWallet),
            );

            logger.debug(
              'VFD account details and balance successfully processed.',
            );
          } catch (error) {
            logger.debug('Error processing VFD account details:', error);
          }
        });
      })
      .catch((err) => {
        logger.error('Error fetching VFD balance:', err);
        this.setError('Error fetching balance');
      });
  }
  initializePayout(payoutAction: PayoutAction, callback: any = null) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
      'cf-cache-cred': this.token,
    };

    const payload = {
      actionItem: payoutAction,
    };

    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/init-payout`,
        { payoutAction },
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Initialise Payout | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success('Payout initialization request is being processed.');
        }
      })
      .catch((err) => {
        this.logger.error(
          `User | Initialise Payout | ${toJS(this.userProfile?.email)}`,
          err,
        );
      })
      .finally(() => {
        if (callback) callback();
      });
  }

  changeLimit(data: any, callback: any = null) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    // console.log('Mary Sending Limit Increase Request');
    // console.log(
    //   'Mary API URL:',
    //   `${BaseDirectories.API_BASE_URL}/users/user-limit-increase-request`,
    // );
    // console.log('Mary Payload Data:', data);

    runInAction(() => {
      this.setSubmitting(true);
    });
    this.setSubmitting(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/users/user-limit-increase-request`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        console.log('Mary Response:', res.data);
        this.logger.info(
          `User | Change limit | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.data.error) {
          toast.error(res.data.message);
          this.setMessage('error', 'limit change denied');
        } else {
          toast.success('Limit increase request is being processed.');
          this.setMessage('success', 'limit increase sucessful!');
          this.getWalletWithoutLoading();
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        console.error('Mary API Error:', err);
        this.logger.error(
          `User | Change limit | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(err.response?.data?.message);
        this.setMessage(
          'error',
          err.response?.data?.message || 'Limit increase failed!',
        );
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setSubmitting(false);
      })
      .finally(() => {
        if (callback) callback();
      });
  }

  transferToWallet(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    this.setSubmitting(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/internal-transfer`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Tranfer to wallet | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.data.error) {
          toast.error(res.data.message);
          this.setMessage('error', 'Transfer failed!');
        } else {
          toast.success(res.data.message);
          //   toast.success('Transfer successful!');
          this.setMessage('success', 'Transfer successful!');
          this.getWalletWithoutLoading();
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Tranfer to wallet | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(err.response?.data?.message);
        this.setMessage(
          'error',
          err.response?.data?.message || 'Transaction failed!',
        );
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setSubmitting(false);
      });
  }

  transferToBank(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.logger.info(`User | Tranfer to bank Data | ${data}`, data);
    logger.debug(`User | Tranfer to bank Data | ${data}`, data);

    this.setSubmitting(true);
    axios
      .post(`${BaseDirectories.API_BASE_URL}/loan-wallet/NIP-transfer`, data, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | Tranfer to bank | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.data.error) {
          toast.error(res.data.message);
          this.setMessage('error', 'Transfer failed!');
        } else {
          toast.success(res.data.message);
          //   toast.success('Transfer successful!');
          this.getWalletWithoutLoading();
          this.setMessage('success', 'Transfer successful!');
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Tranfer to bank | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(err.response.data.message);
        this.setMessage('error', err.response.data.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setSubmitting(false);
      });
  }

  getProvidusNipBanks() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    // this.setGettingBanks(true);
    this.setBanks(BankList);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/get-all-NIP-banks
      `,
        { headers },
      )
      .then((res: any) => {
        // this.setGettingBanks(false);
        if (res.data?.name === 'Error') {
          toast.error('unable to fetch banks');
          return;
        }
        this.setBanks(res.data);
        this.banks = res.data;
      })
      .catch((err) => {
        // this.setGettingBanks(false);
        this.setError('Error');
      });
  }

  verifyNipAccount(accountNumber: string, bankName: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/NIP-account-verification/${accountNumber}/?accountNumber=${accountNumber}&bankName=${bankName}`,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data?.error) {
          toast.error(res.data?.message || 'Unable to verify account');
          return;
        }
        runInAction(() => {
          this.bankReceiverFullName = res.data;
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  verifyWallet(accountNumber: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/verify-wallet/${accountNumber}/?accountNumber=${accountNumber}`,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error(res.data.message || 'Unable to verify account');
          return;
        }
        runInAction(() => {
          this.walletReceiverFullName = res.data;
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getWallet() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    this.setLoadingWallet(true);
    const profile = window.sessionStorage.getItem('user');
    const itemObject = profile ? JSON.parse(profile) : null;

    axios
      .get(`${BaseDirectories.API_BASE_URL}/loan-wallet/get-wallet`, {
        headers,
      })
      .then((res: any) => {
        if (res.data.error) {
          this.setWalletError(res.data);
          // toast.error(res.data.message);
        } else {
          this.setWalletError(null);
        }
        this.setWallet(res.data?.data?.wallet);

        const accountDetail: WalletAccountDetails =
          res.data?.data?.accountDetails;
        if (accountDetail) {
          this.setWalletAccountDetails(accountDetail);
        }
        this.setLoadingWallet(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Get Wallet | ${toJS(this.userProfile?.email)}`,
          err,
        );
      })
      .finally(() => {
        setTimeout(() => {
          this.setLoadingWallet(false);
        }, 5000);
      });
    // }
  }

  getWalletWithoutLoading() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    const profile = window.sessionStorage.getItem('user');
    const itemObject = profile ? JSON.parse(profile) : null;

    if (
      window.sessionStorage.getItem('userStatus') === 'true' &&
      itemObject?.title !== 'User | Get Profile | undefined'
    ) {
      if (this.token) {
        axios
          .get(`${BaseDirectories.API_BASE_URL}/loan-wallet/get-user-wallets`, {
            headers,
          })
          .then((res) => {
            if (res.status != 200 || res.data.error) {
              logger.error('Error fetching wallet data:', res.data.error);
            } else {
              const accountDetail: WalletAccountDetails =
                res.data?.data?.accountDetails;
              logger.debug('accountDetail: ', accountDetail);

              this.setWalletAccountDetails(accountDetail);
              logger.debug(
                'this.walletAccountDetails: ',
                this.walletAccountDetails,
              );

              // Convert Proxy to Plain Object
              const plainAccountDetail = { ...accountDetail };
              logger.debug('Plain accountDetail: ', plainAccountDetail);

              this.setMultipleWallet(res.data?.data?.wallet);
              logger.debug('accountDetailState: ', this.walletAccountDetails);
            }
          })
          .catch((err) => {
            logger.error('Error while making the request:', err);
          });
      }
    }
  }

  getAllTransactions() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    if (this.token) {
      this.setLoading(true);
      axios
        .get(`${BaseDirectories.API_BASE_URL}/loan-wallet/all-transactions`, {
          headers,
        })
        .then((res: any) => {
          this.setLoading(false);
          this.setTransactions(res.data.transactions);
          this.transactions = res.data.transactions;
        })
        .catch((err) => {
          this.setLoading(false);
          this.setError('Error');
        });
    }
  }

  getAllPaginatedTransactions(page: any, limit: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    if (this.token) {
      this.setLoading(true);
      axios
        .get(
          `${BaseDirectories.API_BASE_URL}/loan-wallet/paginated-all-transactions?page=${page}&limit=${limit}`,
          {
            headers,
          },
        )
        .then((res: any) => {
          this.setLoading(false);
          this.setTransactions(res.data.transactions);
          this.transactions = res.data.transactions;
        })
        .catch((err) => {
          this.setLoading(false);
          this.setError('Error');
        });
    }
  }

  getCreditTransactions(page: any, limit: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    if (this.token) {
      this.setLoading(true);
      axios
        .get(
          `${BaseDirectories.API_BASE_URL}/loan-wallet/paginated-credit-transactions?page=${page}&limit=${limit}`,
          {
            headers,
          },
        )
        .then((res: any) => {
          this.setLoading(false);
          this.setCreditTransactions(res.data.transactions);
          this.creditTransactions = res.data.transactions;
        })
        .catch((err) => {
          this.setLoading(false);
          this.setError('Error');
        });
    }
  }

  getDebitTransactions(page: any, limit: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    if (this.token) {
      this.setLoading(true);
      axios
        .get(
          `${BaseDirectories.API_BASE_URL}/loan-wallet/paginated-debit-transactions?page=${page}&limit=${limit}`,
          {
            headers,
          },
        )
        .then((res: any) => {
          this.setLoading(false);
          this.setDebitTransactions(res.data.transactions);
          this.debitTransactions = res.data.transactions;
        })
        .catch((err) => {
          this.setLoading(false);
          this.setError('Error');
        });
    }
  }
  getLeadOptions() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/investment/lead-options`, {
        headers,
      })
      .then((res: any) => {
        this.setLoading(false);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getAllBillCategories() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/bill-payment/get-all-bill-categories
      `,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data?.name === 'Error') {
          toast.error('unable to fetch bill categories');
          return;
        }
        this.setBillCategories(res.data.data);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getBillCategoryById(categoryId: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/bill-payment/get-one-bill-by-id/{categoryId}/?categoryId=${categoryId}
      `,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error('unable to fetch bill category');
          return;
        }
        this.setBillCategory(res.data.data);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getBillFieldById(billId: string | number, walletAccountNumber: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/bill-payment/get-bill-field-by-id/{billId}/?billId=${billId}&AccountNumber=${walletAccountNumber}
      `,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error(res.data.message || 'unable to fetch bill fields');
          return;
        }
        this.setBillField(res.data.data);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  makeBillPayment(data: { [key: string]: any }) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    this.setSubmitting(true);
    axios
      .post(`${BaseDirectories.API_BASE_URL}/bill-payment/makepayment`, data, {
        headers,
      })
      .then((res: any) => {
        this.setSubmitting(false);
        if (res.data?.error) {
          toast.error(res.data?.message || 'Unable to make payment.');
          this.logger.error(
            `User | Make bill payment | ${toJS(this.userProfile?.email)}`,
            res.data.message,
          );
          return;
        }
        this.logger.info(
          `User | Make bill payment | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        toast.success(res.data?.message || 'Payment successful.');
        this.getWalletWithoutLoading();
      })
      .catch((err) => {
        this.setSubmitting(false);
        this.logger.error(
          `User | Make bill payment | ${toJS(this.userProfile?.email)}`,
          err,
        );
        this.setMessage('error', err.response.data.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
      });
  }

  generateTransactionStatement(startDate: string, endDate: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/generate-transaction-statement/?startDate=${startDate}&endDate=${endDate}
      `,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          runInAction(() => {
            this.setMessage('error', res.data.message);
          });
          setTimeout(() => {
            runInAction(() => {
              this.setMessage('', '');
            });
          }, 6000);
          toast.error(res.data.message || 'unable to generate statement');
          return;
        }
        runInAction(() => {
          this.setMessage('success', res.data.message);
        });
        toast.success('Statement generated successfully, check your email.');
        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 6000);
      })
      .catch((err) => {
        this.setLoading(false);
        runInAction(() => {
          this.setMessage('error', err.response.data.message);
        });
        setTimeout(() => {
          runInAction(() => {
            this.setMessage('', '');
          });
        }, 6000);
      });
  }

  upgradeWallet(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    this.setSubmitting(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/upgrade-wallet`,
        data,
        {
          headers,
        },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Wallet name|type update | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          this.getWalletWithoutLoading();
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Wallet name|type update | ${toJS(this.userProfile?.email)}`,
          err,
        );
        toast.error(err.response?.data?.message);
        this.setSubmitting(false);
      });
  }

  verifyWalletNumber(accountNumber: string) {
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/verify-wallet-number/${accountNumber}/?accountNumber=${accountNumber}`,
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data.error) {
          toast.error('Unable to verify account');
          this.setMessage('error', 'Unable to verify account');
          setTimeout(() => {
            this.setMessage('', '');
          }, 3000);
          return;
        }
        this.setFullDetails(res.data);
        runInAction(() => {
          this.setMessage('success', 'Account verified successfully');
          this.fullDetails = res.data;
          setTimeout(() => {
            this.setMessage('', '');
          }, 3000);
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
        this.setMessage('error', err);
        setTimeout(() => {
          this.setMessage('', '');
        }, 3000);
      });
  }

  submitApplicationForm(data: any) {
    this.setLoading(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/save-application-data`,
        data,
      )
      .then((res: any) => {
        if (res.data.error) {
          toast.error(res.data.message);
          this.setMessage('error', 'Unable to process application');
          setTimeout(() => {
            this.setMessage('', '');
          }, 3000);
        } else {
          toast.success(res.data.message);
          this.setMessage('success', 'Application processed successfully');
          setTimeout(() => {
            this.setMessage('', '');
          }, 3000);
        }
        this.setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        this.setMessage('error', 'Unable to process application');
        setTimeout(() => {
          this.setMessage('', '');
        }, 3000);
        this.setLoading(false);
      });
  }

  //seerbit

  getAllWalletWithoutLoading() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    const profile = window.sessionStorage.getItem('user');
    const itemObject = profile ? JSON.parse(profile) : null;

    if (
      window.sessionStorage.getItem('userStatus') === 'true' &&
      itemObject?.title !== 'User | Get Profile | undefined'
    ) {
      if (this.token) {
        axios
          .get(`${BaseDirectories.API_BASE_URL}/loan-wallet/get-user-wallets`, {
            headers,
          })
          .then((res) => {
            if (res.data.error) {
              logger.error('Error fetching wallet data:', res.data.error);
            } else {
              const accountDetail: WalletAccountDetails =
                res.data?.data?.accountDetails;
              logger.debug('accountDetail: ', accountDetail);

              // Convert Proxy to Plain Object
              const plainAccountDetail = { ...accountDetail };
              logger.debug('Plain accountDetail: ', plainAccountDetail);

              this.setWalletAccountDetails(plainAccountDetail);

              this.setMultipleWallet(res.data?.data?.wallet);
              logger.debug('accountDetailState: ', this.walletAccountDetails);

              // TODO:  Remove static price before movin g to prod
              this.multipleWallet.map((wallet: any) => {
                this.getVfdBalance(wallet.walletIdAccountNumber);
                this.getSeerbitWalletBalance(wallet.pocketId);
              });
            }
          })
          .catch((err) => {
            logger.error('Error while making the request:', err);
          });
      }
    }
  }

  getSeerbitWalletBalance(data: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    const profile = window.sessionStorage.getItem('user');
    const itemObject = profile ? JSON.parse(profile) : null;
    if (
      window.sessionStorage.getItem('userStatus') == 'true' &&
      itemObject?.title !== 'User | Get Seerbit Wallet balance | undefined'
    ) {
      if (this.token) {
        axios
          .get(
            `${BaseDirectories.API_BASE_URL}/loan-wallet/seerbit-wallet-balance?pocketId=${data}`,
            {
              headers,
            },
          )
          .then((res: any) => {
            this.setSeerBitWalletBalance(res.data.data.availableBalanceAmount);

            // TODO:  Remove static price before movin g to prod
            const updatedWallets = this.multipleWallet.map(
              (wallet: { banker: string; accountBalance: any }) =>
                wallet.banker === '9PSB' ||
                wallet.banker === '9 Payment Service Bank'
                  ? {
                      ...wallet,
                      availableBalance: res.data.data.availableBalanceAmount,
                    }
                  : wallet,
            );

            this.setMultipleWallet([...updatedWallets]);

            logger.debug(
              'Multiple wallets updated:',
              toJS(this.multipleWallet),
            );

            logger.debug(
              '9PSB account details and balance successfully processed.',
            );
          })

          .catch((err) => {
            // this.setError('Error');
          });
      }
    }
  }

  getSeerbitNipBanks() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setGettingBanks(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/get-seerbit-banks-list
      `,
        { headers },
      )
      .then((res: any) => {
        this.setGettingBanks(false);
        if (res.data?.name === 'Error') {
          toast.error('unable to fetch banks');
          return;
        }
        const bankList: any = [];
        res.data?.data.forEach((bank: any) => {
          bankList.push({
            NIPCode: bank.bankcode,
            bankName: bank.bankname,
          });
        });
        this.setBanks(bankList);
        this.banks = bankList;
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  verifySeerbitNipAccount(accountNumber: string, bankName: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };
    this.setLoading(true);
    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/loan-wallet/NIP-account-verification-seerbit/${accountNumber}/?accountNumber=${accountNumber}&bankName=${bankName}`,
        { headers },
      )
      .then((res: any) => {
        this.setLoading(false);
        if (res.data?.error) {
          toast.error(res.data?.message || 'Unable to verify account');
          return;
        }
        runInAction(() => {
          this.bankReceiverFullName = res.data;
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getSecondWallet() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      'cf-cache-status-check': BaseDirectories.FCP,
    };

    this.setLoadingWallet(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/loan-wallet/get-second-wallet`, {
        headers,
      })
      .then((res: any) => {
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          //
        }
        this.getWalletWithoutLoading();
        this.getAllWalletWithoutLoading();
      })
      .catch((err) => {
        this.logger.error(
          `User | Create Second Wallet | ${toJS(this.userProfile?.email)}`,
          err,
        );
      })
      .finally(() => {
        setTimeout(() => {
          this.setLoadingWallet(false);
        }, 3000);
      });
    // }
  }

  setFullDetails = (res: any) => {
    this.fullDetails = res;
  };

  setWalletAccountDetails = (res: WalletAccountDetails) => {
    this.walletAccountDetails = res;
  };

  setBillCategory = (res: any) => {
    this.billCategory = res;
  };

  setBillField = (res: any) => {
    this.billField = res;
  };

  setBillCategories = (res: any) => {
    this.billCategories = res;
  };

  setWallet = (res: any) => {
    this.wallet = res;
  };

  setMultipleWallet = (res: any) => {
    this.multipleWallet = res;
  };

  setBanks = (res: any) => {
    this.banks = res;
  };

  setSeerbitBanks = (res: any) => {
    this.seerbitBanks = res;
  };

  setTransactions = (res: any) => {
    this.transactions = res;
  };

  setCreditTransactions = (res: any) => {
    this.creditTransactions = res;
  };

  setDebitTransactions = (res: any) => {
    this.debitTransactions = res;
  };

  setTransaction = (res: any) => {
    this.transaction = res;
  };

  setLoading = (val: boolean) => {
    this.loadingData = val;
  };
  setLoadingWallet = (val: boolean) => {
    this.loadingWallet = val;
  };

  setWalletError = (val: any) => {
    this.walletError = val;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setGettingBanks = (val: boolean) => {
    this.gettingBanks = val;
  };

  setResending = (val: boolean) => {
    this.resending = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setError = (err: string) => {
    this.error.msg = err;
  };

  setSuccess = (msg: string) => {
    this.success.msg = msg;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setToLocalStorage = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  clearFromLocalStorage = () => {
    window.sessionStorage.clear();
  };

  setSeerBitWalletBalance = (res: any) => {
    this.seerbitWalletBallance = res;
  };
}
