export interface BeneficiaryNIPAccountData {
  status: string;
  message: string;
  data: {
    name: string;
    clientId: string;
    bvn: string | null; // Assuming BVN can be empty or null
    account: {
      id: string;
      number: string;
    };
    status: string;
    currency: string;
    bank: string;
  };
}

export interface SenderNIPAccountData {
  status: string;
  message: string;
  data: {
    accountNo: string;
    accountBalance: string;
    accountId: string;
    client: string;
    clientId: string;
    savingsProductName: string;
    bvn: string | null; // Assuming BVN can be empty or null
  };
}

export interface InterResponse {
  status: string;
  message: string;
  data: {
    txnId: string;
    sessionId: string;
    reference: string;
  };
}

export interface IntraResponse {
  status: string;
  message: string;
  data: {
    txnId: string;
  };
}

export interface FailedTransactionResponse {
  status: string;
  message: string;
  data: {
    txnId: string;
  };
}

export interface SignatureMismatchResponse {
  status: string;
  message: string;
}

export interface InvalidSourceAccountResponse {
  status: string;
  message: string;
  data: {
    txnId: string;
  };
}

export interface InvalidSourceResponse {
  status: string;
  message: string;
  data: {
    txnId: string;
  };
}

export interface InvalidSenderResponse {
  status: string;
  message: string;
}

export interface Bank {
  id: number;
  code: string;
  name: string;
  logo: string;
  created: string;
}

export interface AllBanksResponse {
  status: string;
  message: string;
  data: Bank[];
}

export interface TransferDto {
  fromAccount: string;
  uniqueSenderAccountId: string;
  fromClientId: string;
  fromClient: string;
  fromSavingsId: string;
  fromBvn: string;
  toClientId: string | number; // Can be NaN, so using string | number
  toClient: string;
  toSavingsId: string;
  toSession: string;
  toBvn: string;
  toAccount: string;
  toBank: string;
  signature: string;
  amount: string | number; // Can be numeric, but represented as string in some cases
  remark: string;
  transferType: string;
  reference: string;
  metaData?: {
    userId: string;
    transactionPin: string;
  };
}

export interface VfdAccountEnquiryDto {
  accountnumber: string;
  bankcode: string;
  transfer_type: string;
}

export enum TransferType {
  Intra = 'intra',
  Inter = 'inter',
}
